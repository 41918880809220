export default function DataStatusTool(props) {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>
              <div>Morning cuff:</div>
            </td>
            <td>
              <strong>Unknown</strong>
            </td>
          </tr>
          <tr>
            <td>
              <div>Afternoon cuff:</div>
            </td>
            <td>
              <strong>Unknown</strong>
            </td>
          </tr>
          <tr>
            <td>
              <div>Evening cuff:</div>
            </td>
            <td>
              <strong>Unknown</strong>
            </td>
          </tr>
          <tr>
            <td>
              <div>Wearing Time:</div>
            </td>
            <td>
              <strong>Unknown</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
