import React, {useState} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

export default function UpdateNoteForm(props) {
    const [text, setText] = useState(props.text == undefined ? '' : props.text);
    const {
        onSave = d => {

        },
        buttonName = 'Save',
        loading = false
    } = props;

    return (
        <Wrapper>

            <Textarea value={text}
                      placeholder={'Your comment'}
                      onChange={evt => {
                          setText(evt.target.value);
                      }}/>

            <ButtonsPlaceholder>
                <Button onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    onSave({
                        text: text
                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : buttonName}
                </Button>
            </ButtonsPlaceholder>

        </Wrapper>
    );
}

const ButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  min-height: 6em;
  outline: none;

  color: #8798AD;
  font-size: 16px;
  line-height: 28px;

  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  border-radius: 6px;
  
  :focus {
    border-color: blue;
  }
`;

const Wrapper = styled.div`

`;

const Button = styled.div`
  width: 200px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const InputPlaceholder = styled.div`
  flex: 1;
  margin-right: 30px;
`;