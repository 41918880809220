export const DARK_GREEN = "#31777F";
export const LIGHT_GREEN = "#6D9CA2";
export const LIGHT_BLUE = "#1EB2FC";
export const RED = "#C35A21";

// export const API_ENDPOINT = process.env.REACT_APP_API_URL || 'https://api.prod.cloud1.sa.cardiomood.com';
export const API_ENDPOINT = process.env.REACT_APP_API_URL || 'https://api.calc.swiss.cardiomood.com';
export const CORSANO_API_ENDPOINT = process.env.REACT_APP_API_URL || 'https://api.study-integration.corsano.com';

export const RONAS_DARK_GREY = "#191A21";
export const RONAS_DARK_GREEN = "#044000";
export const RONAS_LIGHT_GREEN = "#0C6102";
export const RONAS_RED = "#C90303";
export const RONAS_BLUE_MOUSE_OVER = "#1988E3";

export const getColByVal = (perc) => {
    if (perc == undefined) {
        return RONAS_RED;
    }
    console.log("getColByVal: perc = ", perc);
    if (perc > 90) {
        return RONAS_DARK_GREEN;
    }
    if (perc > 75) {
        return RONAS_LIGHT_GREEN;
    }
    if (perc > 60) {
        return RONAS_BLUE_MOUSE_OVER;
    }
    return RONAS_RED;
    
};


export const DEFAULT_DAILY_STEPS = 10000; // steps
export const DEFAULT_DAILY_DISTANCE = 8000; // meters
export const DEFAULT_DAILY_CALORIES = 600; // Cal
export const DEFAULT_DAILY_ACTIVE_MINUTES = 60; // Minutes

export const DEFAULT_SLEEP_NEED_DURATION = 8 * 60; // Minutes

export const MAX_WAKEUPS_NUMBER = 15;

export const DEMO_FAKE_USERS = [
    {
        isDemo: true,
        agreement_to_terms_of_use: null,
        avatar_id: null,
        birthday: "1984-04-07",
        code: null,
        country: null,
        created_at: "2021-05-31 05:51:31",
        customer_code: null,
        customer_tag: "demo",
        email: "rober-mason@gmail.com",
        first_name: "Robert",
        gender: "male",
        is_from_old_db: false,
        last_name: "Mason",
        preventicus_b2b_study_center: null,
        role: "user",
        state: "confirmed",
        updated_at: "2021-05-31 06:35:27",
        uuid: "demo1_c1ab080b-058a-4ada-958c-b816468ba2ee",

        healthUser: {
            weight: 72,
            height: 178,
            gender: "male",
        },

        respiration_rate: 13 + Math.floor(15 * Math.random()),
        bpm: 100 + Math.floor(70 * Math.random()),
        spo2: 96 + Math.floor(4 * Math.random()),
        blood_pressure_up: 90 + Math.floor(30 * Math.random()),
        blood_pressure_down: 60 + Math.floor(30 * Math.random()),
        total_steps: 1000 + Math.floor(10000 * Math.random()),

        sleep_duration: 6.6 * 60 * 60,

        temperature: 36.5,

        raw_activities: [
            {
                respiration_rate: 13 + Math.floor(15 * Math.random()),
                bpm: 100 + Math.floor(70 * Math.random()),
                saturation: 96 + Math.floor(4 * Math.random()),
                blood_pressure_up: 90 + Math.floor(30 * Math.random()),
                blood_pressure_down: 60 + Math.floor(30 * Math.random()),
                steps_number: 1000 + Math.floor(10000 * Math.random()),
            },
        ],
        raw_temperatures: [
            {
                temp_sk1: +(34 + 2 * Math.random()).toFixed(1),
            },
        ],
    },
    {
        isDemo: true,
        agreement_to_terms_of_use: null,
        avatar_id: null,
        birthday: "1984-04-07",
        code: null,
        country: null,
        created_at: "2021-05-31 05:51:31",
        customer_code: null,
        customer_tag: "demo",
        email: "Gilbert-Johanson@gmail.com",
        first_name: "Gilbert",
        gender: "male",
        is_from_old_db: false,
        last_name: "Johanson",
        preventicus_b2b_study_center: null,
        role: "user",
        state: "confirmed",
        updated_at: "2021-05-31 06:35:27",
        uuid: "demo2_c1ab080b-058a-4ada-958c-b816468ba2ee",

        healthUser: {
            weight: 72,
            height: 178,
            gender: "male",
        },

        respiration_rate: 13 + Math.floor(15 * Math.random()),
        bpm: 55 + Math.floor(30 * Math.random()),
        spo2: 96 + Math.floor(4 * Math.random()),
        blood_pressure_up: 90 + Math.floor(30 * Math.random()),
        blood_pressure_down: 60 + Math.floor(30 * Math.random()),
        total_steps: 1000 + Math.floor(10000 * Math.random()),

        sleep_duration: 8.15 * 60 * 60,

        temperature: 36.7,

        raw_activities: [
            {
                respiration_rate: 13 + Math.floor(15 * Math.random()),
                bpm: 55 + Math.floor(30 * Math.random()),
                saturation: 96 + Math.floor(4 * Math.random()),
                blood_pressure_up: 90 + Math.floor(30 * Math.random()),
                blood_pressure_down: 60 + Math.floor(30 * Math.random()),
                steps_number: 1000 + Math.floor(10000 * Math.random()),
            },
        ],
        raw_temperatures: [
            {
                temp_sk1: +(34 + 2 * Math.random()).toFixed(1),
            },
        ],
    },
    {
        isDemo: true,
        agreement_to_terms_of_use: null,
        avatar_id: null,
        birthday: "1984-04-07",
        code: null,
        country: null,
        created_at: "2021-05-31 05:51:31",
        customer_code: null,
        customer_tag: "demo",
        email: "Anselmo-Ricci@gmail.com",
        first_name: "Anselmo",
        gender: "male",
        is_from_old_db: false,
        last_name: "Ricci",
        preventicus_b2b_study_center: null,
        role: "user",
        state: "confirmed",
        updated_at: "2021-05-31 06:35:27",
        uuid: "demo3_c1ab080b-058a-4ada-958c-b816468ba2ee",

        healthUser: {
            weight: 72,
            height: 178,
            gender: "male",
        },

        respiration_rate: 13 + Math.floor(15 * Math.random()),
        bpm: 55 + Math.floor(30 * Math.random()),
        spo2: 85 + Math.floor(10 * Math.random()),
        blood_pressure_up: 90 + Math.floor(30 * Math.random()),
        blood_pressure_down: 60 + Math.floor(30 * Math.random()),
        total_steps: 1000 + Math.floor(1000 * Math.random()),

        sleep_duration: 9.1 * 60 * 60,
        temperature: 36.6,

        raw_activities: [
            {
                respiration_rate: 13 + Math.floor(15 * Math.random()),
                bpm: 55 + Math.floor(30 * Math.random()),
                saturation: 85 + Math.floor(10 * Math.random()),
                blood_pressure_up: 90 + Math.floor(30 * Math.random()),
                blood_pressure_down: 60 + Math.floor(30 * Math.random()),
                steps_number: 1000 + Math.floor(1000 * Math.random()),
            },
        ],
        raw_temperatures: [
            {
                temp_sk1: +(34 + 2 * Math.random()).toFixed(1),
            },
        ],
    },
    {
        isDemo: true,
        agreement_to_terms_of_use: null,
        avatar_id: null,
        birthday: "1984-04-07",
        code: null,
        country: null,
        created_at: "2021-05-31 05:51:31",
        customer_code: null,
        customer_tag: "demo",
        email: "Daniel-Olsen@gmail.com",
        first_name: "Daniel",
        gender: "male",
        is_from_old_db: false,
        last_name: "Olsen",
        preventicus_b2b_study_center: null,
        role: "user",
        state: "confirmed",
        updated_at: "2021-05-31 06:35:27",
        uuid: "demo4_c1ab080b-058a-4ada-958c-b816468ba2ee",

        healthUser: {
            weight: 72,
            height: 178,
            gender: "male",
        },

        respiration_rate: 13 + Math.floor(15 * Math.random()),
        bpm: 55 + Math.floor(30 * Math.random()),
        spo2: 96 + Math.floor(4 * Math.random()),
        blood_pressure_up: 90 + Math.floor(30 * Math.random()),
        blood_pressure_down: 60 + Math.floor(30 * Math.random()),
        total_steps: 1000 + Math.floor(10000 * Math.random()),

        sleep_duration: 5.4 * 60 * 60,

        temperature: 36.6,

        raw_activities: [
            {
                respiration_rate: 13 + Math.floor(15 * Math.random()),
                bpm: 55 + Math.floor(30 * Math.random()),
                saturation: 96 + Math.floor(4 * Math.random()),
                blood_pressure_up: 90 + Math.floor(30 * Math.random()),
                blood_pressure_down: 60 + Math.floor(30 * Math.random()),
                steps_number: 1000 + Math.floor(10000 * Math.random()),
            },
        ],
        raw_temperatures: [
            {
                temp_sk1: +(34 + 2 * Math.random()).toFixed(1),
            },
        ],
    },
    {
        isDemo: true,
        agreement_to_terms_of_use: null,
        avatar_id: null,
        birthday: "1984-04-07",
        code: null,
        country: null,
        created_at: "2021-05-31 05:51:31",
        customer_code: null,
        customer_tag: "demo",
        email: "Stefan-Schmidt@gmail.com",
        first_name: "Stefan",
        gender: "male",
        is_from_old_db: false,
        last_name: "Schmidt",
        preventicus_b2b_study_center: null,
        role: "user",
        state: "confirmed",
        updated_at: "2021-05-31 06:35:27",
        uuid: "demo5_demo1_c1ab080b-058a-4ada-958c-b816468ba2ee",

        healthUser: {
            weight: 72,
            height: 178,
            gender: "male",
        },

        respiration_rate: 13 + Math.floor(15 * Math.random()),
        bpm: 55 + Math.floor(30 * Math.random()),
        spo2: 96 + Math.floor(4 * Math.random()),
        blood_pressure_up: 90 + Math.floor(30 * Math.random()),
        blood_pressure_down: 60 + Math.floor(30 * Math.random()),
        total_steps: 1000 + Math.floor(10000 * Math.random()),

        sleep_duration: 7.1 * 60 * 60,

        temperature: 36.6,

        raw_activities: [
            {
                respiration_rate: 13 + Math.floor(15 * Math.random()),
                bpm: 55 + Math.floor(30 * Math.random()),
                saturation: 96 + Math.floor(4 * Math.random()),
                blood_pressure_up: 90 + Math.floor(30 * Math.random()),
                blood_pressure_down: 60 + Math.floor(30 * Math.random()),
                steps_number: 1000 + Math.floor(10000 * Math.random()),
            },
        ],
        raw_temperatures: [
            {
                temp_sk1: +(34.0 + 2 * Math.random()).toFixed(1),
            },
        ],
    },
];
