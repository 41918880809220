import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {LineChart, XAxis, YAxis, CartesianGrid, Line} from 'recharts'

export default function QrsChartTool(props) {
    const {
        points = [],
        offset = 0
    } = props;
    let data = points.map((a, i) => ({v: a, number: +offset + +i}))

    return (
        <Wrapper>

            <LineChart width={900} height={380} data={data} connectNulls={true}
                       margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                <XAxis dataKey="number" tickCount={25}
                />
                <YAxis
                    domain={[`dataMax * 0.8`, 'dataMax * 1.2']}
                />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                <Line type="monotone" dataKey={'v'} stroke="#D92A3E" strokeWidth={2} animationDuration={1}
                      dot={false}/>
            </LineChart>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;