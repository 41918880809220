import { useEffect, useState } from "react";
import DoctorAPI from "../../../api/DoctorAPI";
import {
  mergeReadiness,
  getFromToFromOption,
  getPointsFromOption,
} from "./TrendsUtils";
import SimpleTrendsChartTool from "./SimpleTrendsChartTool";
import styled from "styled-components";

export default function VitalTrendsChartTool(props) {
  const { uuid, dataType, points = [] } = props;
  // const dataType = "pulse_rate";
  const [selectedOption, setSelectedRangeOption] = useState("M");
  // const [lightSummaryInRange, setLightSummaryInRange] = useState([])
  const [vitalSummaryInRange, setVitalSummaryInRange] = useState(points || []);
  const [loading, setLoading] = useState(false);

  const onChangeSelectedOption = (newOption) => {
    setSelectedRangeOption(newOption);
  };

  // console.log(points);

  useEffect(() => {
    // console.log("VITALTRENDS", points);
    // if (points.length > 0) {
    //   return;
    // }
    let pointsWithinRange = getPointsFromOption(points, selectedOption);
    let d_ = mergeReadiness(pointsWithinRange);
    let _type = dataType;
    if (dataType === "pulse_rate") {
      _type = "heart_rate";
    }
    let _arr = d_.map((x) => ({ date: x.date, ...x[_type] }));
    setVitalSummaryInRange(_arr);
  }, [points, uuid, dataType]);

  useEffect(() => {
    if (uuid == undefined) {
      return;
    }
    // console.log(points);
    let pointsWithinRange = getPointsFromOption(points, selectedOption);
    // console.log("pointsWithinRange", pointsWithinRange);
    let d_ = mergeReadiness(pointsWithinRange);
    let _type = dataType;
    if (dataType === "pulse_rate") {
      _type = "heart_rate";
    }
    let _arr = d_.map((x) => ({ date: x.date, ...x[_type] }));
    setVitalSummaryInRange(_arr);

    // const { from, to } = getFromToFromOption(selectedOption);
    // setLoading(true);
    // setVitalSummaryInRange([]);
    // setLoading(true);

    // DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false).then((d) => {
    //   setLoading(false);
    //   let d_ = mergeReadiness(d);
    //   let _type = dataType;
    //   if (dataType === "pulse_rate") {
    //     _type = "heart_rate";
    //   }
    //   let _arr = d_.map((x) => ({ date: x.date, ...x[_type] }));
    //   setVitalSummaryInRange(_arr);
    // });
  }, [selectedOption]);

  return (
    <Wrapper>
      <SimpleTrendsChartTool
        {...props}
        // dataType={"heart_rate"}
        dataType={dataType}
        loading={loading}
        summaries={vitalSummaryInRange}
        selectedOption={selectedOption}
        onChangeSelectedOption={onChangeSelectedOption}
      />
      <span
        style={{ fontSize: 14, fontWeight: 500, top: -14, osition: "relative" }}
      >
        Average Daily {dataTypeMap[dataType]}
      </span>
    </Wrapper>
  );
}

const dataTypeMap = {
  respiration_rate: "Respiration Rate",
  spo2: "SpO2",
  temperature: "Temperature",
  pulse_rate: "Pulse Rate",
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  display: block;
`;
