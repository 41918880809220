import styled, { css } from "styled-components";

const Textarea = styled.textarea`
  ${(props) => {
    switch (props.$mode) {
      case "mode1":
        return css`
          background: #ffffff;
          border: 1px solid rgba(135, 152, 173, 0.1);
          border-radius: 6px;
          outline: none;
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          line-height: 18px;
          padding-left: 7px;
          min-height: 6em;
          padding-top: 10px;
          ::placeholder {
            color: #8798ad;
            opacity: 0.5;
          }
        `;
      case "test":
        return css`
          border: 4px solid whitesmoke;
          width: 10%;
        `;
      default:
        return css`
          border: 4px solid whitesmoke;
          width: 100%;
          box-sizing: border-box;
          min-height: 6em;
        `;
    }
  }}
`;

export default Textarea;
