import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import MmtTemplate from '../templates/MmtTemplate'
import SleepPanel from "../sleep/panels/SleepPanel";

function SleepApp() {

    return (
        <MmtTemplate active={'sleep'}>

            <Wrapper>
                <SleepPanel/>
            </Wrapper>

        </MmtTemplate>
    );
}

const Wrapper = styled.div`

`;

export default SleepApp
