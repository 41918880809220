import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function ProgressTool(props) {
    const {
        leftText = 'left Name', rightText = '', percent = 0, color = 'red',
        barHeight = 10

    } = props;

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopLeftText>
                    {leftText == '' ? null : leftText}
                </TopLeftText>
                <TopRightText>
                    {rightText == '' ? null : rightText}
                </TopRightText>
            </TopPlaceholder>

            <BarPlaceholder barHeight={barHeight}>
                <Bar color={color} percent={Math.min(percent, 100)}/>
            </BarPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
`;

const TopLeftText = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const TopRightText = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const Bar = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: ${props => (props.percent)}%;
  background: ${props => props.color};
  border-radius: 4px;
  transition-duration: 1s;
`;

const BarPlaceholder = styled.div`
  position: relative;
  height: ${props => (props.barHeight == undefined ? 20 : props.barHeight)}px;
  border-radius: 5px;
  background: lightgrey;
  width: 100%;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;