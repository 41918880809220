import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Button} from "../panels/StudySessionsPanel";



export default function SessionExportTool(props) {
    const {
        from, to
    } = props;

    return (
        <Wrapper>

            <Heading>
                {`${moment(from).format('YYYY-MM-DD HH:mm:ss')} - ${moment(to).format('YYYY-MM-DD HH:mm:ss')}`}
            </Heading>

            <ContentPlaceholder>
                <Button>
                    Export
                </Button>
            </ContentPlaceholder>


        </Wrapper>
    );
}

const Heading = styled.div`
    
`;

const ContentPlaceholder = styled.div`
    margin-top: 10px;
`;

const Wrapper = styled.div`
    
`;