import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import moment from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";

import DoctorAPI from "../../../api/DoctorAPI";
import CommonHelper from "../../../helpers/CommonHelper";

export default function PatientSessionsPanel(props) {
  const {
    id,
    doctorName = "",
    isDemo = false,
    dayTimestamp,
    sessionMode = "current-day",
    theme,
  } = props;

  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);
  let from = +moment(dayTimestamp).startOf("day");
  let to = +moment(dayTimestamp).endOf("day");

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getPatientSessions(id, isDemo).then((arr) => {
      setSessions(arr);
      setLoading(false);
    });
  }, [id]);

  if (sessions.length == 0 && loading == true) {
    return (
      <div>
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }

  let filteredSessions = sessions.filter(
    (x) => +x.timestamp_from >= +from && +x.timestamp_from < +to
  );
//   console.log("sessions", sessions);

  // let filteredSessions = sessions;
  let sessionsToUse = sessions;
  if (sessionMode === "current-day") {
    sessionsToUse = filteredSessions;
  }

  return (
    <Wrapper>
      <SessionsHeading>
        <Num>
          <BoldSpan>{`№`}</BoldSpan>
        </Num>
        <Start>
          <BoldSpan>{`Start`}</BoldSpan>
        </Start>
        <Stop>
          <BoldSpan>{`Stop`}</BoldSpan>
        </Stop>
        <Status>
          <BoldSpan>
            {/* <BoldSpan style={{color: '#000F4B'}}> */}
            {`Status`}
          </BoldSpan>
        </Status>
        <Description>
          <BoldSpan>{"Description"}</BoldSpan>
        </Description>
      </SessionsHeading>

      <SessionsListPlaceholder>
        {sessionsToUse.map((s, i) => {
          let {
            timestamp_from,
            timestamp_to,
            description,
            name,
            status,
            session_code,
          } = s;
          let isInProgress =
            timestamp_to == undefined || timestamp_from == undefined;
          let isUploaded = status == "uploaded";

          return (
            <SessionItem
              className="session-item"
              key={`${s._id}_${i}`}
              onClick={() => {
                if (isDemo == true) {
                  window.alert(
                    "This is a demo. For full access please contact admin."
                  );
                  return;
                }
                // CommonHelper.linkTo(`/user/${id}/session/${s._id}`, true);
                CommonHelper.linkTo(`/user/${id}/session/${s._id}`, false);
              }}
            >
              <Num>{session_code == undefined ? "-" : session_code}</Num>
              <Start>
                {timestamp_from == undefined
                  ? ""
                  : moment(timestamp_from).format("YYYY-MM-DD | HH:mm")}
              </Start>
              <Stop>
                {timestamp_to == undefined
                  ? ""
                  : moment(timestamp_to).format("YYYY-MM-DD | HH:mm")}
              </Stop>
              <Status inProgress={isInProgress} isUploaded={isUploaded}>
                <span style={{ opacity: 0.7 }}>
                  {isInProgress == true
                    ? "In progress..."
                    : status == "uploaded"
                    ? "Uploaded"
                    : "Finished"}
                </span>
              </Status>
              <Description>
                {name == undefined
                  ? ""
                  : `${name}${description == undefined ? "" : " | "}`}
                {description == undefined ? "" : `${description}`}
              </Description>
            </SessionItem>
          );
        })}
      </SessionsListPlaceholder>
    </Wrapper>
  );
}

const SessionItem = styled.div`
  margin-bottom: 2px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  color: #8798ad;
  font-size: 14px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const SessionsListPlaceholder = styled.div`
  margin-bottom: 20px;
`;

const Description = styled.div`
  flex: 1;
`;

const Status = styled.div`
  width: 140px;
  margin-right: 20px;
  color: ${(props) =>
    props.inProgress == true
      ? "#147AFF"
      : props.isUploaded == true
      ? "#01C2A9"
      : "#8798AD"};
`;

const Start = styled.div`
  width: 140px;
  margin-right: 40px;
`;

const Stop = styled.div`
  width: 140px;
  margin-right: 40px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const SessionsHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000f4b;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 15px;
`;

const Num = styled.div`
  width: 40px;
  padding-left: 4px;
  margin-right: 10px;
`;

const Wrapper = styled.div``;

const ModeSwitcherItem = styled.div`
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  margin-left: 5px;
  margin-right: 5px;
`;
