import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import CardioHelper from "../../../helpers/CardioHelper";
import SimpleSelectableLineChart from "../../charts/tools/SimpleSelectableLineChart";

export default function ArtifactsViewer(props) {
    const {points = []} = props;
    const artifactsIndices = CardioHelper.getArtifactsIndices(points);
    let windowSize = 20;
    let hw = Math.floor(windowSize / 2);

    let batches = artifactsIndices.map(i => {
        let from = Math.max(0, i - hw);
        let to = Math.min(points.length, i + hw);
        let arr = points.slice(from, to);
        let p = points[i];
        return {
            p: p,
            neighbours: arr
        }
    });

    return (
        <Wrapper>
            <BatchesList>
                {batches.map((a, i) => {
                    let {p, neighbours} = a;
                    let refDot = {...p, value: p.rr};
                    return (
                        <BatchItem key={`${p.t}_${i}`}>
                            <TimePlaceholder>
                                {moment(p.t).format('HH:mm:ss')}
                            </TimePlaceholder>
                            <ChartPlaceholder>
                                <SimpleSelectableLineChart
                                    name={`selected_chart_${a.t}`}
                                    points={neighbours.map(a => ({...a, value: a.rr}))}
                                    referenceDot={refDot}
                                    hasOverlay={false}
                                />
                            </ChartPlaceholder>
                        </BatchItem>
                    )
                })}
            </BatchesList>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const BatchesList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const BatchItem = styled.div`
    padding: 5px;
    background: white;
    border: 1px solid whitesmoke;
    border-radius: 3px;
    margin: 5px;
`;

const TimePlaceholder = styled.div`
    
`;

const ChartPlaceholder = styled.div`
    width: 200px;
    height: 120px;
`;
