import styled from "styled-components";
import ExportHrvValuesPanel from "../research/melokura/ExportHrvValuesPanel";

export default function MelokuraApp(props) {
  return (
    <Wrapper className="melokura">
      {/*<InnerPlaceholder>*/}

      {/*</InnerPlaceholder>*/}

      <ExportHrvValuesPanel />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 15px;
`;

const InnerPlaceholder = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  width: 720px;
`;
