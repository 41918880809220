import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import todayImg from '../../../assets/images/today_icon.svg'
import backImg from '../../../assets/images/back.svg'

export default function InlineDateSelector(props) {
    const {
        loading = false,
        selectedTimestamp = undefined,
        onDaySelect = tt => {

        }
    } = props;

    return (
        <Wrapper>

            {loading == false ? null :
                <span style={{marginRight: 10}}>
                    <Spin visible={loading}/>
                </span>
            }

            <RangeTodayImg loading={loading} src={todayImg} onClick={() => {
                if (loading == true) {
                    return;
                }
                onDaySelect(+moment().startOf('day'));
            }}/>

            <RangeSideImg src={backImg} loading={loading} onClick={() => {
                let newT = +moment(selectedTimestamp).add(-1, 'days');
                if (loading == true) {
                    return;
                }
                onDaySelect(newT);
            }}/>

            <RangeCenterPlaceholder>
                {moment(selectedTimestamp).format('YYYY MMMM, D')}
            </RangeCenterPlaceholder>

            <RangeSideImg src={backImg} loading={loading} style={{transform: 'rotate(180deg)'}} onClick={() => {
                let newT = +moment(selectedTimestamp).add(1, 'days');
                if (loading == true) {
                    return;
                }
                onDaySelect(newT);
            }}/>

        </Wrapper>
    );
}

const RangeCenterPlaceholder = styled.div`
  width: ${props => (props.narrow == true ? 140 : (props.veryNarrow == true ? 60 : 170))}px;
  text-align: center;
  font-size: 14px;
`;

const RangeSideImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: ${props => (props.loading == true ? 'default' : 'pointer')};
  opacity: 0.5;

  :hover {
    opacity: ${props => (props.loading == true ? 0.5 : 1)};
  }
`;

const RangeTodayImg = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  opacity: 0.2;
  margin-right: 20px;

  :hover {
    opacity: 0.8;
  }
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TodayCurrDateImg = styled.img`
  margin-right: 25px;
`;