import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import SleepAPI from '../../../api/SleepAPI'

import CommonHelper from "../../../helpers/CommonHelper";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as actions from "../../../redux/actions/DataActions";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import SleepViewTool from "../tools/SleepViewTool";
import RawSleepMetricsTableTool from "../tools/RawSleepMetricsTableTool";

export default function RawDevSleepPanel(props) {
    let {currentUserObj, loading, updatedTimestamp, selectedTimestamp} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading || state.data.loading,
            updatedTimestamp: state.data.updatedTimestamp,
            selectedTimestamp: state.data.selectedTimestamp
        }
    }, []));
    const dispatch = useDispatch();
    const [fetching, setFetching] = useState(false);
    const [slots, setSlots] = useState([]);
    const [metrics, setMetrics] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (loading == true || fetching == true || selectedTimestamp == undefined) {
            return;
        }
        setMetrics([]);
        setFetching(true);
        SleepAPI.getInstance().getRawSleepMetrics(selectedTimestamp).then(rawMetrics => {
            console.log('--->>> RAW METRICS = ', rawMetrics);
            setMetrics(rawMetrics);
            let transformedSleepMetrics = TarnsformerHelper.getSleepPointsFromRawSleepPoints(rawMetrics);
            console.log('transformed metrics = ', transformedSleepMetrics);
            setSlots(transformedSleepMetrics);
            setFetching(false);
        });
        return () => {
            mounted = false;
        }
    }, [selectedTimestamp, loading]);

    console.log('-->> selectedTimestamp = ', selectedTimestamp);
    console.log('-->> selectedDate = ', new Date(selectedTimestamp));

    return (
        <Wrapper>

            <CalendarPlaceholder>
                <Calendar value={selectedTimestamp == undefined ? new Date() : new Date(selectedTimestamp)}
                          onChange={d => {
                              dispatch(actions.selectDay(+new Date(d)));
                          }}/>
            </CalendarPlaceholder>

            <CurrentDaySleepChartPlaceholder>
                {slots.length == 0 ? 'no data for this day' :
                    <SleepViewTool slots={slots} loading={loading}/>
                }

            </CurrentDaySleepChartPlaceholder>

            <BottomPlaceholder>

                <Row>
                    <Label>
                        1 - AWAKE, 2 - LIGHT, 3 - DEEP, 4 - REM
                    </Label>
                </Row>


                {metrics.length == 0 ? null :
                    <Row>
                        <Label>
                            First point
                        </Label>
                        <pre>
                            {JSON.stringify(metrics[0], null, 4)}
                        </pre>

                    </Row>
                }

                <Row>
                    <Label>
                        JSON
                    </Label>
                    <Textarea value={JSON.stringify(metrics)}></Textarea>
                </Row>

                <Row>
                    <Label>
                        Table
                    </Label>
                    <RawSleepMetricsTableTool metrics={metrics} />
                </Row>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const CalendarPlaceholder = styled.div`

`;

const Row = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 6em;
`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const CurrentDaySleepChartPlaceholder = styled.div`
  height: 200px;
  margin-top: 10px;
`;


const Wrapper = styled.div`

`;
