import styled, { css } from "styled-components";

const Button = styled.div`
  ${(props) => {
    switch (props.$mode) {
      case "mode1":
        return css`
          background: #147aff;
          height: 48px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          color: white;
          padding-left: 25px;
          padding-right: 25px;
          font-size: 14px;
          font-weight: 800;
          cursor: pointer;

          :hover {
            opacity: 0.8;
          }
        `;
      default:
        return css`
          width: 200px;
          margin: 0 auto;

          background: #147aff;
          border-radius: 5px;
          height: 48px;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: white;
          cursor: pointer;

          font-weight: 800;
          font-size: 14px;
          text-align: center;
          text-transform: uppercase;

          :hover {
            opacity: 0.85;
          }
        `;
    }
  }}
`;

export default Button;