import { useState, useEffect, useRef } from "react";
import { BarChart, XAxis, YAxis, Tooltip, Bar, CartesianGrid } from "recharts";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import { get24HrTicks, get36HrTicks } from "./AiSleepViewTool";
import useDimensions from "react-use-dimensions";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";
import { filterSleepData } from "./SleepWakeTool";
import { GRID_GAP_DASH_ARRAY, GRID_STROKE_COLOR } from "../../ui/templates";

const mapToActivityCount = (sleepData) => {
  const _data = sleepData.map((x, i) => {
    return {
      activity_count: x.activity_count,
      sleepState: x.sleep_state,
      sleep_q: x.sleep_q,
      date: x.date,
      date_without_tz: x.date_without_tz,
      timestamp: x.timestamp,
    };
  });
  return _data;
};

export default function SleepActivityCountTool(props) {
  const { sleepData = [], date, uuid, loading = false } = props;
  const [ref, { x, y, width, height }] = useDimensions();
  const [activityCount, setActivityCounts] = useState([]);
  console.log(width);

  useEffect(() => {
    // console.log("sleepdata", sleepData);
    const _filteredData = filterSleepData(sleepData);
    const _activityCountData = mapToActivityCount(_filteredData);
    // console.log("_activityCountData", _activityCountData);

    setActivityCounts(_activityCountData);
  }, [sleepData, date, uuid]);

  let ticksNumber = 24;
  // const ticks24Hrs = get24HrTicks(12, date);
  let xticks = [];

  function get24HrTicks(ticksNumber, date) {
    const dateFrom = moment(date);
    let twelveHoursBefore = dateFrom.subtract(12, "hours"); // go back 12 hours to construct ticks

    const interval = Math.round(24 / ticksNumber);

    let ticks = Array.from({ length: ticksNumber }).map((x, i) => ({
      left: (100.0 * i) / ticksNumber,
    }));

    ticks = ticks.map((x, i) => {
      const tickObj = {
        ...x,
        t: twelveHoursBefore.utc().valueOf(),
        verbal: twelveHoursBefore.format("DD_HH:mm a z"),
      };
      twelveHoursBefore.add(interval, "hours");
      // return twelveHoursBefore.utc().valueOf();
      return tickObj.t;
    });
    ticks.push(dateFrom.valueOf());
    return ticks;
  }

  xticks = get24HrTicks(12, date);

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        showTopImg={false}
        message={`Sleep activity count data loading...`}
      />
    );
  }
  // console.log("activityCountactivityCount", activityCount);

  return (
    <Wrapper style={{ height: 230, width: "100%" }} ref={ref}>
      {!sleepData || sleepData.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there no activity count data for this day."}
        />
      ) : (
        <div style={{ height: height }} className="activity-count-container">
          <BarChart
            className="bar-chart-wrapper"
            height={height}
            width={width}
            data={activityCount}
            allowDataOverflow={false}
          >
            {/* <CartesianGrid strokeDasharray={"1 1"} /> */}
            <XAxis
              dataKey="timestamp"
              ticks={xticks}
              interval={0}
              // activity_count
              // tickCount={25}
              type="number"
              tickFormatter={(x) => {
                const _t = moment(x).format("HH:mm");
                return _t;
              }}
              // textRendering={}
              domain={([min, max]) => {
                return [xticks[0], xticks[xticks.length - 1]];
              }}
            />
            <YAxis
              width={41}
              dataKey={"activity_count"}
              domain={([min, max]) => {
                return [min, max];
              }}
              style={{
                boxSizing: "border-box",
                border: "1px solid lightgrey",
              }}
            />
            <Bar dataKey="activity_count" fill="#3763f5" />
            <Tooltip content={<ActivityCountToolTip />} />
            {/* <Legend /> */}
          </BarChart>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

function ActivityCountToolTip(props) {
  const { active, payload, label } = props;
  const activityCount = payload[0]?.value;
  const showSeconds = true;
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          showSeconds ? "HH:mm:ss" : "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">
          Activity Count: {activityCount}
        </TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
}

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
