import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import ls from "local-storage";

import empty_ch from "../../../assets/images/checkbox.svg";
import ch_box from "../../../assets/images/chbx.svg";
import TogglerComponent from "../../vital_parameters/tools/TogglerComponent";
import ch_checked from "../../../assets/images/chbx.svg";
import ch_void from "../../../assets/images/checkbox.svg";
import { TABS_OPTIONS } from "../../vital_parameters/tools/VitalParametersEditor";

const ITEMS = [
  "Pulse rate",
  "Saturation",
  "Temperature",
  "Activity",
  "Respiration",
  "NIBP",
  "Spirometer",
  "Weight",
];

let getSelectedItems = (code) => {
  const key = `fda_${code}_items`;
  let items = ls(key) || [];
  return items;
};

let saveItem = (code, s) => {
  const key = `fda_${code}_items`;
  let items = getSelectedItems(code);
  if (items.indexOf(s) == -1) {
    let newItems = items.concat([s]);
    ls(key, newItems);
  }
};

let delItem = (code, s) => {
  const key = `fda_${code}_items`;
  let items = getSelectedItems(code);
  let newItems = items.filter((x) => x != s);
  ls(key, newItems);
};

export default function FDAMetricsSelectorTool(props) {
  const { code = "test" } = props;

  const [updT, setUpdT] = useState(0);
  const [alarmsEnabled, setAlarmsEnabled] = useState(true);

  const [tabs, setTabs] = useState(props.tabs || {});

  let selItems = getSelectedItems(code);

  return (
    <Wrapper>
      <div style={{ fontWeight: "bold", textAlign: "center", marginTop: 20 }} className="normal-text">
        {"Patient App Information"}
      </div>

      <div style={{ textAlign: "center", marginBottom: 0, marginTop: 10 }} className="normal-text">
        Set which information should be shown in the App of the Patient.
      </div>

      <TabOptionsSection>
        <SectionHeading className="heading">Tab Settings</SectionHeading>
        <div
          style={{
            fontSize: 14,
            fontStyle: "italic",
            opacity: 0.7,
            textAlign: "center",
            marginTop: -10,
            marginBottom: 10,
          }}
          className="setting-label"
        >
          Select maximum 4
        </div>
        {/*<TabOptionsPlaceholder>*/}
        {/*    {TABS_OPTIONS.map((a, i) => {*/}
        {/*        let val = (tabs || {})[a.name];*/}
        {/*        if (val == undefined) {*/}
        {/*            val = a.defaultValue;*/}
        {/*        }*/}
        {/*        let isSelected = (val == true);*/}
        {/*        return (*/}
        {/*            <TabOptItem key={a.value}>*/}
        {/*                <OptImg src={isSelected == true ? ch_checked : ch_void} onClick={() => {*/}
        {/*                    let defaults = TABS_OPTIONS.reduce((mp, x) => ({*/}
        {/*                        ...mp,*/}
        {/*                        [x.name]: x.defaultValue*/}
        {/*                    }), {});*/}
        {/*                    setTabs({*/}
        {/*                        ...defaults,*/}
        {/*                        ...tabs,*/}
        {/*                        [a.name]: !val*/}
        {/*                    });*/}
        {/*                }}/>*/}
        {/*                {a.label}*/}
        {/*            </TabOptItem>*/}
        {/*        )*/}
        {/*    })}*/}
        {/*</TabOptionsPlaceholder>*/}
      </TabOptionsSection>

      <OuterPlaceholder>
        <InnerPlaceholder>
          {ITEMS.map((a, i) => {
            let isSelected = selItems.indexOf(a) > -1;
            return (
              <ItemBox
                selected={isSelected}
                key={`${i}_${a}`}
                onClick={() => {
                  if (isSelected) {
                    delItem(code, a);
                  } else {
                    saveItem(code, a);
                  }
                  setUpdT(+new Date());
                }}
              >
                <ItemCheck className="activity-box" src={isSelected == true ? ch_box : empty_ch} />
                <ItemLabel className="setting-label">{a}</ItemLabel>
              </ItemBox>
            );
          })}
        </InnerPlaceholder>
      </OuterPlaceholder>

      <div>
        {/*<CenterHeading>*/}
        {/*    Alarm Notifications*/}
        {/*</CenterHeading>*/}

        {/*<TFlexItemsPlaceholder>*/}
        {/*    <TogglerComponent*/}
        {/*        options={[*/}
        {/*            {*/}
        {/*                label: 'Enabled',*/}
        {/*                value: true*/}
        {/*            },*/}
        {/*            {*/}
        {/*                label: 'Disabled',*/}
        {/*                value: false*/}
        {/*            }*/}
        {/*        ]}*/}
        {/*        value={alarmsEnabled}*/}
        {/*        onChange={v => {*/}
        {/*            setAlarmsEnabled(v);*/}
        {/*        }}*/}
        {/*    />*/}

        {/*</TFlexItemsPlaceholder>*/}

        <BottomPlaceholder>
          <Button onClick={() => {}}>Save</Button>
        </BottomPlaceholder>
      </div>
    </Wrapper>
  );
}

const OuterPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-left: 40px;
`;

const OptImg = styled.img`
  height: 14px;
  margin-right: 5px;
  cursor: pointer;
  opacity: 0.9;

  :hover {
    opacity: 1;
  }
`;

const TabOptItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const TabOptionsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TabOptionsSection = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const SectionHeading = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  padding-top: 20px;
`;

const BottomPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const Button = styled.div`
  width: 100px;

  background: #147aff;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const TFlexItemsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  margin-top: 0px;
`;

const CenterHeading = styled.div`
  padding: 7px;
  text-align: center;
  font-weight: bold;
  margin-top: 15px;
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const ItemLabel = styled.div``;

const ItemCheck = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 7px;
`;

const InnerPlaceholder = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px;
`;
