import styled from "styled-components";

export const ParamItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  // align-items: center;
  align-items: start;
  justify-content: start;
  position: relative;
`;

export const ParamImage = styled.img`
  margin-right: 10px;
  width: 36px;
`;

export const ParamRight = styled.div``;

export const ParamValue = styled.div`
  font-weight: bold;
  font-size: 28px;
  // line-height: 20px;
  color: ${(props) => (props.danger == true ? "#FA4646" : "#8798AD")};
`;

export const ParamLabel = styled.div`
  font-size: 12px;
  line-height: 8px;
  width: max-content;
  color: ${(props) => (props.color == undefined ? "#8798AD" : props.color)};
  margin-top: 4px;
  font-weight: bold;
`;

// for big tiles

export const NameSectionPlaceholderBigTiles = styled.div`
  display: flex;
  width: 100%;
  // width: 70px;
  // align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  margin-bottom: 8px;
`;

export const ParamsItemsPlaceholderBigTiles = styled.div`
  display: grid !important;
  align-items: flex-start;
  // padding: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  row-gap: 1.4rem;
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  // left: -0.2em;
  flex: 1;

  & div.param-item:nth-child(even) {
    justify-self: end;
    right: 24px;
  }
  & div.param-item:nth-child(odd) {
    justify-self: start;
    left: 18px;
  }
`;

export const normalBorder = `2px solid rgba(133, 152, 173, 0.15)`;
export const dangerBorder = `2px solid #FA4646;`;

export const PatientItemBottomBigTiles = styled.div`
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: whitesmoke;
  padding-left: 15px;
  position: relative;
`;

// for small tiles

export const PatientItemTopSmallTiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  height: 200px;
  // padding-right: 15px;
  // padding-right: 5px;
  // padding-bottom: 15px;
  padding-left: 6px;
  // height: 460px;
`;

export const NameSectionPlaceholderSmallTiles = styled.div`
  display: flex;
  width: 100%;
  height: 3rem;
  // width: 70px;
  // align-items: start;
  justify-content: space-between;
  padding-right: 4px;
  padding-left: 4px;
`;

export const ParamsItemsPlaceholderSmallTiles = styled.div`
  display: grid !important;
  align-items: flex-start;
  // padding: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  // row-gap: 1.4rem;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  cursor: pointer;
  margin: 7px auto;
  // position: relative;
  // left: -0.2em;
  // flex: 1;
`;

export const PatientItemBottomSmallTiles = styled.div`
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -14px;
  background: whitesmoke;
  padding-left: 6px;
  position: relative;
`;

export const BottomItemSmallTiles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: end;
  // justify-self: center;
  // max-width: 200px;
  // width: 60px;
  position: relative;
  top: 4px;

  &:hover {
    font-style: italic;
  }
`;
