import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import MmtTemplate from '../templates/MmtTemplate'
import RawDevSleepPanel from "../sleep/panels/RawDevSleepPanel";

function RawDevSleepApp() {

    return (
        <MmtTemplate active={'sleep_dev'}>

            <Wrapper>
                <RawDevSleepPanel/>
            </Wrapper>

        </MmtTemplate>
    );
}

const Wrapper = styled.div`

`;

export default RawDevSleepApp
