import MMTCloud from "./MMTCloud";
import RonasBasicAPI from "./RonasBasicAPI";
import moment from "moment";

interface StudySessionInput {
    timestamp_from: number,
    timestamp_to: number,
    name?: string,
    description?: string,
    session_code?: string
}

class RawAPI {

    private static instance: RawAPI
    private cloud?: MMTCloud;
    private ronasBasicAPI: RonasBasicAPI;

    constructor() {
        this.cloud = MMTCloud.getInstance();
        this.ronasBasicAPI = RonasBasicAPI.getInstance();
    }

    public static getInstance(): RawAPI {
        if (!RawAPI.instance) {
            RawAPI.instance = new RawAPI();
        }
        return RawAPI.instance;
    }

    public async getRawDataPpgForDay(dayTimestamp: number, onProgress: (p: number) => {}) {
        let step = 5 * 60 * 1000;
        let from = +moment(dayTimestamp).startOf('day');
        let n = Math.floor(86400000 / step);
        let arr = [];
        for (let i = 0; i < n; i++) {
            let perc = (n == 0) ? 100.0 : 100.0 * i / n;
            onProgress(perc);
            let start = +from + i * step;
            let end = +start + +step;
            let d = await RonasBasicAPI.getInstance().getRawMetricsForRange((new Date(start)).toJSON(), (new Date(end)).toJSON(), 'heart_rate');
            console.log('d = ', d);
        }
        return [];
    }

    public async getRRIntervalsForDay(dayTimestamp: number) {
        let d = await RonasBasicAPI.getInstance().getRawMetricsForRange((new Date(+moment(dayTimestamp).startOf('day'))).toJSON(), (new Date(+moment(dayTimestamp).endOf('day'))).toJSON(), 'rr_intervals');
        d = d.map((x: any) => ({t: x.timestamp, rr: x.rr, value: x.rr}));
        return d;
    }

}

export default RawAPI;