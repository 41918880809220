import cm_wide_logo from "../assets/images/cors_wide_logo_2.png";
// import cm_fav from "../assets/images/c_fav.png";
import cm_fav from "../assets/images/cors_logo.png";
import dompurify from "dompurify";
import ls from "local-storage";

// import charite_logo from "../assets/images/Logo_Charite.svg";

import cardiomood_fav from "../assets/images/c_fav.png";

import fred_prel from "../assets/images/frede_preloader.png";
import fc_logo_white from "../assets/images/fc_logo_white.png";
import fc_favicon from "../assets/images/fc_favicon.jpg";

import alpina_logo_white from "../assets/images/alpina_logo_white.png";
import alpina_favicon from "../assets/images/alpina_favicon.png";

import corsano_logo_white from "../assets/images/cors_wide_logo.png";
import corsano_favicon from "../assets/images/cors_logo.png";

import cardiomood_wide_logo from "../assets/images/cm_new_logo.png";

import batteryImage from "../assets/images/badges/akar-icons_battery-full.svg";
import zmImage from "../assets/images/badges/zmdi_time-restore.svg";
import zmRedImage from "../assets/images/badges/zmdi_time-restore_red.svg";


import heart_rate_high from "../assets/images/badges/heart_rate_danger.svg";
import heart_rate_medium from "../assets/images/badges/heart_rate_medium.svg";
import heart_rate_low from "../assets/images/badges/heart_rate_low.svg";
import heart_rate_ok from "../assets/images/badges/heart_rate_ok.svg";

import spo2_high from "../assets/images/badges/spo2_danger.svg";
import spo2_medium from "../assets/images/badges/spo2_medium.svg";
import spo2_low from "../assets/images/badges/spo2_low.svg";
import spo2_ok from "../assets/images/badges/spo2_ok.svg";

import lungs_high from "../assets/images/badges/lungs_danger.svg";
import lungs_medium from "../assets/images/badges/lungs_medium.svg";
import lungs_low from "../assets/images/badges/lungs_low.svg";
import lungs_ok from "../assets/images/badges/lungs_ok.svg";

import bp_high from "../assets/images/badges/bp_danger.svg";
import bp_medium from "../assets/images/badges/bp_medium.svg";
import bp_low from "../assets/images/badges/bp_low.svg";
import bp_ok from "../assets/images/badges/bp_ok.svg";

import sleep_ok from "../assets/images/sleep_2.svg";

import thermometer_high from "../assets/images/badges/thermometer_danger.svg";
import thermometer_medium from "../assets/images/badges/thermometer_medium.svg";
import thermometer_low from "../assets/images/badges/thermometer_low.svg";
import thermometer_ok from "../assets/images/badges/thermometer_ok.svg";

import notification_bell from "../assets/images/badges/notification-bell.svg";
import blood_pressure from "../assets/images/badges/blood-pressure.svg";

import footprint_ok from "../assets/images/badges/footprint_ok.svg";
import footprint_danger from "../assets/images/badges/footprint_ok.svg";

/*
    sounds
 */
import high_sound from "../assets/sounds/high.wav";
import medium_sound from "../assets/sounds/medium.wav";

import respiration_sound_high from "../assets/sounds/HP-ventilation.wav";
import respiration_sound_low from "../assets/sounds/MP-ventliation.wav";

import heart_rate_sound_high from "../assets/sounds/HP-cardiovascular.wav";
import heart_rate_sound_low from "../assets/sounds/MP-cardiovascular.wav";

import temperature_sound_high from "../assets/sounds/HP-temperature.wav";
import temperature_sound_low from "../assets/sounds/MP-temperature.wav";

import spo2_sound_high from "../assets/sounds/HP-oxygenation.wav";
import spo2_sound_low from "../assets/sounds/MP-oxygenation.wav";

import battery_sound_high from "../assets/sounds/high.wav";
import battery_sound_low from "../assets/sounds/medium.wav";

import news_warning from "../assets/images/badges/news-warning.svg";
import news_warning_3 from "../assets/images/badges/news-warning-3.svg";
import news_warning_2 from "../assets/images/badges/news-warning-2.svg";
import news_warning_1 from "../assets/images/badges/news-warning-1.svg";
import news_warning_0 from "../assets/images/badges/news-warning-0.svg";

import {
    NO_PRIORITY,
    PR_1,
    PR_2,
    PR_3,
} from "../components/ranges/new_tools/NewRangeTool";

export function isDebugMode() {
    let val = ls("corsano_debug");
    return val == "yes";
}

export function isSleepDebugMode() {
    let val = ls("sleep_debug");
    return val == "yes";
}

export function isDmytroDebugMode() {
    let val = ls("dm_debug");
    return val == "yes";
}

export function setCorsanoDebug() {
    ls("corsano_debug", "yes");
}

export function unsetCorsanoDebug() {
    ls("corsano_debug", "no");
}

export function setSleepDebug() {
    ls("sleep_debug", "yes");
}

export function unsetSleepDebug() {
    ls("sleep_debug", "no");
}

export function setDmytroDebug() {
    ls("dm_debug", "yes");
}

export function unsetDmytroDebug() {
    ls("dm_debug", "no");
}

const isFC = () => {
    let s = window.location.href.toLowerCase();
    // return (s.indexOf('fc') > -1 || s.indexOf('frede') > -1);
    return s.indexOf("frede") > -1;
};

const isCorsano = () => {
    let s = window.location.href.toLowerCase();
    return (
        s.indexOf("fc") > -1 ||
        s.indexOf("corsano") > -1 ||
        s.indexOf("mmt") > -1
    );
};

const isCorsanoPatientPortal = () => {
    let s = window.location.href.toLowerCase();
    return s.indexOf("portal.corsano") > -1;
};

const isCardioMood = () => {
    let s = window.location.href.toLowerCase();
    return s.indexOf("cardiomood") > -1;
};

const isAlpina = () => {
    let s = window.location.href.toLowerCase();
    return s.indexOf("alpina") > -1;
};

const DEFAULT_PRELOADER_LOGO = cm_wide_logo;
const DEFAULT_LOGIN_LOGO = cm_wide_logo;

// const DEFAULT_TEMPLATE_LOGO = charite_logo;
const DEFAULT_TEMPLATE_LOGO = corsano_logo_white;
// const DEFAULT_TEMPLATE_LOGO = cm_wide_logo;
const DEFAULT_FAVICON = cm_fav;
const DEFAULT_SITE_TITLE = "CardioMood";

const FC_PRELOADER_LOGO = fred_prel;
const FC_LOGIN_LOGO = fc_logo_white;
const FC_TEMPLATE_LOGO = fc_logo_white;
const FC_FAVICON = fc_favicon;
const FC_SITE_TITLE = "FC Cloud";

const ALPINA_PRELOADER_LOGO = alpina_logo_white;
const ALPINA_LOGIN_LOGO = alpina_logo_white;
const ALPINA_TEMPLATE_LOGO = alpina_logo_white;
const ALPINA_FAVICON = alpina_favicon;
const ALPINA_SITE_TITLE = "Alpina Cloud";

const CORSANO_PRELOADER_LOGO = corsano_logo_white;
const CORSANO_LOGIN_LOGO = corsano_logo_white;
const CORSANO_TEMPLATE_LOGO = corsano_logo_white;
const CORSANO_FAVICON = corsano_favicon;
const CORSANO_SITE_TITLE = "Corsano Research Portal";
const CORSANO_PATIENT_SITE_TITLE = "Corsano Patient Portal";

const CARDIOMOOD_PRELOADER_LOGO = cardiomood_wide_logo;
const CARDIOMOOD_LOGIN_LOGO = cardiomood_wide_logo;
const CARDIOMOOD_TEMPLATE_LOGO = cardiomood_wide_logo;
const CARDIOMOOD_FAVICON = cardiomood_fav;
const CARDIOMOOD_SITE_TITLE = "CardioMood Portal";

const UiHelper = {
    sanitize(s) {
        const sanitizer = dompurify.sanitize;
        return sanitizer(s);
    },

    isBetaTesting() {
        return this.isBranded();
    },

    setBrandedTags() {
        let fav = this.getFavicon();
        let title = this.getSiteTitle();
        try {
            document.title = title;
            document.querySelector("link[rel*='icon']").href = fav;
        } catch (exc) {}
    },

    getSiteTitle() {
        if (isAlpina()) {
            return ALPINA_SITE_TITLE;
        }
        if (isFC()) {
            return FC_SITE_TITLE;
        }
        if (isCorsanoPatientPortal()) {
            return CORSANO_PATIENT_SITE_TITLE;
        }
        if (isCorsano()) {
            return CORSANO_SITE_TITLE;
        }
        if (isCardioMood()) {
            return CARDIOMOOD_SITE_TITLE;
        }
        return DEFAULT_SITE_TITLE;
    },

    getFavicon() {
        if (isAlpina()) {
            return ALPINA_FAVICON;
        }
        if (isFC()) {
            return FC_FAVICON;
        }
        if (isCorsano()) {
            return CORSANO_FAVICON;
        }
        if (isCardioMood()) {
            return CARDIOMOOD_FAVICON;
        }
        return DEFAULT_FAVICON;
    },

    getTemplateLogoImage() {
        if (isAlpina()) {
            return ALPINA_TEMPLATE_LOGO;
        }
        if (isFC()) {
            return FC_TEMPLATE_LOGO;
        }
        if (isCorsano()) {
            return CORSANO_TEMPLATE_LOGO;
        }
        if (isCardioMood()) {
            return CARDIOMOOD_TEMPLATE_LOGO;
        }
        return DEFAULT_TEMPLATE_LOGO;
    },

    getLoginPageLogoImage() {
        if (isAlpina()) {
            return ALPINA_LOGIN_LOGO;
        }
        if (isFC()) {
            return FC_LOGIN_LOGO;
        }
        if (isCorsano()) {
            return CORSANO_LOGIN_LOGO;
        }
        if (isCardioMood()) {
            return CARDIOMOOD_LOGIN_LOGO;
        }
        return DEFAULT_LOGIN_LOGO;
    },

    getPreloaderLogoImage() {
        if (isAlpina()) {
            return ALPINA_PRELOADER_LOGO;
        }
        if (isFC()) {
            return FC_PRELOADER_LOGO;
        }
        if (isCorsano()) {
            return CORSANO_PRELOADER_LOGO;
        }
        if (isCardioMood()) {
            return CARDIOMOOD_PRELOADER_LOGO;
        }
        return DEFAULT_PRELOADER_LOGO;
    },

    isBranded() {
        return isFC() || isAlpina() || isCorsano();
    },

    getNewsBadgeImage(level) {
        if (level === 3) {
            return news_warning_3;
        } else if (level === 2) {
            return news_warning_2;
        } else if (level === 1) {
            return news_warning_1;
        } else if (level === 0) {
            return news_warning_0;
        } else {
            return news_warning;
        }
    },
    getBadgeImage(type, priority) {
        if (type == "temperature") {
            if (window.location.href.indexOf("portal") == -1) {
                return thermometer_ok;
            }
            if (priority == 10) {
                return thermometer_high;
            }
            if (priority == 9) {
                return thermometer_medium;
            }
            if (priority == 8) {
                return thermometer_low;
            }
            return thermometer_ok;
        }
        if (type == "bp") {
            return bp_ok;
        }

        if (type == "spo2") {
            if (window.location.href.indexOf("portal") == -1) {
                return spo2_ok;
            }
            if (priority == 10) {
                return spo2_high;
            }
            if (priority == 9) {
                return spo2_medium;
            }
            if (priority == 8) {
                return spo2_low;
            }
            return spo2_ok;
        }
        if (type == "respiration_rate") {
            if (window.location.href.indexOf("portal") == -1) {
                return lungs_ok;
            }
            if (priority == 10) {
                return lungs_high;
            }
            if (priority == 9) {
                return lungs_medium;
            }
            if (priority == 8) {
                return lungs_low;
            }
            return lungs_ok;
        }
        if (type == "heart_rate") {
            if (window.location.href.indexOf("portal") == -1) {
                return heart_rate_ok;
            }
            if (priority == 10) {
                return heart_rate_high;
            }
            if (priority == 9) {
                return heart_rate_medium;
            }
            if (priority == 8) {
                return heart_rate_low;
            }
            return heart_rate_ok;
        }

        if (type == "blood_pressure") {
            if (window.location.href.indexOf("portal") == -1) {
                return bp_ok;
            }
            if (priority == 10) {
                return bp_high;
            }
            if (priority == 9) {
                return bp_medium;
            }
            if (priority == 8) {
                return bp_low;
            }
            return bp_ok;
        }

        if (type === "notification_bell") {
            return notification_bell;
        }
        if (type === "blood_pressure") {
            return blood_pressure;
        }
        return null;
    },

    getColorByPriority(priority) {
        if (priority == 10) {
            // return "#EA3223";
            return PR_1.color;
        }
        if (priority == 9) {
            // return "#e5c347";
            return PR_2.color;
        }
        if (priority == 8) {
            // return "#56c3c3";
            return PR_3.color;
        }
        // return NO_PRIORITY.color;
        return "#8798AD";
    },

    async getSoundUrl(type, priority) {
        if (priority == 10) {
            if (type == "temperature") {
                return temperature_sound_high;
            }
            if (type == "spo2") {
                return spo2_sound_high;
            }
            if (type == "respiration_rate") {
                return respiration_sound_high;
            }
            if (type == "heart_rate") {
                return heart_rate_sound_high;
            }
            if (type == "battery") {
                return battery_sound_high;
            }
        }
        if (priority == 9) {
            if (type == "temperature") {
                return temperature_sound_low;
            }
            if (type == "spo2") {
                return spo2_sound_low;
            }
            if (type == "respiration_rate") {
                return respiration_sound_low;
            }
            if (type == "heart_rate") {
                return heart_rate_sound_low;
            }
            if (type == "battery") {
                return battery_sound_low;
            }
        }
    },

    cachedAllAudios() {
        console.log("cachedAllAudios occured!");
        let priorities = [9, 10];
        let types = [
            "temperature",
            "spo2",
            "respiration_rate",
            "heart_rate",
            "battery",
            "internet",
        ];
        for (let i in priorities) {
            let priority = priorities[i];
            for (let j in types) {
                let type = types[j];
                let key = `audio_${type}_${priority}`;
                if (window[key] != undefined) {
                    continue;
                }
                if (priority == 10) {
                    if (type == "temperature") {
                        window[key] = new Audio(temperature_sound_high);
                    }
                    if (type == "spo2") {
                        window[key] = new Audio(spo2_sound_high);
                    }
                    if (type == "respiration_rate") {
                        window[key] = new Audio(respiration_sound_high);
                    }
                    if (type == "heart_rate") {
                        window[key] = new Audio(heart_rate_sound_high);
                    }
                    if (type == "battery") {
                        window[key] = new Audio(battery_sound_high);
                    }
                    if (type == "internet") {
                        window[key] = new Audio(battery_sound_high);
                    }
                }
                if (priority == 9) {
                    if (type == "temperature") {
                        window[key] = new Audio(temperature_sound_low);
                    }
                    if (type == "spo2") {
                        window[key] = new Audio(spo2_sound_low);
                    }
                    if (type == "respiration_rate") {
                        window[key] = new Audio(respiration_sound_low);
                    }
                    if (type == "heart_rate") {
                        window[key] = new Audio(heart_rate_sound_low);
                    }
                    if (type == "internet") {
                        window[key] = new Audio(battery_sound_low);
                    }
                }
            }
        }
    },

    getCachedAudio(type, priority) {
        let key = `audio_${type}_${priority}`;
        if (window[key] != undefined) {
            return window[key];
        }
    },

    playAudio(type, priority) {
        if (window.location.href.indexOf("portal") == -1) {
            return;
        }
        console.log("playAudio: type, priority = ", type, priority);
        let audio = this.getCachedAudio(type, priority);
        console.log("audio = ", audio);
        if (audio == undefined) {
            return;
        }
        try {
            audio.play();
        } catch (exc) {}
    },

    getPatientMaxPriorityDetails(patient) {
        let maxPriority = 0;
        let maxPriorityType = undefined;
        let types = [
            "temperature",
            "spo2",
            "respiration_rate",
            "heart_rate",
            "battery",
        ];
        for (let i in types) {
            let type = types[i];
            let rt = patient?.realtime;
            if (rt == undefined) {
                continue;
            }
            let prVal = rt[`${type}_alarm_priority`];
            if (prVal > maxPriority) {
                maxPriority = prVal;
                maxPriorityType = type;
            }
        }
        return {
            priority: maxPriority,
            type: maxPriorityType,
        };
    },

    getPatientsMaxPriority(patients = []) {
        let arr = patients.map((p) => this.getPatientMaxPriorityDetails(p));
        let maxPriority = 0;
        let maxPriorityType = undefined;
        for (let i in arr) {
            let item = arr[i];
            if (item.priority > maxPriority) {
                maxPriority = item.priority;
                maxPriorityType = item.type;
            }
        }
        return {
            priority: maxPriority,
            type: maxPriorityType,
        };
    },

    playSoundForIllPatients(patients = []) {
        let dd = this.getPatientsMaxPriority(patients);
        console.log("playSoundForIllPatients: patients = ", patients);
        console.log("playSoundForIllPatients: dd = ", dd);
        if (dd == undefined || dd.priority == 0) {
            return;
        }
        try {
            this.playAudio(dd.type, dd.priority);
        } catch (exc) {}
    },

    playNoInternetAudio() {
        try {
            this.playAudio("internet", 10);
        } catch (exc) {}
    },
};

export default UiHelper;
