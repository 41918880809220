import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Input} from "../../auth/ui";
import {EditButton} from "../panels/PatientPanel";

export default function ResetPasswordTool(props) {
    const [email, setEmail] = useState('');

    return (
        <Wrapper>
            <p>
                With this function a password can be reset. Corsano will send an email with to the mobile phone of the
                patient.
            </p>

            <EmRow>
                <div style={{width: 200}}>
                    Email of the patient:
                </div>
                <Input value={email} placeholder={'Type email address here'} style={{width: 320}} />
            </EmRow>

            <SavePlaceholder>
                <EditButton>
                    Save
                </EditButton>
            </SavePlaceholder>

            <p>
                Please ask patient to download Corsano app and click Forget Password. The patient will receive a
                confirmation email. Please ask the patient to follow instructions carefully.
            </p>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SavePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EmRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;