import * as types from '../ActionTypes'

const initialState = {
    currentUserObj: undefined,
    loading: false,
    error: undefined,
    initialized: false
}

const AuthReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.CREATE_USER:
        case types.LOAD_USERS:
        case types.SIGNUP:
        case types.LOGOUT:
        case types.LOGIN:
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case types.CREATE_USER_FAIL:
        case types.UPDATE_USER_FAIL:
        case types.LOAD_USERS_FAIL:
        case types.LOGIN_FAIL:
        case types.LOGOUT_FAIL:
        case types.SIGNUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case types.INITIALIZE_AUTH:
            return {
                ...state,
                loading: true,
                initialized: false,
                error: undefined
            }

        case types.INITIALIZE_AUTH_FAIL:
            return {
                ...state,
                loading: false,
                initialized: false,
                error: action.error,
                currentUserObj: undefined
            }

        case types.SIGNUP_SUCCESS:
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                currentUserObj: action.currentUserObj,
                loading: false
            }

        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                currentUserObj: undefined,
                loading: false
            }

        case types.INITIALIZE_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                initialized: true,
                currentUserObj: action.currentUserObj
            }


        default:
            return state;

    }

}

export default AuthReducer;
