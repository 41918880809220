import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

//todo: take from the sample dashboard
export default function SimpleEcgViewer(props) {
    const {
        data
    } = props;

    if (data == undefined) {
        return (
            <div>
                no data...
            </div>
        )
    }

    return (
        <Wrapper>

            <pre>{JSON.stringify(data, null, 2)}</pre>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;