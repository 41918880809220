import styled from "styled-components";
import DoctorTemplate from "../templates/DoctorTemplate";
import PatientSingleSessionPanel from "../patients/panels/PatientSingleSessionPanel";
import { useParams } from "react-router-dom";

function UserSessionApp(props) {
  let params = useParams();
  let { userId, sessionId } = params;

  return (
    <DoctorTemplate active={"patients"}>
      <Wrapper>
        <PatientSingleSessionPanel uuid={userId} sessionId={sessionId} />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default UserSessionApp;
