import styled from "styled-components";
import GroupsPanel from "../groups/panels/GroupsPanel";
import DoctorTemplate from "../templates/DoctorTemplate";

function StudyIndexApp() {
  return (
    <DoctorTemplate active={"groups"}>
      <Wrapper>
        <GroupsPanel />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div``;

export default StudyIndexApp;
