import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";

function getUrl(uuid, date) {
  return `https://app.sleep.corsano.com/embed?uuid=${uuid}&date=${date}`;
}

export default function AiSleepTool(props) {
  const { uuid, date } = props;

  return (
    <Wrapper>
      <div className="content-record" style={{ position: "relative" }}>
        <iframe
          title="ai-sleep-tool"
          width="100%"
          style={{ height: "300px", border: "none" }}
          src={getUrl(uuid, date)}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
