import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import useComponentSize from "@rehooks/component-size";

import './style.css'


import {
    XAxis, YAxis, CartesianGrid, Tooltip, Scatter, ScatterChart
} from 'recharts';

// shape={<CustomizedShape/>}

function CustomizedShape(props){
    const { cx, cy } = props;
    return (
        <circle cx={cx} cy={cy} r={3} stroke="#D66C6C" strokeWidth={0} fill="#D66C6C" />
    )
}


export default function SimpleScatterChart(props) {
    const {
        points = []
    } = props;

    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;

    let data = [];
    for (let i = 0; i < points.length - 1; i++) {
        data.push({
            RRi: +points[i].value,
            RRi_plus_1: +points[+i + 1].value,
        });
    }
    // console.log('SimpleScatterChart: render: data = ', data);
    let minX = data.reduce((m, a) => (a.x < m ? a.x : m), 10000);
    let minY = data.reduce((m, a) => (a.y < m ? a.y : m), 10000);
    let maxX = data.reduce((m, a) => (a.x > m ? a.x : m), 0);
    let maxY = data.reduce((m, a) => (a.y > m ? a.y : m), 0);

    return (
        <Wrapper ref={ref}>
            <ChartPlaceholder>
                <ScatterChart
                    data={data}
                    width={width}
                    height={height}
                    margin={{top: 0, right: 0, left: -25, bottom: -5}}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="RRi" type={'number'} domain={['dataMin - 100', 'dataMax + 100']}/>
                    <YAxis dataKey="RRi_plus_1" type={'number'} domain={['dataMin - 100', 'dataMax + 100']}/>
                    <Tooltip/>
                    <Scatter strokeWidth={1} fillOpacity={0.9} fill="#D66C6C" tickSize={2} shape={CustomizedShape} />
                </ScatterChart>
            </ChartPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ChartPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
`;
