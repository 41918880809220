import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Sidebar from 'arui-feather/sidebar'
import StudySessionsAPI from "../../../api/StudySessionsAPI";
import UpdateSessionForm from "../forms/UpdateSessionForm";
import Tabs, {TabItem} from "../../ui/Tabs";
import UploadSessionToPreventicusTool from "../tools/UploadSessionToPreventicusTool";
import SessionExportTool from "../tools/SessionExportTool";

export default function StudySessionsPanel(props) {
    const {email} = props;

    const [sessions, setSessions] = useState([]);

    const [selectedId, setSelectedId] = useState(undefined);
    const [addVisible, setAddVisible] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        StudySessionsAPI.getInstance().getSessions().then(arr => {
            // console.log('sessions arr = ', arr);
            setSessions(arr);
            setLoading(false);
        });
    }, []);

    const selectedSession = (selectedId == undefined) ? undefined : sessions.filter(x => (x._id == selectedId))[0];
    let sortedSessions = sessions.sort((a, b) => (+new Date(a.created_at) - +new Date(b.created_at)));

    return (
        <Wrapper>

            <TopSection>

                <div>
                    {`${sessions.length} session${sessions.length > 1 ? 's' : ''}`}
                </div>

                <Button onClick={() => {
                    setAddVisible(true);
                }}>
                    Add session
                </Button>
            </TopSection>

            {sessions.length == 0 ? null :
                <SessionsList>

                    {sortedSessions.map((a, i) => {
                        let {
                            timestamp_to,
                            timestamp_from,
                            preventicus_customer_id,
                            status,
                            name,
                            description,
                            created_at,
                            session_code
                        } = a;
                        return (
                            <SessionItem key={`ss_${a._id}_${i}`}
                                         selected={(selectedId == a._id)}
                                         onClick={() => {
                                             setSelectedId(a._id);
                                         }}>

                                <DatesPlaceholder style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <span>
                                        {`${moment(+timestamp_from).format('DD.MM.YYYY HH:mm')} - ${moment(+timestamp_to).format('DD.MM.YYYY HH:mm')}`}
                                        <span style={{
                                            opacity: 0.5,
                                            marginLeft: 5,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            backgroundColor: 'lightgrey',
                                            borderRadius: 4
                                        }}>
                                            {`${session_code}`}
                                        </span>
                                    </span>
                                    <span style={{fontWeight: 'normal', fontSize: 12}}>
                                        {`Preventicus customer ID: ${preventicus_customer_id}`}
                                    </span>
                                </DatesPlaceholder>

                                <ContentPlaceholder>
                                    {`Name: ${name == undefined ? 'N/A' : name}`}
                                    <br/>
                                    {`Description: ${description == undefined ? 'N/A' : description}`}
                                    <br/>
                                    {`Created at: ${created_at}`}
                                    <br/>
                                    {`Status: ${status}`}
                                    <br/>
                                </ContentPlaceholder>

                            </SessionItem>
                        )
                    })}

                </SessionsList>
            }

            <Sidebar visible={(addVisible)} onCloserClick={() => {
                setAddVisible(false);
            }}>

                {addVisible == false ? null :
                    <div>
                        <UpdateSessionForm
                            onSave={async d => {
                                setLoading(true);
                                await StudySessionsAPI.getInstance().createSession(d);
                                let arr = await StudySessionsAPI.getInstance().getSessions();
                                setSessions(arr);
                                setLoading(false);
                                setAddVisible(false);
                            }}
                            loading={loading}/>
                    </div>
                }

            </Sidebar>

            <Sidebar
                width={Math.min(720, window.innerWidth)}
                visible={(selectedSession != undefined)}
                onCloserClick={() => {
                    setSelectedId(undefined);
                }}>

                {selectedSession == undefined ? null :
                    <div>
                        <Tabs tabs={[
                            {
                                label: 'Information',
                                content: (
                                    <TabItem>
                                        <UpdateSessionForm
                                            {...selectedSession}
                                            onSave={async d => {
                                                setLoading(true);
                                                await StudySessionsAPI.getInstance().updateSession(selectedSession._id, d);
                                                let arr = await StudySessionsAPI.getInstance().getSessions();
                                                setSessions(arr);
                                                setLoading(false);
                                                setSelectedId(undefined);
                                            }}
                                            loading={loading}/>
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Analytics',
                                content: (
                                    <TabItem>
                                        <UploadSessionToPreventicusTool
                                            loading={loading}
                                            session={selectedSession}
                                            onSessionUpload={async () => {
                                                setLoading(true);
                                                await StudySessionsAPI.getInstance().uploadSession(selectedSession._id);
                                                let arr = await StudySessionsAPI.getInstance().getSessions();
                                                setSessions(arr);
                                                setLoading(false);
                                                setSelectedId(undefined);
                                            }}

                                        />
                                    </TabItem>
                                )
                            },
                            {
                                label: 'Export',
                                content: (
                                    <TabItem>
                                        <SessionExportTool
                                            to={+selectedSession.timestamp_to}
                                            from={+selectedSession.timestamp_from}
                                            email={email}
                                        />
                                    </TabItem>
                                )
                            }
                        ]}

                        />

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

export const Button = styled.div`
  height: 42px;
  background: ${props => (props.background == undefined ? '#0072BC' : props.background)};
  border-radius: 8px;
  cursor: ${props => (props.disabled == true ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;

  padding-left: 20px;
  padding-right: 20px;

  :hover {
    opacity: ${props => (props.disabled == true ? 0.5 : 0.8)};
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentPlaceholder = styled.div`

`;

const NamePlaceholder = styled.div`

`;

const DatesPlaceholder = styled.div`
  font-weight: bold;
`;

const SessionsList = styled.div`
  margin-top: 10px;
`;

const SessionItem = styled.div`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;

  background: ${props => (props.selected == true ? 'rgb(34,42,50)' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

const Wrapper = styled.div`

`;