import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import moment from 'moment'
import axios from 'axios'
import {Code} from "react-content-loader";
import {API_ENDPOINT, CORSANO_API_ENDPOINT} from "../../../constants/config";
import Input from "../../ui/templates/Input";
import {useMappedState} from "redux-react-hook";
import Button from "../../ui/templates/Button";

export default function BalanceAndTransactionsPanel(props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);
    const [sum, setSum] = useState('100');

    let {currentUserObj} = useMappedState(
        useCallback((state) => {
            return {
                currentUserObj: state.auth.currentUserObj
            };
        }, [])
    );

    useEffect(() => {
        if (currentUserObj == undefined) {
            return;
        }
        setLoading(true);
        axios.get(`${CORSANO_API_ENDPOINT}/balance-and-transactions?userId=${currentUserObj}`, {}).then(d => d.data).then(x => {
            setData(x);
            setLoading(false);
        });
    }, [currentUserObj])

    if (loading == true) {
        return (<Code/>)
    }

    if (data == undefined) {
        return null;
    }

    let uuid = currentUserObj?.healthUser?.uuid;
    let transactions = (data?.transactions || []);

    return (
        <Wrapper>


            <div style={{marginBottom: '20px', fontSize: '16px'}}>
                {`Current balance: $${data?.balance || 0}`}
            </div>

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{marginRight: '5px'}}>
                    <Input
                        value={sum}
                        placeholder={'Sum'}
                        onChange={evt => {
                            setSum(evt.target.value);
                        }}
                    />
                </div>

                <a href={`${CORSANO_API_ENDPOINT}/billing/top-up/${uuid}/${(sum)}00`} target={'_blank'}>
                    <Button>
                    <span style={{textDecoration: 'none !important'}}>
                        {`Add $${sum}`}
                    </span>
                    </Button>
                </a>
            </div>


            {transactions.length == 0 ? null :
                <div style={{marginTop: '15px'}}>
                    <div style={{marginBottom: '15px'}} >
                        Transactions
                    </div>
                    <div>
                        {transactions.sort((a, b) => (+b.timestamp - +a.timestamp)).map((a, i) => {
                            return (
                                <div key={i} style={{
                                    marginBottom: '15px',
                                    paddingBottom: '5px',
                                    borderBottom: '1px solid lightgray',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>
                                    <div  >
                                        {`${moment(a.timestamp).format('YYYY-MM-DD HH:mm')}`}
                                    </div>
                                    <div style={{color: (a?.type == 'REPLENISHMENT' ? 'green' : 'red')}}>
                                        {`$${a.amount}`}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }



            {/*{JSON.stringify(data, null, 2)}*/}

        </Wrapper>
    );
}

const Wrapper = styled.div`
  padding: 10px;
`;