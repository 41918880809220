import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import ls from "local-storage";

/*
Date          Time         Patient          			Vital     		Value          	Status
20230306 11:03       All audio paused	  	NA		NA 		All audio off


Add when periodic notification is displayed:

20230306 12:22       Notification All muted	  	NA		NA 		Active


Add when periodic notification is dismissed:

20230306 12:23       Notification All muted	  	NA		NA 		Dismissed

 */

const ALARMS_0 = [
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "10:27:12",
    name: "Daniel Jean",
    vital: "Pulse Rate",
    value: "125",
    color: "#e5c347",
    limit: "117",
    priority: "Medium",
    status: "Audio on",
  },
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "10:32:46",
    name: "Daniel Jean",
    vital: "Pulse Rate",
    value: "145",
    color: "#ff0000",
    limit: "139",
    priority: "High",
    status: "Audio on",
  },
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "10:37:33",
    name: "Daniel Jean",
    vital: "SpO2",
    value: "87",
    color: "#e5c347",
    limit: "90",
    priority: "Medium",
    status: "Audio on",
  },
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "10:41:01",
    name: "Daniel Jean",
    vital: "SpO2",
    value: "80",
    color: "#ff0000",
    limit: "85",
    priority: "High",
    status: "Audio on",
  },
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "10:43:07",
    name: "Daniel Jean",
    vital: "Respiration Rate",
    value: "25",
    color: "#e5c347",
    limit: "20",
    priority: "Medium",
    status: "Audio on",
  },
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "10:49:30",
    name: "Daniel Jean",
    vital: "Respiration Rate",
    value: "35",
    color: "#ff0000",
    limit: "30",
    priority: "High",
    status: "Audio on",
  },
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "10:54:17",
    name: "Daniel Jean",
    vital: "Temperature",
    value: "98",
    color: "#e5c347",
    limit: "96",
    priority: "Medium",
    status: "Audio on",
  },
  {
    eventType: "Alarm condition",
    date: "2023-11-01",
    time: "11:01:48",
    name: "Daniel Jean",
    vital: "Temperature",
    value: "98",
    color: "#ff0000",
    limit: "97",
    priority: "High",
    status: "Audio on",
  },
  {
    eventType: "Alarm inactivation",
    date: "2023-11-01",
    time: "11:05:10",
    name: "Daniel Jean",
    vital: "Temperature",
    value: "98",
    color: "#ff0000",
    limit: "80",
    priority: "High",
    status: "Pause Audio",
  },
  {
    eventType: "Alarm inactivation",
    date: "2023-11-01",
    time: "11:08:29",
    name: "All",
    vital: "N/A",
    value: "N/A",
    color: "#d3d3d3",
    limit: "N/A",
    priority: "N/A",
    status: "Pause All Audio",
  },
];

const ALARMS = [
  {
    eventType: "Alarm condition",
    date: "2023-05-03",
    time: "11:03:10",
    name: "Peter Smith",
    vital: "BPM",
    value: "146",
    color: "#e5c347",
    limit: "120",
    priority: "Medium",
    status: "Audio on",
  },

  {
    eventType: "Alarm inactivation",
    date: "2023-05-03",
    time: "11:03:50",
    name: "Peter Smith",
    vital: "N/A",
    value: "N/A",
    limit: "N/A",
    priority: "N/A",
    status: "Paused Audio",
  },

  {
    eventType: "Alarm condition",
    date: "2023-05-03",
    time: "11:04:15",
    name: "Peter Smith",
    vital: "BPM",
    value: "147",
    color: "#e5c347",
    limit: "120",
    priority: "Medium",
    status: "Audio Paused",
  },

  {
    eventType: "Alarm condition",
    date: "2023-05-03",
    time: "11:13:00",
    name: "Peter Smith",
    vital: "BPM",
    value: "151",
    color: "red",
    limit: "150",
    priority: "High",
    status: "Audio On",
  },

  {
    eventType: "Alarm inactivation",
    date: "2023-05-03",
    time: "12:22:00",
    name: "All",
    vital: "N/A",
    value: "N/A",
    limit: "N/A",
    priority: "N/A",
    status: "Pause All Audio",
  },

  {
    eventType: "Alarm condition",
    date: "2023-05-03",
    time: "11:13:00",
    name: "Peter Smith",
    vital: "BPM",
    value: "151",
    limit: "150",
    priority: "High",
    status: "Audio Paused",
  },
];

export default function FakeAlarmsTool(props) {
  const darkmode = ls.get("theme") === "dark";
  return (
    <Wrapper>
      <Table>
        <Thead>
          <Tr style={{ color: darkmode ? "white" : "" }}>
            <Th>Event Type</Th>
            <Th>Date</Th>
            <Th>Time</Th>
            <Th>Patient</Th>
            <Th>Vital</Th>
            <Th>Value</Th>
            <Th>Limit</Th>
            <Th>Priority</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        <Tbody>
          {ALARMS_0.map((a, i) => {
            return (
              <Tr key={i} style={{ color: darkmode ? "white" : "" }}>
                <Td>{a.eventType}</Td>
                <Td>{a.date}</Td>
                <Td>{a.time}</Td>
                <Td>{a.name}</Td>
                <Td>{a.vital}</Td>
                <Td
                  style={{
                    color: a.color,
                    fontWeight: a.color == undefined ? "normal" : "bold",
                  }}
                >
                  {a.value}
                </Td>
                <Td>{a.limit}</Td>
                <Td>{a.priority}</Td>
                <Td>{a.status}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr``;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;
