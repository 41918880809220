import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useInterval} from "../../apps/RouterApp";
import DoctorAPI from "../../../api/DoctorAPI";

import exportImg from './export_img.svg'

export default function SleepExportTool(props) {
    const {
        uuid, sessionId
    } = props;

    return (
        <Wrapper onClick={() => {

        }}>

            <SquareBox>
                <ExportImg src={exportImg}/>
            </SquareBox>

            <DownloadLink href={DoctorAPI.getSessionSleepExportCsvLink(uuid, sessionId)} target={'_blank'}>
                Sleep
            </DownloadLink>

        </Wrapper>
    );
}

const DownloadLink = styled.a`
  color: #147AFF;
  font-size: 18px;
  font-weight: bold;
`;

const ExportImg = styled.img`
  height: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Span = styled.div`
  color: #147AFF;
  font-size: 18px;
  font-weight: bold;
`;

const SquareBox = styled.div`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
`;