import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";

import c0 from "./images/compl_0_10.svg";
import c1 from "./images/compl_10_50.svg";
import c2 from "./images/compl_50_90.svg";
import c3 from "./images/compl_90_100.svg";

function getComplianceSvg(c) {
  if (c <= 10) {
    return {
      image: c0,
      color: "#e90909",
    };
  }
  if (10 < c && c <= 50) {
    return {
      image: c1,
      color: "#E9C46A",
    };
  }
  if (50 < c && c <= 90) {
    return {
      image: c2,
      color: "#FFA823",
    };
  }
  if (90 < c) {
    return {
      image: c3,
      color: "#52cc99",
    };
  }
  return {
    image: c3,
    color: "#000F4B",
  };
}

export default function ComplianceSpan(props) {
  const { compliance } = props;
  if (!compliance) return null;

  let { image, color } = getComplianceSvg(compliance);

  return (
    <Wrapper style={{ color: color }}>
      <img
        style={{ height: 25, marginRight: 10 }}
        src={image}
        // className={"battery-icon"}
      />
      <span style={{ opacity: 1, color: color }}>{`${compliance.toFixed(0)}%`}</span>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
  color: #8798ad;
  font-weight: 700;
  font-size: 12px;
`;
