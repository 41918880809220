import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import useComponentSize from "@rehooks/component-size";

import './style.css'

import {
    PieChart, Pie, Sector, Cell, Legend
} from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
                                   cx, cy, midAngle, innerRadius, outerRadius, percent, index,
                               }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default function SimplePieChart(props) {
    const {
        HF_percent = 10,
        LF_percent = 20,
        VLF_percent = 70,

    } = props;

    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;

    const data = [
        {name: 'HF%', value: HF_percent},
        {name: 'LF%', value: LF_percent},
        {name: 'VLF%', value: VLF_percent}
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

    if (width == undefined || width == 0 || height == undefined || height == 0) {
        return (<Wrapper ref={ref}></Wrapper>)
    }

    let radius = Math.min(height, width) * 0.30;

    return (
        <Wrapper ref={ref}>
            <ChartPlaceholder>

                <PieChart width={width} height={height}>
                    <Pie
                        data={data}
                        cx={width / 2}
                        cy={height / 2 - 30}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={radius}
                        fill="#8884d8"
                        dataKey="value"

                    >
                        {
                            data.map((entry, index) => <Cell key={`cell-${index}`}
                                                             fill={COLORS[index % COLORS.length]}/>)
                        }
                    </Pie>
                    <Legend verticalAlign="bottom" height={24}/>
                </PieChart>

                {/*<PieChart*/}
                {/*animationEasing="ease-out"*/}
                {/*label*/}
                {/*data={[*/}
                {/*{title: 'HF%', value: (+HF_percent).toFixed(1), color: '#0088FE'},*/}
                {/*{title: 'LF%', value: (+LF_percent).toFixed(1), color: '#00C49F'},*/}
                {/*{title: 'VLF%', value: (+VLF_percent).toFixed(1), color: '#FFBB28'}*/}
                {/*]}*/}
                {/*labelStyle={{*/}
                {/*fontFamily: 'sans-serif',*/}
                {/*fontSize: '4px',*/}
                {/*color: 'white'*/}
                {/*}}*/}
                {/*paddingAngle={0}*/}
                {/*radius={radius}*/}
                {/*rounded={false}*/}
                {/*lengthAngle={360}*/}
                {/*lineWidth={20}*/}
                {/*/>*/}

                {/*<ReactMinimalPieChart*/}
                {/*animate={false}*/}
                {/*animationDuration={500}*/}
                {/*animationEasing="ease-out"*/}
                {/*rounded={true}*/}
                {/*data={[*/}
                {/*{*/}
                {/*color: '#E38627',*/}
                {/*title: 'One',*/}
                {/*value: 10*/}
                {/*},*/}
                {/*{*/}
                {/*color: '#C13C37',*/}
                {/*title: 'Two',*/}
                {/*value: 15*/}
                {/*},*/}
                {/*{*/}
                {/*color: '#6A2135',*/}
                {/*title: 'Three',*/}
                {/*value: 20*/}
                {/*}*/}
                {/*]}*/}
                {/*label*/}
                {/*labelPosition={60}*/}
                {/*labelStyle={{*/}
                {/*fontFamily: 'sans-serif',*/}
                {/*fontSize: '5px'*/}
                {/*}}*/}
                {/*radius={radius}*/}
                {/*startAngle={0}*/}
                {/*viewBoxSize={[*/}
                {/*width / 2.0,*/}
                {/*height / 2.0*/}
                {/*]}*/}
                {/*/>*/}

            </ChartPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ChartPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    //padding: 30px;
    box-sizing: border-box;
`;
