import { useState } from "react";
import styled from "styled-components";
import NiceModal from "../../modals/NiceModal";

import afProcess from "../../../assets/images/af_process.png";
import infoImg from "./info_b.svg";

export default function InfoToolBadge(props) {
  const { type = "", width = 40, height = 40 } = props;

  const [visible, setVisible] = useState(false);

  let d = getData(type);

  let { title, content } = d;

  return (
    <Wrapper>
      <IconPlaceholder
        onClick={() => {
          setVisible(true);
        }}
      >
        <Box
          style={{
            width: width,
            height: height,
          }}
        >
          <Icon src={infoImg} className="bp-info-icon" />
        </Box>
        <InfoSpan className="bp-info-span">Info</InfoSpan>
      </IconPlaceholder>

      {visible === false ? null : (
        <NiceModal
          onClose={() => {
            console.log("setvisible false");

            setVisible(false);
          }}
        >
          <DialogInner>
            {title === undefined || title === "" ? null : (
              <Heading>{title}</Heading>
            )}

            <ContentPlaceholder>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </ContentPlaceholder>
          </DialogInner>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const Icon = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Box = styled.div`
  border: 1px solid rgba(135, 152, 173, 0.1);
  -webkit-filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  // width: 40px; height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const IconPlaceholder = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InfoSpan = styled.div`
  color: rgb(20, 122, 255);
  cursor: pointer;
  font-weight: 300;
  margin-right: 15px;
`;

const DialogInner = styled.div`
  width: 620px;
  padding: 10px;
  box-sizing: border-box;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #000f4b;
`;

const ContentPlaceholder = styled.div`
  font-family: sans-serif;
`;

const Wrapper = styled.div``;

const getData = (type) => {
  let d = INFO_DATA[type];
  if (d == undefined) {
    return DEFAULT;
  }
  return d;
};

const DEFAULT = {
  title: "No data",
  content: `<p>No information</p>`,
};

const INFO_DATA = {
  SLEEP: {
    title: "Sleep",
    content: `<p>
            Some information about sleep
        </p>`,
  },
  HEART_RATE: {
    title: "Pulse rate",
    content: `
    <div style="line-height: 2">

     <p>
              Pulse rate is the speed of the heartbeat measured by the number of contractions of the heart per minute.
              The heart rate can vary according to the body's physical needs, including the need to absorb oxygen and excrete carbon dioxide.
              Activities that can provoke change include physical exercise, sleep, anxiety, stress, illness, and ingestion of drugs.
              Pulse rate is measured in beats per minute.
          </p>
   </div>
        `,
  },
  SPO2: {
    title: "Spo2",
    content: `
    <div style="line-height: 2">
      <p>
      The Corsano Bracelet utilizes the Photoplethysmography (PPG) sensor photodiodes to capture reflected light to calculate the patient’s oxygen saturation (SpO2) using pulse oximetry, a non-invasive technique to monitor oxygenation. It monitors the percentage of hemoglobin that is oxygen-saturated. </p>
      <p>The working principle is spectrophotometry: the relative absorption of red (absorbed by deoxygenated blood) and infrared (absorbed by oxygenated blood) light of the systolic component of the absorption waveform correlates to arterial blood oxygen saturations. Two light-emitting diodes, red with wavelength of 660 nm and infrared with a wavelength of 880 nm, are positioned so that they are opposite their respective photodiodes through 5-10 mm of tissue. Absorption of light at these wavelengths differs significantly between blood loaded with oxygen and blood lacking oxygen. Oxygenated hemoglobin absorbs more infrared light and allows more red light to pass through. Deoxygenated hemoglobin allows more infrared light to pass through and absorbs more red light.
      </p>
    </div>
    `,
  },
  BRPM: {
    title: "BRPM",
    content: `
    <div style="line-height: 2">

      <p>The Respiration Rate is the number of breaths a person takes per minute (RR). The Corsano Bracelet utilizes a proprietary time-frequency algorithm to extract the respiratory-induced intensity, amplitude and frequency variation signals from the photoplethysmography (PPG) signal.</p>
      <p>The accelerometer (ACC) is utilized to compensate for movement artifacts. PPG and ACC are measured at 32Hz and data is processed by the algorithm to give a new reading of Respiration Rate every 28 seconds utilizing averaging to smooth pulse rate data and prevent inappropriate and transient artifacts from affecting stability of results.</p> 
    </div>`,
  },
  ACTIVITY: {
    title: "STEPS",
    content: `
  <div style="line-height: 2">
      <p>Steps are calculated by the proprietary algorithm based on the intensity and frequency of the patient’s acceleration data.</p>
      <p>Accuracy of Steps with Corsano Bracelet were studied by RadboudMC versus Reference Device FDA Cleared Actigraph CENTREPOINT Insight Watch. Data was monitored during free living: sitting, walking, typing, drinking and during exercise: warming up, walking, running.</p>
    </div>
    `,
  },
  CBT: {
    title: "CBT",
    content: `
    <div style="line-height: 2">
      <p>Core Body Temperature (CBT) is monitored with a heat flux sensor in combination with PPG to allow for reliable continuous monitoring.</p>

      <p>CBT was validated versus electronic pills as a reference to assess the accuracy of the device and the algorithm. CardioWatch 287-2 raw measurement values were recorded during a clinical study with volunteers that were vaccinated for Covid, about half got a fever. Their body temperature was subsequently recorded and plotted against the temperature reference. The sensor signals were algorithmically combined to yield a single temperature output per day while at the same time compensating for physiological thermoregulation. The CBT algorithm is self-calibrating, meaning, after a certain calibration time, the algorithm will automatically determine several system parameters. </p>
    </div>
`,
  },
  BLOOD_PRESSURE: {
    title: "Blood Pressure",
    content: `
        <div style="line-height: 2">
<p>Please perform first BP Cuff measurment to initialize the NIBP Algorithm. </p>

<p>After first initialization BP Cuff measurement, please do second and third measurement in next 24 hours. </p>

<p>Continuous Blood Pressure requires cuff measurement at 3 different periods. The accuracy of the continuous blood pressure improves with multiple calibrations. Please perform first BP Cuff measurement.</p>

<p>Currently, there are warnings / errors implemented in the output of the SBP and DBP values.</p>

<p>If you receive <b>BP Cuff Measurement Error</b>: Please redo measurement.</p>

<p>Currently the following warnings are implemented:</p>


<ul>
  <li>Night dip < 10%</li>
  <li>Night dip > 30%</li>
  <li>Not enough night history</li>
  <li>Not enough day history</li>
</ul>

<p>If you want to discuss SBP and DBP values with Corsano staff, please let us know the voucher ID of the patient, the date and observation or question. Please make sure you only communicate the anonyme voucher ID.</p>

        </div>`,
  },

  AF: {
    title: "AFIB Detection",
    content: `
        <div style="line-height: 2">
   <p>Corsano AFib functionality is based on detection algorithm based on PPG and ECG.</p>

<p>The following shows the feature schematic:</p>
        <div style="display: flex; flex-direction: column; text-align: center">
            <img src=${afProcess} width="100%"/>
        </div>
<p>Clinical benefits:</p>

<ol>
  <li><b>Early Detection and Diagnosis</b>:
  <ul>
    <li><b>PPG</b>: Non-invasive and can be integrated into wearable devices, allowing continuous monitoring and early detection of irregular heart rhythms.</li>
    <li><b>ECG</b>: Provides a detailed and accurate measurement of the electrical activity of the heart, which is the gold standard for diagnosing AF.</li>
  </ul>
  </li>
  <li><b>Continuous Monitoring</b>:
  <ul>
    <li><b>PPG</b>: Enables long-term and continuous monitoring outside of clinical settings, which helps in identifying intermittent AF episodes that might be missed during a short ECG recording.</li>
    <li><b>ECG</b>: Holter monitors or implantable devices provide continuous ECG monitoring, capturing AF episodes over days or weeks.</li>
  </ul>
  </li>
</ol>

<h3>AF - detector - description</h3>
<p>To detect atrial fibrillation, the following stages of ECG signal processing are performed:</p>

<h4>1.	Preprocessing</h4>

<p>Includes</p>
<ol type="a">
   <li>Elimination of the signal baseline drifts (ADS)</li>
   <li>Standardization of the signal amplitude (auto-amplification)</li>
   <li>Filtering noises at frequencies of 50 and 60 Hz by finite impulse response (FIR) filter</li>
</ol>

<h4>2.	Detection of ECG beats</h4>

<p>Includes</p>
<ol type="a">
  <li>Calculation of Pan-Tompkins digital convolution</li>
  <li>Automatic determination of the initial threshold value, the excess of which is considered a fact of detection of the ECG beat</li>
  <li>Using a time delay to eliminate multiple exceedings of the threshold value and, as a result, eliminate false detection of a close series of ECG beats.</li>
  <li>Dynamic correction of the threshold value to avoid missing detection of weak ECG beats</li>
</ol>



<h4>3.	Measuring elements of ECG beats</h4>

<p>Includes</p>
<ol type="a">
  <li>Calculation of the position and amplitude of the peak R</li>
  <li>Calculation of r-s interval and peak amplitude S</li>
  <li>Calculation of the q-r interval and amplitude of the Q peak</li>
  <li>Calculation of the q0-r interval and signal amplitude before the onset of the QRS beat</li>
  <li>Calculation of the position (p1-r, p-r, p2-r) and amplitude of the peak P</li>
  <li>Calculating the position (r-j) and amplitude of point J</li>
  <li>Calculation of the position (r-t1, r-t, r-t2) and amplitude of the peak T</li>
  <li>Calculation of ST segment values (amplitude of points A and B, ST level, ST delta and ST slope)</li>
</ol>

<h4>4.	Filtering (cleaning) the signal</h4>

<p>Includes</p>
<ol type="a">
  <li>B-spline interpolation [3] of the section from point J to Q0 of the next beat with a high Lambda coefficient</li>
  <li>Interpolation with a B-spline in the section from peak Q to S with low coefficient Lambda</li>
</ol>

<h4>5.	Form factor analysis</h4>

<p>When analyzing the form factor of ECG beats, the set is divided into groups based on the similarity of their shape, after which for each beat its group number (GN) and group size (GC) are recorded. Using the Fast Dynamic Time Warping (DTW) algorithm [4, 5] to measure the distance between time series (beat shapes).</p>

<h4>6.	Primary marking of ECG beats</h4>

<p>Primary marking of ECG beats is performed using the fully-connected neural network (FCN) with Residual Connections. As a result of the network operation, each beat belongs to one of the classes:</p>
<ol type="1">
  <li>Regular</li>
  <li>Allorhythmia</li>
  <li>Chaotic (atrial fibrillation)</li>
  <li>Chaotic (noise, signal not containing ECG)</li>
  <li>Induced periodic signal (IPS) (as various interference from other medical equipment)</li>
</ol>

<p>List of parameters to train neural network and detect classification code of each record beat:</p>

 
    
<table style="border: 1px solid black;   border-collapse: collapse;" class="popup-table">
  <tr><th>NN</th>	<th>Parameter</th>	<th>Index</th></tr>
  <tr><td>1</td>	<td>Instantaneous heart rate</td>	<td>HR</td></tr>
  <tr><td>2</td>	<td>Ratio of the RR interval from the previous beat to the current one and pre-previous to the previous one	</td><td>NN</td></tr>
  <tr><td>3</td>	<td>Ratio of the RR interval from the previous beat to the current one and the average value of the RR interval over 5 beats</td>	<td>NN1</td></tr>
  <tr><td>4</td>	<td>Ratio of the RR interval from the current beat to the next one and the average value of the RR interval over 5 beats</td>	<td>NN2</td></tr>
  <tr><td>5</td>	<td>Height of the beat (as the difference between the amplitudes of the R and S peak)</td>	<td>RSamp</td></tr>
  <tr><td>6</td>	<td>Beat detection quality index (ratio of the average convolution value inside the QRS section and outside it)</td>	<td>QI</td></tr>
  <tr><td>7</td>	<td>Beat deformation index (ratio of the difference between the amplitudes Q0 and point J to the difference between the amplitudes R and S of the peak)</td>	<td>DI</td></tr>
  <tr><td>8</td>	<td>Width of the beat (as the sum of q0-r and r-j interval)</td>	<td>W</td></tr>
  <tr><td>9</td>	<td>Count of beats similar in shape in the group</td>	<td>GC</td></tr>
</table>

<h4>7.	Identification of primary rhythm ranges</h4>

<p>Detection of primary ranges is performed based on the assignment of each beat to one of the classes and the formation of sequences. From these sequences, ranges of the following classes are formed:</p>

 <ol type="1">
   <li>Regular rhythm</li>
   <li>Allorhythmia (extrasystole)</li>
   <li>Chaotic rhythm (atrial fibrillation)</li>
   <li>Chaotic rhythm (noise, signal not containing ECG)</li>
   <li>Induced periodic signal (IPS) (as various interference from other medical equipment)</li>
 </ol>

<h4>8.	Rhythm detection</h4>

<p>Based on the primary rhythm ranges and data for each of the beats, rhythms belonging to one of the classes are formed:</p>

<ol type="1">
  <li>No ECG signal (including no signal, chaotic signal not containing ECG and induced periodic IPS signal)</li>
  <li>Regular rhythm</li>
  <li>Arrhythmia</li>
  <li>Allorhythmias (extrasystoles)</li>
  <li>Supraventricular rhythm (including atrial fibrillation)</li>
  <li>Ventricular rhythm</li>
</ol>

<h4>9.	Detailed classification of the regular rhythm class</h4>

<p>Based on the data obtained from sections of the regular rhythm, the following rhythm subclasses are distinguished:</p>

<ol type="1">
  <li>Rigid rhythm</li>
  <li>Normal sinus rhythm (NSR)</li>
  <li>Sinus tachycardia (TA)</li>
  <li>Sinus bradycardia (BRA)</li>
</ol>

<h4>10.	Detailed clarification of the class of supraventricular rhythm</h4>
<p>Based on the data obtained from the areas of supraventricular rhythm, the following rhythm subclasses are distinguished:</p>

 <ol type="1">
   <li>Supraventricular rhythm (without details)</li>
   <li>Atrial fibrillation</li>
 </ol>

    </div>
    `,
  },

  EMOGRAPHY: {
    title: "",
    content: `
               <h3>
                EmoGraphy
                </h3>
            <p>
                 EmoGraphy provides insight in how we respond to stress. Humans respond to stress in two ways; an immediate response to the stressor (adrenaline release) and a slower response (cortisol release) to recover from that stressor. Stress has a direct impact on our cognitive performance. High cortisol levels may negatively affect cognitive performance such as contemplation, strategic thinking and memory function. Low levels are thought to increase fatigue by making tasks appear tougher, and limiting endurance of sustained physical and mental activities.
            </p>
            <p>
            EmoGraphy provides detailed insights in this mechanism by displaying stress levels in real-time and calculating the related cognitive functioning of the brain – the CognitiveZone - that is impacted by cortisol. Through these measurements and calculations, EmoGraphy can also predict changes in cognitive abilities up to an hour ahead (assuming the user performs similar activities).
            </p>
            <p>
                EmoGraphy is based on skin conductance (EDA); an accurate measurement of sympathetic nervous system activity that reflects the body’s so-called <i>fight</i> or <i>flight</i> response. The Cognitive Zones help the user to understand how to optimize his/her cognitive performances and <i>avoid under- and over-stimulation</i>.
            </p>
            <h3>
                Stress Level
            </h3>
            <p>
                Stress Level reflects the immediate response to a stressor. It indicates the amount of physical (strong exercise, spicy food, or the temperature) and emotional stress of the user at present time.  
            </p>
            <h3>
                Cognitive Zone
            </h3>
            <p>
                Cognitive Zone is based on the slower response (cortisol release) to a stressor. By remaining in a zone of optimal emotional stimulation, you can avoid both drowsiness and too much stress. This is the objective of Cognitive Zone. The Balanced Zone represents the area of emotional fitness, essential for healthy lifestyle and optimal cognitive performance.  
            </p>
            <ul>
                <li>
                    Cognitive Zone is the current cognitive zone at present time.
                </li>
                <li>
                    Cognitive Zone in One Hour is a prediction of the cognitive zone in one hour assuming the user performs similar activities as at present time
                </li>
                <li>
                    Predictive Cognitive Zone is the next predicated cognitive zone in a number of minutes given by the Predictive Cognitive Zone Transition Time.
                </li>
                <li>
                    Predictive Cognitive Zone Transition Time is the time to reach the next predicted cognitive zone
                    <ul>
                        <li>
                            <u>Over stimulated zone</u>
                            <br/>
                            Decreased cognitive performance; strategic thinking, memory, etc. - you might want to do a relaxing exercise to reach the balanced zone for optimal performance
                        </li>
                        <li>
                            <u>Balanced zone</u>
                            <br/>
                            Optimal cognitive performance – to stay in this zone take regular breaks
                        </li>
                        <li>
                            <u>Under stimulated zone</u>
                            <br/>
                            Enhanced perception of effort, reduced endurance of mental activity – you might want to take a walk or activity to stimulate yourself to reach the balanced zone
                        </li>
                    </ul>
                </li>
            </ul>
            
            <h3>
                Cortisol Contribution
            </h3>
            <p>
                The Cognitive Zones are derived from the estimated Cortisol Contribution which is the amount of cortisol that can be accounted for by stress. Cortisol is the most accurate measure of stress but it can only be measured via blood, hair or saliva samples. By calculating the cortisol contributions, Philips is able to determine the easy-to-understand CognitiveZone.
            </p>
`,
  },
};
