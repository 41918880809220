import axios from 'axios'
import env from "react-dotenv";

import ls from 'local-storage'

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const BloodPressureAPI = {

    async getBpReportData(uuid, date){
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v1/reports/bp/${uuid}/${date}`)).data;
        return pld;
    }

}

export default BloodPressureAPI;
