import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as actions from '../../../redux/actions/AuthActions'
import CommonHelper from "../../../helpers/CommonHelper";

export default function SettingsPanel(props) {
    const dispatch = useDispatch();
    let {loading, selectedTimestamp, currentUserObj} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading || state.data.loading,
            updatedTimestamp: state.data.updatedTimestamp,
            selectedTimestamp: state.data.selectedTimestamp
        }
    }, []));

    const [deleteDataVisible, setDeleteDataVisible] = useState(false);
    const [archiveDataVisible, setArchiveDataVisible] = useState(false);

    if (currentUserObj == undefined) {
        return null;
    }
    let {healthUser, usersUser} = currentUserObj;
    if (usersUser == undefined) {
        return null;
    }
    let {first_name, last_name, email, birthday, gender} = usersUser;
    let {weight, height} = healthUser;

    return (
        <Wrapper>

            <TopHeaderPlaceholder>
                <TopLeft>
                    <span style={{fontWeight: 'bold'}}>
                        {`${first_name} ${last_name}`}
                    </span>
                </TopLeft>
                <TopRight>
                    <LogoutSpan onClick={async () => {
                        dispatch(actions.logout());
                        CommonHelper.linkTo('/');
                        window.location.reload();
                    }}>
                        Logout
                    </LogoutSpan>
                </TopRight>
            </TopHeaderPlaceholder>

            <ContentPlaceholder>
                <p>
                    {`Email: ${email}`}
                </p>
                {birthday == undefined ? null :
                    <p>
                        {`Birthday: ${birthday}`}
                    </p>
                }
                {gender == undefined ? null :
                    <p>
                        {`Gender: ${gender}`}
                    </p>
                }
                {weight == undefined ? null :
                    <p>
                        {`Weight: ${weight} kg`}
                    </p>
                }
                {height == undefined ? null :
                    <p>
                        {`Height: ${height} cm`}
                    </p>
                }

                <BottomSectionPlaceholder>
                    <BRow>
                        <Label>
                            GDPR Archive Data
                        </Label>
                        <BoButton onClick={() => {
                            setArchiveDataVisible(true);
                        }}>
                            Archive my data
                        </BoButton>
                    </BRow>

                    <BRow>
                        <Label>
                            GDPR delete data
                        </Label>
                        <BoButton onClick={() => {
                            setDeleteDataVisible(true);
                        }}>
                            Delete my data
                        </BoButton>
                    </BRow>
                </BottomSectionPlaceholder>

            </ContentPlaceholder>

            <Sidebar visible={deleteDataVisible} onCloserClick={() => {
                setDeleteDataVisible(false);
            }}>

                <h2>
                    Thank you for your request!
                </h2>

                <p>
                    We will delete your data in 1-2 workdays. Please note that we cannot reverse this action, your data
                    will be permanently deleted.
                    <br/>
                    Please contact us if any questions.
                </p>

            </Sidebar>

            <Sidebar visible={archiveDataVisible} onCloserClick={() => {
                setArchiveDataVisible(false);
            }}>

                <h2>
                    Thank you for your request!
                </h2>

                <p>
                    We will send you your data in 1-2 workdays.
                    <br/>
                    Please contact us if any questions.
                </p>

            </Sidebar>

            {/*{JSON.stringify(currentUserObj)}*/}

        </Wrapper>
    );
}

const BoButton = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  background: rgb(14, 20, 24);
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  align-items: center;
  justify-content: center;

  :hover {
    opacity: 0.8;
  }
`;

const BRow = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  opacity: 0.7;
`;

const Wrapper = styled.div`

`;

const ContentPlaceholder = styled.div`

`;

const BottomSectionPlaceholder = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid lightgrey;
`;

const TopHeaderPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TopLeft = styled.div`

`;

const TopRight = styled.div`

`;

const LogoutSpan = styled.span`
  opacity: 0.75;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;