import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function SurveyViewerTool(props) {
    let items = Object.keys(props).map(x => ({
        key: x,
        value: props[x]
    }))

    return (
        <Wrapper>

            {items.map((a, i) => {
                return (
                    <Row key={i}>
                        <Label>
                            {a.key}
                        </Label>
                        <Value>
                            {typeof a.value == 'object' ? a.value.join(', ') : a.value}
                        </Value>
                    </Row>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid whitesmoke;
`;

const Label = styled.div`
  font-weight: bold;
  opacity: 0.7;
`;

const Value = styled.div`

`;