import { Map } from "immutable";
import * as types from "../ActionTypes.js";

const initialState = {
  loading: false,
  gatewaysMap: Map(),
  error: undefined,
};

// const startLoading = (state, action) => {
//   return { ...state, loading: true, error: undefined };
// };

// const stopLoading = (state, action) => {
//   return { ...state, loading: false, error: action.error };
// };

const GatewaysReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_GATEWAY:
    case types.UPDATE_GATEWAY:
    case types.DELETE_GATEWAY:
    case types.LOAD_GATEWAYS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case types.CREATE_GATEWAY_FAIL:
    case types.UPDATE_GATEWAY_FAIL:
    case types.DELETE_GATEWAY_FAIL:
    case types.LOAD_GATEWAYS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.CREATE_GATEWAY_SUCCESS:
    case types.UPDATE_GATEWAY_SUCCESS:
      return {
        ...state,
        loading: false,
        gatewaysMap: state.gatewaysMap.set(action.gateway.id, action.gateway),
      };

    case types.DELETE_GATEWAY_SUCCESS:
      return {
        ...state,
        loading: false,
        gatewaysMap: state.gatewaysMap.delete(action.id),
      };

    case types.LOAD_GATEWAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        gatewaysMap: state.gatewaysMap.merge(
          action.gateways.reduce((map, form) => map.set(form.id, form), Map())
        ),
      };

    default:
      return state;
  }
};

export default GatewaysReducer;
