import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SimpleSelectableLineChart from "../../charts/tools/SimpleSelectableLineChart";
import CardioHelper from "../../../helpers/CardioHelper";

export default function HrvChart(props) {
    const {points = [], stepDuration = 5 * 1000, startTimestamp = 0, paramName = 'avrHR', windowSize = 120 * 1000} = props;
    let pts = points.map((a, i) => ({t: +startTimestamp + stepDuration * i + +windowSize, value: a[paramName]}));
    if (pts.length == 0) {
        return null;
    }
    // console.log('HrvChart: render: pts = ', pts);
    let dPoints = CardioHelper.makePointsDownsampling(pts, 900);

    return (
        <Wrapper>
            <SimpleSelectableLineChart
                name={'hrv_chart'}
                points={dPoints} hasOverlay={false}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
