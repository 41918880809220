import {Set} from 'immutable'

function mean(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
        throw new Error("Input must be a non-empty array");
    }

    const sum = arr.reduce((acc, val) => {
        if (typeof val !== 'number') {
            throw new Error("Array must contain only numbers");
        }
        return acc + val;
    }, 0);

    return sum / arr.length;
}

function std(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
        throw new Error("Input must be a non-empty array");
    }

    if (arr.length === 1) {
        return 0;  // Standard deviation of a single value is 0
    }

    const arrMean = mean(arr);
    const variance = arr.reduce((acc, val) => {
        if (typeof val !== 'number') {
            throw new Error("Array must contain only numbers");
        }
        return acc + Math.pow(val - arrMean, 2);
    }, 0) / (arr.length - 1);

    return Math.sqrt(variance);
}

const CardioHelper = {

    getTotalRecordedTime(sessions) {
        return sessions.reduce((sum, sess) => (sess.lastPointTimestamp == undefined ? +sum : (+sum + +sess.lastPointTimestamp - +sess.startTimestamp)), 0);
    },

    getTotalHeartbeatsNumber(sessions) {
        return sessions.reduce((sum, sess) => (+sum + +sess.savedPointsNumber), 0);
    },

    getMax(arr = [], max = 0) {
        return arr.reduce((mx, a) => (+a > +mx ? +a : +mx), max);
    },

    makePointsDownsampling(points, max = 700) {
        if (points.length <= max) {
            return points;
        }
        let arr = [];
        let n = points.length;
        console.log('makePointsDownsampling: length = ' + n);
        let startTime = +points[0].t;
        let endTime = +points[n - 1].t;
        let dt = 1.0 * (endTime - startTime) / max;

        let step = 1.0 * n / max;
        for (let i = 0; i < max; i++) {
            let a = Math.ceil(step * i);
            let b = Math.floor(step * (i + 1));
            b = Math.min(b, n - 1);
            let sum = 0;
            let t = +points[a].t;
            let kk = 0;
            for (let j = a; j <= b; j++) {
                sum += +points[j].value;
                kk++;
            }
            let avr = 1.0 * sum / kk;
            arr.push({
                t: t,
                value: avr
            });
        }
        return arr;
    },

    getArtifactsIndices(points) {
        console.log('getArtifactsIndices: points = ', points);
        if (points == undefined || points.length == 0) {
            return [];
        }
        console.log('getArtifactsIndices: points = ', points);
        let windowSize = 20;
        let step = 5;
        let from = 1;
        let to = Math.min(points.length, +from + windowSize);
        let artifactsIndicesSet = Set();
        while (to < points.length) {
            let pts = points.slice(from, to);
            let rrs = pts.map(a => a.rr);
            // console.log('befre mean: rrs = ', rrs);
            let m = mean(rrs);
            let st = std(rrs);
            let a = +m - 2.0 * st;
            let b = +m + 2.0 * st;
            for (let i = from; i < Math.min(to, points.length - 2); i++) {
                let RRi_minus_1 = points[i - 1].rr;
                let RRi = points[i].rr;
                let RRi_plus_one = points[i + 1].rr;
                let C0 = (RRi < a || RRi > b);
                let C1 = (RRi < a && RRi_plus_one > b);
                let C2 = (RRi < 0.75 * RRi_minus_1 || RRi_plus_one < 0.75 * RRi_minus_1);
                let C3 = (RRi > 1.75 * RRi_minus_1);
                if (C0 && (C1 || C2 || C3)) {
                    artifactsIndicesSet = artifactsIndicesSet.add(i);
                }
            }
            from = +from + +step;
            to = +from + +windowSize;
        }
        return artifactsIndicesSet.toArray().sort((a, b) => (+a - +b));
    },

    getHeartRateZones(age) {
        let maxHr = 220 - +age;
        let arr = [
            {
                color: '#D92A3E',
                to: maxHr,
                from: 0.9 * maxHr,
                name: 'Max',
                description: '',
                benefits: 'Increases maximum sprint race speed',
                feelsLike: 'Very exhausting for breathing and muscles',
                recommendedFor: 'Very fit persons with athletic training background',
            },
            {
                color: '#F1AB17',
                to: 0.89 * maxHr,
                from: 0.8 * maxHr,
                name: 'Performance',
                description: '',
                benefits: 'Increases maximum performance capacity',
                feelsLike: 'Muscular fatigue and heavy breathing',
                recommendedFor: 'Fit users and for short exercises'
            },
            {
                color: '#2BA054',
                to: 0.79 * maxHr,
                from: 0.7 * maxHr,
                name: 'Endurance',
                description: '',
                benefits: 'Improves aerobic fitness',
                feelsLike: 'Light muscular fatigue, easy breathing, moderate sweating',
                recommendedFor: 'Everybody for typical, moderately long exercises'
            },
            {
                color: '#369BE3',
                to: 0.69 * maxHr,
                from: 0.6 * maxHr,
                name: 'Fat Burn',
                description: '',
                benefits: 'Improves basic endurance and helps recovery',
                feelsLike: 'Comfortable, easy breathing, low muscle load, light sweating',
                recommendedFor: 'Everybody for longer and frequently repeated shorter exercises'
            },
            {
                color: '#A4A4A4',
                to: 0.59 * maxHr,
                from: 0.5 * maxHr,
                name: 'Warm Up',
                description: '',
                benefits: 'Improves overall health and metabolism, helps recovery',
                feelsLike: 'Very easy for breathing and muscles',
                recommendedFor: 'Basic training for novice exercises, weight management and active recovery'
            },
            {
                color: '#A4A4A4',
                to: 0.49 * maxHr,
                from: 0,
                name: 'Rest',
                description: '',
                benefits: '',
                feelsLike: '',
                recommendedFor: ''
            }
        ].sort((a, b) => (+a.to - +b.to));
        return arr;
    },

    getMappedRanges(ranges, from, to) {
        // console.log('getMappedRanges: ranges, from, to = ', ranges, from, to);
        let arr = [];
        let isIn = (x, a, b) => (+x >= a && +x <= +b);
        let filteredRanges = ranges.filter(r => (isIn(r.from, from, to) || isIn(r.to, from, to)));
        let newRanges = filteredRanges.map(r => ({...r, from: Math.max(+from, +r.from), to: Math.min(+to, +r.to)}));
        // console.log('getMappedRanges: newRanges = ', newRanges);
        return newRanges;
    },

    getRandomSleepPoints(from, to, step = 10 * 60 * 1000) {
        let duration = +to - +from;
        let n = Math.ceil(1.0 * duration / step);
        let arr = [];
        for (let i = 0; i < n; i++) {
            let d = 1 + Math.round(Math.random() * 3);
            // let d = Math.round(Math.random() * 4);
            let t = +from + i * step;
            arr.push({
                from: t,
                to: +t + step,
                value: d
            });
        }
        return arr;
    },

    getGroupedSleepPoints(points = []) {
        let result = [];
        if (points.length == 0) {
            return [];
        }
        let currPoint = points[0];
        for (let i in points) {
            let p = points[i];
            if (currPoint.value == p.value) {
                currPoint.to = p.to;
            } else {
                result.push(currPoint);
                currPoint = p;
            }
        }
        result.push(currPoint);
        return result;
    },

    saveRealtimeData(d){
        let key = `realtimeData`;
        if (window[key] == undefined){
            window[key] = {};
        }
        let {uuid} = d;
        if (window[key][uuid] == undefined){
            window[key][uuid] = [];
        }
        window[key][uuid] = window[key][uuid].concat([d]);
    },

    getRealtimeData(){
        let key = `realtimeData`;
        if (window[key] == undefined){
            window[key] = {};
        }
        return window[key];
    }

}

export default CardioHelper;
