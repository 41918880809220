import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import { CORSANO_API_ENDPOINT } from "../../../constants/config";
import axios from "axios";
import toast from "react-hot-toast";
import Spin from "arui-feather/spin";
import { Input, Button } from "../../ui/templates";

export function getMaskedEmail(email) {
  if (email == undefined || email.indexOf("@") == -1) {
    return email;
  }
  let parts = email.split("@");
  let first = parts[0];
  let second = parts[1];
  let firstPart = first.substring(0, 3);
  let secondPart = first.substring(3);
  let secondPartFirst = second.substring(0, 2);
  let secondPartSecond = second.substring(2);
  let domain = second.split(".");
  let lastPart = domain[domain.length - 1];

  return `${firstPart}***@${secondPartFirst}**${lastPart}`;
}

export default function FindHcpTool(props) {
  const { onAdd = (x) => {} } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [text, setText] = useState("");
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      searchHCP();
    }
  }

  const searchHCP = async () => {
    if (loading == true) {
      return;
    }
    setLoading(true);
    let pld = (
      await axios.get(`${CORSANO_API_ENDPOINT}/find-hcps`, {
        params: {
          search: text,
        },
      })
    ).data;
    toast.success("Found " + pld.length + " HCPs");
    setItems(pld);
    setLoading(false);
  };

  return (
    <Wrapper>
      <TopPlaceholder>
        <Label className="normal-text">Find HCP</Label>
        {/* <p>The patient</p> */}
        <TopFindPlaceholder>
          <Input
            $mode={"mode1"}
            placeholder={"Search by name or email"}
            value={text}
            onChange={(evt) => {
              setText(evt.target.value);
            }}
            style={{ marginRight: 15 }}
            onKeyDown={handleKeyDown}
          />

          <Button onClick={searchHCP}>
            <Spin visible={loading} theme={"alfa-on-color"} />
            {loading == true ? null : <span>Find</span>}
          </Button>
        </TopFindPlaceholder>
      </TopPlaceholder>

      {items.length == 0 ? null : (
        <ItemsPlaceholder>
          {items.map((a, i) => {
            return (
              <BraceletItem
                key={a.uuid}
                onClick={() => {
                  onAdd(a);
                }}
              >
                {`${a?.first_name || ""} ${a?.last_name || ""}`}
                <div
                  style={{
                    opacity: 0.5,
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                >
                  {`${getMaskedEmail(a?.email || "")}`}
                </div>
              </BraceletItem>
            );
          })}
        </ItemsPlaceholder>
      )}
    </Wrapper>
  );
}

const TopFindPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const normalBorder = `1px solid rgba(135, 152, 173, 0.15)`;
const dangerBorder = `2px solid #FA4646;`;

const BraceletItem = styled.div`
  padding: 10px;
  border-radius: 4px;
  background: #ffffff;
  border: ${(props) => (props.danger == true ? dangerBorder : normalBorder)};
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    background: whitesmoke;
  }
`;

const ItemsPlaceholder = styled.div``;

const TopPlaceholder = styled.div``;

const Wrapper = styled.div``;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
`;
