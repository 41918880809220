import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import moment from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import UpdateUserInfoForm from "../forms/UpdateUserInfoForm";

import Spin from "arui-feather/spin";
import { useMappedState } from "redux-react-hook";
import Tabs, { TabItem } from "../../ui/Tabs";
import UsersInterfaceAPI from "../../../api/UsersInterfaceAPI";
import alarms_icon from "../../dashboard/panels/images/alarm_icon.png";
import PatientRangesTool from "../../ranges/tools/PatientRangesTool";
import { Button } from "../../auth/ui";
import ResetPasswordTool from "../tools/ResetPasswordTool";
import CommonHelper from "../../../helpers/CommonHelper";
import { isCardioMoodMode } from "../../apps/RouterApp";
import DictionariesAPI from "../../../api/DictionariesAPI";
import { toast } from "react-hot-toast";

export default function EditPatientPanel(props) {
  const { id } = props;
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState(undefined);
  const [device, setDevice] = useState(undefined);

  const [healthSettings, setHealthSettings] = useState(undefined);

  const [statisticsSelectedDayTimestamp, setStatisticsSelectedDayTimestamp] =
    useState();

  const [complianceCurrentMonthTimestamp, setComplianceCurrentMonthTimestamp] =
    useState(+moment().startOf("month").add(2, "days"));

  const [statisticsCalendarOpen, setStatisticsCalendarOpen] = useState(false);
  const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(false);

  const [selectedRanges, setSelectedRanges] = useState({});
  const [rangesLoading, setRangesLoading] = useState(false);

  useEffect(() => {
    setRangesLoading(true);
    UsersInterfaceAPI.getLazySettings(id).then((x) => {
      console.log("setSelectedRanges: x = ", x);
      setSelectedRanges(x.ranges);
      setRangesLoading(false);
    });
    DoctorAPI.getPatientHealthUserSettings(id).then((x) => {
      console.log("!!! got health settings! x = ", x);
      setHealthSettings(x);
    });
  }, [id]);

  const { currentUserObj, email, isDemo, inmate } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      let allInmates = state.inmates.inmatesMap.toArray();
      let inmate = allInmates.filter((x) => x.uuid == id)[0];
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        isDemo: email == "demo@corsano.com_",
        inmate: inmate,
      };
    })
  );

  let tag = currentUserObj?.usersUser?.customer_tag;

  let currentUUID = currentUserObj?.healthUser?.uuid;
  const [dictionaryItems, setDictionaryItems] = useState([]);
  const [dictionaryData, setDictionaryData] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getPatient(id).then((x) => {
      console.log("got patient! x = ", x);
      setPatient(x);
      setLoading(false);
      DoctorAPI.getPatientDevice(id).then((d) => {
        console.log("device = ", d);
      });
    });
    DictionariesAPI.getUserItems(currentUUID).then((x) => {
      setDictionaryItems(x);
    });
    DictionariesAPI.getUserItems(id).then((arr) => {
      let arr2 = arr.filter((x) => x.type == "dictionary_data");
      let a = arr2.length == 0 ? undefined : arr2[0];
      setDictionaryData(a?.data);
    });
  }, [id]);

  if (loading == true && patient == undefined) {
    return <Code />;
  }

  if (patient == undefined) {
    return null;
  }

  let { first_name, last_name, healthUser } = patient;
  let name = `${first_name == undefined ? "-" : first_name} ${
    last_name == undefined ? "-" : last_name
  }`;
  let weight = healthUser == undefined ? undefined : healthUser.weight;
  let wearing =
    healthSettings == undefined ? undefined : healthSettings?.wearing;
  let height = healthUser == undefined ? undefined : healthUser.height;
  let gender = healthUser == undefined ? undefined : healthUser.gender;
  let birthday = healthUser == undefined ? undefined : healthUser.birthday;

  let hairDensity = healthUser?.hair_density;
  let skinType = healthUser?.skin_color;

  if (name == "- -") {
    let { code, email } = patient;
    if (code != undefined) {
      name = code;
    }
    // if (inmate?.name != undefined) {
    //     name = `${name} [${inmate?.name}]`;
    // }
    console.log("- -", patient);
  }

  return (
    <Wrapper>
      <HeadingPlaceholder className="heading">
        {`Edit profile | ${CommonHelper.getPatientHeadingString(patient)}`}
      </HeadingPlaceholder>

      <FormPlaceholder>
        <Tabs
          tabs={[
            {
              label: "Profile",
              content: (
                <TabItem>
                  <UpdateUserInfoForm
                    {...patient}
                    dictionaryItems={dictionaryItems}
                    dictionaryData={dictionaryData}
                    weight={weight}
                    height={height}
                    wearing={wearing}
                    gender={gender}
                    birthday={birthday}
                    skinType={skinType}
                    hairDensity={hairDensity}
                    loading={loading}
                    onSave={async (d, dictData) => {
                      if (isDemo == true) {
                        return window.alert(
                          "This is a demo portal. Please contact admin for full access."
                        );
                      }
                      console.log("onSave: dictData = ", dictData, d);
                      setLoading(true);
                      await DoctorAPI.updateUsersProfile(id, d);
                      await DoctorAPI.updateHealthProfile(id, d);
                      if (d.wearing != undefined) {
                        await DoctorAPI.savePatientHealthUserSettings(id, {
                          wearing: d.wearing,
                        });
                      }
                      await DictionariesAPI.saveUserItem({
                        uuid: id,
                        type: "dictionary_data",
                        data: dictData,
                      });
                      setLoading(false);
                      toast("Saved!");
                      if (window.location.href.indexOf("portal") > -1) {
                        CommonHelper.linkTo(`/departments/${tag}/user/${id}`);
                      } else {
                        CommonHelper.linkTo(`/studies/${tag}/user/${id}`);
                      }
                    }}
                  />
                </TabItem>
              ),
            },
            true == false
              ? {
                  label: "Alarms",
                  content: (
                    <TabItem>
                      <AlarmsInnerPlaceholder>
                        <h2
                          style={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ marginRight: 10 }}>Alarms</span>
                          <img
                            src={alarms_icon}
                            style={{ height: 40, marginRight: 10 }}
                          />
                        </h2>

                        <PatientRangesTool
                          ranges={selectedRanges}
                          onChange={(newRanges) => {
                            setSelectedRanges(newRanges);
                          }}
                        />

                        <div>
                          <Button
                            onClick={async () => {
                              setRangesLoading(true);
                              await UsersInterfaceAPI.updateUserSettings({
                                uuid: id,
                                ranges: selectedRanges,
                              });
                              setRangesLoading(false);
                            }}
                          >
                            <Spin visible={rangesLoading} />
                            {rangesLoading == true ? null : "Save"}
                          </Button>
                        </div>
                      </AlarmsInnerPlaceholder>
                    </TabItem>
                  ),
                }
              : undefined,
            window.location.href.indexOf("cardiomood") > -1
              ? undefined
              : {
                  label: "Reset password",
                  content: (
                    <TabItem>
                      {patient?.email != undefined && patient?.email != "" ? (
                        <div className="psd-reset">
                          {`You can reset the password in the Corsano app with the "forgot my password" button.`}
                        </div>
                      ) : (
                        <div className="psd-reset">
                          To reset password, please contact{" "}
                          <a href={"mailto:philippe.fraboulet@corsano.com"}>
                            philippe.fraboulet@corsano.com
                          </a>
                          . For Voucher users, there is no email. The Study
                          Owner must contact Corsano and request a new password.
                          Please send email to Philippe with Voucher Code and he
                          will reset the PW for that Voucher and inform you.
                        </div>
                      )}

                      {/*philippe.fraboulet@corsano.com*/}
                      {/*The Study Owner must contact Corsano and request a new password.*/}
                      {/*Only registered Study Owners are entitled to request a new password.*/}
                      {/*Please send email to Philippe with Voucher Code and he will reset the PW for that Voucher and inform you.*/}
                      {/*<ResetPasswordTool/>*/}
                    </TabItem>
                  ),
                },
          ].filter((x) => x != undefined)}
        />
      </FormPlaceholder>

      {/*{JSON.stringify(patient)}*/}
    </Wrapper>
  );
}

const AlarmsInnerPlaceholder = styled.div`
  width: 820px;
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const FormPlaceholder = styled.div``;

const HeadingPlaceholder = styled.div`
  text-align: center;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 40px;
  font-weight: bold;
  margin-top: 40px;
`;

const Wrapper = styled.div`
  width: 100%;
`;
