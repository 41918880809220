import { useCallback } from "react";
import styled from "styled-components";
import DoctorTemplate from "../templates/DoctorTemplate";
import DoctorBraceletsPanel from "../bracelets/panels/DoctorBraceletsPanel";
import { useMappedState } from "redux-react-hook";
import { useParams } from "react-router-dom";

function BraceletsApp(props) {
  let params = useParams();
  let { id, tag } = params;

  let { currentUserObj } = useMappedState(
    useCallback((state) => {
      return {
        currentUserObj: state.auth.currentUserObj,
      };
    }, [])
  );
  let currentUUID = currentUserObj?.healthUser?.uuid;
  // let isDemo = false;
  let isDemo = id != undefined && id.indexOf("demo") > -1;

  return (
    <DoctorTemplate active={"bracelets"}>
      <Wrapper>
        <DoctorBraceletsPanel uuid={currentUUID} />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default BraceletsApp;
