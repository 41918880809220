import {useState, useEffect} from 'react';
import styled from 'styled-components';

import axios from 'axios'
import {CORSANO_API_ENDPOINT} from "../../constants/config";
import BpSquareTool from "../bp/tools/BpSquareTool";

export default function DevApp(props) {
    const [myInfo, setMyInfo] = useState(undefined);

    useEffect(() => {
        axios.get(`${CORSANO_API_ENDPOINT}/v3/my-info`).then(d => d.data).then(d => {
            setMyInfo(d);
            console.log('my info = ', myInfo);
        })
    }, []);

    return (
        <Wrapper>

            <BpSquareTool/>

            {/*<PrintVoucherLetterTool*/}
            {/*    codes={['CODE11', 'CODE22', 'CODE33']}*/}
            {/*    template={'Hello, [QR CODE] world!'}*/}
            {/*/>*/}

            <InnerPlaceholder>

                {/*<GaitViewTool*/}
                {/*    type={'speed'}*/}
                {/*    value={1.5}*/}
                {/*/>*/}

                {/*<GenealAITool/>*/}

                {/*<VitalParametersEditor*/}
                {/*    onSave={d => {*/}
                {/*        console.log('VitalParametersEditor: onSave: d = ', d);*/}
                {/*    }}*/}
                {/*/>*/}

                {/*<PatientRangesTool*/}
                {/*/>*/}

                {/*<NewRangeTool*/}

                {/*/>*/}

                {/*<SastTool/>*/}

                {/*<pre dangerouslySetInnerHTML={{__html: JSON.stringify(myInfo, undefined, 2)}}>*/}

                {/*</pre>*/}

            </InnerPlaceholder>

            {/*<ExportHrvValuesPanel/>*/}


        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 15px;
`;

const InnerPlaceholder = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  //width: 1420px;
  width: 1020px;
  padding: 40px;
`;