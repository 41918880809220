import React, { useState } from "react";
import styled from "styled-components";
import Spin from "arui-feather/spin";
import { Button, Input } from "../../ui/templates";

export default function NoteForm(props) {
  const [text, setText] = useState(props.text == undefined ? "" : props.text);
  const {
    onSave = (d) => {},
    buttonName = "Save",
    loading = false,
    placeholder = "Your comment",
  } = props;

  return (
    <Wrapper>
      <InputPlaceholder>
        <Input
          value={text}
          className="patient-note-input"
          placeholder={placeholder}
          onChange={(evt) => {
            setText(evt.target.value);
          }}
        />
      </InputPlaceholder>
      <Button
        onClick={() => {
          if (loading == true) {
            return;
          }
          if (text == undefined || text == "") {
            return;
          }
          onSave({
            text: text,
          });
          setText("");
        }}
      >
        <Spin visible={loading} />
        {loading == true ? null : buttonName}
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputPlaceholder = styled.div`
  flex: 1;
  margin-right: 30px;
`;
