import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import useComponentSize from "@rehooks/component-size";

import './style.css'

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Area, ReferenceDot, AreaChart
} from 'recharts';
import CardioHelper from "../../../helpers/CardioHelper";

export const gradientOffset = (data) => {
    const dataMax = Math.max(...data.map((i) => i.value));
    const dataMin = Math.min(...data.map((i) => i.value));

    if (dataMax <= 0) {
        return 0
    }
    else if (dataMin >= 0) {
        return 1
    }
    else {
        return dataMax / (dataMax - dataMin);
    }
}

export default function PnsSnsAreaChart(props) {
    const {
        name = '',
        points = [], fromNumber = undefined, toNumber = undefined,
        hasOverlay = false,
        overlayBackground = `rgba(0, 0, 0, 0.01)`,
        xAxisMomentFormatString = 'HH:mm:ss',
        referenceDotColor = 'red',
        timeMode = 'astro',
        t0 = 0,
        colorRanges = [],
        vertValueOffset = 1
    } = props;
    let sPoints = points.map(a => ({...a, value: +a.value - vertValueOffset}))

    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;

    // console.log('PnsSnsAreaChart: render: points = ', points);
    const off = gradientOffset(sPoints);

    return (
        <Wrapper ref={ref}>
            {width == 0 || height == 0 ? null :
                <ChartPlaceholder width={width} height={height}>
                    <AreaChart width={width} height={height}
                               data={sPoints.map((a, j) => ({
                                   ...a,
                                   t: +a.t - (timeMode == 'astro' ? 0 : t0)
                               }))}
                               margin={{top: 0, right: 10, left: -25, bottom: -5}}>
                        <XAxis tickCount={7} dataKey="t" tickFormatter={a => {
                            return (timeMode == 'astro' ? moment(a).format(xAxisMomentFormatString) : moment(a).format(xAxisMomentFormatString))
                        }}/>
                        <Tooltip  labelFormatter={t => moment(t).format(xAxisMomentFormatString)} />
                        <defs>
                            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset={off} stopColor="green" stopOpacity={1}/>
                                <stop offset={off} stopColor="red" stopOpacity={1}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>

                        <Area type="monotone" dataKey="value" stroke="#666" fill="url(#splitColor)"/>

                    </AreaChart>
                </ChartPlaceholder>
            }


            {hasOverlay == false ? null :
                <Overlay overlayBackground={overlayBackground}>
                </Overlay>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ChartPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const Overlay = styled.div`
    position: absolute;
    background: ${props => props.overlayBackground};
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 2;
`;
