import moment from "moment";

const BaselineHelper = {
  getXaxisTicksEachHour(startOfDayTimestamp) {
    let xticks = [];
    const tickNum = 24;
    for (let i = 0; i < tickNum; i++) {
      xticks.push(moment(startOfDayTimestamp).add(i, "hour").valueOf());
    }

    return xticks;
  },
  getXaxisTicksEachHourWithTz(startOfDayTimestamp, tz) {
    let xticks = [];
    const tickNum = 24;
    for (let i = 0; i < tickNum; i++) {
      xticks.push(
        moment(startOfDayTimestamp)
          .add(i + tz, "hour")
          .valueOf()
      );
    }

    return xticks;
  },

  calculateMAFromArray(data, keyName) {
    let length = data.length;
    if (!data || length === 0) {
      return 0;
    }
    let sum = 0;
    let validSpots = 0;
    let _f = undefined;
    for (let i = 0; i <= length - 1; i++) {
      let _s = data[i][keyName];
      if (_s) {
        _f = _s;
        sum += _s;
        validSpots += 1;
      }
    }
    // console.log("sum valid", sum, validSpots);

    let avg = (sum / validSpots).toFixed(1);
    if (isNaN(avg)) {
      avg = _f;
    }
    return avg;
  },

  calculateWMAFromArray(data, keyName, t0) {
    /*
     * Weighted moving average
     */
    let length = data.length;
    if (!data || length === 0) {
      return 0;
    }

    const calculateWeight = (minutesBefore) => {
      if (minutesBefore <= 5) {
        return 0.8;
      } else if (minutesBefore > 5 && minutesBefore <= 10) {
        return 0.1;
      } else if (minutesBefore > 10) {
        return 0.1;
      } else {
        return 1;
      }
    };
    let sum = 0;
    let validSpots = 0;
    let _f = undefined;
    for (let i = 0; i <= length - 1; i++) {
      let _s = data[i][keyName];
      let t1 = moment(data[i].slot);
      let _diff = t0.diff(t1, "minutes");
      let _weight = calculateWeight(_diff);
      //   console.log("_diff", _diff, _weight);

      if (_s) {
        _f = _s * _weight;
        sum += _s * _weight;
        validSpots += _weight;
      }
    }

    let avg = (sum / validSpots).toFixed(1);
    if (isNaN(avg)) {
      avg = _f;
    }
    return avg;
  },

  calculateBWMAFromArray(data, keyName, t0, rateOfChange) {
    /*
     * Weighted moving average bi direction
     */
    let length = data.length;
    if (!data || length === 0) {
      return 0;
    }

    let sum = 0;
    let validSpots = 0;
    let _f = undefined;

    for (let i = 0; i <= length - 1; i++) {
      let current = data[i][keyName];
      //   let prev = i > 0 ? data[i - 1][keyName] : current;
      //   if (prev === null || prev === undefined) {
      //     prev = current;
      //   }
      //   let t1 = moment(data[i].slot);
      // let t1 = moment(data[i].t);
      // let _diff = t0.diff(t1, "minutes");
      let t1 = +data[i].t;
      let _diff = (+t0 - t1) / 60000;
      let _weight = rateOfChange < 0 ? calculateWeight(_diff) : 1;
      //   let _weight = 1;
      //   console.log("_diff", rateOfChange, _weight);

      if (current) {
        _f = current * _weight;
        sum += current * _weight;
        validSpots += _weight;
      }
    }

    let avg = (sum / validSpots).toFixed(1);
    // let avg = validSpots === 1 ? null : (sum / validSpots).toFixed(1);
    if (isNaN(avg)) {
      avg = _f;
    }
    return avg;
  },
};

const calculateWeight = (minutesBefore) => {
  if (minutesBefore <= 5) {
    return 0.9;
  } else if (minutesBefore > 5 && minutesBefore <= 10) {
    return 0.05;
  } else if (minutesBefore > 10) {
    return 0.05;
  } else {
    return 1;
  }
};
export default BaselineHelper;
