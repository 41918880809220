const CommonHelper = {

    // getTimestampFromDateString(s) {
    //     // new Date(Date.parse('2021-02-24T22:47:03.000Z'))
    //     if (s == undefined) {
    //         return +new Date();
    //     }
    //     if (s.indexOf('T') == -1) {
    //         s = `${s}T00:00:00.000Z`;
    //     }
    //     return Date.parse(`${s}.000Z`);
    // },

    getTimestampFromDateString(s) {
        // new Date(Date.parse('2021-02-24T22:47:03.000Z'))
        if (s == undefined) {
            return +new Date();
        }
        if (s.indexOf('T') == -1) {
            s = `${s}T00:00:00`;
        }

        function parseISOLocal(s) {
            var b = s.split(/\D/);
            return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
        }

        return +parseISOLocal(s);
    },


    linkTo(patientUrl, inNewTab = false) {
        let url = window.location.origin + window.location.pathname + '#' + patientUrl;
        if (inNewTab == true) {
            window.open(url, '_blank').focus();
        } else {
            window.location.href = url;
        }
    },

    roundUp(num, precision) {
        precision = Math.pow(10, precision)
        return Math.ceil(num * precision) / precision
    },

    downloadFile(filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    },

    getPatientHeadingString(patient) {
        if (patient == undefined) {
            return '';
        }
        let {first_name, last_name, code} = patient;
        let fn = (first_name == undefined || first_name == '') ? 'Patient' : first_name;
        let ln = (last_name == undefined || last_name == '') ? (code == undefined || code == '' ? '' : code) : last_name;
        let name = `${fn} ${ln}`;
        if (code != undefined) {
            name = `${code} [${name}]`;
        }
        return name;
    }


};

export default CommonHelper;
