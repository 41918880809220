import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function TogglerComponent(props) {
    const {
        options = [],
        value,
        onChange = x => {

        }
    } = props;


    return (
        <Wrapper>

            {options.map((a, i) => {
                let isSelected = (a.value == value);
                return (
                    <Item key={`${i}`} selected={isSelected} onClick={() => {
                        onChange(a.value);
                    }} >
                        {a.label}
                    </Item>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 1px solid whitesmoke;
`;

const Item = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  background: ${props => (props.selected == true ? '#147AFF' : 'white')};
  color: ${props => (props.selected == true ? 'white' : 'black')};
  :first-of-type{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  :last-of-type{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
