import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import Select from "react-select";

import './style.css'

import * as actions from '../../../redux/actions/BraceletsActions'


export default function SelectStudyTool(props) {
    const {
        placeholder = 'None',

        studyTag,

        onChange = newStudyTag => {

        }

    } = props;
    const dispatch = useDispatch();

    const {
        currentUserObj,
        email,
        isDemo,
        groups
    } = useMappedState(useCallback(state => {
        let email = state?.auth?.currentUserObj?.usersUser?.email;
        return {
            currentUserObj: state.auth.currentUserObj,
            email: email,
            isDemo: (email == 'demo@corsano.com_'),
            groups: state.groups.groupsMap.toArray().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            })
        }
    }));

    const options = groups.map((a, i) => {
        return {
            value: a.code,
            label: a.code
        }
    })

    // console.log('SelectBraceletTool: render: all bracelets = ', bracelets);
    //
    // let patientsBraceletsMap = getBraceletsMap(bracelets);
    // let avBraceletsOptions = bracelets.filter(x => (x.patientUUID == '' || x.patientUUID == undefined || x.patientUUID == patientUUID)).map(x => ({
    //     value: x.id,
    //     label: x.name,
    //     patientUUID: x.patientUUID
    // }));

    return (
        <Wrapper className={'SelectStudyTool'}>

            <Select
                placeholder={placeholder}
                isClearable={true}
                options={options}
                value={options.filter(x => x.value == studyTag)}
                onChange={async x => {
                    onChange(x?.value);
                    // if (x == undefined) {
                    //     let br = gr.filter(x => (x.patientUUID == studyTag))[0];
                    //     if (br != undefined) {
                    //         await dispatch(actions.updateBracelet(currentUserObj?.usersUser?.uuid, {
                    //             id: br.id,
                    //             patientUUID: ''
                    //         }));
                    //     }
                    //
                    // } else {
                    //     let brId = x.value;
                    //     await dispatch(actions.updateBracelet(currentUserObj?.usersUser?.uuid, {
                    //         id: brId,
                    //         patientUUID: patientUUID
                    //     }));
                    // }
                }}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;