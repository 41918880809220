import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {useInterval} from "../../apps/RouterApp";
import ExportAPI from "../../../api/ExportAPI";
import NewExportAPI from "../../../api/NewExportAPI";
import ProgressTool from "../../progress/tools/ProgressTool";

import download_img from '../../../assets/images/download_folder_2.svg'

function stringHash(s) {
    let x = s.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a
    }, 0);
    return `x${x}`;
}

export default function NewExportServiceTool(props) {
    const {
        userId,
        type,
        from, to,
        name = '',
        expUserId
    } = props;

    const [job, setJob] = useState(undefined);
    const [jobId, setJobId] = useState(undefined);
    const [exportFinished, setExportFinished] = useState(false);
    const [notingToExport, setNothingToExport] = useState(false);

    useEffect(() => {
        NewExportAPI.createExportJob(userId, from, to, type).then(jId => {
            if (jId == undefined){
                setNothingToExport(true);
            }
            setJobId(jId);
        });
    }, [userId, from, to, type]);

    useInterval(() => {
        if (exportFinished == true) {
            return;
        }
        NewExportAPI.getJobStatusObject(userId, jobId).then(pld => {
            setJob(pld);
            if (pld?.status == 'done') {
                setExportFinished(true);
            }
        });
    }, 3 * 1000);

    if (notingToExport == true){
        return (
            <div>
                No data
            </div>
        )
    }

    if (jobId == undefined) {
        return (
            <Code/>
        )
    }

    let jobStatsus = job?.status;
    let filesObj = (job?.files || {});
    let jobFiles = Object.keys(filesObj).map(xx => ({key: xx, status: filesObj[xx]}));
    let progress = (jobFiles.length == 0) ? 0 : Math.round(100.0 * jobFiles.filter(x => (x.status == 'done')).length / jobFiles.length);
    if (jobStatsus == 'new') {
        jobStatsus = 'enqueued';
    }

    return (
        <Wrapper>

            {(job?.status == 'processing' || job?.status == 'done') ? null :
                <TopPlaceholder>
                    <div>
                        {`Export status: ${jobStatsus || 'New'}`}
                    </div>
                </TopPlaceholder>
            }

            {progress == undefined || progress == 100.0 ? null :
                <div style={{marginBottom: 7}}>
                    <ProgressTool
                        leftText={`Export progress - ${progress.toFixed(0)}%`}
                        percent={+job?.progress}
                        color={'blue'}
                    />
                </div>
            }

            {(job?.status == 'done') ? null :
                <LogsPlaceholder>
                    {jobFiles.map((a, i) => {
                        return (
                            <LogItem key={`${a.key}_${i}`}>
                                <LogDate>
                                    {`file ${a.key}`}
                                </LogDate>
                                <LogMessage>
                                    {a.status}
                                </LogMessage>
                            </LogItem>
                        )
                    })}
                </LogsPlaceholder>
            }

            <DownloadPlaceholder>
                {job?.status != 'done' ? 'Preparing archive...' :
                    <DownloadArchiveLink href={NewExportAPI.getJobDownloadUrl(userId, jobId, name)} target={'_blank'}>
                        <DownloadImage src={download_img}/>
                        <div>
                            Download archive
                        </div>
                    </DownloadArchiveLink>
                }
            </DownloadPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const LogItem = styled.div`

`;

const LogMessage = styled.div`
  opacity: 0.5;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const LogDate = styled.div`
  font-size: 12px;
`;

const TopPlaceholder = styled.div`
  font-size: 16px;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 5px;
`;

const LogsPlaceholder = styled.div`
  max-height: 320px;
  height: 320px;
  overflow-y: auto;
  border-bottom: 1px solid whitesmoke;
`;

const DownloadPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DownloadArchiveLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DownloadImage = styled.img`
  height: 80px;
`;