import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";

import arrow_down from "../../../assets/images/arrow_down.svg";

export default function LeftRightArrowsTool(props) {
  const { onLeft = () => {}, onRight = () => {}, theme } = props;
  console.log(theme);

  return (
    <Wrapper>
      <LeftItem
        onClick={() => {
          onLeft();
        }}
      >
        <ArrowImage
          src={arrow_down}
          style={{ transform: `rotate(90deg)` }}
          theme={theme}
        />
      </LeftItem>
      <RightItem
        onClick={() => {
          onRight();
        }}
      >
        <ArrowImage
          src={arrow_down}
          style={{ transform: `rotate(270deg)` }}
          theme={theme}
        />
      </RightItem>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 3px;
`;

const LeftItem = styled.div`
  border-right: 1px solid lightgrey;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;

const RightItem = styled.div`
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;

const ArrowImage = styled.img`
  width: 14px;
  height: 14px;
  filter: ${(props) => (props.theme === "dark" ? "invert(1)" : "")};
  :hover {
    opacity: 0.75;
  }
`;
