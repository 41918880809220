import { useState, useEffect } from "react";
import styled from "styled-components";

import ch_not_sel from "../../../assets/images/checkbox.svg";
import ch_sel from "../../../assets/images/chbx.svg";
import { Input } from "../../bracelets/forms/UpdateCorsanoPatientForm";

export default function DeliveryTypeSelector(props) {
  const { onChange = (type, deliveryEmail) => {} } = props;
  const [email, setEmail] = useState(props.defaultEmail || "");
  const [type, setType] = useState(props.type || "archive");

  useEffect(() => {
    onChange(type, email);
  }, [type, email]);

  return (
    <Wrapper>
      <TopPlaceholder>
        <SectionHeading>Delivery Type</SectionHeading>
        <InnerWrapper>
          <Item>
            <ChImg
              src={type == "archive" ? ch_sel : ch_not_sel}
              onClick={() => {
                setType("archive");
              }}
            />
            <span>{"Archive"}</span>
          </Item>

          <RItem>
            <EmItem>
              <ChImg
                src={type == "email" ? ch_sel : ch_not_sel}
                onClick={() => {
                  setType("email");
                }}
              />
              <span>{"Email"}</span>
            </EmItem>
            {type != "email" ? null : (
              <div>
                <Input
                  value={email}
                  onChange={(evt) => {
                    let em_ = evt.target.value.replace(/ /g, "");
                    setEmail(em_);
                  }}
                />
              </div>
            )}
          </RItem>
        </InnerWrapper>
      </TopPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const SectionHeading = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 15px;
`;


const RItem = styled.div`
  flex: 1;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  flex: 1;
`;

const EmItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;

const ChImg = styled.img`
  width: 16px;
  right: 16px;
  margin-right: 5px;
  cursor: pointer;
`;

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  opacity: ${(props) => (props.loading == true ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
