import * as types from '../ActionTypes'

const initialState = {
    selectedTimestamp: undefined,
    updatedTimestamp: 0,
    loading: false,
    error: undefined
}

const AuthReducer = (state = initialState, action = {}) => {

    switch (action.type) {

        case types.SELECT_DAY:
            return {
                ...state,
                selectedTimestamp: action.t
            }

        case types.LOAD_DATA:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.LOAD_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case types.LOAD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                updatedTimestamp: +new Date()
            }


        default:
            return state;

    }

}

export default AuthReducer;
