import { useState } from "react";
import styled from "styled-components";
import { Button, Input } from "../../ui/templates";

import Spin from "arui-feather/spin";

export default function NewVouchersForm(props) {
  const { onSave = (d) => {}, loading = false } = props;

  const [number, setNumber] = useState("1");
  const [error, setError] = useState(undefined);

  return (
    <Wrapper>
      <h2>Create New Vouchers</h2>
      <TopPlaceholder className="heading">
        Number of new vouchers you want to create.
      </TopPlaceholder>

      <InputPlaceholder>
        <Input
          style={{
            border: "1px solid whitesmoke",
            flex: "1",
          }}
          value={number}
          onChange={(evt) => {
            setNumber(evt.target.value);
          }}
        />

        <Button
          style={{
            paddingInline: "25px",
            marginLeft: "10px",
          }}
          onClick={() => {
            if (window.isNaN(number) == true) {
              setError("Please specify a number between 1 and 20");
              return;
            }
            if (+number > 20) {
              setError("Number of vouchers should be between 1 and 20");
              return;
            }
            onSave(+number);
          }}
        >
          <Spin visible={loading} />
          {loading == true ? null : "Add users"}
        </Button>
      </InputPlaceholder>

      {error == undefined ? null : <ErrorPlaceholder>{error}</ErrorPlaceholder>}
    </Wrapper>
  );
}

const TopPlaceholder = styled.div``;

const InputPlaceholder = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ErrorPlaceholder = styled.div`
  color: red;
  padding: 10px;
`;

const Wrapper = styled.div``;