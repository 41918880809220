import styled, { css } from "styled-components";
import ls from "local-storage";
import {
  ParamImage,
  ParamItem,
  ParamLabel,
  ParamRight,
  ParamValue,
} from "./ui/ParamElements";

const renderParam = (
  value,
  label,
  icon,
  dangerIcon,
  danger = false,
  layout,
  alarmType
) => {
  if (window.location.href.indexOf("af.") > -1) {
    return null;
  }

  let sValue = value == undefined ? "-" : value;
  let darkmode = ls.get("theme") === "dark";
  let darkModeDefaultColor = "#8798AA";
  let ico = danger == true ? dangerIcon : icon;
  if (ico == undefined) {
    ico = icon;
  }
  if (label == "Temperature (F)" && value != undefined) {
    if (window.location.href.indexOf("portal") > -1) {
      sValue = +value * 1.8 + 32.0;
      sValue = (+sValue).toFixed(1);
    }
  }

  return (
    <ParamItemEnhanced className={`param-item param-item-${alarmType}`}>
      <ParamImageEnhanced
        src={ico}
        className={
          danger == true && window.location.href.indexOf("portal") > -1
            ? "blink_me"
            : `ico-priority-none param-icon`
        }
      />
      <ParamRightEnhanced>
        <ParamValueEnhanced
          className="param-value"
          style={{ color: darkmode ? darkModeDefaultColor : "" }}
          danger={danger}
        >
          {sValue}
        </ParamValueEnhanced>
        <ParamLabelEnhanced
          className="param-label"
          style={{ color: darkmode ? darkModeDefaultColor : "" }}
        >
          {label}
        </ParamLabelEnhanced>
      </ParamRightEnhanced>
    </ParamItemEnhanced>
  );
};

export default renderParam;

const ParamItemEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          height: 48px;
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: row;
          // align-items: center;
          align-items: start;
          // justify-content: end;
          box-sizing: border-box;
          position: relative;
          &:hover {
            opacity: 80%;
          }
        `;
      default:
        return css`
          height: 48px;
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: row;
          // align-items: center;
          align-items: start;
          //   justify-content: center;
          position: relative;
          // width: 100%;
          width: ${(props) =>
            props.type === "temperature" || props.type === "blood_pressure"
              ? "60px"
              : "100px"};
          &:hover {
            opacity: 80%;
          }
        `;
    }
  }}
`;
let order = `  order: ${(props) =>
  props.type === "blood_pressure" &&
  props.alarmtype === "flags" &&
  props.issmalltiles
    ? "5"
    : ""};`;

const ParamRightEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          display: flex;
          flex-direction: column;
          // justify-content: end;
          align-items: start;
          position: relative;
          // left: -0.2em;
          // border: 1px solid red;
        `;
      default:
        return css`
          display: flex;
          flex-direction: column;
          //   justify-content: end;
          align-items: start;
          position: relative;
          left: -0.2em;
        `;
    }
  }}
`;

// const a = `  font-weight: ${(props) =>
//   props.priority == undefined || props.priority == 0 ? "normal" : "bold"};`;
export const ParamLabelEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          font-size: ${(props) => (props.issmalltiles ? "10px" : "14px")};
          width: max-content;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          font-weight: bold;
          position: relative;
          top: ${(props) => (props.issmalltiles ? "-4px" : "-2px")};
        `;
      default:
        return css`
          font-size: ${(props) => (props.issmalltiles ? "10px" : "14px")};
          width: max-content;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          font-weight: bold;
          position: relative;
          top: ${(props) => (props.issmalltiles ? "-4px" : "-2px")};
        `;
    }
  }}
`;

const reducedImageTypes = ["temperature", "respiration_rate"];
export const ParamImageEnhanced = styled.img`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          margin-right: 10px;
          //   width: 36px;
          //   width: ${(props) =>
            props.type === "temperature" ? "32px" : "36px"};
          width: ${(props) => {
            if (props.issmalltiles) {
              if (reducedImageTypes.includes(props.type)) {
                return "26px";
              } else {
                return "28px";
              }
            } else {
              if (reducedImageTypes.includes(props.type)) {
                return "32px";
              } else {
                return "36px";
              }
            }
          }};
        `;
      default:
        return css`
          margin-right: 10px;
          //   width: 36px;
          //   width: ${(props) =>
            props.type === "temperature" ? "32px" : "36px"};
          width: ${(props) => {
            if (props.issmalltiles) {
              if (reducedImageTypes.includes(props.type)) {
                return "26px";
              } else {
                return "28px";
              }
            } else {
              if (reducedImageTypes.includes(props.type)) {
                return "32px";
              } else {
                return "36px";
              }
            }
          }};
        `;
    }
  }}
`;

const ParamValueEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          font-weight: bold;
          font-size: ${(props) => (props.issmalltiles ? "24px" : "30px")};
          margin-bottom: ${(props) => (props.issmalltiles ? "0px" : "0px")};
          margin-top: ${(props) => (props.issmalltiles ? "0px" : "2px")};
          line-height: 1em;
          justify-self: end;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          position: relative;
        `;
      default:
        return css`
          //   margin-bottom: 20px;
          font-weight: bold;
          //   font-size: 30px;
          font-size: ${(props) => (props.issmalltiles ? "20px" : "30px")};
          line-height: 1em;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          position: relative;
          left: ${(props) => (props.issmalltiles ? "0" : "-0.0rem")};

          // :NewsParamTooltip {
          //   .NewsParamTooltip {
          //     display: block;
          //   }
          // }
        `;
    }
  }}
`;
