import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import ProgressTool from "../../progress/tools/ProgressTool";

import {
  DEFAULT_DAILY_ACTIVE_MINUTES,
  DEFAULT_DAILY_CALORIES,
  DEFAULT_DAILY_DISTANCE,
  DEFAULT_DAILY_STEPS,
  DEFAULT_SLEEP_NEED_DURATION,
  getColByVal,
  MAX_WAKEUPS_NUMBER,
  RONAS_BLUE_MOUSE_OVER,
  RONAS_DARK_GREEN,
  RONAS_LIGHT_GREEN,
  RONAS_RED,
} from "../../../constants/config";
import {
  getGoalsMapByArray,
  getProgressColor,
} from "../../../helpers/DrawHelper";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import { isCorsanoMode } from "../../patients/panels/PatientDayAnalyticsPanel";

const getTranquilityPercent = (x) => {
  let n = Math.min(+x, MAX_WAKEUPS_NUMBER);
  let p = (100.0 * n) / MAX_WAKEUPS_NUMBER;
  return +(100.0 - p).toFixed(1);
};

const getWorkoutsPercData = (n) => {
  // console.log("getWorkoutsPercData: n = ", n);
  if (n == undefined) {
    return {
      color: RONAS_RED,
      percent: 10,
    };
  }
  if (n > 5) {
    return {
      color: RONAS_DARK_GREEN,
      percent: 100,
    };
  }
  if (n > 3) {
    return {
      color: RONAS_LIGHT_GREEN,
      percent: 75,
    };
  }
  if (n > 1) {
    return {
      color: RONAS_BLUE_MOUSE_OVER,
      percent: 60,
    };
  }
  return {
    percent: 10,
    color: RONAS_RED,
  };
};

const getTimeStringBySeconds = (seconds) => {
  let minutes = Math.floor(seconds / 60.0);
  let hours = Math.floor(minutes / 60);
  let mins = minutes - 60 * hours;
  return `${`${hours}`.padStart(2, "0")}:${`${mins}`.padStart(2, "0")}`;
};

const getSleepTypeLabel = (seconds, percents) => {
  return `${getTimeStringBySeconds(seconds)} / ${(+percents).toFixed(0)}%`;
};

export default function ActivityGoalsTool(props) {
  const {
    goals = [],
    active_minutes = 0,
    calories,
    daily_percent,
    distance = 0,
    move_every_hour = 0,
    total_glasses,
    total_steps = 0,
    workouts,
  } = props;

  if (total_steps == undefined || total_steps == 0) {
    return (
      <div style={{ width: "100%", height: 320 }}>
        <SimpleNoDataMessageTool
          message={"Sorry, there is no sleep data for this day."}
        />
      </div>
    );
  }

  // console.log("ActivityGoalsTool: props = ", props);
  let goalsMap = getGoalsMapByArray(goals);

  let activeMinutesPercent =
    active_minutes == undefined ||
    active_minutes == 0 ||
    goalsMap.active_minutes?.value == 0
      ? 0
      : (100.0 * active_minutes) /
        (goalsMap.active_minutes?.value == undefined
          ? DEFAULT_DAILY_ACTIVE_MINUTES
          : goalsMap.active_minutes.value);
  let distancePercent =
    distance == undefined || distance == 0 || goalsMap.distance?.value == 0
      ? 0
      : (100.0 * distance) /
        (goalsMap.distance?.value == undefined
          ? DEFAULT_DAILY_DISTANCE
          : goalsMap.distance.value);
  let stepsPercent =
    total_steps == undefined || total_steps == 0 || goalsMap.steps?.value == 0
      ? 0
      : (100.0 * total_steps) /
        (goalsMap.steps?.value == undefined
          ? DEFAULT_DAILY_STEPS
          : goalsMap.steps.value);
  let caloriesPercent =
    calories == undefined || calories == 0 || goalsMap.calories?.value == 0
      ? 0
      : (100.0 * calories) /
        (goalsMap.calories?.value == undefined
          ? DEFAULT_DAILY_CALORIES
          : goalsMap.calories.value);

  // console.log("--->>> stepsPercent = ", stepsPercent);

  activeMinutesPercent =
    activeMinutesPercent == undefined ? 0 : activeMinutesPercent.toFixed(1);
  distancePercent =
    distancePercent == undefined ? 0 : distancePercent.toFixed(1);
  stepsPercent = stepsPercent == undefined ? 0 : stepsPercent.toFixed(1);
  caloriesPercent = caloriesPercent.toFixed(1);

  // let goalsMap = getGoalsMapByArray(goals);
  // let sleepNeedMinutes = (goalsMap.sleep == undefined || goalsMap.sleep.value == undefined) ? DEFAULT_SLEEP_NEED_DURATION : goalsMap.sleep.value;

  return (
    <Wrapper>
      <Row>
        <ProgressTool
          barHeight={18}
          leftText={"STEPS"}
          percent={stepsPercent}
          color={getProgressColor(stepsPercent)}
          rightText={
            stepsPercent == 0 || stepsPercent == undefined
              ? ""
              : `${(+stepsPercent).toFixed(1)}%`
          }
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={18}
          leftText={"DISTANCE"}
          percent={distancePercent}
          color={getProgressColor(distancePercent)}
          rightText={
            distance == undefined || distancePercent == 0
              ? ""
              : `${+(distance * 0.001).toFixed(2)} KM / ${distancePercent}%`
          }
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={18}
          leftText={"CALORIES"}
          percent={caloriesPercent}
          color={getProgressColor(caloriesPercent)}
          rightText={
            calories == undefined ||
            caloriesPercent == undefined ||
            caloriesPercent == 0
              ? ""
              : `${calories} CAL / ${caloriesPercent}%`
          }
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={18}
          leftText={"ACTIVE MINUTES"}
          percent={activeMinutesPercent}
          color={getProgressColor(activeMinutesPercent)}
          rightText={
            active_minutes == undefined || active_minutes == 0
              ? ""
              : `${getTimeStringBySeconds(
                  active_minutes * 60
                )} / ${activeMinutesPercent}%`
          }
        />
      </Row>

      {isCorsanoMode() ? null : (
        <Row>
          <ProgressTool
            barHeight={18}
            leftText={"WORKOUTS"}
            rightText={`${
              workouts == 0
                ? "NO WORKOUTS"
                : `${workouts} WORKOUT${workouts > 1 ? "S" : ""}`
            }`}
            color={getWorkoutsPercData(workouts).color}
            percent={getWorkoutsPercData(workouts).percent}
          />
        </Row>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 2px;
`;

const Row = styled.div`
  margin-bottom: 22px;

  :last-of-type {
    margin-bottom: 0px;
  }
`;
