import axios from 'axios'
import env from "react-dotenv";
import ls from 'local-storage'

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const CorsanoPatientsAPI = {

    async getDoctorCorsanoPatients(uuid) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/doctors/${uuid}/corsano-patients`)).data;
        return pld;
    },

    async updateCorsanoPatient(data) {
        let pld = (await axios.put(`${DOCTORS_API_ENDPOINT}/corsano-patients/${data.id}`, {
            ...data
        })).data;
        return pld;
    },

    async createCorsanoPatient(doctorUUID, data) {
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/doctors/${doctorUUID}/corsano-patients`, {
            ...data
        })).data;
        return pld;
    },

    async deleteCorsanoPatient(id) {
        await axios.delete(`${DOCTORS_API_ENDPOINT}/corsano-patients/${id}`);
    },

    async getCorsanoPatientsByTag(tag) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/study/${tag}/corsano-patients`)).data;
        return pld;
    },



}

export default CorsanoPatientsAPI;
