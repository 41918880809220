import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DoctorAPI from "../../../api/DoctorAPI";
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis, AreaChart, Area} from "recharts";
import moment from "moment/moment";

import useDimensions from "react-use-dimensions";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import {gradientOffset} from "../../charts/tools/PnsSnsAreaChart";


export default function HealthBatteryWidgetTool(props) {
    const {
        uuid,
        date,
        minutesInterval = 10
    } = props;

    const [ref, {x, y, width, height}] = useDimensions();
    const [aggregatedPoints, setAggregatedPoints] = useState([]);

    useEffect(() => {
        if (uuid == undefined || date == undefined) {
            return;
        }
        setAggregatedPoints([]);
        DoctorAPI.getBatteryAggregatedPointsForDate(uuid, date, minutesInterval).then(x => {
            setAggregatedPoints(x);
        });
    }, [uuid, date]);

    console.log('HealthBatteryWidgetTool: aggregatedPoints = ', aggregatedPoints);

    console.log(aggregatedPoints.map(x => `${moment(x.t).format('HH:mm')} - ${x.value}`).join(', '))

    let data = aggregatedPoints.map(x => ({
        t: x.t,
        value: x.value
    }));

    let w = width - 40;
    let dItemWidth = (aggregatedPoints.length == 0) ? 0 : (w / aggregatedPoints.length);
    let totalSteps = aggregatedPoints.reduce((acc, x) => acc + x.steps, 0);
    let maxSteps = aggregatedPoints.reduce((acc, x) => Math.max(acc, x.steps || 0), 0);
    console.log('--->>> totalSteps = ', totalSteps);

    let vertValueOffset = 60;

    let sPoints = aggregatedPoints.map(a => ({...a, value: +a.sin - vertValueOffset}))

    // const off = gradientOffset(sPoints);

    return (
        <Wrapper style={{height: (height == undefined) ? 'auto' : height}}>


            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={data}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}
                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line dataKey={'value'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            <BottomPlaceholder>

                <BottomInfoPlaceholder>

                    {aggregatedPoints.map((a, i) => {
                        let {isSleep, si} = a;
                        let color = 'transparent';
                        if (isSleep == true) {
                            color = 'lightgrey';
                            color = TarnsformerHelper.getColorOfSleepBar(a.sleepType);
                        }
                        let hasStessValue = (si != 0);
                        let stepsPercent = (totalSteps == 0) ? 0 : (1.0 * (+a.steps) / (+maxSteps)) * 100;

                        return (
                            <Item key={a.t} left={(i * dItemWidth)} width={dItemWidth} color={color}>

                                <ItemInnerPlaceholder>

                                    <StressBottomPlaceholder>
                                        {hasStessValue == false ? null :
                                            <StressLine color={(si > 180 ? 'red' : 'green')}>

                                            </StressLine>
                                        }
                                    </StressBottomPlaceholder>

                                    <ActivityInnerPlaceholder>
                                        <ActivityBar percent={stepsPercent}>
                                            <ActivityInnerBar>
                                                <ActivityHint className={'ActivityHint'}>
                                                    {a.steps}
                                                </ActivityHint>
                                            </ActivityInnerBar>
                                        </ActivityBar>
                                    </ActivityInnerPlaceholder>

                                </ItemInnerPlaceholder>

                            </Item>
                        )
                    })}

                </BottomInfoPlaceholder>

            </BottomPlaceholder>

            <StressChartPlaceholder>

                <AreaChart width={width} height={height - 80} data={aggregatedPoints}
                           margin={{top: 5, right: 0, left: 40, bottom: 5}}>
                    {/*<XAxis dataKey="t" tickCount={25}*/}
                    {/*       tickFormatter={a => {*/}
                    {/*           if (`${a}`.length < 8) {*/}
                    {/*               return a;*/}
                    {/*           }*/}
                    {/*           return (moment(+a).format('HH:mm'))*/}
                    {/*       }}*/}
                    {/*/>*/}
                    {/*<YAxis/>*/}
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>

                    {/*<defs>*/}
                    {/*    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">*/}
                    {/*        <stop offset={off} stopColor="green" stopOpacity={1}/>*/}
                    {/*        <stop offset={off} stopColor="red" stopOpacity={1}/>*/}
                    {/*    </linearGradient>*/}
                    {/*</defs>*/}

                    <Area dataKey={'sin'}
                          fill={'green'}
                          stroke={'#00000000'}
                          animationDuration={1}
                          dot={false}/>
                </AreaChart>

            </StressChartPlaceholder>

        </Wrapper>
    );
}

const StressChartPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
`;

const ActivityInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const ActivityBar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: ${props => (props.percent == undefined ? 0 : props.percent)}%;
  background-color: darkblue;

  :hover {
    background-color: orange;

    .ActivityHint {
      display: flex;
    }
  }
`;

const ActivityInnerBar = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ActivityHint = styled.div`
  position: absolute;
  top: -30px;
  left: -18px;
  box-sizing: border-box;
  width: 50px;
  padding: 3px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  background-color: orange;
  border: 1px solid grey;
  display: none;
  color: darkblue;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const StressBottomPlaceholder = styled.div`
  position: absolute;
  top: -10px;
  height: 10px;
  left: 0px;
  right: 0px;
  background-color: lightgrey;
`;

const StressLine = styled.div`
  background-color: ${props => (props.color)};
  width: 100%;
  height: 100%;
`;

const ItemInnerPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;

  margin-top: -75px;

  height: 40px;
  padding-left: 40px;
  opacity: 0.3;
`;

const Item = styled.div`
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: ${props => props.width}px;
  left: ${props => props.left}px;

  background-color: ${props => props.color};

`;

const BottomInfoPlaceholder = styled.div`
  height: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: lightgrey;
`;

const ChartPlaceholder = styled.div`
  height: 320px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const H2 = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
`;