import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import Spin from "arui-feather/spin";
import DoctorAPI from "../../../api/DoctorAPI";
import { Textarea, Button } from "../../ui/templates";

export default function AiAssisstantTool(props) {
  const { uuid } = props;
  const [text, setText] = useState("");
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  let repl =
    data == undefined || data.length == 0
      ? undefined
      : data[data.length - 1]?.content;

  return (
    <Wrapper>
      <InputPlaceholder>
        <Textarea
          $mode={"mode1"}
          placeholder={""}
          value={text}
          onChange={(evt) => {
            setText(evt.target.value);
          }}
        />
      </InputPlaceholder>

      <Button
        $mode={"mode1"}
        onClick={async () => {
          if (text == undefined || text.length < 10) {
            return;
          }
          setLoading(true);
          setData(undefined);
          let pld = await DoctorAPI.askAi(uuid, text);
          setData(pld);
          setLoading(false);
        }}
      >
        <Spin visible={loading} />
        {loading == true ? null : "Ask"}
      </Button>

      {data == undefined ? null : (
        <ResultPlaceholder>
          <div
            dangerouslySetInnerHTML={{
              __html: `${repl}`.replace(/\n/g, "<br/>"),
            }}
          ></div>
        </ResultPlaceholder>
      )}

      {error == undefined ? null : <ErrorPlaceholder>{error}</ErrorPlaceholder>}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ResultPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: calc(90vh - 100px);
  overflow-y: auto;
  box-sizing: border-box;
  font-style: italic;
  background-color: whitesmoke;
  padding: 10px;
`;

const InputPlaceholder = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ErrorPlaceholder = styled.div`
  color: red;
  padding: 10px;
`;
