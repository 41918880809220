import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import moment from "moment";
import axios from "axios";
import { API_ENDPOINT, CORSANO_API_ENDPOINT } from "../../../constants/config";

export default function VivalinkItemsTool(props) {
  const { uuid, from, to } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [loadingId, setLoadingId] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    setItems([]);
    DoctorAPI.getVivalinkItems(uuid, from, to).then((x) => {
      setItems(x);
      setLoading(false);
    });
  }, [uuid, from, to]);

  if (items.length === 0 && loading == false) {
    return null;
  }

  return (
    <Wrapper style={{ marginTop: "4rem" }}>
      <ComplianceHeading className="heading">ECG patch data</ComplianceHeading>

      {/*{items.map((a, i) => {*/}
      {/*    return (*/}
      {/*        <Row>*/}
      {/*            <RowName>*/}
      {/*                {`${moment(a.timestamp).format('YYYY-MM-DD HH:mm:ss')}`}*/}
      {/*            </RowName>*/}
      {/*            <RowRight>*/}
      {/*                <DownloadSpan onClick={async () => {*/}
      {/*                    setLoadingId(a._id);*/}
      {/*                    let ss = JSON.stringify(a);*/}
      {/*                    download(`${moment(a.timestamp).format('YYYY-MM-DD HH:mm:ss')}.json`, ss);*/}
      {/*                    // let pld = (await axios.get(`${CORSANO_API_ENDPOINT}/app-items/${uuid}/files/${a._id}/download`)).data;*/}
      {/*                    // window.open(pld.url, '_blank').focus();*/}
      {/*                    setLoadingId(undefined);*/}
      {/*                }}>*/}
      {/*                    {loadingId == a._id ? '...' : 'download'}*/}
      {/*                </DownloadSpan>*/}
      {/*            </RowRight>*/}
      {/*        </Row>*/}
      {/*    )*/}
      {/*})}*/}

      <Row>
        {/*<RowName>*/}
        {/*    {''}*/}
        {/*</RowName>*/}
        <DownloadSpan
          onClick={async () => {
            let ss = JSON.stringify(items);
            download(`vivalink_export.json`, ss);
          }}
        >
          {"Export JSON"}
        </DownloadSpan>

        <DownloadSpan
          style={{ marginLeft: 20 }}
          onClick={async () => {
            // let ss = JSON.stringify(items);
            // download(`vivalink_export.json`, ss);
            let csv =
              `timestamp;timezone;heart_rate;respiration;rr1;rr2;rr3;rr4;rr5\n` +
              items
                .map((x) => {
                  return `${x.timestamp};${x.timezone};${x.heart_rate};${x.respiration};${x.rr[0]};${x.rr[1]};${x.rr[2]};${x.rr[3]};${x.rr[4]}`;
                })
                .join("\n");
            download(`vivalink_export.csv`, csv);
          }}
        >
          {"Export CSV"}
        </DownloadSpan>
      </Row>
    </Wrapper>
  );
}

const ComplianceHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;

  border-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
`;

const DownloadSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;

const RowName = styled.div`
  font-size: 14px;
  color: #000;
  margin-right: 10px;
`;

const RowRight = styled.div``;

function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
