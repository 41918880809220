import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import SimpleSelectableLineChart from "../../charts/tools/SimpleSelectableLineChart";
import HorSelector from "./HorSelector";
import LeftRightArrowsTool from "./LeftRightArrowsTool";
import SimpleHistChart from "../../charts/tools/SimpleHistChart";
import SimpleScatterChart from "../../charts/tools/SimpleScatterChart";
import CalcHrvParamsTool from "../../calc/tools/CalcHrvParamsTool";

import HrvPlotsTool from "../../calc/tools/HrvPlotsTool";
import SimplePieChart from "../../charts/tools/SimplePieChart";
import CalcAPI from "../../../api/CalcAPI";
import ArtifactsViewer from "../../artifacts/tools/ArtifactsViewer";
import CardioHelper from "../../../helpers/CardioHelper";
import gear from "../../../assets/images/gear.svg";
import Tabs, { TabItem } from "../../ui/Tabs";

const AXIS_OPTIONS = [
  {
    label: "Local time",
    value: "astro",
  },
  {
    label: "Segment time",
    value: "relative",
  },
];

const Y_AXIS_OPTIONS = [
  {
    label: "RR-intervals (ms)",
    value: "rr",
  },
  {
    label: "Pulse Rate (BpM)",
    value: "hr",
  },
];

const ZONES_ENABLE_OPTIONS = [
  {
    label: "With zones",
    value: "with",
  },
  {
    label: "Without zones",
    value: "without",
  },
];

const WINDOWS_SIZES_OPTIONS = [
  {
    label: "2 min.",
    value: 2 * 60 * 1000,
  },
  {
    label: "5 min.",
    value: 5 * 60 * 1000,
  },
  {
    label: "10 min.",
    value: 10 * 60 * 1000,
  },
];

const STEPS_OPTIONS = [
  {
    label: "10 sec.",
    value: 10 * 1000,
  },
  {
    label: "30 sec.",
    value: 30 * 1000,
  },
  {
    label: "1 min.",
    value: 60 * 1000,
  },
  {
    label: "5 min.",
    value: 5 * 60 * 1000,
  },
  {
    label: "30 min.",
    value: 30 * 60 * 1000,
  },
];

export default function SessionAnalysisTool(props) {
  const {
    miniPoints = [],
    points = [],
    startTimestamp = 0,
    age,
    theme,
  } = props;
  const [windowSize, setWindowSize] = useState(WINDOWS_SIZES_OPTIONS[0].value);
  const [step, setStep] = useState(STEPS_OPTIONS[0].value);
  const [hrvData, setHrvData] = useState();
  const [hrvLoading, setHrvLoading] = useState(false);
  const [artifactsIndices, setArtifactsIndices] = useState([]);
  const [artifactsPanelVisible, setArtifactsPanelVisible] = useState(false);
  const [hrMode, setHrMode] = useState("rr");
  const [timeMode, setTimeMode] = useState("astro");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [zonesMode, setZonesMode] = useState(
    age == undefined ? "without" : "with"
  );

  // console.log('SessionAnalysisTool: miniPoints = ', miniPoints);

  const [selectedRange, setSelectedRange] = useState({
    from: miniPoints.length == 0 ? undefined : miniPoints[0].t,
    to:
      miniPoints.length == 0
        ? undefined
        : Math.min(
          +miniPoints[miniPoints.length - 1].t,
          +miniPoints[0].t + +WINDOWS_SIZES_OPTIONS[0].value
        ),
  });

  useEffect(() => {
    setSelectedRange({
      from: miniPoints.length == 0 ? undefined : miniPoints[0].t,
      to:
        miniPoints.length == 0
          ? undefined
          : Math.min(
            +miniPoints[miniPoints.length - 1].t,
            +miniPoints[0].t + +WINDOWS_SIZES_OPTIONS[0].value
          ),
    });
  }, [miniPoints.length]);

  useEffect(() => {
    console.log("--->>> points = ", points);
    setArtifactsIndices(
      CardioHelper.getArtifactsIndices(
        points.map((x) => ({ ...x, rr: x.rr || x.value }))
      )
    );
  }, [points.length]);

  let fromNumber = undefined,
    toNumber = undefined,
    minFound = false;

  for (let i in miniPoints) {
    let p = miniPoints[i];
    if (+p.t >= +selectedRange.from && minFound == false) {
      minFound = true;
      fromNumber = i;
    }
    if (minFound == true) {
      toNumber = i;
    }
    if (p.t >= selectedRange.to) {
      break;
    }
  }
  let firstTimestamp = miniPoints.length == 0 ? undefined : miniPoints[0].t;
  // console.log('render: selectedRange = ', selectedRange);
  // console.log('miniPoints = ', miniPoints);
  let selectedMainPoints = points.filter(
    (a) => +a.t >= +selectedRange.from && +a.t < +selectedRange.to
  );

  useEffect(() => {
    let mRrs = selectedMainPoints.map((a) => a.rr);
    if (mRrs.length == 0) {
      return;
    }
    setHrvLoading(true);
    CalcAPI.calcParams(mRrs).then((pld) => {
      setHrvData(pld);
      setHrvLoading(false);
    });
  }, [selectedMainPoints.map((a) => a.rr).join("_")]);

  const t0 = points.length == 0 ? 0 : +points[0].t;

  // const isTooFewSelected = (selectedMainPoints.length < 60);
  const isTooFewSelected = selectedMainPoints.length < 1;
  console.log("miniPoints", miniPoints);

  return (
    <Wrapper>
      <TopRightSectionPlaceholder>
        {artifactsIndices.length == 0 ? null : (
          <ShowArtsSpan
            onClick={() => {
              setArtifactsPanelVisible(true);
            }}
          >
            {`${artifactsIndices.length} artifacts found`}
          </ShowArtsSpan>
        )}
      </TopRightSectionPlaceholder>

      {/*<div style={{width: '100%', height: 120, marginBottom: 10}}>*/}
      {/*<PnsSnsChart startTimestamp={startTimestamp} rrs={points.map(a => a.rr)} />*/}
      {/*</div>*/}


      <TopChartPlaceholder>
        <SimpleSelectableLineChart
          name={"main_chart"}
          t0={t0}
          timeMode={timeMode}
          points={miniPoints.map((a) => ({
            ...a,
            value:
              hrMode == "rr"
                ? a.rr
                : a.rr == 0
                ? 0
                : (60000.0 / a.rr).toFixed(0),
          }))}
          fromNumber={fromNumber}
          toNumber={toNumber}
          colorRanges={
            zonesMode == "with" && hrMode == "hr"
              ? CardioHelper.getHeartRateZones(+age)
              : []
          }
        />
      </TopChartPlaceholder>

      <TopControlsBlock>
        <TopHorField>
          <TopHorLabel className="normal-text">Window:</TopHorLabel>
          <HorSelector
            options={WINDOWS_SIZES_OPTIONS}
            value={windowSize}
            onSelect={(a) => {
              let newSize = +a.value;
              let nextTo = Math.min(
                +miniPoints[miniPoints.length - 1].t,
                +selectedRange.from + newSize
              );
              setWindowSize(newSize);
              setSelectedRange({ ...selectedRange, to: nextTo });
            }}
          />
        </TopHorField>
        <TopHorField>
          <TopHorLabel className="normal-text">Step:</TopHorLabel>
          <HorSelector
            options={STEPS_OPTIONS}
            value={step}
            onSelect={(a) => {
              setStep(a.value);
            }}
          />
        </TopHorField>

        <TopHorField
          style={{
            justifySelf: "flex-end",
            marginLeft: "auto",
            display: "inline-block",
            flex: 1,
            textAlign: "right",
          }}
        >
          <TopHorLabel className="normal-text">
            {`Selected ${moment(selectedRange.from).format(
              "HH:mm:ss"
            )} - ${moment(selectedRange.to).format("HH:mm:ss")}`}
            <SettingsGearImg
              style={{ marginLeft: 4 }}
              src={gear}
              onClick={() => {
                setSettingsOpen(true);
              }}
            />
          </TopHorLabel>
        </TopHorField>

        {miniPoints.length == 0 ? null : (
          <TopHorField
            style={{
              justifySelf: "flex-end",
              marginLeft: "auto",
              display: "inline-block",
            }}
          >
            <LeftRightArrowsTool
              theme={theme}
              onLeft={() => {
                console.log("onLeft occured!");
                // let nextFrom = Math.min(Math.max(0, +selectedRange.from - +step), +miniPoints[miniPoints.length - 1].t - +windowSize);
                let nextFrom = Math.max(
                  firstTimestamp,
                  +selectedRange.from - +step
                );
                setSelectedRange({
                  from: nextFrom,
                  to: Math.min(
                    +miniPoints[miniPoints.length - 1].t,
                    +nextFrom + windowSize
                  ),
                });
              }}
              onRight={() => {
                let nextFrom = Math.min(
                  +miniPoints[miniPoints.length - 1].t - +windowSize,
                  +selectedRange.from + +step
                );
                setSelectedRange({
                  from: nextFrom,
                  to: Math.min(
                    +miniPoints[miniPoints.length - 1].t,
                    nextFrom + windowSize
                  ),
                });
              }}
            />
          </TopHorField>
        )}
      </TopControlsBlock>

      <MiddlePlaceholder>
        <MiddleLeftPlaceholder>
          <ASection>
            <ChartHeading className="normal-text">
              {hrMode == "hr" ? "Pulse Rate" : "RR-intervals"}
            </ChartHeading>
            <SelectedChartPlaceholder>
              <SimpleSelectableLineChart
                t0={t0}
                name={"selected_chart"}
                timeMode={timeMode}
                points={selectedMainPoints.map((a) => ({
                  ...a,
                  value:
                    hrMode == "rr"
                      ? a.rr
                      : a.rr == 0
                      ? 0
                      : (60000.0 / a.rr).toFixed(0),
                }))}
                hasOverlay={false}
                colorRanges={
                  zonesMode == "with" && hrMode == "hr"
                    ? CardioHelper.getHeartRateZones(+age)
                    : []
                }
              />
            </SelectedChartPlaceholder>
          </ASection>
          {isTooFewSelected ? (
            "Not enough data points for analysis"
          ) : (
            <BSection>
              <SquarePlaceholder>
                <ChartHeading paddingLeft={10} className="normal-text">
                  Poincaré plot
                </ChartHeading>
                <SimpleScatterChart
                  points={selectedMainPoints.map((a) => ({
                    ...a,
                    value: a.rr,
                  }))}
                />
              </SquarePlaceholder>

              <SquarePlaceholder>
                <ChartHeading paddingLeft={10} className="normal-text">
                  Histogram
                </ChartHeading>
                <SimpleHistChart
                  points={selectedMainPoints.map((a) => ({
                    ...a,
                    value: a.rr,
                  }))}
                />
              </SquarePlaceholder>

              <SquarePlaceholder>
                <ChartHeading paddingLeft={10} className="normal-text">
                  Spectrum
                </ChartHeading>
                {hrvData == undefined ? null : <SimplePieChart {...hrvData} />}
                {/*<Spin visible={hrvLoading}/>*/}
                {/*<SimpleHistChart points={selectedMainPoints.map(a => ({...a, value: a.rr}))}/>*/}
              </SquarePlaceholder>
            </BSection>
          )}
        </MiddleLeftPlaceholder>

        <MiddleRightPlaceholder>
          <HrvParamsBlock className="normal-text">
            {isTooFewSelected == true ? (
              "Not enough data"
            ) : (
              <CalcHrvParamsTool hrvData={hrvData} loading={hrvLoading} />
            )}
          </HrvParamsBlock>
        </MiddleRightPlaceholder>
      </MiddlePlaceholder>

      <VeryBottomChartsPlaceholder>
        <HrvPlotsTool
          startTimestamp={startTimestamp}
          rrs={points.map((a) => a.rr)}
          theme={theme}
        />
      </VeryBottomChartsPlaceholder>

      <Sidebar
        width={Math.min(1020, window.innerWidth)}
        visible={artifactsPanelVisible}
        onCloserClick={() => {
          setArtifactsPanelVisible(false);
        }}
      >
        {artifactsPanelVisible == false ? null : (
          <div>
            <h3>Artifacts</h3>
            <TopChartPlaceholder>
              <SimpleSelectableLineChart
                hasOverlay={false}
                name={"main_chart"}
                points={miniPoints.map((a) => ({ ...a, value: a.rr }))}
              />
            </TopChartPlaceholder>
            <ArtifactsViewer points={points} />
          </div>
        )}
      </Sidebar>

      <Sidebar
        visible={settingsOpen}
        onCloserClick={() => {
          setSettingsOpen(false);
        }}
      >
        <div>
          <h3>Time axis</h3>
          <div style={{ display: "inline-block" }}>
            <HorSelector
              options={AXIS_OPTIONS}
              value={timeMode}
              onSelect={(a) => {
                setTimeMode(a.value);
              }}
            />
          </div>
          <h3>Value axis</h3>
          <div style={{ display: "inline-block" }}>
            <HorSelector
              options={Y_AXIS_OPTIONS}
              value={hrMode}
              onSelect={(a) => {
                setHrMode(a.value);
              }}
            />
          </div>

          {hrMode == "rr" || age == undefined ? null : (
            <>
              <h3>Pulse rate zones</h3>
              <div style={{ display: "inline-block" }}>
                <HorSelector
                  options={ZONES_ENABLE_OPTIONS}
                  value={zonesMode}
                  onSelect={(a) => {
                    setZonesMode(a.value);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </Sidebar>
    </Wrapper>
  );
}

const ChartHeading = styled.div`
  font-weight: bold;
  opacity: 0.5;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: ${(props) =>
    props.paddingLeft == undefined ? 0 : props.paddingLeft}px;
`;

const MiddlePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 10px;
`;

const MiddleLeftPlaceholder = styled.div`
  flex: 1;
`;

const ASection = styled.div`
  height: 283px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  //background: pink;
`;

const BSection = styled.div`
  height: 283px;
  width: 100%;
  //background: blue;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const MiddleRightPlaceholder = styled.div`
  width: 300px;
  padding-left: 15px;
  padding-top: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const TopChartPlaceholder = styled.div`
  height: 300px;
  width: 100%;
`;

const TopControlsBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
`;

const middleHeight = 300;

const HrvParamsBlock = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding-left: 20px;
`;

const SelectedChartPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1;
`;

const SquarePlaceholder = styled.div`
  flex: 1;
`;

// const HistChartPlaceholder = styled.div`
//     width: ${middleHeight}px;
// `;

const TopHorField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;

  :last-of-type {
    margin-right: 0px;
  }
`;

const TopHorLabel = styled.div`
  margin-right: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VeryBottomChartsPlaceholder = styled.div`
  margin-top: 45px;
`;

const TopRightSectionPlaceholder = styled.div`
  padding: 5px;
  padding-top: 0px;
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ShowArtsSpan = styled.span`
  cursor: pointer;
  margin-left: 10px;
  border-bottom: 1px dashed grey;
  padding: 4px;
  padding-top: 0px;
  padding-bottom: 0px;

  :hover {
    opacity: 0.7;
  }
`;

const AnalysisBarsPlaceholder = styled.div`
  //max-height: 600px;
  max-height: 480px;
  overflow-y: auto;
`;

const SettingsTogglerPlaceholder = styled.div``;

const SettingsGearImg = styled.img`
  cursor: pointer;
  opacity: 0.4;
  height: 14px;

  :hover {
    opacity: 0.8;
  }
`;
