import { useState, useEffect } from "react";
import moment, { min } from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";
import ls from "local-storage";
import DoctorAPI from "../../../api/DoctorAPI";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AXES_COLOR } from "../../ui/templates";
import useDimensions from "react-use-dimensions";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let tt = payload[0]?.payload?.t;
    let SQI = payload[0]?.payload?.SQI;
    let respiration = payload[0]?.payload?.respiration_rate;
    return (
      <TooltipWrapper>
        {tt == undefined ? null : (
          <TooltipRow>{`${moment(tt).format("HH:mm")}`}</TooltipRow>
        )}
        <TooltipRow>{`Respiration rate: ${respiration}`}</TooltipRow>
        <TooltipRow>{`Q: ${SQI}`}</TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
};

export function downsample(from, data, seconds = 1800.0) {
  // Map of half-hour intervals to values
  const intervalMap = {};

  // Helper function to round a timestamp to the nearest half hour
  function roundToHalfHour(ts) {
    let dt = +ts - +from;
    let x =
      Math.floor(+dt / 1000.0 / (seconds * 1.0)) * Math.round(seconds) * 1000;
    return +from + x;
    // const time = moment(ts);
    // const minutes = time.minutes();
    // time.minutes(minutes >= 30 ? 30 : 0);
    // time.seconds(0);
    // time.milliseconds(0);
    // return time.valueOf();
  }

  // Populate intervalMap with values from data
  data.forEach((item) => {
    const roundedTs = roundToHalfHour(item.timestamp);
    let key = `t-${roundedTs}`;
    if (!intervalMap[key]) {
      intervalMap[key] = {
        t: roundedTs,
        points: [],
      };
    }
    intervalMap[key].points.push(item);
  });

  // console.log("down: intervalMap = ", intervalMap);

  // Calculate the average for each half-hour interval
  const result = [];
  const startOfDay = moment(+from).startOf("day").valueOf();
  const endOfDay = moment(+from).endOf("day").valueOf();

  for (let ts = +from; ts <= endOfDay; ts += Math.round(seconds) * 1000) {
    // 30 minutes in milliseconds
    if (intervalMap[`t-${ts}`]) {
      let respPoints = intervalMap[`t-${ts}`].points.map(
        (x) => x.respiration_rate
      );
      let qPoints = intervalMap[`t-${ts}`].points.map((x) => x.resp_q);
      const avgValue =
        respPoints.length == 0
          ? 0
          : respPoints.reduce((acc, val) => acc + val, 0) / respPoints.length;
      const avgValueQ =
        qPoints.length == 0
          ? 0
          : qPoints.reduce((acc, val) => acc + val, 0) / qPoints.length;
      result.push({
        t: ts,
        respiration_rate: Math.round(+avgValue),
        SQI: Math.round(avgValueQ),
      });
    } else {
      result.push({ t: ts, respiration_rate: null, SQI: null }); // or 0 or some default value
    }
  }

  return result;
}

export default function TempRespTool(props) {
  const {
    uuid,
    from,
    to,
    startTimestamp,
    downsampleMode = false,
    isActivityRaw = false,
    min,
    max,
  } = props;

  const theme = ls.get("theme");
  const [loading, setLoading] = useState([]);
  const [points, setPoints] = useState([]);
  const [ref, { x, y, width, height }] = useDimensions();

  useEffect(() => {
    setLoading(true);
    setPoints([]);
    if (isActivityRaw == false) {
      DoctorAPI.getTempActivityPoints(uuid, from, to).then((arr) => {
        setPoints(arr);
        setLoading(false);
        // console.log("getTempActivityPoints: points = ", arr);
      });
    } else {
      DoctorAPI.getActivityPoints(uuid, from, to).then((arr) => {
        setPoints(arr);
        setLoading(false);
        // console.log("getActivityPoints: points = ", arr);
      });
    }
  }, [from, to]);

  // if (points.length == 0 && loading == true) {
  //     return (
  //         <Code/>
  //     )
  // }

  // console.log("render: points temp = ", points);
  let dSeconds = downsampleMode == true ? 300.0 : 60.0;
  let fPoints = points.filter(
    (x) => x.respiration_rate != undefined && x.respiration_rate != 0
  );
  let downPoints = downsample(from, fPoints, dSeconds).map((x) => ({
    ...x,
    date: moment(+x.t).format("HH:mm"),
  }));
  // console.log("render: downPoints = ", downPoints);

  // if (points.length == 0) {
  //     return null;
  // }
  let xticks = [];
  const tickNum = 24;
  for (let i = 0; i < tickNum; i++) {
    xticks.push(moment(min).add(i, "hour").valueOf());
  }

  return (
    <Wrapper ref={ref}>
      {/*{loading == true ? <Code/> :*/}
      {/*    <>*/}
      {/*        <LineChart width={width} height={height} data={fPoints} connectNulls={false}*/}
      {/*                   margin={{top: 5, right: 0, left: -20, bottom: 5}}>*/}
      {/*            <XAxis dataKey="date" tickCount={25}*/}

      {/*            />*/}
      {/*            <YAxis*/}
      {/*            />*/}
      {/*            <Tooltip/>*/}
      {/*            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>*/}
      {/*            <Line type="monotone" dataKey={'respiration_rate'} stroke="#D92A3E"*/}
      {/*                  strokeDashArray="4 1"*/}
      {/*                  strokeWidth={1} animationDuration={1}*/}
      {/*                  dot={false}*/}
      {/*            />*/}
      {/*        </LineChart>*/}
      {/*    </>*/}
      {/*}*/}

      {loading == true ? (
        <>
          <Code />
        </>
      ) : (
        <>
          <LineChart
            width={width}
            height={height}
            data={downPoints}
            connectNulls={false}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <XAxis
              dataKey="t"
              tickCount={25}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth="1px"
              ticks={xticks}
              type="number"
              domain={[min, max]}
              tickFormatter={(a) => moment(a).format("HH:mm")}
            />
            <YAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth="1px"
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey={"respiration_rate"}
              stroke={downsampleMode == true ? "#D92A3E" : "#D92A3E"}
              strokeDashArray="4 1"
              strokeWidth={1}
              animationDuration={1}
              dot={
                downsampleMode == true
                  ? { stroke: "#D92A3E", strokeWidth: 1 }
                  : false
              }
            />
          </LineChart>
        </>
      )}
    </Wrapper>
  );
}

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  //height: 300px;
  //background: pink;
  box-sizing: border-box;
  position: relative;
`;

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 300px;
  //background: pink;
  box-sizing: border-box;
  position: relative;
`;
