import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import EcgProcessingAPI from "../../../api/EcgProcessingAPI";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.4.168/build/pdf.worker.mjs";

async function loadOneFile(uuid, mediaId) {
  let pld = await EcgProcessingAPI.getMediaLink(uuid, mediaId);
  return pld?.link;
}

async function loadManyFiles(uuid, file_ids = []) {
  let arr = await Promise.all(file_ids.map((x) => loadOneFile(uuid, x)));
  arr = arr.filter((x) => x != undefined);
  return arr;
}

export default function SimplePdfViewer(props) {
  const { uuid, file_ids = [], isImage = false } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const iframeRef = useRef(null);

  const handlePrint = () => {
    // This will trigger the print function
    iframeRef.current.contentWindow.focus();
    iframeRef.current.contentWindow.print();
  };

  useEffect(() => {
    setLoading(true);
    loadManyFiles(uuid, file_ids).then((x) => {
      //   console.log("loadManyFiles", x);
      setData(x);
      setLoading(false);
    });
  }, [uuid]);

  if (loading == true) {
    return <Code />;
  }

  return (
    <Wrapper className="pdf-popup-wrapper">
      {data.map((x, i) => {
        let pdfPath = x.replace(
          "cloudconnect-uploads-prod.s3.eu-central-1.amazonaws.com",
          "files.corsano.com"
        );
        // console.log("filename", pdfPath);
        return (
          <div className={"af-document-container"} key={`x-${i}`}>
            {isImage == true ? (
              <img src={x} style={{ width: "580px" }} />
            ) : (
              <div className="af-document-area">
                <Document className={"corsano_pdf_doc"} file={pdfPath}>
                  <Page className={"af-document-page"} pageNumber={1} />
                </Document>
              </div>
            )}
          </div>
        );
      })}

      <div
        className={"no-print"}
        style={{ textAlign: "center", padding: "7px" }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            try {
              window.print();
            } catch (exc) {
              console.error(exc);
            }
          }}
        >
          Print
        </span>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
