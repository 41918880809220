import MMTCloud from "./MMTCloud";
import moment from 'moment'
import Dexie from "dexie";

class RonasBasicAPI {

    private static instance: RonasBasicAPI
    private cloud: MMTCloud | undefined;
    private db?: any;

    constructor() {
        this.cloud = MMTCloud.getInstance();
        this.db = new Dexie("DailySummariesDatabase");
        this.db.version(1).stores({
            activity: "[userUUID+date],date,userUUID,active_minutes,calories,daily_percent,distance,move_every_hour,total_steps,workouts,slots",
            heart_rate: "[userUUID+date],date,userUUID,all_time,avg_daily_heart_rate,avg_daily_heat_rate,daily_percent,endurance_time,fat_burn_time,max_daily_heart_rate,max_daily_heat_rate,max_time,performance_time,rest_daily_heart_rate,rest_daily_heat_rate,warm_up_time,slots",
            sleep: "[userUUID+date],date,userUUID,awake_time,consistency,daily_percent,deep_time,light_time,performance,rem_time,sleep_duration,tranquility,slots"
        });
    }


    private getUsersAxios() {
        return this.cloud?.usersApiAxios;
    }

    private getHealthAxios() {
        return this.cloud?.healthApiAxios;
    }

    public static getInstance(): RonasBasicAPI {
        if (!RonasBasicAPI.instance) {
            RonasBasicAPI.instance = new RonasBasicAPI();
        }
        return RonasBasicAPI.instance;
    }

    private async cacheSummary(summary: any) {
        if (summary == undefined) {
            return;
        }
        // console.log('cacheSummary: summary = ', summary);
        let uuid: string | undefined | null = this.cloud?.getUUID();
        let self = this;

        async function updType(type: 'activity' | 'sleep' | 'heart_rate', dd: any) {
            if (dd == undefined) {
                return;
            }
            let val = await self.db[type].get([dd.userUUID, dd.date]);
            if (val == undefined) {
                self.db[type].put(dd);
            } else {
                self.db[type].update([dd.userUUID, dd.date], dd)
            }
        }

        let {activity, heart_rate, sleep, date} = summary;
        let dd0 = {
            date: date,
            userUUID: uuid
        };
        await updType('activity', {...dd0, ...activity});
        await updType('heart_rate', {...dd0, ...heart_rate});
        await updType('sleep', {...dd0, ...sleep});
    }

    public async getAllSummariesOfSpecifiedTypeFromCache(type: "activity" | "sleep" | "heart_rate") {
        let uuid: string | undefined | null = this.cloud?.getUUID();
        if (uuid == undefined) {
            return [];
        }
        console.log('getAllSummariesOfSpecifiedTypeFromCache: uuid = ', uuid);
        let items = await (await this.db[type].where({userUUID: uuid})).toArray();
        console.log('items = ', items);
        return items;
    }

    public async getDailySummaryActivitySleepOrHeartRateFromCache(dayTimestamp: number, type: "activity" | "sleep" | "heart_rate") {
        let date: string = moment(dayTimestamp).format('YYYY-MM-DD');
        let uuid: string | undefined | null = this.cloud?.getUUID();
        console.log('getDailySummaryActivitySleepOrHeartRate: date, uuid = ', date, uuid);
        try {
            let val = await this.db[type].get([`${uuid}`, date]);
            return val;
        } catch (exc) {

        }
    }

    public async getUserSummaryForDay(dayTimestamp: number) {
        let date: string = moment(dayTimestamp).format('YYYY-MM-DD');
        let ax = this.getHealthAxios();
        let pld = (await ax.get('/user-summaries', {
            params: {
                date_from: date,
                date_to: date,
                include_slots: 1
            }
        })).data;
        if (pld == undefined || pld.total == undefined || pld.total == 0) {
            return null;
        }
        console.log('--->> getUserSummariesForDay: pld = ', pld);
        if (pld.data == undefined || pld.data.length == 0) {
            return null;
        }
        let summary = pld.data[0];
        await this.cacheSummary(summary);
        return summary;
    }


    public async getDailySummaryActivitySleepOrHeartRate(dayTimestamp: number, type: "activity" | "sleep" | "heart_rate") {
        let summary = await this.getUserSummaryForDay(dayTimestamp);
        return summary && summary[type];
    }

    public async getUserSummariesForTimeRange(fromDayTimestamp: number, toDayTimestamp: number, with_slots: boolean = false) {
        let max_res_num = Math.ceil((toDayTimestamp - fromDayTimestamp) / 86400000.0) + 2;
        let ax = this.getHealthAxios();
        let pld = (await ax.get('/user-summaries', {
            params: {
                date_from: moment(fromDayTimestamp).format('YYYY-MM-DD'),
                date_to: moment(toDayTimestamp).format('YYYY-MM-DD'),
                include_slots: (with_slots == true) ? 1 : 0,
                per_page: max_res_num
            }
        })).data;
        if (pld == undefined || pld.total == undefined || pld.total == 0) {
            return null;
        }
        if (pld.data == undefined || pld.data.length == 0) {
            return null;
        }
        let summaries = pld.data;
        for (let summary of summaries) {
            await this.cacheSummary(summary);
        }
        return summaries;
    }

    public async getRawMetricsForRange(fromDate: string, toDate: string, type: "activity" | "sleep" | "heart_rate" | "rr_intervals") {
        let ax = this.getHealthAxios();
        let s = {
            'heart_rate': 'raw_heart_rates',
            'sleep': 'raw_sleeps',
            'activity': 'raw_activities',
            'rr_intervals': 'raw_rr_intervals'
        }[type];
        try {
            let pld = (await ax.get(`/raw-metrics`, {
                params: {
                    per_page: 100000,
                    only_metrics: [s],
                    date_from: fromDate,
                    date_to: toDate
                }
            })).data;
            console.log('got getRawMetricsForRange pld = ', pld);
            return pld[s];
        } catch (exc) {

        }
    }

    public async getHealthUserSettings() {
        let ax = this.getHealthAxios();
        try {
            let pld = (await ax.get(`/user-settings`)).data;
            console.log('got getUserSettings pld = ', pld);
            return pld;
        } catch (exc) {

        }
    }

    public async saveHealthUserSettings(d: any) {
        let ax = this.getHealthAxios();
        try {
            let pld = (await ax.put(`/user-settings`, {...d})).data;
            console.log('got getUserSettings pld = ', pld);
            return pld;
        } catch (exc) {

        }
    }

    public async getUserSummariesForMonth(monthTimestamp: number, with_slots: boolean = false) {
        let from = +moment(monthTimestamp).startOf('month');
        let to = +moment(monthTimestamp).endOf('month');
        let summaries = await this.getUserSummariesForTimeRange(from, to, with_slots);
        return summaries;
    }

    public async getUserSummariesForYear(yearTimestamp: number, with_slots: boolean = false) {
        let from = +moment(yearTimestamp).startOf('year');
        let to = +moment(yearTimestamp).endOf('year');
        let summaries = await this.getUserSummariesForTimeRange(from, to, with_slots);
        return summaries;
    }

    public async getUserSummariesFor7DaysFromCloud(dayTimestamp: number, with_slots: boolean = false) {
        let from = +moment(dayTimestamp).add(-7, 'days');
        let to = +moment(dayTimestamp).endOf('day');
        let summaries = await this.getUserSummariesForTimeRange(from, to, with_slots);
        return summaries;
    }

}

export default RonasBasicAPI;