import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import { get24HrTicks, get36HrTicks } from "./AiSleepViewTool";
import useDimensions from "react-use-dimensions";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";
import CommonHelper from "../../../helpers/CommonHelper";

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const IS_ASLEEP_HEIGHT = 1;
const IS_AWAKE_HEIGHT = 2;
const IS_AWAKE_COLOR = "#FC0903ff";
const IS_ASLEEP_COLOR = "#3763B5ff";

function findSleepStateChange(sleepData) {
  let changePoints = [];
  if (sleepData.length === 0) {
    return changePoints;
  }
  let lastValue = sleepData[0].sleepState;
  let lastIndex = 0;

  for (let i = 1; i < sleepData.length; i++) {
    if (sleepData[i].sleepState !== lastValue) {
      changePoints.push({
        index: i,
        indexFrom: lastIndex,
        indexTo: i,
        sleepState: sleepData[i - 1].sleepState,
        // dateFrom: sleepData[i - 1].date,
        dateFrom: sleepData[lastIndex].date,
        _dateFrom: moment(sleepData[i - 1].date).format("DD HH:mm Z"),
        dateTo: sleepData[i].date,
        timestamp: sleepData[i].timestamp,
      });
      lastIndex = i;
      lastValue = sleepData[i].sleepState;
    }
  }
  changePoints.push({
    index: sleepData.length - 1,
    indexFrom: lastIndex,
    indexTo: sleepData.length - 1,
    dateFrom: sleepData[sleepData.length - 2].date,
    dateTo: sleepData[sleepData.length - 1].date,
    sleepState: sleepData[sleepData.length - 1].sleepState,
    timestamp: sleepData[sleepData.length - 1].timestamp,
  });
  return changePoints;
}

const VALID_SLEEP_STATES_VALUES = [0, 1, 2, 3, 4, 5];
const SLEEP_VALUES = [2, 3, 4, 5];
const SleepStatusName = [
  "undefined",
  "Awake",
  "Light",
  "Deep",
  "REM",
  "GoToSleep",
];

const getSleepStatus = (input) => {
  if (VALID_SLEEP_STATES_VALUES.includes(input)) {
    return SleepStatusName[input];
  } else {
    return "unknown";
  }
};
const SLEEP_STATUS_PROPERTIES = {
  undefined: {
    color: "#657",
    height: 0.0,
    isAsleep: undefined,
  },
  Awake: {
    color: IS_AWAKE_COLOR,
    height: IS_AWAKE_HEIGHT,
    isAsleep: false,
  },
  Light: {
    color: IS_ASLEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  Deep: {
    color: IS_ASLEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  REM: {
    color: IS_ASLEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  GoToSleep: {
    color: IS_ASLEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
};

const SleepOrWake = (input) => {
  /**
   *
   * SleepStatus: Int {
   *    case Undefined = 0
   *    case Awake     = 1
   *    case Light     = 2
   *    case Deep      = 3
   *    case Rem       = 4
   *     Case GoToSleep = 5
   *}
   * output: 1: awake
   *         2: sleep
   *
   **/
  if (input == 1) {
    return 1;
  } else if (SLEEP_VALUES.includes(input)) {
    return 2;
  } else {
    return 0;
  }
};

export const filterSleepData = (data) => {
  const _validPoints = data.filter((x) => {
    const _sleep_state = x?.sleep_state;
    const _date = x?.date;
    return (
      _sleep_state !== undefined &&
      _sleep_state !== null &&
      _date &&
      VALID_SLEEP_STATES_VALUES.includes(_sleep_state)
    );
  });
  return _validPoints;
};

const mapToSleepData = (data) => {
  const _sleepData = data.map((x, i) => {
    return {
      sleepState: x.sleep_state,
      date: x.date,
      sleep_q: x.sleep_q,
      date_without_tz: x.date_without_tz,
      timestamp: x.timestamp,
    };
  });
  return _sleepData;
};

export default function SleepWakeTool(props) {
  const {
    uuid,
    date,
    sleepDataResponse = [],
    sleepDataLoading = false,
    sleepAndWakeUpTime = {
      time_to_sleep: undefined,
      time_to_wake_up: undefined,
      start_date: undefined,
    },
  } = props;
  const [sleepData, setSleepData] = useState([]);
  const [unprocessedSleepData, setUnprocessedSleepData] = useState([]);
  // const [sleepDataLoading, setSleepDataLoading] = useState(false);
  const [sleepDataStatus, setSleepDataStatus] = useState([]);
  const [mode, setMode] = useState("24"); // or "36"
  const [timeToSleep, setTimeToSleep] = useState(
    sleepAndWakeUpTime.time_to_sleep
  );
  const [timeToWakeUp, setTimeToWakeup] = useState(
    sleepAndWakeUpTime.time_to_wake_up
  );

  const handleModeChange = (newMode) => {
    setMode(newMode);
  };

  // useEffect(() => {}, [date, uuid, sleepDataResponse]);

  useEffect(() => {
    // setSleepDataLoading(true);
    // setSleepData([]);
    const d = sleepDataResponse;

    // console.log({ timeToSleep, timeToWakeUp });

    setSleepDataStatus([]);
    // console.log("getPatientNoonToNoonSleep", d);
    // console.table(d, ["sleep_state", "date", "activity_count"]);
    setUnprocessedSleepData(d);
    const _validPoints = filterSleepData(d);
    const _sleepData = mapToSleepData(_validPoints);
    let statesChange = findSleepStateChange(_sleepData);
    setSleepDataStatus(statesChange);
    setSleepData(_sleepData);
  }, [date, uuid, sleepDataResponse]);

  // useEffect(() => {
  //   setSleepDataLoading(true);
  //   setSleepData([]);
  //   setSleepDataStatus([]);
  //   DoctorAPI.getPatientNoonToNoonSleep(uuid, date).then((d) => {
  //     const _validPoints = filterSleepData(d);
  //     const _sleepData = _validPoints.map((x, i) => {
  //       return {
  //         sleepState: x.sleep_state,
  //         date: x.date,
  //         sleep_q: x.sleep_q,
  //         date_without_tz: x.date_without_tz,
  //         timestamp: x.timestamp,
  //       };
  //     });
  //     let statesChange = findSleepStateChange(_sleepData);
  //     if (mode === "24") {
  //       setSleepDataStatus(statesChange);
  //       setSleepData(_sleepData);
  //       setSleepDataLoading(false);
  //     } else {
  //       DoctorAPI.getPatientDaySleep(uuid, date).then((dd) => {
  //         const _validPointsDD = filterSleepData(dd);
  //         const _sleepDataDD = _validPointsDD.map((x, i) => {
  //           return {
  //             sleepState: x.sleep_state,
  //             date: x.date,
  //             sleep_q: x.sleep_q,
  //             date_without_tz: x.date_without_tz,
  //             timestamp: x.timestamp,
  //           };
  //         });
  //         const combinedData = [..._sleepData, ..._sleepDataDD];
  //         setSleepDataLoading(false);
  //         setSleepData(combinedData);
  //         let statesChangeDD = findSleepStateChange(combinedData);
  //         // console.log("combinedStatus", combinedStatus);
  //         setSleepDataStatus(statesChangeDD);
  //       });
  //     }
  //   });
  // }, [mode]);

  const dateFrom = moment(date);
  //   const hourOffset = 12 + tz + diff;
  const hourOffset = 12;
  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");

  // console.log("sleepDataStatus", sleepDataStatus);

  const toFixedValue = 2;
  let slots = [];
  if (mode === "24") {
    slots = sleepDataStatus.map((sleepSlot, i) => {
      const _left_percent =
        i === 0
          ? 0
          : (100 *
              moment(sleepData[sleepSlot.indexFrom]?.date).diff(
                twelveHoursBefore,
                "seconds"
              )) /
            (60 * HOURS_PER_DAY * MINUTES_PER_HOUR);

      const _width =
        1000 *
        MINUTES_PER_HOUR *
        60 *
        moment(sleepData[sleepSlot.indexTo]?.date).diff(
          moment(sleepData[sleepSlot.indexFrom]?.date),
          "seconds"
        );

      const _width_percent =
        (100 *
          moment(sleepData[sleepSlot.indexTo]?.date).diff(
            moment(sleepData[sleepSlot.indexFrom]?.date),
            "seconds"
          )) /
        (60 * HOURS_PER_DAY * MINUTES_PER_HOUR);
      return {
        key: i,
        sleepState: sleepSlot.sleepState,
        color:
          SLEEP_STATUS_PROPERTIES[getSleepStatus(sleepSlot.sleepState)].color,
        start_date: sleepData[sleepSlot.indexFrom]?.date,
        end_date: sleepData[sleepSlot.indexTo]?.date,
        from: Date.parse(sleepData[sleepSlot.indexFrom]?.date),
        to: Date.parse(sleepData[sleepSlot.indexTo]?.date),
        height:
          SLEEP_STATUS_PROPERTIES[getSleepStatus(sleepSlot.sleepState)].height,
        timestamp: sleepData[sleepSlot.indexFrom]?.date,
        type: SLEEP_STATUS_PROPERTIES[getSleepStatus(sleepSlot.sleepState)]
          .isAsleep,
        // raw: moment(x.raw).diff(twelveHoursBefore, "minutes") / (24 * 60),
        left_percent: _left_percent.toFixed(toFixedValue),
        width: _width.toFixed(toFixedValue), // in milliseconds
        width_percent: _width_percent.toFixed(toFixedValue),

        // left_percent: (100 * x.indexFrom) / sleepDataLength,
        // width: 1000 * 60 * (x.indexTo - x.indexFrom + 1),
        // width_percent: (100 * (x.indexTo - x.indexFrom + 1)) / sleepDataLength,
      };
    });
  } else if (mode === "36") {
    slots = sleepDataStatus.map((sleepSlot, i) => {
      const _left_percent =
        i === 0
          ? 0
          : (100 *
              moment(sleepData[sleepSlot.indexFrom]?.date).diff(
                twelveHoursBefore,
                "seconds"
              )) /
            (60 * 36 * MINUTES_PER_HOUR);
      const _width =
        1000 *
        MINUTES_PER_HOUR *
        60 *
        moment(sleepData[sleepSlot.indexTo]?.date).diff(
          moment(sleepData[sleepSlot.indexFrom]?.date),
          "seconds"
        );

      const _width_percent =
        (100 *
          moment(sleepData[sleepSlot.indexTo]?.date).diff(
            moment(sleepData[sleepSlot.indexFrom]?.date),
            "seconds"
          )) /
        (60 * 36 * MINUTES_PER_HOUR);
      return {
        key: i,
        sleepState: sleepSlot.sleepState,
        color:
          SLEEP_STATUS_PROPERTIES[getSleepStatus(sleepSlot.sleepState)].color,
        start_date: sleepData[sleepSlot.indexFrom]?.date,
        end_date: sleepData[sleepSlot.indexTo]?.date,
        from: Date.parse(sleepData[sleepSlot.indexFrom]?.date),
        to: Date.parse(sleepData[sleepSlot.indexTo]?.date),
        height:
          SLEEP_STATUS_PROPERTIES[getSleepStatus(sleepSlot.sleepState)].height,
        timestamp: sleepData[sleepSlot.indexFrom]?.date,
        type: SLEEP_STATUS_PROPERTIES[getSleepStatus(sleepSlot.sleepState)]
          .isAsleep,
        // raw: moment(x.raw).diff(twelveHoursBefore, "minutes") / (24 * 60),
        left_percent: _left_percent.toFixed(toFixedValue),
        width: _width.toFixed(toFixedValue), // in milliseconds
        width_percent: _width_percent.toFixed(toFixedValue),

        // left_percent: (100 * x.indexFrom) / sleepDataLength,
        // width: 1000 * 60 * (x.indexTo - x.indexFrom + 1),
        // width_percent: (100 * (x.indexTo - x.indexFrom + 1)) / sleepDataLength,
      };
    });
  } else {
  }

  // console.log("binary raw data:", sleepData);
  let ticksNumber = 24;
  // const ticks24Hrs = get24HrTicks(12, date);
  let xticks = [];
  if (mode === "24") {
    xticks = get24HrTicks(12, date);
  } else {
    xticks = get36HrTicks(12, date);
  }
  // console.log(xticks);

  if (sleepDataLoading) {
    return (
      <SimpleNoDataMessageTool
        showTopImg={false}
        message={`${mode} hours Sleep/Wake data loading...`}
      />
    );
  }

  const ExportButtons = (props) => {
    const { sleepData = [] } = props;
    return (
      <ModeSelector style={{ height: 30, marginTop: "1rem" }}>
        Export Data:{" "}
        <ModeOption
          style={{
            // textDecoration: mode === "24" ? "underline" : "",
            fontWeight: 500,
          }}
          onClick={() => {
            let d = sleepData;
            let head = `sleep_state;sleep_q;movement_level;activity_count;reserved1;data_source_id;_id;timestamp;date;date_without_tz;calendar_date;updated_at;created_at`;
            let csv = `${head}\n${d
              .map(
                (x, i) =>
                  `${x.sleep_state};${x.sleep_q};${x.movement_level};${x.activity_count};${x.reserved1};${x.data_source_id};${x._id};${x.timestamp};${x.date};${x.date_without_tz};${x.calendar_date};${x.updated_at};${x.created_at}`
              )
              .join("\n")}`;
            CommonHelper.downloadFile(`corsano_sleep_${uuid}_${date}.csv`, csv);
          }}
        >
          CSV
        </ModeOption>{" "}
        |{" "}
        <ModeOption
          style={{
            // textDecoration: mode === "36" ? "underline" : "",
            fontWeight: 500,
          }}
          onClick={() => {
            // console.log(sleepData);
            let json = JSON.stringify(sleepData);
            CommonHelper.downloadFile(
              `corsano_sleep_${uuid}_${date}.json`,
              json
            );
          }}
        >
          JSON
        </ModeOption>
      </ModeSelector>
    );
  };

  const RangeModeSelector = (props) => {
    // const { sleepData = [] } = props;
    return (
      <ModeSelector style={{ height: 30, marginTop: "1rem" }}>
        Mode:{" "}
        <ModeOption
          style={{
            textDecoration: mode === "24" ? "underline" : "",
            fontWeight: mode === "24" ? 600 : 400,
          }}
          onClick={() => {
            handleModeChange("24");
          }}
        >
          24 Hours
        </ModeOption>{" "}
        |{" "}
        <ModeOption
          style={{
            textDecoration: mode === "36" ? "underline" : "",
            fontWeight: mode === "36" ? 600 : 400,
          }}
          onClick={() => {
            handleModeChange("36");
          }}
        >
          36 Hours
        </ModeOption>
      </ModeSelector>
    );
  };

  // console.log("slots", slots);

  return (
    <div sfyle={{ height: 260 }}>
      {!slots || slots.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there no sleep/wake data for this day."}
        />
      ) : (
        <>
          <div style={{ height: 230 }}>
            <BinarySleepViewTool
              vertAxisLabels={["SLEEP", "AWAKE"]}
              slots={slots}
              ticks={xticks}
              mode={mode}
              //   utcHandler={adjustUTCOffset}
              //   utcOffset={utcOffset}
            />
          </div>
          <div style={{ display: "flex" }}>
            {/* <RangeModeSelector /> */}
            <ExportButtons sleepData={unprocessedSleepData} />
          </div>
        </>
      )}
    </div>
  );
}

function BinarySleepViewTool(props) {
  const {
    slots = [],
    vertAxisLabels = ["Sleep", "Awake"],
    ticksNumber = 8,
    ticks = [],
    noDataText = "...",
    utcHandler = () => {},
    loading,
    mode = "24",
  } = props;

  const optSlots = slots;
  const [hoveredIndex, setHoveredIndex] = useState(null);
  // const chartRef = useRef(null);
  const [mousePos, setMousePos] = useState({
    mousex: undefined,
    mousey: undefined,
  });
  const [ref, { y, x, left, height, width, bottom }] = useDimensions();
  // console.log(ref, size);
  const SleepWakeTriangleMark = (props) => {
    const { status = "" } = props;
    // console.log(status);
    // Bedtime, Sleep Onset, Out of Bed, Wake Up

    return (
      <div>
        <TriangleMark $status={status} />
      </div>
    );
  };

  if (optSlots.length == 0) {
    return (
      <NoDataPlaceholder>
        <NoDataText>{noDataText}</NoDataText>
      </NoDataPlaceholder>
    );
  }

  return (
    <ViewWrapper>
      {/* <div>
          <div
            style={{ height: "10px", width: "10px", background: "red" }}
            onClick={() => utcHandler(1)}
          />
          <div
            style={{ height: "10px", width: "10px", background: "blue" }}
            onClick={() => utcHandler(-1)}
          />
          <div>{props.utcOffset}</div>
        </div> */}
      <LeftPlaceholder>
        <LeftInnerPlaceholder>
          {vertAxisLabels.map((x, i) => (
            <VertAxisLabel
              className="sleep-vert-label"
              key={`${x}`}
              height={+i + 1}
            >
              {x}
            </VertAxisLabel>
          ))}
        </LeftInnerPlaceholder>
      </LeftPlaceholder>

      <RightPlaceholder>
        <TopPlaceholder className="sleep-top-placeholder">
          <ChartPlaceholder ref={ref}>
            {[1, 2].map((x) => (
              <HorLine key={x} height={x} />
            ))}
            {optSlots.map((opt, i) => {
              //   console.log(opt);
              let { sleepState, end_date, start_date, from, to } = opt;

              let sw = SleepOrWake(sleepState);
              let text = "";
              if (sw === 2) {
                text = "Sleep";
              } else if (sw === 1) {
                text = "Awake";
              } else {
                text = "";
              }
              const startString = moment(from).format("DD HH:mm");
              const untilString = moment(to).format("DD HH:mm");

              return (
                <Box
                  {...opt}
                  key={`opt_${i}_${opt.width_percent}`}
                  onMouseEnter={() => {
                    setHoveredIndex(i);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(undefined);
                  }}
                  onMouseMove={(event) => {
                    const pageX = event.pageX;
                    const pageY = event.pageY;
                    // console.log({ pageX, pageY });
                    // console.log(
                    //   90 + pageX - (window.innerWidth * opt.left_percent) / 100
                    // );
                    // console.log(window.innerWidth, opt.left_percent);

                    setMousePos({
                      mousex: pageX,
                      mousey: pageY,
                    });
                  }}
                >
                  {/* <SleepWakeTriangleMark status={text} /> */}
                  {hoveredIndex === i ? (
                    <BoxToolTip
                      className="box-info"
                      $mousex={mousePos.mousex}
                      $offset={(
                        mousePos.mousex -
                        x -
                        opt.left_percent * 0.01 * width -
                        100 * (mousePos.mousex / window.innerWidth)
                      ).toFixed(0)}
                      $voffset={sw === 1 ? 60 : -40}
                    >
                      <div>Sleep Status: {text}</div>
                      <div>Start: {startString}</div>
                      <div>Until: {untilString}</div>
                    </BoxToolTip>
                  ) : null}
                </Box>
              );
            })}
          </ChartPlaceholder>
        </TopPlaceholder>

        <BottomPlaceholder className="sleep-bottom-placeholder" $mode={mode}>
          {ticks.map((x, i) => {
            return (
              <Tick key={i} left={x.left}>
                <TickInner className="sleep-tick-label">
                  {moment(x.t).format("DD dd HH:mm")}
                  {/* {moment(x.t).format("DD dd HH:mm ZZ")} */}
                </TickInner>
              </Tick>
            );
          })}
        </BottomPlaceholder>
      </RightPlaceholder>

      {/*{JSON.stringify(optSlots)}*/}
    </ViewWrapper>
  );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = "lightgrey";

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${(props) => `${props.left}%`};
`;

const TickInner = styled.div`
  width: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  // background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const ViewWrapper = styled.div`
  box-sizing: border-box;
  //   border: 1px solid blue;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${(props) => `calc(${(100.0 * props.height) / 2.0}% - 1px)`};
  z-index: 0;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${(props) => `${(100.0 * props.height) / 2.0}%`};
  width: ${(props) => `${props.width_percent}%`};
  left: ${(props) => `${props.left_percent}%`};
  box-sizing: border-box;
  background: ${(props) => props.color};
  //   z-index: ${(props) => `${props.type}`};
  //   z-index: 10;
  &:hover {
    .box-info {
      display: block;
    }
  }
`;
const BoxToolTip = styled.div`
  display: none;
  padding: 10px;
  color: #111;
  width: max-content;
  height: 60px;
  border: 1px solid lightgrey;
  background: #ffffff;
  font-size: 16px;
  position: absolute;
  // top: 2rem;
  top: ${(props) => `${props.$voffset}px`};
  left: ${(props) => `${props.$offset}px`};
  z-index: 100;
`;

const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${(props) =>
    `calc(${(100.0 * props.height) / 2.0}% - 1rem + ${bottomHeight}px)`};
  z-index: 1;
  :last-of-type {
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
  // border: 1px solid blue;
  background: ${(props) =>
    props.$mode === "24"
      ? "-webkit-linear-gradient(180deg, #ffffff00 50%, #ffffff00 50%);"
      : "-webkit-linear-gradient(180deg, #ffffff00 66.66%, #ffffff00 0%);"}
  z-index: 100;
`;
const Wrapper = styled.div``;

// const SleepStatusMap = {

// }

const ModeSelector = styled.div``;

const ModeOption = styled.span`
  cursor: pointer;
`;

const TriangleMark = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent
    ${(props) => (props.$status === "Awake" ? IS_ASLEEP_COLOR : IS_AWAKE_COLOR)}
    transparent;
  transform: rotate(${(props) => (props.$status === "Awake" ? "180" : "0")}deg);
`;
