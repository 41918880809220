import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import DoctorAPI from "../../../api/DoctorAPI";

export default function SpirometerTool(props) {
    const {
        uuid
    } = props;

    const [items, setItems] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        DoctorAPI.getSpiroMeasurements(uuid).then(arr => {
            setItems(arr);
            setLoading(false);
        });
    }, []);

    let sortedItems = items.sort((a, b) => (+b.timestamp - +a.timestamp));

    return (
        <Wrapper>

            <Table>

                <Thead>
                    <Tr className='spirometry-table'>

                        <Th>
                            Date and Time
                        </Th>

                        <Th>
                            Measurement result
                        </Th>

                        <Th>
                            FVC
                        </Th>

                        <Th>
                            FEV1
                        </Th>

                        <Th>
                            FEV6
                        </Th>

                        <Th>
                            FEV1/FVC
                        </Th>

                        <Th>
                            FEV2575
                        </Th>

                        <Th>
                            PEF
                        </Th>

                    </Tr>
                </Thead>

                <Tbody>

                    {sortedItems.map((a, i) => {
                        return (
                            <Tr key={`d${i}_${a?._id}`} className='spirometry-table'>
                                <Td>
                                    {moment(a.timestamp).format('DD.MM.YYYY HH:mm')}
                                </Td>
                                {['measurement_result', 'fvc', 'fev1', 'fev6', 'fev1_fvc', 'fev2575', 'pef'].map((x, j) => {
                                    return (
                                        <Td key={j}>
                                            {a[x]}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}

                </Tbody>

            </Table>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;


const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;


const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;