import { useEffect, useState } from "react";
import DoctorAPI from "../../../api/DoctorAPI";
import SabirBarChart from "../../activity/tools/SabirBarChart";
import moment from "moment";

export default function SleepActivitityTool(props) {
  const { date, uuid, points = [] } = props;

  const [activityPoints, setActivityPoints] = useState([]);
  const [activityPointsLoading, setActivityPointsLoading] = useState(false);

  useEffect(() => {
    setActivityPoints(points);
  }, [points, date, uuid]);

  // useEffect(() => {
  //   let activityDataFrom = moment(date)
  //     .startOf("day")
  //     .subtract(12, "hour")
  //     .valueOf();
  //   let activityDataTo = moment(date).startOf("day").add(12, "hour").valueOf();

  //   setActivityPointsLoading(true);
  //   DoctorAPI.getActivityPoints(uuid, activityDataFrom, activityDataTo).then(
  //     (d) => {
  //       setActivityPointsLoading(false);
  //       if (!d || d.length === 0) {
  //         return;
  //       }
  //       let _ActivityPoints = d;
  //       _ActivityPoints = _ActivityPoints.map((s) => {
  //         return {
  //           ...s,
  //           t: moment(s.timestamp).valueOf(),
  //           slot: moment(s.timestamp).valueOf(),
  //           date: moment(s.timestamp).format("HH:mm:ss"),
  //         };
  //       });
  //       setActivityPoints(_ActivityPoints);
  //     }
  //   );
  // }, [date, uuid]);

  let noonToNoonPerHourSteps = [];
  let dateFormatting = "DD dd HH:00";

  for (let hour = -12; hour < 12; hour++) {
    // const formattedHour = hour.toString().padStart(2, "0");
    const formattedDateHour = moment(date)
      .startOf("day")
      .add(hour, "hours")
      .format(dateFormatting);
    const timeString = `${formattedDateHour}`;
    noonToNoonPerHourSteps.push({
      time: timeString,
      value: 0,
    });
  }
  // console.log(noonToNoonPerHourSteps);

  activityPoints.map((activityPoint, i) => {
    let ts = activityPoint.timestamp;
    let steps = activityPoint.steps;

    const _hour = moment(+ts).format(dateFormatting);
    for (let item of noonToNoonPerHourSteps) {
      if (item.time === _hour) item.value += +steps;
    }
  });

  return (
    <div style={{ height: 300 }} className="sleep-activity-chart">
      <SabirBarChart
        shouldChangeColorOfSelected={false}
        barColor={"#FF962A"}
        items={noonToNoonPerHourSteps}
        type="sleep-activity"
        renderItem={(it) => (
          <span
            style={{
              fontWeight: "normal",
              color: "grey",
              fontSize: 10,
              // opacity: "50%",
              top: 6,
              textAlign: "center",
              position: "relative",
              // right: 6,
            }}
          >
            {it.time}
          </span>
        )}
      />
    </div>
  );
}
