import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'


export default function UpdateCorsanoPatientForm(props) {

    const {
        loading = false,
        onSave = d => {

        }
    } = props;

    const [name, setName] = useState(props.name || '');

    return (
        <Wrapper>
            <Row>
                <Label>
                    Name
                </Label>
                <Input value={name}
                       placeholder={'Patient name'}
                       onChange={evt => {
                           setName(evt.target.value);
                       }}/>
            </Row>


            <ButtonsPlaceholder>

                <Button onClick={() => {
                    onSave({
                        name
                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        'Save'
                    }
                </Button>

            </ButtonsPlaceholder>


        </Wrapper>
    );
}

export const Row = styled.div`
  margin-bottom: 10px;
`;

export const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
`;

const ButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  border-radius: 6px;
  outline: none;
  height: 52px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding-left: 7px;

  ::placeholder {
    color: #8798AD;
    opacity: 0.5;
  }
`;

export const Textarea = styled.textarea`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  border-radius: 6px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding-left: 7px;
  min-height: 6em;
  padding-top: 10px;

  ::placeholder {
    color: #8798AD;
    opacity: 0.5;
  }

`;

const Wrapper = styled.div`

`;

const Button = styled.div`
  width: 200px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const InputPlaceholder = styled.div`
  flex: 1;
  margin-right: 30px;
`;