import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import ls from "local-storage";
// import { clearScreenDown } from "readline";
import toast from "react-hot-toast";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";

// const LAYOUT_STATES = ["Rows", "Big Tiles", "Small Tiles"];
// const VALID_LAYOUTS = ["normal", "big_tiles", "small_tiles"];
const LAYOUT_STATES = [
  {
    label: "Rows (Default)",
    value: "normal",
  },
  {
    label: "Big Tiles (4 patients per row)",
    value: "big_tiles",
  },
  {
    label: "Small Tiles (6 patients per row)",
    value: "small_tiles",
  },
];
const DEFAULT_THEME = "light";

export default function UserDisplayTool(props) {
  // const [layoutStatus, setLayoutStatus] = useState(0);
  let { theme, layout, unit } = useMappedState(
    useCallback((state) => {
      return {
        theme: state.ui.theme,
        layout: state.ui.layout,
        unit: state.ui.unit,
      };
    }, [])
  );
  const dispatch = useDispatch();

  // useEffect(() => {}, []);

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <SettingSubHeader className="setting-top-section">
          Theme
        </SettingSubHeader>
        <Label className="setting-text-active">
          <Checkbox
            id="light-mode"
            name="select-light-mode"
            type="checkbox"
            color="blue"
            checked={theme === "light"}
            onChange={() => {
              const newTheme = "light";
              ls.set("theme", newTheme);
              // setTheme(newTheme);
              dispatch(UiActions.changeTheme(newTheme));
            }}
          />
          Light Mode
        </Label>
        <Label className="setting-text-active">
          <Checkbox
            onChange={() => {
              const newTheme = "dark";
              ls.set("theme", newTheme);
              dispatch(UiActions.changeTheme(newTheme));
            }}
            id="dark-mode"
            name="select-dark-mode"
            type="checkbox"
            checked={theme === "dark"}
          />
          Dark Mode
        </Label>
      </div>
      <div>
        <SettingSubHeader className="setting-top-section">
          Patient Layout
        </SettingSubHeader>
        {LAYOUT_STATES.map((layoutState, i) => {
          return (
            <Label key={i} className="setting-text-active">
              <Checkbox
                type="checkbox"
                onChange={() => {
                  const newLayout = layoutState.value;
                  ls.set("layout", newLayout);
                  dispatch(UiActions.changeLayout(newLayout));
                  toast.success("Success");
                }}
                checked={layout == layoutState.value}
              />
              {layoutState.label}
            </Label>
          );
        })}
      </div>
      <div>
        <SettingSubHeader className="setting-top-section">
          Temperature Unit
        </SettingSubHeader>
        <Label className="setting-text-active">
          <Checkbox
            onChange={() => {
              const newUnit = "C";
              ls.set("unit", newUnit);
              dispatch(UiActions.changeTempUnit(newUnit));
              toast.success("Success");
            }}
            id="temp-cel"
            name="select-cel"
            type="checkbox"
            checked={unit === "C"}
          />
          Celsius (°C)
        </Label>
        <Label className="setting-text-active">
          <Checkbox
            onChange={() => {
              const newUnit = "F";
              ls.set("unit", newUnit);
              dispatch(UiActions.changeTempUnit(newUnit));
              toast.success("Success");
            }}
            id="temp-fah"
            name="select-fah"
            type="checkbox"
            checked={unit === "F"}
          />
          Fahrenheit (°F)
        </Label>
      </div>
    </>
  );
}

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
`;

const Checkbox = styled.input`
  &[type="checkbox"] {
    accent-color: #ddd;
  }
`;

const SettingSubHeader = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

//
// input[type="checkbox"] {
//     accent-color: #ddd
// }
//
