import axios from 'axios'
import env from "react-dotenv";

const {API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const TemplatesAPI = {

    async getStudyGroupTemplate(doctorUUID, groupCode) {
        let pld = (await axios.get(`${API_ENDPOINT}/templates/${doctorUUID}/${groupCode}`)).data;
        return pld;
    },

    async saveStudyGroupTemplate(doctorUUID, groupCode, content) {
        let pld = (await axios.post(`${API_ENDPOINT}/templates/${doctorUUID}/${groupCode}`, {
            content: content
        })).data;
        return pld;
    },

    async getPrintInfo(groupCode) {
        let pld = (await axios.get(`${API_ENDPOINT}/print/groups/${groupCode}`)).data;
        return pld;
    },





}

export default TemplatesAPI;
