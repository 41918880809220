import styled from "styled-components";
import DoctorTemplate from "../templates/DoctorTemplate";
import EditPatientPanel from "../patients/panels/EditPatientPanel";
import { useParams } from "react-router-dom";

function PatientEditApp(props) {
  let params = useParams();
  let { id } = params;

  return (
    <DoctorTemplate active={"patients"}>
      <Wrapper>
        <EditPatientPanel id={id} />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default PatientEditApp;
