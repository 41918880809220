import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CardiolyseAPI from "../../../api/CardiolyseAPI";
import DetailsValuesTable from "./DetailsValuesTable";
import QrsChartTool from "./QrsChartTool";

export function selectFiles() {
    return new Promise(resolve => {
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.onchange = _ => {
            let files = Array.from(input.files);
            resolve(files);
        };
        input.click();
    });
}

export default function CardiolyseCsvTool(props) {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState(undefined);
    const [recordId, setRecordId] = useState();
    useEffect(() => {
        if (recordId == undefined) {
            return;
        }
        setInfo(undefined);
        setLoading(true);
        CardiolyseAPI.getAllCombinedData(recordId).then(x => {
            setInfo(x);
            setLoading(false);
        })
    }, [recordId]);

    let detailsValues = info?.details?.data?.values || [];
    let qrsPoints = [];
    try {
        qrsPoints = info?.qrs?.data[0]?.data;
    } catch (e) {

    }

    return (
        <Wrapper>

            <TopParagraph>
                This is a page for testing ECG csv files produced by <b>287-2</b> bracelet. Please select file, wait for
                processing.{' '}
                <a href={'https://scr.sabir.pro/Z00mUE'} target={'_blank'}>
                    Check the CSV structure
                </a>
            </TopParagraph>

            <TopPlaceholder>
                <AddButton
                    style={{marginRight: 20}}
                    onClick={async () => {
                    let files = await selectFiles();
                    if (files.length == 0) {
                        return;
                    }
                    setRecordId(undefined);
                    setInfo(undefined);
                    let file = files[0];
                    setLoading(true);
                    let pld = await CardiolyseAPI.uploadCsvEcg(file);
                    console.log('pld = ', pld);
                    setLoading(false);
                    if (pld != undefined) {
                        setRecordId(pld?.recordId);
                    }
                }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null :
                        'Select ECG csv'
                    }

                </AddButton>

                <AddButton
                    style={{marginRight: 20}}
                    onClick={async () => {
                        let files = await selectFiles();
                        if (files.length == 0) {
                            return;
                        }
                        setRecordId(undefined);
                        setInfo(undefined);
                        let file = files[0];
                        setLoading(true);
                        let pld = await CardiolyseAPI.uploadWiffEcg(file);
                        console.log('pld = ', pld);
                        setLoading(false);
                        if (pld != undefined) {
                            setRecordId(pld?.recordId);
                        }
                    }}>
                    <Spin visible={loading} theme={'alfa-on-color'}/>
                    {loading == true ? null :
                        'Select ECG .wiff file '
                    }

                </AddButton>

            </TopPlaceholder>


            {info == undefined ? null :
                <ContentPlaceholder>

                    {qrsPoints.length == 0 ? null :
                        <>
                            <Section>
                                <SubHeading>
                                    Average QRS
                                </SubHeading>

                                <QrsChartTool
                                    points={qrsPoints}
                                />
                            </Section>

                            <Section>
                                <SubHeading>
                                    Reports
                                </SubHeading>
                                <DownloadPlaceholder>
                                    <DownloadLink target={'_blank'} href={info?.pdfUrl}>
                                        Download PDF report
                                    </DownloadLink>
                                    <DownloadLink target={'_blank'} href={info?.excelUrl}>
                                        Download Excel report
                                    </DownloadLink>
                                </DownloadPlaceholder>
                            </Section>

                            <Section>
                                <SubHeading>
                                    Parameters
                                </SubHeading>

                                <DetailsValuesTable
                                    values={detailsValues}
                                />
                            </Section>
                        </>
                    }

                    <Section>
                        <SubHeading>
                            JSON
                        </SubHeading>
                        <RawPlaceholder>
                            <pre>{JSON.stringify(info, null, 2)}</pre>
                        </RawPlaceholder>
                    </Section>

                </ContentPlaceholder>
            }

        </Wrapper>
    );
}

const Section = styled.div`
  margin-bottom: 40px;
`;

const SubHeading = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold;
`;

const ContentPlaceholder = styled.div`

`;

const RawPlaceholder = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  background: whitesmoke;
`;

const DownloadPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const DownloadLink = styled.a`
  margin-right: 15px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background: white;
  border-radius: 4px;
`;

const TopParagraph = styled.div`
  margin-bottom: 30px;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
`;

const AddButton = styled.div`
  background: #147AFF;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
