import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import { Input, Label, Row, Textarea } from "../../bracelets/forms/UpdateCorsanoPatientForm";
import { Button } from "./DictionaryEditorTool";
import TranslationsEditorTool from "./TranslationsEditorTool";

export default function ItemForm(props) {
  const {
    onSave = (d) => {},
    loading = false,
    hasTranslations = true,
    hasDescription = true,
    nameHeading = "Name",
  } = props;

  const [name, setName] = useState(props.name || "");
  const [description, setDescription] = useState(props.description || "");
  const [translations, setTranslations] = useState(props.translations || []);

  return (
    <Wrapper>
      <Row>
        <Label>{nameHeading}</Label>
        <Input
          value={name}
          onChange={(evt) => {
            setName(evt.target.value);
          }}
        />
      </Row>

      {hasDescription == false ? null : (
        <Row>
          <Label>Description [English]</Label>
          <Textarea
            value={description}
            onChange={(evt) => {
              setDescription(evt.target.value);
            }}
          />
        </Row>
      )}

      {hasTranslations == false ? null : (
        <Row>
          <TranslationsEditorTool
            items={translations}
            onChange={(arr) => {
              setTranslations(arr);
            }}
          />
        </Row>
      )}

      <Row>
        <Button
          onClick={() => {
            onSave({
              name: name,
              description: description,
              translations: translations,
            });
          }}
        >
          {loading == true ? <Spin visible={true} /> : "Save"}
        </Button>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div``;
