import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";

import asymmetry_image from "./gait_asymmetry.png";
import variability_image from "./gait_variability.png";
import speed_image from "./gait_speed.png";

import gait_bg_speed from "./grad_red_green.jpeg";
import gait_bg_var from "./grad_var_back.jpeg";
import gait_bg_assym from "./gait_bg_assym.jpeg";

const OPTIONS = [
  {
    label: "Speed",
    image: speed_image,
    background: gait_bg_speed,
    type: "speed",
    min: 0,
    max: 20,
  },
  {
    label: "Variability",
    image: variability_image,
    background: gait_bg_var,
    type: "variability",
    min: 0,
    max: 100,
  },
  {
    label: "Asymmetry",
    image: asymmetry_image,
    type: "asymmetry",
    background: gait_bg_assym,
    min: -60,
    max: 60,
  },
];

export default function GaitViewTool(props) {
  const { type, value } = props;
  let opt = OPTIONS.find((x) => x.type == type);
  if (opt == undefined) {
    return null;
  }
  let { min, max, label, image, background } = opt;

  let dVar = max - min;
  let dVal = value - min;
  let percent = (100.0 * dVal) / dVar;
  percent = Math.max(0, Math.min(100, percent));

  let rullerItemsNumber = 5;
  let rullerItems = [];

  for (let i = 0; i <= rullerItemsNumber; i++) {
    let val = min + (1.0 * (max - min) * i) / rullerItemsNumber;
    rullerItems.push(val);
  }
  let rullerLabels = [];
  for (let i = 0; i <= rullerItemsNumber; i++) {
    let val = min + (1.0 * (max - min) * i) / rullerItemsNumber;
    rullerLabels.push(val.toFixed(1));
  }

  return (
    <Wrapper>
      <Left>
        <LeftImg className="gait-img" src={image} />
        <LabelPlaceholder>{label}</LabelPlaceholder>
      </Left>
      <Right>
        <GradComponent src={background}>
          <Circle percent={percent}>
            <CircleInner>
              <CircleValue>{value}</CircleValue>
            </CircleInner>
          </Circle>
          <RullerPlaceholder>
            <RullerInnerPlaceholder>
              {/*{rullerItems.map((x, i) => {*/}
              {/*    let dVal = x - min;*/}
              {/*    let percent = 100.0 * dVal / dVar;*/}
              {/*    percent = Math.max(0, Math.min(100, percent));*/}
              {/*    return (*/}
              {/*        <div key={i} style={{position: 'absolute', left: `${percent}%`, top: 0, height: '100%', width: 1, background: 'white'}}/>*/}
              {/*    )*/}
              {/*})}*/}
              {rullerLabels.map((x, i) => {
                let dVal = x - min;
                let percent = (100.0 * dVal) / dVar;
                percent = Math.max(0, Math.min(100, percent));
                return (
                  <div
                    key={i}
                    style={{
                      position: "absolute",
                      left: `${percent}%`,
                      top: 0,
                      height: "100%",
                      width: 1,
                      background: "white",
                    }}
                  >
                    <div
                      className="gait-ruler-label"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: -20,
                        width: 20,
                        textAlign: "center",
                        fontSize: 11,
                        opacity: 0.6,
                      }}
                    >
                      {rullerLabels[i]}
                    </div>
                  </div>
                );
              })}
            </RullerInnerPlaceholder>
          </RullerPlaceholder>
        </GradComponent>
      </Right>
    </Wrapper>
  );
}

const LabelPlaceholder = styled.div`
  font-size: 11px;
  opacity: 0.6;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const GradComponent = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.src});
  background-size: 100% 100%;
  border-radius: 1000px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0.9;
`;

const RullerPlaceholder = styled.div`
  position: absolute;
  bottom: -18px;
  line-height: 16px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  height: 16px;
`;

const RullerInnerPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
`;

const Circle = styled.div`
  background: white;
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  position: absolute;
  top: calc(20px - 10px);
  left: calc(${(props) => props.percent}% - 10px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
`;

const CircleInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const CircleValue = styled.div`
  position: absolute;
  top: -30px;
  left: -10px;
  width: 40px;
  text-align: center;
  font-weight: bold;
  opacity: 0.55;
  font-size: 12px;
`;

const LeftImg = styled.img`
  height: 30px;
`;

const Left = styled.div`
  text-align: center;
  width: 45px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Right = styled.div`
  flex: 1;
`;
