import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Spin from 'arui-feather/spin'
import pencilImage from "../../notes/panels/clarity_edit-solid.svg";
import trashImage from "../../../assets/images/trash.svg";
import NiceModal from "../../modals/NiceModal";
import UpdateGatewayForm from "../forms/UpdateGatewayForm";
import GatewaysAPI from "../../../api/GatewaysAPI";
import {useInterval} from "../../apps/RouterApp";
import CorsanoPatientsAPI from "../../../api/CorsanoPatientsAPI";
import CommonHelper from "../../../helpers/CommonHelper";

import Select from 'react-select'
import UpdateCorsanoPatientForm from "../forms/UpdateCorsanoPatientForm";
import SelectBraceletTool from "../tools/SelectBraceletTool";

import * as groupsActions from '../../../redux/actions/GroupsActions'
import * as inmatesActions from '../../../redux/actions/InmatesActions'
import SelectStudyTool from "../tools/SelectStudyTool";
import {toast} from "react-hot-toast";

export default function DoctorCorsanoPatientsPanel(props) {

    const dispatch = useDispatch();

    const [selectedId, setSelectedId] = useState(undefined);
    const [createOpen, setCreateOpen] = useState(false);
    const [deletingId, setDeletingId] = useState(undefined);
    const [updatingId, setUpdatingId] = useState(undefined);
    const [updatingCode, setUpdatingCode] = useState(undefined);

    const {
        currentUserObj,
        inmates,
        loading
    } = useMappedState(useCallback(state => {
        let email = state?.auth?.currentUserObj?.usersUser?.email;
        return {
            currentUserObj: state.auth.currentUserObj,
            email: email,
            isDemo: (email == 'demo@corsano.com__'),
            loading: state.inmates.loading,
            inmates: state.inmates.inmatesMap.toArray().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            })
        }
    }));

    const uuid = currentUserObj?.usersUser?.uuid;

    useEffect(() => {
        dispatch(inmatesActions.loadAllInmates(uuid)).then(() => {
            console.log('inmates loaded!');
        });
    }, [uuid])

    useEffect(() => {
        dispatch(groupsActions.loadAllGroups());
    }, []);

    if (inmates.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }
    let selectedItem = (selectedId == undefined) ? undefined : inmates.filter(x => (x?.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopLeft>
                    <TopHeading>
                        Patients
                    </TopHeading>
                </TopLeft>
                <TopRight>
                    <Button onClick={() => {
                        setCreateOpen(true);
                    }}>
                        Add new
                    </Button>
                </TopRight>
            </TopPlaceholder>

            {inmates.map((a, i) => {
                let isDeleting = (a.id == deletingId);
                let isUpdating = (updatingId == a.id);
                return (
                    <BraceletItem key={a.id}>

                        <BraceletInner>

                            <BrLeft>
                                <BrName onClick={() => {
                                    CommonHelper.linkTo(`/user/${a?.uuid}`, true);
                                }}>
                                    {a?.name}
                                </BrName>
                                <BrSelectPlaceholder>
                                    <BrSelect>
                                        <SelectBraceletTool
                                            laceholder={'None'}
                                            patientUUID={a?.uuid}
                                        />
                                        <BrSelectLabel>
                                            Bracelet
                                        </BrSelectLabel>
                                        {/*<BrSelectInner index={i}>*/}
                                        {/*    <BrSelectInnerInner>*/}
                                        {/*        */}
                                        {/*        */}
                                        {/*    </BrSelectInnerInner>*/}
                                        {/*</BrSelectInner>*/}
                                    </BrSelect>
                                </BrSelectPlaceholder>

                                <BrSelectPlaceholder>
                                    <BrSelect>
                                        {isUpdating == true ?
                                            <span>
                                                <Spin visible={true}/>
                                                <span style={{marginRight: 10, marginLeft: 10}}>
                                                    {updatingCode}
                                                </span>
                                            </span>
                                            :
                                            <SelectStudyTool
                                                placeholder={'None'}
                                                studyTag={a.studyTag}
                                                onChange={async newStudyTag => {
                                                    setUpdatingId(a?.id);
                                                    setUpdatingCode(newStudyTag);
                                                    await dispatch(inmatesActions.updateInmate({
                                                        id: a.id,
                                                        studyTag: newStudyTag || 'none'
                                                    }));
                                                    setUpdatingId(undefined);
                                                    setUpdatingCode(undefined);
                                                    toast(`Study tag "${newStudyTag}" is assigned to patient "${a.name}"`);
                                                }}
                                            />
                                        }
                                    </BrSelect>
                                    <BrSelectLabel>
                                        {isUpdating == true ? 'Assigning Study Tag...' : 'Assigned Study Tag'}
                                    </BrSelectLabel>
                                </BrSelectPlaceholder>
                            </BrLeft>

                            <BrRight>
                                <ContrItem>
                                    <Spin visible={isDeleting}/>
                                    {isDeleting == true ? null :
                                        <NoteEditIcon style={{height: 28, marginRight: 20}} src={trashImage}
                                                      onClick={async () => {
                                                          if (loading == true) {
                                                              return;
                                                          }
                                                          if (window.confirm('Are you sure you want to delete patient?') == false) {
                                                              return;
                                                          }
                                                          setDeletingId(a?.id);
                                                          await dispatch(inmatesActions.deleteInmate(a?.id));
                                                          toast('Deleted');
                                                          setDeletingId(undefined);
                                                      }}/>
                                    }
                                </ContrItem>
                                <ContrItem>
                                    <NoteEditIcon src={pencilImage} onClick={() => {
                                        setSelectedId(a?.id);
                                        // CommonHelper.linkTo(`/user/${a?.uuid}/edit`, true);
                                    }}/>
                                </ContrItem>
                            </BrRight>

                        </BraceletInner>

                    </BraceletItem>
                )
            })}

            {selectedItem == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <DialogInner2>

                        <UpdateCorsanoPatientForm {...selectedItem} loading={loading}
                                                  onSave={async d => {
                                                      if (loading == true) {
                                                          return;
                                                      }
                                                      await dispatch(inmatesActions.updateInmate({id: selectedItem?.id, ...d}))
                                                      setSelectedId(undefined);
                                                  }}/>

                    </DialogInner2>

                </NiceModal>
            }

            {createOpen == false ? null :
                <NiceModal onClose={() => {
                    setCreateOpen(false);
                }}>

                    <DialogInner2>

                        <UpdateNoteHeading>
                            {'New Patient'}
                        </UpdateNoteHeading>

                        <UpdateCorsanoPatientForm loading={loading}
                                                  onSave={async d => {
                                                      if (loading == true) {
                                                          return;
                                                      }
                                                      await dispatch(inmatesActions.createInmate(uuid, {...d}));
                                                      // setLoading(true);
                                                      // await CorsanoPatientsAPI.createCorsanoPatient(uuid, {...d});
                                                      // setLoading(false);
                                                      // let arr = await CorsanoPatientsAPI.getDoctorCorsanoPatients(uuid);
                                                      // setItems(arr);
                                                      // setLoading(false);
                                                      setCreateOpen(false);
                                                  }}/>

                    </DialogInner2>

                </NiceModal>
            }

        </Wrapper>
    );
}

const SubInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
`;

const TopHeading = styled.div`
  color: #000F4B;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div`

`;

const TopRight = styled.div`

`;

const Button = styled.div`
  width: 200px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const UpdateNoteHeading = styled.div`
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  color: #000F4B;
  margin-bottom: 10px;
`;


const DialogInner2 = styled.div`
  width: 600px;
`;

const NoteEditIcon = styled.img`
  height: 22px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`

`;

const BrType = styled.div`
  margin-left: 20px;
`;

const BraceletInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BrLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BrSelectPlaceholder = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const BrSelect = styled.div`
  width: 200px;
  position: relative;
`;

const BrSelectInner = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: ${props => (40000 - +props.index)};
`;

const BrSelectInnerInner = styled.div`
  position: relative;
  width: 200px;
  z-index: 1000000;
`;

const BrSelectLabel = styled.div`
  font-size: 12px;
  opacity: 0.7;
  margin-top: 5px;
`;

const BrName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  width: 220px;
  margin-right: 10px;
`;

const BrRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContrItem = styled.div`

`;

const normalBorder = `1px solid rgba(135, 152, 173, 0.15)`;
const dangerBorder = `2px solid #FA4646;`;

const BraceletItem = styled.div`
  border-radius: 4px;
  background: #FFFFFF;
  border: ${props => (props.danger == true ? dangerBorder : normalBorder)};
  box-shadow: ${props => (props.danger == true ? '0px 4px 5px rgba(250, 70, 70, 0.05);' : 'none')};
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;