import MMTCloud from "./MMTCloud";
import RonasBasicAPI from "./RonasBasicAPI";
import TarnsformerHelper from "../helpers/TarnsformerHelper";
import moment from "moment";

interface StudySessionInput {
    timestamp_from: number,
    timestamp_to: number,
    name?: string,
    description?: string,
    session_code?: string
}

class StudySessionsAPI {

    private static instance: StudySessionsAPI
    private cloud?: MMTCloud;
    private ronasBasicAPI: RonasBasicAPI;

    constructor() {
        this.cloud = MMTCloud.getInstance();
        this.ronasBasicAPI = RonasBasicAPI.getInstance();
    }

    public static getInstance(): StudySessionsAPI {
        if (!StudySessionsAPI.instance) {
            StudySessionsAPI.instance = new StudySessionsAPI();
        }
        return StudySessionsAPI.instance;
    }

    private async getUserStatisticsSlots(timestamp_from: number, timestamp_to: number) {
        let uuid = this.cloud?.getUUID();
        let pld = (await this.cloud?.healthApiAxios.get(`/users/${uuid}/heart-rate-measurement-statistic`, {
            params: {
                per_page: 1000,
                date_from: moment(timestamp_from).add(-1, 'days').format('YYYY-MM-DD'),
                date_to: moment(timestamp_to).add(1, 'days').format('YYYY-MM-DD')
            }
        })).data.data;
        return pld;
    }

    private async getDaySlotItems(slotId: string) {
        let pld = (await this.cloud?.healthApiAxios.get(`/user-heart-rate-measurement-statistic/${slotId}/slots`)).data;
        return pld;
    }

    public async getSessionStatisticsItems(timestamp_from: number, timestamp_to: number) {
        let summaries = await this.getUserStatisticsSlots(timestamp_from, timestamp_to);
        console.log('got summaries = ', summaries);
        let arr = [];
        for (let i in summaries) {
            let summary = summaries[i];
            let {date} = summary;
            let items = await this.getDaySlotItems(summary._id);
            arr.push({
                date: date,
                items: items
            });
        }
        return arr;
    }

    public async createSession(data: StudySessionInput) {
        let pld = (await this.cloud?.healthApiAxios.post(`/clinical-trial-sessions`, data)).data;
        return pld;
    }

    public async getPreventicusSequences(preventicus_customer_id: string){
        let pld = (await this.cloud?.healthApiAxios.get(`/preventicus-sequences`, {
            params: {
                preventicus_customer_id: preventicus_customer_id,
                per_page: 1000
            }
        })).data.data;
        return pld;
    }

    public async updateSession(id: string, data: StudySessionInput) {
        let pld = (await this.cloud?.healthApiAxios.put(`/clinical-trial-sessions/${id}`, data)).data;
        return pld;
    }

    public async uploadSession(id: string) {
        let pld = (await this.cloud?.healthApiAxios.put(`/clinical-trial-sessions/${id}/upload`)).data;
        return pld;
    }


    public async getSessions() {
        let pld = (await this.cloud?.healthApiAxios.get(`/clinical-trial-sessions`, {
            params: {
                per_page: 1000
            }
        })).data.data;
        return pld;
    }





}

export default StudySessionsAPI;