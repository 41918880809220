import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { Map, Set } from "immutable";
import styled from "styled-components";
import Select from "react-select";

import "./style.css";

import * as actions from "../../../redux/actions/BraceletsActions";
import { toast } from "react-hot-toast";

const getBraceletsMap = (bracelets) => {
  return bracelets.filter((x) => x.patientUUID != undefined).reduce((mp, x) => mp.set(x.patientUUID, x), Map());
};

export default function SelectBraceletTool(props) {
  const { patientUUID, placeholder = "Corsano trials", onSelected = () => {} } = props;
  const dispatch = useDispatch();

  // console.log('SelectBraceletTool: props = ', props);
  const [saving, setSaving] = useState(false);

  const { currentUserObj, email, isDemo, bracelets } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        isDemo: email == "demo@corsano.com_",
        bracelets: state.bracelets.braceletsMap.toArray().sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        }),
      };
    })
  );

  let patientsBraceletsMap = getBraceletsMap(bracelets);
  let avBraceletsOptions = bracelets
    .filter((x) => x.patientUUID == "" || x.patientUUID == undefined || x.patientUUID == patientUUID)
    .map((x) => ({
      value: x.id,
      label: x.name,
      patientUUID: x.patientUUID,
    }));
  let currentBracelet = bracelets.filter((x) => x?.patientUUID == patientUUID)[0];

  let braceletIsSelected = avBraceletsOptions.filter((x) => x.patientUUID == patientUUID).length > 0;
  const hasDropdown = bracelets.length > 0;

  return (
    <Wrapper className={"SelectBraceletTool"}>
      {braceletIsSelected == false && hasDropdown == false ? "Corsano trials" : null}

      {hasDropdown == false ? null : (
        <Select
          className="selector"
          classNamePrefix="brace-select"
          placeholder={saving == true ? "Saving..." : placeholder}
          isClearable={true}
          options={avBraceletsOptions}
          value={avBraceletsOptions.filter((x) => x.patientUUID == patientUUID)}
          isLoading={saving}
          onChange={async (x) => {
            setSaving(true);
            if (x == undefined) {
              let br = bracelets.filter((x) => x.patientUUID == patientUUID)[0];
              if (br != undefined) {
                await dispatch(
                  actions.updateBracelet(
                    currentUserObj?.usersUser?.uuid,
                    {
                      id: br.id,
                      patientUUID: "",
                    },
                    props.code
                  )
                );
              }
            } else {
              let brId = x.value;
              if (currentBracelet != undefined) {
                await dispatch(
                  actions.updateBracelet(
                    currentUserObj?.usersUser?.uuid,
                    {
                      id: currentBracelet?.id,
                      patientUUID: "",
                    },
                    props.code
                  )
                );
              }
              await dispatch(
                actions.updateBracelet(
                  currentUserObj?.usersUser?.uuid,
                  {
                    id: brId,
                    patientUUID: patientUUID,
                  },
                  props.code
                )
              );
              toast("Saved");
            }
            setSaving(false);
            onSelected();
          }}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`;
