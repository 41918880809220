import { Map } from "immutable";
import * as types from "../ActionTypes.js";

const initialState = {
  loading: false,

  groupsMap: Map(),
  error: undefined,
};

// const startLoading = (state, action) => {
//     return {...state, loading: true, error: undefined}
// }

// const stopLoading = (state, action) => {
//     return {...state, loading: false, error: action.error}
// }

const GroupsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_GROUP:
    case types.UPDATE_GROUP:
    case types.DELETE_GROUP:
    case types.LOAD_GROUPS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case types.CREATE_GROUP_FAIL:
    case types.UPDATE_GROUP_FAIL:
    case types.DELETE_GROUP_FAIL:
    case types.LOAD_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.CREATE_GROUP_SUCCESS:
    case types.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsMap: state.groupsMap.set(action.group.id, action.group),
      };

    case types.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsMap: state.groupsMap.delete(action.id),
      };

    case types.LOAD_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        groupsMap: state.groupsMap.merge(
          action.groups.reduce((map, form) => map.set(form.id, form), Map())
        ),
      };

    default:
      return state;
  }
};

export default GroupsReducer;
