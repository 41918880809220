import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from "axios";
import {CORSANO_API_ENDPOINT} from "../../../constants/config";
import {Code} from 'react-content-loader'
import Sidebar from "arui-feather/sidebar";
import CommonHelper from "../../../helpers/CommonHelper";
import EcgRecordPanel from "../../ecg/panels/EcgRecordPanel";

function getQ(p){
    let q = 0;
    if (+p > 20){
        q = 1;
    }
    if (+p > 80){
        q = 2;
    }
    if (+p > 90){
        q = 3;
    }
    if (+p > 95){
        q = 4;
    }
    return q;
}

export default function GroupEcgExportTool(props) {
    const {
        code
    } = props;

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [selectedRecordId, setSelectedRecordId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        axios.get(`${CORSANO_API_ENDPOINT}/v2/groups/${code}/ecg-records`).then(d => d.data).then(x => {
            setData(x);
            setLoading(false);
        });
    }, [code]);

    if (data == undefined && loading == true){
        return (
            <Code />
        )
    }
    console.log('render: data = ', data);

    if (data == undefined){
        return null;
    }
    let {map = {}, items = []} = data;
    let arr = items.map(x => ({...x, userData: map[x.userUUID]})).sort((a, b) => (+b.start_timestamp - +a.start_timestamp));

    let s = `Date;Time;Patient Name;ECG Record ID;Q;Percents;ECG analyzable seconds`;
    for (let i in arr){
        let a = arr[i];
        if (a.userData == undefined || a.userData.user == undefined || a.processing_result_data == undefined){
            continue;
        }
        let {first_name = '', last_name = '', code = ''} = a.userData.user;
        let userName = `${first_name} ${last_name} ${code}`.trim();
        let date = `${a.created_at}`.split('T')[0];
        let time = `${a.created_at}`.split('T')[1];
        let q = getQ(a.processing_result_data.qi_percents);
        s = `${s}\n${date};${time};${userName};${a.uuid};${q};${a.processing_result_data.qi_percents};${a.processing_result_data.usable_seconds}`
    }

    let selItem = (selectedRecordId == undefined) ? undefined : arr.find(x => (x._id == selectedRecordId));

    return (
        <Wrapper>

            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            Date
                        </Th>
                        <Th>
                            Time
                        </Th>
                        <Th>
                            Patient Name
                        </Th>
                        <Th>
                            ECG Record ID
                        </Th>
                        <Th>
                            Q
                        </Th>
                        <Th>
                            Percents
                        </Th>
                        <Th>
                            ECG analyzable seconds
                        </Th>
                        {/*<Th>*/}
                        {/*    ECG Success*/}
                        {/*</Th>*/}
                    </Tr>
                </Thead>

                <Tbody>
                    {arr.map((a, i) => {
                        let {first_name = '', last_name = '', code = ''} = a.userData.user;
                        let userName = `${first_name} ${last_name} ${code}`.trim();
                        let date = `${a.created_at}`.split('T')[0];
                        let time = `${a.created_at}`.split('T')[1];
                        let q = getQ(a.processing_result_data.qi_percents);
                        let color = ['red', 'red', 'purple', 'yellow', 'green'][q];
                        let rec_id = `${a.userUUID}_${a.uuid}`;
                        return (
                            <Tr key={i} style={{cursor: 'pointer'}} onClick={() => {
                                setSelectedRecordId(a._id);
                            }} >
                                <Td style={{width: '80px'}} >
                                    {date}
                                </Td>
                                <Td>
                                    {`${time}`.replace('Z', '')}
                                </Td>
                                <Td>
                                    {userName}
                                </Td>
                                <Td>
                                    {a.uuid}
                                </Td>
                                <Td style={{backgroundColor: color, textAlign: 'center', width: '32px'}} >
                                    {q}
                                </Td>
                                <Td >
                                    {a.processing_result_data.qi_percents}
                                </Td>
                                <Td>
                                    {a.processing_result_data.usable_seconds}
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>

            </Table>

            <div style={{marginTop: 10, marginBottom: 10}} >
                <span style={{cursor: 'pointer'}} onClick={() => {
                    window.open("data:text/csv;charset=utf-8," + escape(s))
                }} >
                    download .csv
                </span>
            </div>

            <Sidebar
                width={window.innerWidth}
                visible={(selItem != undefined)} onCloserClick={() => {
                setSelectedRecordId(undefined);
            }} >



                {selItem == undefined ? null :
                    <>
                        <h2 style={{marginTop: -30}}>

                            {selItem == undefined ? null :
                                <span style={{marginRight: 20}}>
                                    {CommonHelper.getPatientHeadingString(selItem?.userData?.user)}
                                </span>
                            }
                            {moment(selItem?.start_timestamp).format('DD.MM.YYYY HH:mm')}
                        </h2>
                        <EcgRecordPanel
                            userUUID={selItem?.userUUID}
                            measurementUUID={selItem?.uuid}
                        />
                    </>
                }


            </Sidebar>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;



const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
  font-size: 12px;
`;


const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;
