import axios from 'axios'
import env from "react-dotenv";
import ls from 'local-storage'

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const GatewaysAPI = {

    async getDoctorGateways(uuid) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/doctors/${uuid}/gateways`)).data;
        return pld;
    },

    async getDoctorGatewaysOnlineMap(uuid) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/doctors/${uuid}/gateways-last-seen`)).data;
        return pld;
    },

    async updateGateway(doctorUUID, data) {
        let pld = (await axios.put(`${DOCTORS_API_ENDPOINT}/doctors/${doctorUUID}/gateways/${data.id}`, {
            ...data
        })).data;
        return pld;
    },

    async createGateway(doctorUUID, data) {
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/doctors/${doctorUUID}/gateways`, {
            ...data
        })).data;
        return pld;
    },


    async deleteGateway(doctorUUID, id) {
        await axios.delete(`${DOCTORS_API_ENDPOINT}/doctors/${doctorUUID}/gateways/${id}`);
    }

}

export default GatewaysAPI;
