import axios from 'axios'
import env from "react-dotenv";
import {API_ENDPOINT} from "../constants/config";

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const SUCCESS_RESULT_CODE = 1; //todo: check in specs

const EcgProcessingAPI = {

    async getMediaLink(uuid, mediaId){
        try{
            let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v2/users/${uuid}/media/${mediaId}/link`)).data;
            return pld;
        }catch(exc){

        }
    },

    async scheduleProcessing(userUUID, measurementUUID) {
        let record_id = `${userUUID}_${measurementUUID}`
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v2/szobin/run-ecg-processing`, {
            userUUID: userUUID,
            measurementUUID: measurementUUID,
            record_id: record_id
        })).data;
        return pld;
    },



    async getStatus(userUUID, measurementUUID) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v2/szobin/signal/status/get`, {
            params: {
                userUUID,
                measurementUUID
            }
        })).data;
        return pld?.status;
    },

    async canRunProcessing(userUUID, measurementUUID){
        let st = await this.getStatus(userUUID, measurementUUID);
        return (st != SUCCESS_RESULT_CODE);
    },

    async checkIfTaskIsReady(userUUID, measurementUUID){
        let st = await this.getStatus(userUUID, measurementUUID);
        return (st == SUCCESS_RESULT_CODE);
    },

    async scheduleProcessingLazily(userUUID, measurementUUID){
        let st = await this.getStatus(userUUID, measurementUUID);
        if (st == undefined){
            await this.scheduleProcessing(userUUID, measurementUUID);
        }
    },

    async getTaskResult(userUUID, measurementUUID) {
        // let status = (await this.getStatus(userUUID, measurementUUID));
        // check status before getting result
        // if (status != SUCCESS_RESULT_CODE){
        //     return;
        // }
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v2/szobin/signal/result`, {
            params: {
                userUUID,
                measurementUUID
            }
        })).data;
        return pld;
    }

}

export default EcgProcessingAPI;
