import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import './style.css'

import backImg from '../../../assets/images/back.svg'

import StepsAPI from "../../../api/StepsAPI";
import {getSleepClassNameByValue} from "../../sleep/GithubSleepSessionsView";


export default function GithubActivitySessionsView(props) {
    const {
        userId, monthsNumber = 9,
        selectedTimestamp = undefined,
        loading = false,
        onDayClick = t => {

        },
        onSquareOver = t => {

        },
        onSquareLeave = t => {

        },
        onYearTimestampChange = t => {

        }
    } = props;
    const [points, setPoints] = useState([]);
    const [selectedYearTimestamp, setSelectedYearTimestamp] = useState(props.selectedTimestamp == undefined ? +new Date() : +props.selectedTimestamp);

    useEffect(() => {
        onYearTimestampChange(selectedYearTimestamp);
        if (loading == true) {
            return;
        }
        StepsAPI.getInstance().getActivityStatsForYearFromCache(selectedYearTimestamp).then(arr => {
            console.log('got points = ', arr);
            setPoints(arr);
        });
    }, [userId, selectedYearTimestamp, loading]);

    let end = moment(selectedYearTimestamp).endOf('year').endOf('day');
    let start = moment(selectedYearTimestamp).startOf('year').startOf('day');


    return (
        <Wrapper className={'sleep-sessions-gh-chart'}>

            <Side>
                <ButtonImg
                    src={backImg}
                    onClick={() => {
                        setSelectedYearTimestamp(+moment(selectedYearTimestamp).add(-1, 'years'))
                    }}
                />
            </Side>

            <CenterPlaceholder>
                <HeadingPlaceholder>
                    {moment(selectedYearTimestamp).format('YYYY')}
                </HeadingPlaceholder>
                <ChartPlaceholder>
                    <CalendarHeatmap
                        classForValue={a => (a != undefined && selectedTimestamp != undefined && +moment(a.date).startOf('day') == +moment(selectedTimestamp).startOf('day') ? 'color-selected' : getSleepClassNameByValue(a == undefined ? undefined : a.daily_percent))}
                        startDate={new Date(start)}
                        endDate={new Date(end)}
                        values={points}
                        onClick={a => {
                            console.log('CalendarHeatmap: onClick: a = ', a);
                            try {
                                onDayClick(+new Date(a.date));
                            } catch (exc) {

                            }
                        }}
                        onMouseOver={(event, value) => {
                            // console.log('onMouseOver: event, value = ', event, value);
                        }}
                        onMouseLeave={(event, value) => {
                            // console.log('onMouseLeave: event, value = ', event, value);
                        }}
                        // transformDayElement={(element, value, index) => React.cloneElement(element, { title: value })}
                    />
                </ChartPlaceholder>
            </CenterPlaceholder>

            <Side>
                <ButtonImg
                    src={backImg}
                    style={{transform: `rotate(180deg)`}}
                    onClick={() => {
                        setSelectedYearTimestamp(+moment(selectedYearTimestamp).add(1, 'years'))
                    }}
                />
            </Side>

            {loading == false ? null :
                <Overlay>
                    <div>
                        <Spin visible={true}/>
                    </div>
                </Overlay>
            }


        </Wrapper>
    );
}

const headerHeight = 20;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CenterPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

const HeadingPlaceholder = styled.div`
  height: ${headerHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  opacity: 0.7;
`;

const ChartPlaceholder = styled.div`
  flex: 1;
`;

const Side = styled.div`
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${headerHeight}px;
  box-sizing: border-box;
`;

const ButtonImg = styled.img`
  height: 18px;
  width: 18px;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

