import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
} from "react";
import styled from "styled-components";
import { Input } from "../../bracelets/forms/UpdateCorsanoPatientForm";
import infoImg from "../../info/tools/help.svg";

import Select from "react-select";

const HOURS = Array.from({ length: 24 }, (x, i) => i);
const OPTIONS = HOURS.map((x) => {
    return {
        label: `${`${x}`.padStart(2, 0)}:00`,
        value: x,
    };
});

export default function AlertsForm(props) {
    const { onSave = (d) => {}, loading = false } = props;
    const [showComplianceInfo, setShowComplianceInfo] = useState(false);

    const [raw_activity, setRaw_activity] = useState(
        props?.doctor_alert_data_limits?.raw_activity || 1200
    );
    const [raw_sleep, setRaw_sleep] = useState(
        // props?.doctor_alert_data_limits?.raw_sleep || 300
        props?.doctor_alert_data_limits?.raw_sleep || 0
    );
    const [raw_ppg, setRaw_ppg] = useState(
        // props?.doctor_alert_data_limits?.raw_ppg || 2000000
        props?.doctor_alert_data_limits?.raw_ppg || 0
    );
    const [doctor_alert_hour, setDoctor_alert_hour] = useState(
        props.doctor_alert_hour || 12
    );
    const [emailsText, setEmailsText] = useState(
        (props.doctor_emails || []).join(", ") || ""
    );

    return (
        <Wrapper className="normal-text">
            <h2>Daily Compliance Alert Setup</h2>
            <Box>
                <Row>
                    Please provide expected number of data points per 24 hours
                    for each of the following:
                </Row>

                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <Row>
                        <Label>Activity</Label>
                        <Input
                            value={raw_activity}
                            onChange={(e) => {
                                setRaw_activity(e.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>Sleep</Label>
                        <Input
                            value={raw_sleep}
                            onChange={(e) => {
                                setRaw_sleep(e.target.value);
                            }}
                        />
                    </Row>

                    <Row>
                        <Label>PPG</Label>
                        <Input
                            value={raw_ppg}
                            onChange={(e) => {
                                setRaw_ppg(e.target.value);
                            }}
                        />
                    </Row>
                </div>
            </Box>

            <Box>
                <Row>
                    <Label>
                        What time of the day (UTC) do you want to receive the
                        alerts for past 24 hours?
                    </Label>
                    <Select
                        options={OPTIONS}
                        value={OPTIONS.find(
                            (x) => +x.value == doctor_alert_hour
                        )}
                        onChange={(x) => {
                            setDoctor_alert_hour(+x.value);
                        }}
                        menuPlacement="auto"
                        maxMenuHeight={160}
                    />
                </Row>

                <Row>
                    <Label>Emails to send alerts</Label>
                    <Input
                        value={emailsText}
                        placeholder={"Emails separated by comma"}
                        onChange={(x) => {
                            setEmailsText(x.target.value);
                        }}
                    />
                </Row>
            </Box>

            <div style={{ marginTop: 10 }}>
                {loading == true ? (
                    "..."
                ) : (
                    <Button
                        onClick={() => {
                            onSave({
                                doctor_alert_data_limits: {
                                    raw_activity: +raw_activity,
                                    raw_sleep: +raw_sleep,
                                    raw_ppg: +raw_ppg,
                                },
                                doctor_alert_hour: +doctor_alert_hour,
                                doctor_emails: extractEmails(emailsText),
                            });
                        }}
                    >
                        Save
                    </Button>
                )}
            </div>
            <IconPlaceholder>
                <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                        setShowComplianceInfo(!showComplianceInfo);
                    }}
                >
                    <InfoBox>
                        <Icon src={infoImg} className="bp-info-icon" />
                    </InfoBox>
                    <InfoSpan className="bp-info-span">
                        Have Questions Setting Numbers?
                    </InfoSpan>
                </div>
                {showComplianceInfo ? (
                    <div
                        className="normal-text"
                        style={{ maxWidth: "480px" }}
                        dangerouslySetInnerHTML={{ __html: complianceInfoDoc }}
                    ></div>
                ) : null}
            </IconPlaceholder>
        </Wrapper>
    );
}

function extractEmails(text) {
    let s = text || "";
    const potentialEmails = s.split(","); // Split by comma
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex

    return potentialEmails
        .map((email) => email.trim()) // Remove spaces
        .filter((email) => emailPattern.test(email)); // Keep valid emails
}

const Box = styled.div`
    //border: 1px dashed lightgrey;
    //padding: 10px;
    //border-radius: 10px;
    //margin-bottom: 5px;
`;

const Wrapper = styled.div``;

const Row = styled.div`
    margin-bottom: 5px;
    padding-left: 3px;
    padding-right: 3px;
    box-sizing: border-box;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
    opacity: 0.5;
`;

const Button = styled.div`
    width: 200px;
    margin: 0 auto;

    background: #147aff;
    border-radius: 5px;
    height: 48px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;

    font-weight: 800;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;

    :hover {
        opacity: 0.85;
    }
`;

const IconPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.img`
    width: 22px;
    height: 22px;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;
const InfoSpan = styled.div`
    color: rgb(20, 122, 255);
    cursor: pointer;
    font-weight: 300;
    margin-right: 15px;
    text-decoration: underline;
`;

const InfoBox = styled.div`
    border: 1px solid rgba(135, 152, 173, 0.1);
    -webkit-filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
    filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
    border-radius: 8px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;
const complianceInfoDoc = `
<h3>Activity</h3>
<p>The Activity File is the main datafile in the CardioWatch System and includes a.o. PR, SpO2, Respiration Rate, Steps. Activity Files by default arrive 1/min when 1/min is set in Study Settings. In other words, if the study has a frequency set at 1/min, the Activity File will be generated each minute. Consequently, in 24 hours there will be 60 min * 24 hr = 1’440 datapoints. Please note that every 30 min, the CardioWatch Bracelet also records and saves 2 min at 128 Hz, resulting in an extra 6 * 2 = 12. Hence the maximum for the day is 1’452 datapoints. We suggest to start checking PPG with value of 1’200 to compensate for charging hours. </p>

<p>Please note that limits may be set at 80% to avoid getting an alert when i.e. patients take the bracelet off when they are under the shower or for when they charge the bracelet. </p> 
<h3>Sleep</h3>
<p><b>By default it is 0</b>. Please decide if you want to monitor sleep and enter value. Sleep is recorded at 1/min. A minimum of 6 hours of sleep will generate 60 * 6 = 360 datapoints per day. </p> 

<h3>PPG</h3>

<p><b>By default it is 0</b>. Please decide if you want to monitor PPG and enter value. Typically, PPG is recorded at 32Hz resulting in 32 * 60 sec * 60 min * 24 hours = 2’764’800 datapoints per day. We suggest to start checking PPG with value of 2’000’000 to compensate for charging hours.</p>

<h3>Time Alert</h3>
<p>Set time for when the system should send a Compliance Alert</p> 

<h3>Emails </h3> 
<p>Type emails where the alert should be send separated by commas. Multiple emails are possible.</p> 

<h3>Compliance Alert </h3> 
<p>The Corsano System will send a Compliance Alert every day indicating which patient metric is below the set number of datapoints.  </p>`;
