import moment from "moment";

const DrawHelper = {
  getFineGridsForDay(dayTimestamp) {
    let steps = [
      60 * 1000, // 1 min
      2 * 60 * 1000,
      4 * 60 * 1000,
      5 * 60 * 1000,
      10 * 60 * 1000,
      15 * 60 * 1000,
      20 * 60 * 1000,
      30 * 60 * 1000,
      60 * 60 * 1000,
      2 * 60 * 60 * 1000,
      3 * 60 * 60 * 1000,
      4 * 60 * 60 * 1000,
      6 * 60 * 60 * 1000,
    ];
    let from = +moment(dayTimestamp).startOf("day");
    let getArr = (step = steps[0], totalDur = 24 * 60 * 60 * 1000) => {
      let n = Math.floor(totalDur / step);
      let arr = [];
      for (let i = 0; i < n; i++) {
        arr.push(+from + i * step);
      }
      return arr;
    };
    return steps.map((a) => getArr(a)).reverse();
  },

  getFineGridsForTimeSpan(from, to) {
    let steps = [
      60 * 1000, // 1 min
      2 * 60 * 1000,
      4 * 60 * 1000,
      5 * 60 * 1000,
      10 * 60 * 1000,
      15 * 60 * 1000,
      20 * 60 * 1000,
      30 * 60 * 1000,
      45 * 60 * 1000,
      60 * 60 * 1000,
      90 * 60 * 1000,
      2 * 60 * 60 * 1000,
      2.5 * 60 * 60 * 1000,
      3 * 60 * 60 * 1000,
      4 * 60 * 60 * 1000,
      6 * 60 * 60 * 1000,
    ];
    let totalDur = +to - +from;
    let getArr = (step = steps[0]) => {
      let n = Math.ceil((1.0 * totalDur) / step) + 5;
      let arr = [];
      for (let i = 0; i < n; i++) {
        arr.push(+from + i * step);
      }
      return arr;
    };
    return steps.map((a) => getArr(a)).reverse();
  },

  getStepsGridValues(max, number = 4) {
    let steps = [
      0.1, 0.2, 0.4, 0.5, 0.6, 0.8, 1, 2, 3, 4, 5, 10, 20, 40, 50, 80, 100, 120,
      150, 200, 250, 300, 250, 400, 500, 600, 700, 750, 1000, 1100, 1200, 1250,
      1300, 1500, 1750, 2000, 2250, 2500, 2750, 3000, 3250, 3500, 4000, 4500,
      5000, 6000, 7000, 7500, 8000, 9000, 10000, 12000, 13000, 14000, 15000,
      17500, 20000, 30000, 40000, 50000,
    ];
    let index = 0;
    let arr = steps.sort((a, b) => +a - +b);
    for (let i in arr) {
      let st = arr[i];
      let x = st * (number - 1); // 0 included
      if (+x > +max) {
        index = i;
        break;
      }
    }
    let step = arr[index];
    let res = [0];
    for (let i = 0; i < number - 1; i++) {
      res.push((+i + 1) * step);
    }
    return res;
  },

  getSleepVertItems(minFromSeconds, maxToSeconds) {
    let step = 7200.0;
    let negStepsNum =
      minFromSeconds > 0 ? 0 : Math.ceil(Math.abs(minFromSeconds) / step);
    let posStepsNum = Math.ceil(maxToSeconds / step);
    console.log(
      "getSleepVertItems: negStepsNum, posStepsNum = ",
      negStepsNum,
      posStepsNum
    );

    let n = negStepsNum + posStepsNum;
    let arr = [];
    let from = -(negStepsNum * step);
    // let to = posStepsNum * step;
    for (let i = 0; i < n; i++) {
      let t = from + i * step;
      let label = `${((86400 + t) % 86400) / 3600}`.padStart(2, "0") + ":00";
      arr.push({
        label: label,
        t: t,
        value: t,
      });
    }
    return arr;
  },

  getGridForTimeSpan(from, to, maxN = 10) {
    console.log("getGridForTimeSpan: maxN = ", maxN);
    console.log(
      "getGridForTimeSpan: from, to = ",
      new Date(from),
      new Date(to)
    );
    // let grids = this.getFineGridsForTimeSpan(from, to).map(a => a.filter(x => (+x >= +from && +x <= +to)));
    let grids = this.getFineGridsForTimeSpan(from, to);
    console.log("grids = ", grids);
    // console.log('getGridForTimeSpan: grids = ', grids);
    let getIn = (arr, a, b) =>
      arr.reduce((sm, x) => (+x >= +a && +x <= +b ? +sm + 1 : +sm), 0);
    let scores = grids.map((a) => getIn(a, from, to));
    // console.log('scores = ', scores);
    // console.log('maxN = ', maxN);
    let resIndex = grids.length - 1;
    for (let i in scores) {
      let sc = scores[i];
      if (sc <= maxN) {
        resIndex = i;
        // break;
      }
    }
    // console.log('resIndex = ', resIndex);
    let result = grids[resIndex].filter((a) => +a >= +from && +a <= +to);
    console.log("maxN = ", maxN);
    console.log(
      "result = ",
      result.map((x) => new Date(x))
    );
    console.log("from, to = ", new Date(from), new Date(to));
    return result;
  },

  getMonotoneForAxisHeartRatePoints(oldPoints, minTimestamp, maxTimestamp) {
    let step = 5 * 60 * 1000; // 5 minutes step
    let nFrom = Math.floor(minTimestamp / step) * step;
    let nTo = Math.ceil(maxTimestamp / step) * step;
    let arr = [];
    let map = {};
    for (let p of oldPoints) {
      map[p.date] = p;
    }
    for (let i = nFrom; i <= nTo; i = +i + step) {
      let date = moment(i).format("HH:mm");
      arr.push({
        ...map[date],
        timestamp: i,
        date: date,
      });
    }
    return arr;
  },

  getSpo2MonotonePoints(oldPoints) {
    console.log("getSpo2MonotonePoints: oldPoints = ", oldPoints);
    let xMap = {};
    let gridArr = [];
    let points = JSON.parse(JSON.stringify(oldPoints));
    let extraPoints = [];
    for (let i in points) {
      let oldDate = points[i].date;
      if (oldDate === undefined) {
        continue;
      }
      let hh = oldDate.split(":")[0];
      let mm = +oldDate.split(":")[1];
      let xMin = +mm < 30 ? "00" : "30";
      let xKey = `${hh}:${xMin}`;
      // xMap[xKey] = points[i];
      if (xMap[xKey] === undefined) {
        xMap[xKey] = points[i];
      } else {
        extraPoints.push({
          ...points[i],
          xKey: xKey,
        });
      }
    }
    console.log("xMap = ", xMap);
    for (let i = 0; i < 24; i++) {
      let hh = `${i}`.padStart(2, "0");
      for (let j = 0; j < 2; j++) {
        let min = `${30 * j}`.padStart(2, "0");
        let date = `${hh}:${min}`;
        gridArr.push({
          ...xMap[date],
          recorded_t: xMap[date]?.t,
          date: date,
        });
        let extras = extraPoints.filter((xx) => xx.xKey === date);
        for (let q in extras) {
          let pt = extras[q];
          gridArr.push({
            ...pt,
            recorded_t: pt?.t,
            date: date,
          });
        }
      }
    }
    console.log("gridArr = ", gridArr);
    return gridArr;
    // let getDate = tt => {
    //     let hours = Math.floor(tt / 2.0);
    // }
    // let gridPoints = Array.from({length: 24 * 2}).map((a, i) => ({}))
    // let xArr = oldPoints.filter(xx => (xx.date !== undefined));
  },

  getBpMonotonePoints(oldPoints, startTimestamp, mode) {
    let dt = 30 * 60 * 1000;
    // console.log("getBpMonotonePoints: oldPoints = ", oldPoints);
    let xMap = {};
    let gridArr = [];
    let points = JSON.parse(JSON.stringify(oldPoints));
    let extraPoints = [];
    for (let i in points) {
      let tt = +points[i].t - +startTimestamp;
      let num = Math.floor((1.0 * tt) / dt);
      let xKey = `x${+startTimestamp + num * dt}`;
      let xKeyExtra = `x${+startTimestamp + num * dt + +i + 1}`;
      if (xMap[xKey] === undefined) {
        xMap[xKey] = points[i];
      } else {
        xMap[xKeyExtra] = points[i];
        extraPoints.push({
          ...points[i],
          xKey: xKey,
        });
      }
    }
    // console.log("getBpMonotonePoints: extraPoints = ", extraPoints);
    // console.log("xMap = ", xMap);
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 2; j++) {
        let xDate = `x${+startTimestamp + i * 3600000 + j * 1800000}`;
        gridArr.push({
          ...xMap[xDate],
          recorded_t: xMap[xDate]?.t,
          t: +`${xDate}`.replace("x", ""),
        });
        let extras = extraPoints.filter((xx) => xx.xKey === xDate);
        for (let q in extras) {
          let pt = extras[q];
          gridArr.push({
            ...pt,
            recorded_t: pt?.t,
            t: pt.t,
          });
        }
      }
    }
    // console.log("AFTER: gridArr = ", gridArr);
    return gridArr;

    // return gridArr;
    // let getDate = tt => {
    //     let hours = Math.floor(tt / 2.0);
    // }
    // let gridPoints = Array.from({length: 24 * 2}).map((a, i) => ({}))
    // let xArr = oldPoints.filter(xx => (xx.date !== undefined));
  },

  getExtraDottedBpmChartsData(fPoints) {
    try {
      let lines = [];
      for (let i = 1; i < fPoints.length; i++) {
        let prevP = fPoints[i - 1];
        let currP = fPoints[i];
        if (
          (currP.bpm !== undefined && prevP.bpm !== undefined) ||
          (currP.bpm === undefined && prevP.bpm === undefined)
        ) {
          continue;
        }
        if (prevP.bpm !== undefined && currP.bpm === undefined) {
          // starting new line
          lines.push({
            points: [prevP],
          });
          continue;
        }
        if (prevP.bpm === undefined && currP.bpm !== undefined) {
          // starting new line
          if (lines.length === 0) {
            continue;
          }
          lines[lines.length - 1].points.push(currP);
          continue;
        }
      }
      lines = lines.filter((x) => x.points.length === 2);
      return lines;
    } catch (exc) {
      console.log("getExtraDottedBpmChartsData: exc = ", exc);
    }
    return [];
  },

  getExtraDottedHrChartsData(fPoints) {
    try {
      let lines = [];
      let n = 0;
      for (let i = 1; i < fPoints.length; i++) {
        let prevP = fPoints[i - 1];
        let currP = fPoints[i];
        if (
          (currP.bpm !== null && prevP.bpm !== null) ||
          (currP.bpm === null && prevP.bpm === null)
        ) {
          continue;
        }
        if (prevP.bpm !== null && currP.bpm === null) {
          // starting new line
          lines.push({
            points: [
              {
                ...prevP,
                [`bpm_gap_${n}`]: prevP.bpm,
              },
            ],
          });
          continue;
        }
        if (prevP.bpm === null && currP.bpm !== null) {
          // starting new line
          if (lines.length === 0) {
            continue;
          }
          lines[lines.length - 1].points.push({
            ...currP,
            [`bpm_gap_${n}`]: currP.bpm,
          });
          n += 1;
          continue;
        }
      }

      lines = lines.filter((x) => x.points.length === 2);
      return lines;
    } catch (exc) {
      // console.log("getExtraDottedBpmChartsData: exc = ", exc);
    }
    return [];
  },

  getExtraDottedChartsData(fPoints, dataType) {
    try {
      let lines = [];
      let n = 0;
      for (let i = 1; i < fPoints.length; i++) {
        let prevP = fPoints[i - 1];
        let currP = fPoints[i];
        if (
          (currP[dataType] !== null && prevP[dataType] !== null) ||
          (currP[dataType] === null && prevP[dataType] === null)
        ) {
          continue;
        }
        if (prevP[dataType] !== null && currP[dataType] === null) {
          // starting new line
          lines.push({
            points: [
              {
                ...prevP,
                [`${dataType}_gap_${n}`]: prevP[dataType],
              },
            ],
          });
          continue;
        }
        if (prevP[dataType] === null && currP[dataType] !== null) {
          // starting new line
          if (lines.length === 0) {
            continue;
          }
          lines[lines.length - 1].points.push({
            ...currP,
            [`${dataType}_gap_${n}`]: currP[dataType],
          });
          n += 1;
          continue;
        }
      }

      lines = lines.filter((x) => x.points.length === 2);
      return lines;
    } catch (exc) {
      // console.log("getExtraDottedBpmChartsData: exc = ", exc);
    }
    return [];
  },

  getExtraDottedBloodPressureChartsData(fPoints) {
    // console.log("getExtraDottedBloodPressureChartsData: fPoints = ", fPoints);
    try {
      let lines = [];
      for (let i = 1; i < fPoints.length; i++) {
        let prevP = fPoints[i - 1];
        let currP = fPoints[i];
        if (
          (currP.sbp !== undefined && prevP.sbp !== undefined) ||
          (currP.sbp === undefined && prevP.sbp === undefined)
        ) {
          continue;
        }
        if (prevP.sbp !== undefined && currP.sbp === undefined) {
          // starting new line
          // console.log(
          //   "starting new line because prevP.sbp !== undefined && currP.sbp === undefined",
          //   prevP,
          //   currP
          // );
          lines.push({
            points: [prevP],
          });
          continue;
        }
        if (prevP.sbp === undefined && currP.sbp !== undefined) {
          // starting new line
          if (lines.length === 0) {
            continue;
          }
          // console.log(
          //   "continuing line because prevP.sbp === undefined && currP.sbp !== undefined",
          //   prevP,
          //   currP
          // );
          lines[lines.length - 1].points.push(currP);
          continue;
        }
      }
      lines = lines.filter((x) => x.points.length === 2);
      return lines;
    } catch (exc) {
      console.log("getExtraDottedBloodPressureChartsData: exc = ", exc);
    }
    return [];
  },

  getExtraDottedBloodPressureChartsDataV1(fPoints, sbpKey, dbpKey) {
    try {
      let lines = [];
      for (let i = 1; i < fPoints.length; i++) {
        
        let prevP = fPoints[i - 1];
        let currP = fPoints[i];
        if (
          (currP[sbpKey] !== undefined && prevP[sbpKey] !== undefined) ||
          (currP[sbpKey] === undefined && prevP[sbpKey] === undefined)
        ) {
          continue;
        }
        if (prevP[sbpKey] !== undefined && currP[sbpKey] === undefined) {
          // starting new line
          // console.log(
          //   "starting new line because prevP.sbp !== undefined && currP.sbp === undefined",
          //   prevP,
          //   currP
          // );
          lines.push({
            points: [prevP],
          });
          continue;
        }
        if (prevP[sbpKey] === undefined && currP[sbpKey] !== undefined) {
          // starting new line
          if (lines.length === 0) {
            continue;
          }
          // console.log(
          //   "continuing line because prevP.sbp === undefined && currP.sbp !== undefined",
          //   prevP,
          //   currP
          // );
          lines[lines.length - 1].points.push(currP);
          continue;
        }
      }
      lines = lines.filter((x) => x.points.length === 2);
      return lines;
    } catch (exc) {
      console.log("getExtraDottedBloodPressureChartsData: exc = ", exc);
    }
    return [];
  },

  getExtraDottedBloodPressureChartsDataShortTerm(fPoints) {
    try {
      let lines = [];
      for (let i = 1; i < fPoints.length; i++) {
        let prevP = fPoints[i - 1];
        let currP = fPoints[i];
        if (
          (currP.nibp_systolic !== null && prevP.nibp_systolic !== null) ||
          (currP.nibp_systolic === null && prevP.nibp_systolic === null)
        ) {
          continue;
        }
        if (prevP.nibp_systolic !== null && currP.nibp_systolic === null) {
          lines.push({
            points: [prevP],
          });
          continue;
        }
        if (prevP.nibp_systolic === null && currP.nibp_systolic !== null) {
          // starting new line
          if (lines.length === 0) {
            continue;
          }
          lines[lines.length - 1].points.push(currP);
          continue;
        }
      }

      lines = lines.filter((x) => x.points.length === 2);
      return lines;
    } catch (exc) {
      console.error("getExtraDottedBloodPressureChartsData: exc = ", exc);
    }
    return [];
  },

  transformAiSleepStages(stages = []) {
    if (stages === undefined || stages.length < 2) {
      return [];
    }
    let arr = [];
    for (let i = 0; i < stages.length - 1; i++) {
      let curr = stages[i];
      let next = stages[i + 1];
      let curr_t = +curr.t * 1000;
      let next_t = +next.t * 1000;
      let start_date =
        moment(curr_t).format("YYYY-MM-DD") +
        "T" +
        moment(curr_t).format("HH:mm:ss");
      let end_date =
        moment(next_t).format("YYYY-MM-DD") +
        "T" +
        moment(next_t).format("HH:mm:ss");
      arr.push({
        start_date: start_date,
        end_date: end_date,
        type: curr.sleepStage,
        timestamp: curr_t,
      });
    }
    return arr;
  },

  transformAiSleepStages2(stages = []) {
    if (stages === undefined || stages.length < 2) {
      return [];
    }
    let arr = [];
    for (let i = 0; i < stages.length - 1; i++) {
      let curr = stages[i];
      let next = stages[i + 1];
      let curr_t = +curr.t;
      let next_t = +next.t;
      let start_date =
        moment(curr_t).format("YYYY-MM-DD") +
        "T" +
        moment(curr_t).format("HH:mm:ss");
      let end_date =
        moment(next_t).format("YYYY-MM-DD") +
        "T" +
        moment(next_t).format("HH:mm:ss");
      arr.push({
        start_date: start_date,
        end_date: end_date,
        type: curr.sleepStage,
        timestamp: curr_t,
      });
    }
    return arr;
  },
};

export default DrawHelper;

export const getGoalsMapByArray = (goals) => {
  let mp = {};
  for (let i in goals) {
    let g = goals[i];
    mp[g.type] = g;
  }
  return mp;
};

export const getColByVal = (perc) => {
  if (perc === undefined) {
    return RONAS_RED;
  }
  if (perc > 90) {
    return RONAS_DARK_GREEN;
  }
  if (perc > 75) {
    return RONAS_LIGHT_GREEN;
  }
  if (perc > 60) {
    return RONAS_BLUE_MOUSE_OVER;
  }
  return RONAS_RED;
};

export const getProgressColor = (progress) => {
  if (progress < 30) {
    return RONAS_RED;
  }
  if (progress < 50) {
    return RONAS_BLUE_MOUSE_OVER;
  }
  if (progress < 75) {
    return RONAS_LIGHT_GREEN;
  }
  return RONAS_DARK_GREEN;
};

export const DARK_GREEN = "#31777F";
export const LIGHT_GREEN = "#6D9CA2";
export const LIGHT_BLUE = "#1EB2FC";
export const RED = "#C35A21";

export const RONAS_DARK_GREY = "#191A21";
export const RONAS_DARK_GREEN = "#044000";
export const RONAS_LIGHT_GREEN = "#0C6102";
export const RONAS_RED = "#C90303";
export const RONAS_BLUE_MOUSE_OVER = "#1988E3";
