import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";

// import voidImg from '../../patients/icons/void_data_placeholder.png'
import voidImg from "../../patients/icons/frame_no_data.svg";

export default function SimpleNoDataMessageTool(props) {
  const { loading = false } = props;
  const { message = "Sorry,there is no data for this day" } = props;
  const { showTopImg = true } = props;

  return (
    <Wrapper>
      {showTopImg ? (
        <TopImgPlaceholder>
          <TopImg src={voidImg} />
        </TopImgPlaceholder>
      ) : null}

      <Text>
        <Spin visible={loading} />
        {loading == true ? null : message}
      </Text>
    </Wrapper>
  );
}

const TopImgPlaceholder = styled.div`
  margin-bottom: 20px;
  height: calc(100% - 80px);
  box-sizing: border-box;
`;

const TopImg = styled.img`
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const Text = styled.div`
  text-align: center;
`;
