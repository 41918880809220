import React, {useState, useEffect} from "react";
import styled from "styled-components";
import TemplatesAPI from "../../../api/TemplatesAPI";
import {toast} from "react-hot-toast";
import QRCode from "react-qr-code";

function getContentForCode(template = "", code = "", shouldBreak = true) {
    if (template == "") {
        return "";
    }
    let s = template;
    if (s.indexOf("[QR CODE]") == -1) {
        s = `${s}\n\n[QR CODE]\n\n`;
    }
    let component = null;
    if (s.indexOf("[QR CODE]") != -1) {
        let beforeCode = s.split("[QR CODE]")[0];
        let afterCode = s.split("[QR CODE]")[1];
        component = (
            <div
                style={{
                    pageBreakAfter: shouldBreak == true ? "always" : undefined,
                    padding: "60px",
                    paddingTop: "80px",
                    boxSizing: "border-box",
                }}
            >
                <div>
                    <div
                        style={{fontSize: "15px"}}
                        dangerouslySetInnerHTML={{
                            __html: beforeCode.replace(/\n/g, "<br/>"),
                        }}
                    ></div>
                </div>
                <div style={{marginTop: "10px", marginBottom: "10px"}}>
                    <QRCode
                        value={`https://install.corsano.com/?voucher=${code}`}
                        size={120}
                    />
                </div>
                <div style={{fontStyle: "italic", fontSize: "12px"}}>
                    {`${code}`}
                </div>
                <div>
                    <div
                        style={{fontSize: "15px"}}
                        dangerouslySetInnerHTML={{
                            __html: afterCode.replace(/\n/g, "<br/>"),
                        }}
                    ></div>
                </div>
            </div>
        );
    }
    return component;
}

export default function PrintVoucherLetterTool(props) {
    const {code} = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        if (code == undefined) {
            return;
        }
        setLoading(true);
        TemplatesAPI.getPrintInfo(code)
            .then((d) => {
                setData(d);
                setLoading(false);
                setTimeout(() => {
                    window.print();
                }, 100);
            })
            .catch((exc) => {
                setLoading(false);
                toast.error("Failed to load print info");
            });
    }, [code]);

    let codes = data?.codes || [];
    let template = data?.template?.content || "";

    if (codes.length == 0) {
        return <div>...</div>;
    }

    return (
        <Wrapper className="print-voucher-page">
            {codes.map((a, i) => {
                return (
                    <React.Fragment key={`code_${a}_${i}`}>
                        {getContentForCode(template, a, !(i == codes.length - 1))}
                    </React.Fragment>
                );
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
`;

/*
Dear Patient,

Thank you for your participation in the study.

Please find instructions to download the Corsano App and register your anonymized voucher code. Please follow these steps:

1) Take your mobile phone and scan the QR Code with your camera:

[QR CODE]

2) You will be directed to the Corsano App on the store. Please download and install the Corsano App on your phone.

3) Open the Corsano App on your mobile phone and press the [VOUCHER] button. You can now scan the QR Code again and your voucher code will be registered.

4) Please follow all instructions your receive in the Corsano App to create a password and then pair the bracelet

Please contact us any time if you are having questions via: https://corsano.com/knowledge-base/

Best regards,

Your Research Team
 */
