import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import SleepAPI from "../../../api/SleepAPI";
import StepsAPI from "../../../api/StepsAPI";
import RonasBasicAPI from "../../../api/RonasBasicAPI";
import useDimensions from "react-use-dimensions";
import {CartesianGrid, Line, LineChart, XAxis, YAxis} from "recharts";
import SabirBarChart from "./SabirBarChart";
import CommonHelper from "../../../helpers/CommonHelper";

export default function SevenDaysActivityTool(props) {
    const {
        dayTimestamp,
        param = 'total_steps',
        transformValue = x => x,
        momentMask = 'ddd, D',
        strokeColor = 'rgb(25, 136, 227)',
        loading = false,
        renderTimeLabel = it => {
            return (
                <div style={{marginTop: 5, fontSize: 10, lineHeight: `12px`, textAlign: 'center'}}>
                    <div>
                        {moment(it.t).format('ddd')}
                    </div>
                    <div>
                        {moment(it.t).format('D')}
                    </div>
                </div>
            )
        }
    } = props;
    const [points, setPoints] = useState([]);
    const [ref, {x, y, width, height}] = useDimensions();

    useEffect(() => {
        let mounted = true;
        if (mounted == false) {
            return;
        }
        StepsAPI.getInstance().getActivityStatsFor7DaysFromCache(dayTimestamp).then(arr => {
            if (mounted == false) {
                return;
            }
            console.log('getActivityStatsFor7DaysFromCache: arr = ', arr);
            setPoints(arr.map(xx => ({
                value: xx[param],
                t: +moment(CommonHelper.getTimestampFromDateString(xx.date)).startOf('day')
            })).map(x => ({...x, value: Math.max(0, x.value)})));
            StepsAPI.getInstance().getActivityStatsFor7DaysFromCache(dayTimestamp).then(arr2 => {
                if (mounted == false) {
                    return;
                }
                console.log('getSleepStatsFor7DaysFromCache: arr2 = ', arr2);
                setPoints(arr2.map(xx => ({
                    value: xx[param],
                    t: +moment(CommonHelper.getTimestampFromDateString(xx.date)).startOf('day')
                })).map(x => ({...x, value: (x.value <= 0) ? null : Math.max(0, x.value)})));
            });
        });
        return () => {
            mounted = false;
        }
    }, [dayTimestamp]);

    let trPoints = points.map(x => ({t: x.t, value: transformValue(x.value)}));
    let noData = (trPoints.filter(x => (x.value != undefined)).length == 0);
    // if (noData == true){
    //     return (
    //         <NoDataPlaceholder>
    //             <NoDataText>
    //                 No data
    //             </NoDataText>
    //         </NoDataPlaceholder>
    //     )
    // }

    return (
        <Wrapper ref={ref}>
            {param == 'distance' ?
                <LineChart width={width} height={height} data={trPoints} connectNulls={false}
                           margin={{top: 5, right: 5, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25} tickFormatter={a => {
                        return (moment(a).format(momentMask))
                    }}/>
                    <YAxis/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey="value" stroke={strokeColor} strokeWidth={2} animationDuration={2}
                          dot={true}/>
                </LineChart>
                :
                <SabirBarChart items={trPoints} renderItem={renderTimeLabel}/>
            }
        </Wrapper>
    );
}

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;