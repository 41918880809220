import { useCallback } from "react";
import styled from "styled-components";
import DoctorTemplate from "../templates/DoctorTemplate";
import { useMappedState } from "redux-react-hook";
import PatientPanel from "../patients/panels/PatientPanel";
import { useParams } from "react-router-dom";

function PatientApp(props) {
  let params = useParams();
  let { id, tag } = params;
  // let isDemo = false;
  let isDemo = id != undefined && id.indexOf("demo") > -1;
  const { role } = useMappedState(
    useCallback((state) => {
      return {
        role: state.ui.role,
      };
    })
  );
  const isHCPAdmin = role === "hcp-admin";
  // useEffect(() => {
  //     DoctorAPI.getPatient(id).then(x => {
  //         console.log('xxx: x = ', x);
  //         DoctorAPI.getUserDaySummary(id, moment().add(-1, 'days').format('YYYY-MM-DD')).then(z => {
  //             console.log('z = ', z);
  //         });
  //     });
  // }, [id]);

  let isEmbedMode = window.location.href.indexOf("embed") > -1;

  if (isEmbedMode == true) {
    return <PatientPanel isEmbedMode={true} id={id} />;
  }

  return (
    <DoctorTemplate active={"patients"}>
      <Wrapper key={`xx${id}`}>
        <PatientPanel
          id={id}
          tag={tag}
          isDemo={isDemo}
          isHCPAdmin={isHCPAdmin}
        />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default PatientApp;
