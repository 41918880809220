import axios from 'axios'
import moment from 'moment'
import env from "react-dotenv";
import * as ls from 'local-storage'
import CommonHelper from "../helpers/CommonHelper";
import {DEMO_FAKE_USERS} from "../constants/config";

const {EXPORT_SERVICE_API_ENDPOINT = 'https://study.corsano.com/export-service'} = env;

const ExportAPI = {

    async createExportJob(data) {
        console.log('createExportJob: data = ', data);
        let pld = (await axios.post(`${EXPORT_SERVICE_API_ENDPOINT}/jobs`, {
            ...data
        })).data.result;
        return pld;
    },

    async getJob(id) {
        let pld = (await axios.get(`${EXPORT_SERVICE_API_ENDPOINT}/jobs/${id}`)).data.result;
        return pld;
    },

    getJobDownloadUrl(id, name) {
        let s = `${EXPORT_SERVICE_API_ENDPOINT}/jobs/${id}/download`;
        if (name != undefined && name != '') {
            let xName = `${name}`
            s = `${s}?name=${xName}`;
        }
        return s;
    }


}

export default ExportAPI;
