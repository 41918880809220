import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import DoctorAPI from "../../../api/DoctorAPI";
import { download } from "../../bp/PatientDayBpTool";

export default function UserInitBpMeasurementsTool(props) {
  const { uuid } = props;
  const [items, setItems] = useState([]);
  const [loadingId, setLoadingId] = useState(undefined);

  useEffect(() => {
    DoctorAPI.getAIBpInitDataMeasurements(uuid).then((arr) => {
      console.log("getAIBpInitDataMeasurements", arr);

      setItems(arr);
    });
  }, [uuid]);

  return (
    <Wrapper>
      {items.map((a, i) => {
        let isLoading = a._id == loadingId;
        return (
          <RowItem key={i}>
            <span>
              <span>
                {`${moment(a.start_timestamp).format(
                  "DD.MM.YYYY HH:mm:ss"
                )} - ${moment(a.end_timestamp).format("DD.MM.YYYY HH:mm:ss")}`}
                <span
                  style={{
                    fontSize: 12,
                    opacity: 0.5,
                    marginLeft: 5,
                  }}
                >{`status=${a.status}`}</span>
              </span>

              {a._raw_response == undefined ? null : (
                <>
                  <br />
                  <span>
                    {`raw response = ${JSON.stringify(a._raw_response)}`}
                  </span>
                </>
              )}
            </span>

            {isLoading == true ? (
              <RowDownload2>loading...</RowDownload2>
            ) : (
              <RowDownload
                onClick={async () => {
                  setLoadingId(a._id);
                  let pld = (
                    await axios.get(
                      `https://api.study-integration.corsano.com/v2/ds/user/${uuid}/init-pressure-measurements/${a._id}/download`
                    )
                  ).data;
                  setLoadingId(false);
                  let txt = JSON.stringify(pld);
                  download(
                    `${moment(a.start_timestamp).format(
                      "DD_MM_YYYY_HH_mm_ss"
                    )}_init_spo2_raw_ppg2.json`,
                    txt
                  );
                }}
              >
                download
              </RowDownload>
            )}
          </RowItem>
        );
      })}
    </Wrapper>
  );
}

const RowDownload2 = styled.div`
  font-size: 10px;
  opacity: 0.5;
`;

const Wrapper = styled.div``;

const RowItem = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowDownload = styled.a`
  font-size: 10px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;
