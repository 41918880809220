import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import ProgressTool from "../../progress/tools/ProgressTool";

import {
  DEFAULT_SLEEP_NEED_DURATION,
  getColByVal,
  MAX_WAKEUPS_NUMBER,
} from "../../../constants/config";
import {
  getGoalsMapByArray,
  getProgressColor,
} from "../../../helpers/DrawHelper";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";

const getTranquilityPercent = (x) => {
  let n = Math.min(+x, MAX_WAKEUPS_NUMBER);
  let p = (100.0 * n) / MAX_WAKEUPS_NUMBER;
  return +(100.0 - p).toFixed(1);
};

const getTimeStringBySeconds = (seconds) => {
  let minutes = Math.floor(seconds / 60.0);
  let hours = Math.floor(minutes / 60);
  let mins = minutes - 60 * hours;
  return `${`${hours}`.padStart(2, "0")}:${`${mins}`.padStart(2, "0")}`;
};

const getSleepTypeLabel = (seconds, percents) => {
  return `${getTimeStringBySeconds(seconds)} / ${(+percents).toFixed(0)}%`;
};

export default function SleepGoalsTool(props) {
  const {
    goals = [],
    awake_time = 0,
    consistency,
    daily_percent,
    deep_time = 0,
    light_time = 0,
    performance,
    rem_time = 0,
    sleep_duration,
    tranquility,
  } = props;

  // console.log("SleepGoalsTool: props = ", props);
  // let goalsMap = getGoalsMapByArray(goals);
  // let sleepNeedMinutes = (goalsMap.sleep == undefined || goalsMap.sleep.value == undefined) ? DEFAULT_SLEEP_NEED_DURATION : goalsMap.sleep.value;

  if (sleep_duration == undefined) {
    return (
      <div style={{ width: "100%", height: 320 }}>
        <SimpleNoDataMessageTool
          message={"Sorry, there is no sleep data for this day."}
        />
      </div>
    );
  }

  let deepTimePercent = (100.0 * deep_time) / sleep_duration;
  let awakeTimePercent = (100.0 * awake_time) / sleep_duration;
  let remTimePercent = (100.0 * rem_time) / sleep_duration;
  let lightTimePercent = (100.0 * light_time) / sleep_duration;

  let tranquilityPercent =
    tranquility == undefined ? 0 : getTranquilityPercent(tranquility);

  return (
    <Wrapper >
      <Row>
        <ProgressTool
          barHeight={10}
          leftText={"AWAKE"}
          percent={awakeTimePercent}
          color={getProgressColor(awakeTimePercent)}
          rightText={getSleepTypeLabel(awake_time, awakeTimePercent)}
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={10}
          leftText={"REM"}
          percent={remTimePercent}
          color={getProgressColor(remTimePercent)}
          rightText={getSleepTypeLabel(rem_time, remTimePercent)}
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={10}
          leftText={"LIGHT"}
          percent={lightTimePercent}
          color={getProgressColor(lightTimePercent)}
          rightText={getSleepTypeLabel(light_time, lightTimePercent)}
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={10}
          leftText={"DEEP"}
          percent={deepTimePercent}
          color={getProgressColor(deepTimePercent)}
          rightText={getSleepTypeLabel(deep_time, deepTimePercent)}
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={10}
          leftText={"PERFORMANCE"}
          percent={performance}
          color={getProgressColor(performance)}
          rightText={performance == undefined ? "" : `${performance}%`}
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={10}
          leftText={"TRANQUILITY"}
          rightText={
            tranquility == undefined
              ? ""
              : `${tranquility} WAKE UP${+tranquility > 1 ? "S" : ""}`
          }
          percent={tranquilityPercent}
          color={getProgressColor(tranquilityPercent)}
        />
      </Row>

      <Row>
        <ProgressTool
          barHeight={10}
          leftText={"CONSISTENCY"}
          percent={consistency}
          color={getProgressColor(consistency)}
          rightText={
            consistency == undefined || consistency == 0
              ? ""
              : `${consistency}%`
          }
        />
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 2px;
`;

const Row = styled.div`
  margin-bottom: 10px;
  :last-of-type {
    margin-bottom: 0px;
  }
`;
