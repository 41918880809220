import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import { Input, Label, Row, Textarea } from "../../../bracelets/forms/UpdateCorsanoPatientForm";
import { Button } from "../../../settings/tools/DictionaryEditorTool";

export default function DescriptionTool(props) {
  const { onSave = (d) => {}, sessionId, loading = false, hasDescription = true, originalDescription = "" } = props;

  const [description, setDescription] = useState(props.originalDescription || "");

  return (
    <Wrapper>
      {hasDescription == false ? null : (
        <Row>
          <Label>Description</Label>
          <Textarea
            style={{ width: 300, maxWidth: 400 }}
            value={description}
            maxLength={400}
            onChange={(evt) => {
              setDescription(evt.target.value);
            }}
          />
        </Row>
      )}

      <Row>
        <Button
          onClick={() => {
            onSave({
              id: sessionId,
              description: description,
            });
          }}
        >
          {loading == true ? <Spin visible={true} /> : "Save"}
        </Button>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
