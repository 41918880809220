import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import { Input, Button } from "../../ui/templates";

export default function UpdateGatewayForm(props) {
  const { id, accessToken, loading = false, onSave = (d) => {} } = props;

  const [mode, setMode] = useState("basic");

  const [wifiSSID, setWifiSSID] = useState(props.wifiSSID || "");
  const [wifiPassword, setWifiPassword] = useState(props.wifiPassword || "");

  const [name, setName] = useState(props.name || "");
  const [serialNumber, setSerialNumber] = useState(props.serialNumber || "");

  const [hostIP, setHostIP] = useState(props.hostIP || "");
  const [gateway_data_app_name, setGateway_data_app_name] = useState(
    props.gateway_data_app_name || ""
  );
  const [gateway_data_app_key, setGateway_data_app_key] = useState(
    props.gateway_data_app_key || ""
  );

  const [gateway_control_app_name, setGateway_control_app_name] = useState(
    props.gateway_control_app_name || ""
  );
  const [gateway_control_app_key, setGateway_control_app_key] = useState(
    props.gateway_control_app_key || ""
  );

  const [gateway_onboarding_app_name, setGateway_onboarding_app_name] =
    useState(props.gateway_onboarding_app_name || "");
  const [gateway_onboarding_app_key, setGateway_onboarding_app_key] = useState(
    props.gateway_onboarding_app_key || ""
  );

  return (
    <Wrapper>
      <Row>
        <Label>Name</Label>
        <Input
          $mode={"mode2"}
          value={name}
          onChange={(evt) => {
            setName(evt.target.value);
          }}
        />
      </Row>

      <Row>
        <Label>Serial number</Label>

        <Input
          $mode={"mode2"}
          value={serialNumber}
          onChange={(evt) => {
            setSerialNumber(evt.target.value);
          }}
        />
      </Row>

      {/*<Row>*/}
      {/*    <Label>*/}
      {/*        WiFi Network*/}
      {/*    </Label>*/}
      {/*    <Input value={wifiSSID} onChange={evt => {*/}
      {/*        setWifiSSID(evt.target.value);*/}
      {/*    }}/>*/}
      {/*</Row>*/}

      {/*<Row>*/}
      {/*    <Label>*/}
      {/*        WiFi Password*/}
      {/*    </Label>*/}
      {/*    <Input*/}
      {/*        type={'password'}*/}
      {/*        value={wifiPassword} onChange={evt => {*/}
      {/*        setWifiPassword(evt.target.value);*/}
      {/*    }}/>*/}
      {/*</Row>*/}

      {id == undefined ? null : (
        <>
          <div>
            <Row>
              <Label>ID</Label>
              <div>{id}</div>
            </Row>

            <Row style={{ marginBottom: 20 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Label>Token</Label>
                <div>
                  <AdvancedSpan
                    onClick={() => {
                      setMode({ advanced: "basic", basic: "advanced" }[mode]);
                    }}
                  >
                    {mode != "advanced"
                      ? "Advanced settings"
                      : "Hide advanced settings"}
                  </AdvancedSpan>
                </div>
              </div>
              <div style={{ fontSize: 12 }}>{accessToken}</div>
            </Row>
          </div>
        </>
      )}

      {mode != "advanced" ? null : (
        <>
          <Row>
            <Label>Host IP</Label>
            <Input
              value={hostIP}
              onChange={(evt) => {
                setHostIP(evt.target.value);
              }}
            />
          </Row>
          <ColumnsPlaceholder>
            <Row>
              <Label>Gateway data app name</Label>
              <Input
                value={gateway_data_app_name}
                onChange={(evt) => {
                  setGateway_data_app_name(evt.target.value);
                }}
              />
            </Row>
            <Row>
              <Label>Gateway data app key</Label>
              <Input
                value={gateway_data_app_key}
                onChange={(evt) => {
                  setGateway_data_app_key(evt.target.value);
                }}
              />
            </Row>
            <Row>
              <Label>Gateway control app name</Label>
              <Input
                value={gateway_control_app_name}
                onChange={(evt) => {
                  setGateway_control_app_name(evt.target.value);
                }}
              />
            </Row>
            <Row>
              <Label>Gateway control app key</Label>
              <Input
                value={gateway_control_app_key}
                onChange={(evt) => {
                  setGateway_control_app_key(evt.target.value);
                }}
              />
            </Row>
            <Row>
              <Label>Gateway onboarding app name</Label>
              <Input
                value={gateway_onboarding_app_name}
                onChange={(evt) => {
                  setGateway_onboarding_app_name(evt.target.value);
                }}
              />
            </Row>
            <Row>
              <Label>Gateway onboarding app key</Label>
              <Input
                value={gateway_onboarding_app_key}
                onChange={(evt) => {
                  setGateway_onboarding_app_key(evt.target.value);
                }}
              />
            </Row>
          </ColumnsPlaceholder>
        </>
      )}

      <ButtonsPlaceholder>
        <Button
          onClick={() => {
            onSave({
              name,
              serialNumber,
              wifiSSID,
              wifiPassword,

              hostIP,
              gateway_data_app_name,
              gateway_data_app_key,
              gateway_control_app_name,
              gateway_control_app_key,
              gateway_onboarding_app_name,
              gateway_onboarding_app_key,
            });
          }}
        >
          <Spin visible={loading} />
          {loading == true ? null : "Save"}
        </Button>
      </ButtonsPlaceholder>
    </Wrapper>
  );
}

const ColumnsPlaceholder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  box-sizing: border-box;
`;

const AdvancedSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.7;
  font-size: 12px;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
`;

const ButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div``;
