// import BraceletsAPI from '../../api/BraceletsAPI'

import * as types from '../ActionTypes'
import BraceletsAPI from "../../api/BraceletsAPI";


const loadBracelets_ = () => {
    return {
        type: types.LOAD_BRACELETS
    }
}
export const loadBraceletsSuccess = (bracelets) => {
    return {
        type: types.LOAD_BRACELETS_SUCCESS,
        bracelets: bracelets
    }
}
let loadBraceletsFailed = (error) => {
    return {
        type: types.LOAD_BRACELETS_FAIL,
        error: error
    }
}

export function setForceBracelet(bracelet) {
    return (dispatch, getState) => {
        dispatch(loadBraceletsSuccess([bracelet]))
    }
}

//thunk
export function loadAllBracelets(uuid) {
    return (dispatch, getState) => {
        dispatch(loadBracelets_());
        return BraceletsAPI.getDoctorBracelets(uuid).then(
            bracelets => dispatch(loadBraceletsSuccess(bracelets)),
            error => dispatch(loadBraceletsFailed(error))
        )
    }
}

export function getBracelet(id) {
    return (dispatch, getState) => {
        dispatch(loadBracelets_());
        return BraceletsAPI.getBracelet(id).then(
            proj => dispatch(loadBraceletsSuccess([proj])),
            error => dispatch(loadBraceletsFailed(error))
        )
    }
}

let createBracelet_ = () => {
    return {
        type: types.CREATE_BRACELET
    }
}
let createBraceletSuccess = (bracelet) => {
    return {
        type: types.CREATE_BRACELET_SUCCESS,
        bracelet: bracelet
    }
}
let createBraceletFailed = (error) => {
    return {
        type: types.CREATE_BRACELET_FAIL,
        error: error
    }
}
//
// //thunk
export function createBracelet(doctorUUID, data) {
    return (dispatch, getState) => {
        dispatch(createBracelet_());
        return BraceletsAPI.createBracelet(doctorUUID, data).then(
            bracelet => dispatch(createBraceletSuccess(bracelet)),
            error => dispatch(createBraceletFailed(error))
        )
    }
}

//
let updateBracelet_ = () => {
    return {
        type: types.UPDATE_BRACELET
    }
}
let updateBraceletSuccess = (bracelet) => {
    return {
        type: types.UPDATE_BRACELET_SUCCESS,
        bracelet: bracelet
    }
}
let updateBraceletFailed = (error) => {
    return {
        type: types.UPDATE_BRACELET_FAIL,
        error: error
    }
}

//thunk
export function updateBracelet(doctorUUID, data, userCode) {
    return (dispatch, getState) => {
        dispatch(updateBracelet_());
        return BraceletsAPI.updateBracelet(doctorUUID, data, userCode).then(
            bracelet => dispatch(updateBraceletSuccess(bracelet)),
            error => dispatch(updateBraceletFailed(error))
        )
    }
}

//

let deleteBracelet_ = () => {
    return {
        type: types.DELETE_BRACELET
    }
}
let deleteBraceletSuccess = (id) => {
    return {
        type: types.DELETE_BRACELET_SUCCESS,
        id: id
    }
}
let deleteBraceletFailed = (error) => {
    return {
        type: types.DELETE_BRACELET_FAIL,
        error: error
    }
}

//thunk
export function deleteBracelet(doctorUUID, id) {
    return (dispatch, getState) => {
        dispatch(deleteBracelet_());
        return BraceletsAPI.deleteBracelet(doctorUUID, id).then(
            () => dispatch(deleteBraceletSuccess(id)),
            error => dispatch(deleteBraceletFailed(error))
        )
    }
}
