import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import ls from "local-storage";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";

import { Range, getTrackBackground } from "react-range";

export const PR_1 = {
  color: "#EA3223",
  priority: 10,
  newsColor: "#F59681",
};

export const PR_2 = {
  color: "#e5c347",
  newsColor: "#FDC889",
  priority: 9,
};

export const PR_3 = {
  color: "#56c3c3",
  newsColor: "#FFF1AB",
  priority: 8,
};

export const NEWS2_PR_3 = {
  color: "#8798AD",
  priority: 8,
};
export const NO_PRIORITY = {
  color: "#D3D3D3",
  newsColor: "#e3e3e3",
  priority: 0,
};

export const NEWS_PR1 = {
  color: "#F59681",
  priority: 3,
};

export const NEWS_PR2 = {
  color: "#FDC889",
  priority: 2,
};

export const NEWS_PR3 = {
  // color: "#FCF298",
  color: "#FFF1AB",
  priority: 1,
};

export const NEWS_NO_PR = {
  color: "#CFCFCF",
  newsColor: "#CFCFCF",
  priority: 0,
};

export function getRangesSettings(type, tempUnit) {
  // console.log("tempUnit", tempUnit);

  if (type == "temperature") {
    return {
      options: [PR_1, PR_3, NO_PRIORITY, PR_3, PR_2],
      transformLabel:
        // window.location.href.indexOf("portal") == -1
        tempUnit === "C" ? (x) => x : (x) => (+x * 1.8 + 32.0).toFixed(1),
      defaults: [35.0, 36.0, 38.0, 39.0],
      step: 0.1,
      // min: 14.9,
      min: 34.0,
      max: 42.3,
    };
  }
  if (type == "heart_rate") {
    return {
      options: [PR_1, PR_3, NO_PRIORITY, PR_3, PR_2, PR_1],
      transformLabel: (x) => x,
      // defaults: [40, 50, 91, 111, 131],
      defaults: [36, 50, 91, 111, 131], // new Default
      step: 1,
      min: 10,
      max: 220,
    };
  }
  if (type == "spo2") {
    return {
      options: [PR_1, PR_2, PR_3, NO_PRIORITY],
      transformLabel: (x) => x,
      // defaults: [91, 94, 96],
      defaults: [90, 93, 96], // new default
      step: 1,
      min: 85,
      max: 100,
    };
  }
  if (type == "respiration_rate") {
    return {
      options: [PR_1, PR_3, NO_PRIORITY, PR_2, PR_1],
      transformLabel: (x) => x,
      defaults: [8, 12, 21, 24],
      step: 1,
      min: 4,
      max: 40,
    };
  }
  if (type === "blood_pressure") {
    //
    return {
      options: [PR_1, PR_2, PR_3, NO_PRIORITY, PR_1],
      transformLabel: (x) => x,
      // defaults: [90, 100, 110, 220], // old
      defaults: [80, 90, 110, 220], // current
      step: 1,
      min: 60,
      max: 251,
    };
  }
  if (type == "NEWS2") {
    return {
      options: [NEWS2_PR_3, PR_2, PR_1],
      transformLabel: (x) => x,
      defaults: [2, 3],
      step: 1,
      min: 0,
      max: 4,
    };
  }
  return {
    options: [],
    defaults: [],
    step: 1,
  };
}

export function getDefaultsRangesMap() {
  let arr = [
    "heart_rate",
    "respiration_rate",
    "spo2",
    "temperature",
    "NEWS2",
    "blood_pressure",
  ];
  let map = {};
  for (let i in arr) {
    let v = arr[i];
    map[v] = getRangesSettings(v).defaults;
  }
  return map;
}

export function getOptionByTypeAndValue(type, userRanges = [], value) {
  if (value == undefined) {
    return NO_PRIORITY;
  }
  let settings = getRangesSettings(type);
  let { options = [], defaults = [] } = settings;
  let ranges =
    userRanges == undefined || userRanges.length != defaults.length
      ? defaults
      : userRanges;
  let k = 0;
  let v = +value;
  for (let i in ranges) {
    let r = ranges[i];
    if (+v > +r) {
      k = +i + 1;
    }
  }
  let opt = options[Math.min(options.length - 1, k)];
  return opt;
}

export function getOptionsByTypesAndValues(items = []) {
  let map = {};
  for (let i in items) {
    let a = items[i];
    let { type, value, ranges = [] } = a;
    let opt = getOptionByTypeAndValue(type, ranges, value);
    map[type] = opt;
  }
  return map;
}

export default function NewRangeTool(props) {
  const {
    type = "heart_rate",
    onChange = (newArr) => {},
    loading = false,
  } = props;

  const { alarm, unit } = useMappedState(
    useCallback((state) => {
      return {
        alarm: state.ui.alarm,
        unit: state.ui.unit,
      };
    })
  );

  let {
    options,
    defaults,
    step,
    min,
    max,
    transformLabel = (x) => x,
  } = getRangesSettings(type, unit);
  // console.log("options", options);

  let colors = options.map((x) => x.color);
  // console.log(colors);
  if (alarm.includes("news")) {
    colors = options.map((x) => x.newsColor);
  }
  
  const [ranges, setRanges] = useState(
    props.ranges == undefined || props.ranges.length != colors.length - 1
      ? defaults
      : props.ranges
  );

  // useEffect(() => {
  //     onChange(ranges);
  // }, [ranges]);

  useEffect(() => {
    let newRanges =
      props.ranges == undefined || props.ranges.length != colors.length - 1
        ? defaults
        : props.ranges;
    setRanges(newRanges);
  }, [props.ranges]);

  return (
    <Wrapper>
      <Range
        values={ranges}
        step={step}
        min={min}
        max={max}
        onChange={(values) => {
          setRanges(values);
          onChange(values);
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: ranges,
                  colors: colors,
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, index, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "22px",
              width: "22px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-25px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "14px",
                fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                padding: "2px",
                borderRadius: "4px",
                backgroundColor: "#548BF4",
              }}
              className="range-label"
            >
              {transformLabel(ranges[index])}
            </div>
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged ? "#548BF4" : "#CCC",
              }}
            />
          </div>
        )}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
