import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import MmtTemplate from '../templates/MmtTemplate'
import HeartRatePanel from "../heart_rate/panels/HeartRatePanel";
import StudySessionsPanel from "../trials/panels/StudySessionsPanel";

function StudyIndexApp() {

    return (
        <MmtTemplate active={'index'}>

            <Wrapper>

                <StudySessionsPanel  />

            </Wrapper>

        </MmtTemplate>
    );
}

const Wrapper = styled.div`

`;

export default StudyIndexApp
