import axios from 'axios'
import moment from 'moment'
import env from "react-dotenv";
import * as ls from 'local-storage'

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const NewExportAPI = {

    async createExportJob(userId, from, to, type) {
        let xKey = `export_${userId}_${from}_${to}_${type}`;
        let d = window[xKey];
        if (d != undefined) {
            return d;
        }
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v4/export/users/${userId}/${type}`, {
            from: +from,
            to: +to
        })).data;
        console.log('createExportJob: --->>>  pld = ', pld);
        let {_id} = pld;
        window[xKey] = _id;
        return _id;
    },

    async getJobStatusObject(userId, id) {
        if (userId == undefined || id == undefined) {
            return undefined;
        }
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v4/export/users/${userId}/status/${id}`)).data;
        return pld;
    },

    getJobDownloadUrl(userId, id, name) {
        let s = `${DOCTORS_API_ENDPOINT}/v4/export/users/${userId}/file/${id}?name=${name}`;
        return s;
    }


}

export default NewExportAPI;
