import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
// import GithubSleepSessionsView from "../GithubSleepSessionsView";

import * as actions from '../../../redux/actions/DataActions'
import InlineDateSelector from "../../dates/tools/InlineDateSelector";
import HeartRateAPI from "../../../api/HeartRateAPI";
import SimpleBpmSlotsChart from "../../heart_rate/tools/SimpleBpmSlotsChart";
// import GithubActivitySessionsView from "../tools/GithubActivitySessionsView";
import {Code} from "react-content-loader";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import GithubHeartRateSessionsView from "../tools/GithubHeartRateSessionsView";
import StepsAPI from "../../../api/StepsAPI";

import DayHrvAnalysisPanel from "../../hrv/panels/DayHrvAnalysisPanel";

export default function HeartRatePanel(props) {
    let {loading, selectedTimestamp} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading || state.data.loading,
            updatedTimestamp: state.data.updatedTimestamp,
            selectedTimestamp: state.data.selectedTimestamp
        }
    }, []));
    const dispatch = useDispatch();
    const [yearTimestamp, setYearTimestamp] = useState(+new Date());
    const [selectedDaySlots, setSelectedDaySlots] = useState([]);
    const [selectedDayHeartRateSlots, setSelectedDayHeartRateSlots] = useState();
    const [heartRateFetching, setHeartRateFetching] = useState(false);

    useEffect(() => {
        console.log('---((((');
        dispatch(actions.loadYearSummaries(yearTimestamp));
    }, [+moment(yearTimestamp).startOf('year')]);

    useEffect(() => {
        console.log('---((((');
        dispatch(actions.loadYearSummaries(yearTimestamp)).then(yPld => {
            let pts = (yPld?.data ? yPld.data : []).filter(x => x.heart_rate != undefined).sort((a, b) => (+new Date(a.date) - +new Date(b.date)));
            console.log('--->>> pts = ', pts);
            if (pts.length > 0) {
                dispatch(actions.selectDay(+new Date(pts[pts.length - 1].date)));
            }
        });
    }, []);


    useEffect(() => {
        if (loading == true || selectedTimestamp == undefined) {
            return;
        }
        setHeartRateFetching(true);
        StepsAPI.getInstance().getStepsItemsForDayFromCloud(selectedTimestamp).then(() => {
            HeartRateAPI.getInstance().getHeartRateSlotsForTodayFromCache(selectedTimestamp).then(hrSlots => {
                console.log('--->>> hrSlots = ', hrSlots);
                setSelectedDayHeartRateSlots(hrSlots);
                setHeartRateFetching(false);
            });
        });
    }, [selectedTimestamp, loading]);

    let isSomethingLoading = (heartRateFetching || loading);

    return (
        <Wrapper>
            <YearPlaceholder>
                <GithubHeartRateSessionsView
                    loading={loading}
                    onYearTimestampChange={t => {
                        if (isSomethingLoading == true) {
                            return;
                        }
                        setYearTimestamp(t);
                    }}
                    onDayClick={t => {
                        if (isSomethingLoading == true) {
                            return;
                        }
                        dispatch(actions.selectDay(t));
                    }}
                />
            </YearPlaceholder>

            {selectedTimestamp == undefined ? null :
                <React.Fragment>
                    <MainContentPlaceholder key={`qq${yearTimestamp}_${selectedTimestamp}`}>

                        <MainContentHeading>

                            <TopSleepHeadingName>
                                Pulse Rate summary
                            </TopSleepHeadingName>

                            <InlineDateSelector loading={isSomethingLoading} selectedTimestamp={selectedTimestamp}
                                                onDaySelect={t => {
                                                    dispatch(actions.selectDay(t));
                                                }}/>

                        </MainContentHeading>

                        <HeartRateChartPlaceholder>
                            {isSomethingLoading ? <Code/> :
                                <>
                                    {selectedDayHeartRateSlots == undefined || selectedDayHeartRateSlots.length == 0 ?
                                        <SimpleNoDataMessageTool
                                            message={'Sorry, there is no heart rate data for this day'}/> :
                                        <SimpleBpmSlotsChart slots={selectedDayHeartRateSlots}
                                                             preserveMinMax={false}
                                                             min={+moment(selectedTimestamp).startOf('day')}
                                                             max={+moment(selectedTimestamp).endOf('day')}
                                                            connectNulls={false}
                                        />
                                    }
                                    <div style={{marginTop: 30}} >
                                        <DayHrvAnalysisPanel dayTimestamp={selectedTimestamp} />
                                    </div>
                                </>
                            }

                        </HeartRateChartPlaceholder>


                        {/*<HrvImagePlaceholder>*/}
                        {/*    <HrvImage src={testersImg}/>*/}
                        {/*</HrvImagePlaceholder>*/}

                    </MainContentPlaceholder>

                </React.Fragment>
            }

        </Wrapper>
    );
}

const HrvImagePlaceholder = styled.div`
  padding: 80px;
  width: 100%;
  box-sizing: border-box;
`;

const HrvImage = styled.img`
  width: 100%;
`;

const SevenDaysChartPlaceholder = styled.div`
  margin-bottom: 20px;
  height: 200px;
`;

const RowPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 20px;
`;

const Half = styled.div`
  flex: 1;
  box-sizing: border-box;

  :first-of-type {
    padding-right: 20px;
  }

  :last-of-type {
    padding-right: 20px;
  }
`;

const HeartRateChartPlaceholder = styled.div`
  height: 200px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
`;

const CurrentDaySleepChartPlaceholder = styled.div`
  height: 200px;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

const MainContentHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopSleepHeadingName = styled.div`
  font-weight: bold;
`;

const MainContentPlaceholder = styled.div`
  margin-top: 40px;
`;

const Wrapper = styled.div`

`;

const YearPlaceholder = styled.div`

`;