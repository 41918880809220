import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import ls from "local-storage";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";

const PARAMETERS = [
  {
    label: "Raw Value",
    value: "raw",
    default: true,
  },
  {
    label: "Baseline",
    value: "baseline",
    default: false,
    disable: false,
  },
];
export default function UserAlarmBaseTool(props) {
  const { alarmsBase, onChange } = props;
  const handleCheckboxChange = (param, value) => {};

  const [selected, setSelected] = useState(alarmsBase || "raw");

  return (
    <div style={{ display: "flex" }}>
      {PARAMETERS.map((state, index) => {
        return (
          <div style={{ marginLeft: "10px", marginRight: "10px" }} key={index}>
            <Label className="setting-text-active" disable={state.disable}>
              <Checkbox
                id={state.value}
                name={`select-alarm-param-${index}`}
                type="checkbox"
                disabled={false}
                // checked={state.default}
                checked={state.value === selected}
                onChange={(e) => {
                  setSelected(state.value);
                  console.log(state.value);
                  //   console.log(state.label, state.value, e.target.checked);
                  handleCheckboxChange(state.value, e.target.checked);
                  onChange(state.value);
                }}
              />
              {state.label}
            </Label>
          </div>
        );
      })}
    </div>
  );
}

const Label = styled.div`
  // font-weight: bold;
  margin-bottom: 5px;
  opacity: ${(props) => (props.disable ? 0.5 : 0.9)};
`;

const Checkbox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;
