import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import useComponentSize from "@rehooks/component-size";

import './style.css'

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Area, Bar, BarChart
} from 'recharts';

export default function SimpleHistChart(props) {
    const {
        points = []
    } = props;

    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;

    let histData = [];
    const min = 300, step = 50, max = 1300;
    let from = min;
    while (from < max){
        let to = +from + +step;
        let k = points.filter(a => (+a.value >= from && +a.value < to)).length;
        histData.push({
            w: from,
            value: k
        });
        from = to;
    }

    return (
        <Wrapper ref={ref}>
            <ChartPlaceholder>
                <BarChart
                    width={width}
                    height={height}
                    data={histData}
                    margin={{top: 0, right: 0, left: -25, bottom: -5}}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="w" />
                    <YAxis/>
                    <Bar dataKey="value" fill="#8884d8"/>
                </BarChart>
            </ChartPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ChartPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
`;
