import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import BloodPressureAPI from "../../../api/BloodPressureAPI";
import BpSquareTool from "./BpSquareTool";
import DataStatusTool from "./DataStatusTool";
import NIBPOverviewTool from "./NIBPOverviewTool";
import corsano_logo_white from "../../../assets/images/cors_wide_logo.png";
import moment from "moment";
import footer_logo from "../../../assets/images/footer_logo.png";

export default function BpReportTool(props) {
  const { date, uuid } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    BloodPressureAPI.getBpReportData(uuid, date).then((pld) => {
      setData(pld);
      setLoading(false);
    });
  }, [date, uuid]);

  if (loading == true && data == undefined) {
    return <div>loading...</div>;
  }

  let { bpMeasurements = [] } = data || {};
  // console.log("MOment:", moment().diff("1962-12-30", "years"));

  const InfoTool = () => {
    return (
      <InfoWrapper>
        <div>
          <h1>CardioWatch</h1>
          <Subheading>
            <strong>NIBP</strong> <i>Report</i>{" "}
            <span style={{ fontSize: "14px" }}>
              ({moment(date).subtract(1, "days").format("YYYY MMM DD")} {" - "}
              {moment(date).format("YYYY MMM DD")})
            </span>
          </Subheading>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <img
            src={corsano_logo_white}
            style={{ marginLeft: "auto", width: "200px", marginBlock: "1rem" }}
          />
          <div>
            <div>
              <b>
                {data?.userInfo?.user?.first_name +
                  " " +
                  data?.userInfo?.user?.last_name}
              </b>
              <i> ({data?.userInfo?.health?.gender})</i>
            </div>
            <div>
              <InfoSpan>
                <b>Age: </b>
                {moment().diff(moment(data?.userInfo?.user?.birthday), "years")}
              </InfoSpan>
              <InfoSpan>
                <b>Height: </b>
                {data?.userInfo?.health?.height}cm
              </InfoSpan>
              <InfoSpan>
                <b>Weight: </b>
                {data?.userInfo?.health?.weight}kg
              </InfoSpan>
            </div>
            {/* <div>
              {moment(date).subtract(1, "days").format("YYYY MMM DD")} {" - "}
              {moment(date).format("YYYY MMM DD")}
            </div> */}
          </div>
        </div>
      </InfoWrapper>
    );
  };

  return (
    <Wrapper className="report-wrapper">
      <InfoTool />
      <NIBPOverviewTool data={data} date={date} />
      <BpSquareTool
        items={bpMeasurements
          .map((x) => ({ ...x, s: x?.sbp_ai, d: x?.dbp_ai }))
          .filter((x) => x?.s != undefined && x?.d != undefined)}
      />
      <DataStatusTool />
      <BpReportFooter />
      {/* <pre
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data, null, 2) }}
      ></pre> */}
    </Wrapper>
  );
}

const BpReportFooter = () => {
  return (
    <div
      style={{
        marginTop: "4rem",
        textAlign: "center",
      }}
    >
      <img src={footer_logo} />
      <div style={{ opacity: "50%" }}>
        Corsano Health B.V. - Wilhelmina van Pruisenweg 35 - 2595 AN The Hague -
        The Netherlands
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  background: white;
  width: 80%;
  margin: 0 auto;
  // padding-right: 20px;
  // padding-left: 20px;
`;

const InfoWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  aligh-items: center;
  position: relative;
  // width: 100%;
`;

const InfoSpan = styled.span`
  margin-right: 1rem;
`;
const Subheading = styled.p`
  font-size: 28px;
`;
