import { useEffect } from "react";
import styled from "styled-components";

import ls from "local-storage";
import CommonHelper from "../../helpers/CommonHelper";
import { useParams } from "react-router-dom";

function TokAuthApp(props) {
  let params = useParams();
  let { uToken, hToken } = params;

  useEffect(() => {
    ls("users_cloud_token", uToken);
    ls("health_cloud_token", hToken);
    CommonHelper.linkTo("/");
    window.location.reload();
  }, [uToken, hToken]);

  return <Wrapper>loading...</Wrapper>;
}

const Wrapper = styled.div``;

export default TokAuthApp;
