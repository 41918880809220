import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CardiolyseAPI from "../../../api/CardiolyseAPI";
import QrsChartTool from "./QrsChartTool";


export default function ViewPpg2RawChartTool(props) {
    const {
        pageSize = 256 * 2
    } = props;

    const [page, setPage] = useState(3);
    let {userUUID, measurementUUID, metric} = props;
    // console.log('ViewPpg2RawChartTool: ');

    // console.log('ViewPpg2RawChartTool: userUUID, measurementUUID, metric = ', userUUID, measurementUUID, metric);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const [points, setPoints] = useState([]);

    useEffect(() => {
        if (userUUID == undefined || measurementUUID == undefined){
            return;
        }
        setPoints([]);
        setLoading(true);
        setError(undefined);
        CardiolyseAPI.getMeasurementPpg2Points(userUUID, measurementUUID, metric).then(x => {
            setPoints(x);
            setLoading(false);
            setPage(3);
        }).catch(exc => {
            setLoading(false);
            setError('Can not process file');
        });
    }, [userUUID, measurementUUID]);


    if (error != undefined) {
        return (
            <div style={{color: 'red'}}>
                {error}
            </div>
        )
    }

    if (loading == true) {
        return (
            <Code/>
        )
    }

    let slicedPoints = points.slice((+page) * pageSize, (+page + 1) * pageSize);
    // console.log('page = ', page);
    // console.log('points, slicedPoints = ', points, slicedPoints);

    return (
        <Wrapper>

            <QrsChartTool
                offset={pageSize * page}
                points={slicedPoints.map(x => x.v)}
            />

            <BottomButtonsPlaceholder>
                <ArrSpan onClick={() => {
                    setPage(Math.max(0, +page - 1));
                }}>
                    {'<<<<'}
                </ArrSpan>
                <ArrSpan onClick={() => {
                    setPage(+page + 1);
                }}>
                    {'>>>>'}
                </ArrSpan>
            </BottomButtonsPlaceholder>

        </Wrapper>
    );
}

const ChBoxImg = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 10px;
`;

const FiltDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const BottomButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ArrSpan = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background: white;
  border-radius: 4px;
`;