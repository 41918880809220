// import InmatesAPI from '../../api/InmatesAPI'

import * as types from '../ActionTypes'
import CorsanoPatientsAPI from "../../api/CorsanoPatientsAPI";


const loadInmates_ = () => {
    return {
        type: types.LOAD_INMATES
    }
}
export const loadInmatesSuccess = (inmates) => {
    return {
        type: types.LOAD_INMATES_SUCCESS,
        inmates: inmates
    }
}
let loadInmatesFailed = (error) => {
    return {
        type: types.LOAD_INMATES_FAIL,
        error: error
    }
}

export function setForceInmate(inmate) {
    return (dispatch, getState) => {
        dispatch(loadInmatesSuccess([inmate]))
    }
}

//thunk
export function loadAllInmates(uuid) {
    return (dispatch, getState) => {
        dispatch(loadInmates_());
        return CorsanoPatientsAPI.getDoctorCorsanoPatients(uuid).then(
            inmates => dispatch(loadInmatesSuccess(inmates)),
            error => dispatch(loadInmatesFailed(error))
        )
    }
}

// export function getInmate(id) {
//     return (dispatch, getState) => {
//         dispatch(loadInmates_());
//         return CorsanoPatientsAPI.get(id).then(
//             proj => dispatch(loadInmatesSuccess([proj])),
//             error => dispatch(loadInmatesFailed(error))
//         )
//     }
// }

let createInmate_ = () => {
    return {
        type: types.CREATE_INMATE
    }
}
let createInmateSuccess = (inmate) => {
    return {
        type: types.CREATE_INMATE_SUCCESS,
        inmate: inmate
    }
}
let createInmateFailed = (error) => {
    return {
        type: types.CREATE_INMATE_FAIL,
        error: error
    }
}
//
// //thunk
export function createInmate(doctorUUID, data) {
    return (dispatch, getState) => {
        dispatch(createInmate_());
        return CorsanoPatientsAPI.createCorsanoPatient(doctorUUID, data).then(
            inmate => dispatch(createInmateSuccess(inmate)),
            error => dispatch(createInmateFailed(error))
        )
    }
}

//
let updateInmate_ = () => {
    return {
        type: types.UPDATE_INMATE
    }
}
let updateInmateSuccess = (inmate) => {
    return {
        type: types.UPDATE_INMATE_SUCCESS,
        inmate: inmate
    }
}
let updateInmateFailed = (error) => {
    return {
        type: types.UPDATE_INMATE_FAIL,
        error: error
    }
}

//thunk
export function updateInmate(data) {
    return (dispatch, getState) => {
        dispatch(updateInmate_());
        return CorsanoPatientsAPI.updateCorsanoPatient(data).then(
            inmate => dispatch(updateInmateSuccess(inmate)),
            error => dispatch(updateInmateFailed(error))
        )
    }
}

//

let deleteInmate_ = () => {
    return {
        type: types.DELETE_INMATE
    }
}
let deleteInmateSuccess = (id) => {
    return {
        type: types.DELETE_INMATE_SUCCESS,
        id: id
    }
}
let deleteInmateFailed = (error) => {
    return {
        type: types.DELETE_INMATE_FAIL,
        error: error
    }
}

//thunk
export function deleteInmate(id) {
    return (dispatch, getState) => {
        dispatch(deleteInmate_());
        return CorsanoPatientsAPI.deleteCorsanoPatient(id).then(
            () => dispatch(deleteInmateSuccess(id)),
            error => dispatch(deleteInmateFailed(error))
        )
    }
}
