import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import moment from "moment";
import axios from "axios";
import { API_ENDPOINT, CORSANO_API_ENDPOINT } from "../../../constants/config";

export default function AppLogsTool(props) {
  const { uuid, from, to } = props;

  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [loadingId, setLoadingId] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    setLogs([]);
    DoctorAPI.getAppLogs(uuid, from, to).then((x) => {
      setLogs(x);
      setLoading(false);
    });
  }, [uuid, from, to]);

  if (logs.length === 0 && loading == false) {
    return (
      <div className="normal-text">
        {`No logs for ${moment(from).format("YYYY-MM-DD HH:mm:ss")} - ${moment(
          to
        ).format("YYYY-MM-DD HH:mm:ss")}`}
      </div>
    );
  }

  return (
    <Wrapper>
      {logs.map((a, i) => {
        return (
          <Row>
            <RowName>
              {`${moment(a.timestamp).format("YYYY-MM-DD HH:mm:ss")} ${
                a.app_name
              } | ${a.os_name} | ${a.app_version}`}
            </RowName>
            <RowRight>
              <DownloadSpan
                onClick={async () => {
                  setLoadingId(a._id);
                  let pld = (
                    await axios.get(
                      `${CORSANO_API_ENDPOINT}/app-logs/${uuid}/files/${a._id}/download`
                    )
                  ).data;
                  window.open(pld.url, "_blank").focus();
                  setLoadingId(undefined);
                }}
              >
                {loadingId == a._id ? "..." : "download"}
              </DownloadSpan>
            </RowRight>
          </Row>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
`;

const DownloadSpan = styled.span`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }
`;

const RowName = styled.div`
  font-size: 14px;
  color: #000;
  margin-right: 10px;
`;

const RowRight = styled.div``;
