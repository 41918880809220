import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import useComponentSize from "@rehooks/component-size";

import './style.css'

import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Area, ReferenceDot
} from 'recharts';
import CardioHelper from "../../../helpers/CardioHelper";


const getGRadDefs = (ranges = [], id = 'colorUv', maxVal = 200) => {
    // console.log('getGRadDefs: ranges = ', ranges);
    // console.log('maxVal = ', maxVal);
    // console.log('ranges = ', ranges);

    if (ranges == undefined || ranges.length == 0) {
        return null;
    }
    let min = ranges.reduce((m, r) => (+r.from < +m ? +r.from : +m), ranges[0].from);
    let max = ranges.reduce((m, r) => (+r.to > +m ? +r.to : +m), ranges[0].to);
    let offs = ranges.map(a => ({value: a.from, color: a.color}))
        .concat(ranges.map(a => ({value: a.to, color: a.color}))).sort((a, b) => (+a.value - +b.value));
    let variationRange = +max - +min;

    // let offs = ranges.map(a => ({value: a.from, color: a.color}));

    // console.log('offs = ', offs);

    return (
        <defs>
            <linearGradient id={id} x1="0%" y1="100%" x2="0%" y2="0%">
                <stop offset="0%" stopColor={ranges[0].color}/>
                {offs.map((r, j) => {
                    return (
                        <React.Fragment key={j}>
                            <stop offset={`${Math.round(1000.0 * (+r.value / variationRange)) / 10.0}%`}
                                  stopColor={r.color}/>
                        </React.Fragment>
                    )
                })}
                <stop offset="100%" stopColor={ranges[ranges.length - 1].color}/>
            </linearGradient>
        </defs>
    )
}

export default function SimpleSelectableLineChart(props) {
    const {
        name = '',
        points = [], fromNumber = undefined, toNumber = undefined,
        hasOverlay = true,
        overlayBackground = `rgba(0, 0, 0, 0.01)`,
        xAxisMomentFormatString = 'HH:mm:ss',
        referenceDot = undefined,
        referenceDotSize = 20,
        referenceDotColor = 'red',
        timeMode = 'astro',
        t0 = 0,
        colorRanges = [],
        connectNulls = true
    } = props;
    // console.log('SimpleSelectableLineChart: render: colorRanges = ', colorRanges);

    const ref = useRef(null);
    let size = useComponentSize(ref);
    let {width, height} = size;
    let maxValue = points.reduce((m, p) => (+p.value > +m ? +p.value : +m), 0);
    let minValue = points.reduce((m, p) => (+p.value < +m ? +p.value : +m), 0);
    let hasSelection = (fromNumber != undefined && toNumber != undefined && +fromNumber < +toNumber);
    let from, to;
    if (hasSelection == true) {
        from = Math.max(0, fromNumber);
        to = Math.min(toNumber, points.length);
    }
    // console.log('render: referenceDot = ', referenceDot);
    // const t0 = (points.length == 0) ? 0 : points[points.length - 1].t;
    // console.log(`SimpleSelectableLineChart ${name}: size = ${width} x ${height}`);
    let fittedRanges = CardioHelper.getMappedRanges(colorRanges, minValue, maxValue);
    // if (colorRanges.length > 0) {
    //     maxValue = colorRanges[colorRanges.length - 1].to;
    // }

    // console.log('render: maxValue = ', maxValue);

    // console.log('SimpleSelectableLineChart: render: points = ', points);
    let xPoints = points.map((a, j) => ({
        ...a,
        value: (a.value == undefined) ? undefined : (+a.value).toFixed(1),
        t: +a.t - (timeMode == 'astro' ? 0 : t0),
        f: (+j < from || +j > to) ? undefined : Math.ceil(maxValue * 1.00)
    }));
    // console.log('SimpleSelectableLineChart: render: xPoints = ', xPoints);

    return (
        <Wrapper ref={ref}>
            {width == 0 || height == 0 ? null :
                <ChartPlaceholder width={width} height={height}>
                    <ComposedChart width={width} height={height}
                                   data={xPoints}
                                   margin={{top: hasSelection == true ? 10 : 40, right: 10, left: -25, bottom: -5}}
                    >
                        <XAxis tickCount={7}
                               domain={['auto', 'auto']}
                               interval={100} dataKey="t" tickFormatter={a => {
                            return (timeMode == 'astro' ? moment(a).format(xAxisMomentFormatString) : moment(a + +moment().startOf('day')).format(xAxisMomentFormatString))
                        }}/>
                        <YAxis domain={[0, Math.ceil(maxValue)]} type={'number'}/>
                        <Tooltip
                            labelFormatter={t => (timeMode == 'astro' ? moment(t).format('HH:mm:ss') : moment(t + +moment().startOf('day')).format('HH:mm:ss'))}/>
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                        {getGRadDefs(fittedRanges, 'colorUv')}
                        <Line animationDuration={0} dot={false}
                              connectNulls={connectNulls}
                              strokeWidth={2}
                              dataKey="value" stroke={colorRanges.length == 0 ? `#D92A3E` : `url(#colorUv)`}/>
                        {hasSelection == false ? null :
                            <Area animationDuration={0} dot={false} dataKey="f" fill="#8884d8"/>
                        }
                        {referenceDot &&
                        <ReferenceDot isFront={true} {...referenceDot} r={referenceDotSize} fill={referenceDotColor}
                                      stroke={'none'}/>}
                    </ComposedChart>
                </ChartPlaceholder>
            }


            {hasOverlay == false ? null :
                <Overlay overlayBackground={overlayBackground}>
                </Overlay>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ChartPlaceholder = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const Overlay = styled.div`
    position: absolute;
    background: ${props => props.overlayBackground};
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 2;
`;
