import styled from "styled-components";

import DoctorTemplate from "../templates/DoctorTemplate";
import CardiolyseWiffMeasurementTool from "../cardiolyse/tools/CardiolyseWiffMeasurementTool";
import { useParams } from "react-router-dom";

function BinaryMeasurementFileApp(props) {
  let params = useParams();
  let { userUUID, measurementUUID, t } = params;

  return (
    <DoctorTemplate active={"tools"}>
      <Wrapper>
        <CardiolyseWiffMeasurementTool
          userUUID={userUUID}
          measurementUUID={measurementUUID}
          t={t}
        />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div``;

export default BinaryMeasurementFileApp;
