import thermometer_0 from "../assets/images/badges/NEWS-Badges/thermometer_0.svg";
import thermometer_1 from "../assets/images/badges/NEWS-Badges/thermometer_1.svg";
import thermometer_2 from "../assets/images/badges/NEWS-Badges/thermometer_2.svg";
import thermometer_3 from "../assets/images/badges/NEWS-Badges/thermometer_3.svg";

import bp_0 from "../assets/images/badges/NEWS-Badges/bp_0.svg";
import bp_1 from "../assets/images/badges/NEWS-Badges/bp_1.svg";
import bp_2 from "../assets/images/badges/NEWS-Badges/bp_2.svg";
import bp_3 from "../assets/images/badges/NEWS-Badges/bp_3.svg";

import lungs_0 from "../assets/images/badges/NEWS-Badges/lungs_0.svg";
import lungs_1 from "../assets/images/badges/NEWS-Badges/lungs_1.svg";
import lungs_2 from "../assets/images/badges/NEWS-Badges/lungs_2.svg";
import lungs_3 from "../assets/images/badges/NEWS-Badges/lungs_3.svg";

import spo2_0 from "../assets/images/badges/NEWS-Badges/spo2_0.svg";
import spo2_1 from "../assets/images/badges/NEWS-Badges/spo2_1.svg";
import spo2_2 from "../assets/images/badges/NEWS-Badges/spo2_2.svg";
import spo2_3 from "../assets/images/badges/NEWS-Badges/spo2_3.svg";

import hr_0 from "../assets/images/badges/NEWS-Badges/heart_rate_0.svg";
import hr_1 from "../assets/images/badges/NEWS-Badges/heart_rate_1.svg";
import hr_2 from "../assets/images/badges/NEWS-Badges/heart_rate_2.svg";
import hr_3 from "../assets/images/badges/NEWS-Badges/heart_rate_3.svg";
import {
  NO_PRIORITY,
  PR_1,
  PR_2,
  PR_3,
} from "../components/ranges/new_tools/NewRangeTool";

export const ConsciousnessLevels = {
  A: {
    label: "A",
    point: 0,
    color: "#CBCCCB",
  },
  C: {
    label: "C",
    point: 3,
    color: "#EB9079",
  },
  V: {
    label: "V",
    point: 3,
    color: "#EB9079",
  },
  P: {
    label: "P",
    point: 3,
    color: "#EB9079",
  },
  U: {
    label: "U",
    point: 3,
    color: "#EB9079",
  },
};

const NewsScoreHelper = {
  /*
      // some ideas and suggestions

          PR_1 = 10
          PR_2 = 9
          PR_3 = 8
          NO_PRIORITY = 0
     */
  PR_TO_NEWS: [
    { ...PR_1, newsScore: 3 },
    { ...PR_2, newsScore: 2 },
    { ...PR_3, newsScore: 1 },
    { ...NO_PRIORITY, newsScore: 0 },
  ],

  getRespirationNewsScore(respirationAlarmPriority) {
    if (!respirationAlarmPriority) {
      return 0;
    }
    return this.PR_TO_NEWS.find((x) => x.priority === respirationAlarmPriority)
      ?.newsScore;
  },

  getPulseRateNewsScore(heartRateAlarmPriority) {
    if (!heartRateAlarmPriority) {
      return 0;
    }
    //todo(Z)
    return this.PR_TO_NEWS.find((x) => x.priority === heartRateAlarmPriority)
      ?.newsScore;
  },

  getSpo2NewsScore(spo2AlarmPriority) {
    if (!spo2AlarmPriority) {
      return 0;
    }
    return this.PR_TO_NEWS.find((x) => x.priority === spo2AlarmPriority)
      ?.newsScore;
  },

  getBloodPressureNewsScore(bloodPressureAlarmPriority) {
    if (!bloodPressureAlarmPriority) {
      return 0;
    }
    return this.PR_TO_NEWS.find(
      (x) => x.priority === bloodPressureAlarmPriority
    )?.newsScore;
  },

  getTemperatureNewsScore(temperatureAlarmPriority) {
    if (!temperatureAlarmPriority) return 0;
    return this.PR_TO_NEWS.find((x) => x.priority === temperatureAlarmPriority)
      ?.newsScore;
    // return 0;
  },

  /*
      Alarms:

      "pulse_rate": 0
      "respiration_rate": 0,
      "spo2": 10,
      "temperature": 8,
      "blood_pressure": 10,
     */
  getNewsScoreData(alarmsPrioritiesMap = {}) {
    let respirationScore = this.getRespirationNewsScore(
      alarmsPrioritiesMap["respiration_rate"]
    );
    let pulseRateScore = this.getPulseRateNewsScore(
      alarmsPrioritiesMap["pulse_rate"]
    );
    let spo2Score = this.getSpo2NewsScore(alarmsPrioritiesMap["spo2"]);
    let temperatureScore = this.getTemperatureNewsScore(
      alarmsPrioritiesMap["temperature"]
    );
    let bloodPressureScore = this.getBloodPressureNewsScore(
      alarmsPrioritiesMap["blood_pressure"]
    );
    // here comes the logic to get the total score
    let totalScore =
      +respirationScore +
      +pulseRateScore +
      +spo2Score +
      +temperatureScore +
      +bloodPressureScore; // please doublecheck

    const result = {
      total: totalScore ? totalScore : 0,

      pulse_rate: pulseRateScore,
      spo2: spo2Score,
      respiration_rate: respirationScore,
      blood_pressure: bloodPressureScore,
      temperature: temperatureScore,

      total_color: "todo",

      pulse_rate_color: this.getColorByScore(pulseRateScore),
      spo2_color: this.getColorByScore(spo2Score),
      respiration_rate_color: this.getColorByScore(respirationScore),
      blood_pressure_color: this.getColorByScore(bloodPressureScore),
      temperature_color: this.getColorByScore(temperatureScore),

      // total_sound: getSoundFromCombinedScore(totalScore),
    };
    // console.log(("NEWS RESULT", result));
    const _agg = [
      respirationScore,
      spo2Score,
      bloodPressureScore,
      pulseRateScore,
      temperatureScore,
      0,
      0,
    ];

    const [newsScore, notes] = this.processScore(_agg);
    result["level"] = newsScore;
    result["note"] = notes;

    return result;
  },

  /*
      ----- end of section
     */

  getSoundFromCombinedScore(score) {
    if (score === 5 || score === 6) {
      return "medium_pitch_sound";
    } else if (score >= 7) {
      return "high_pitch_sound";
    } else {
      return "";
    }
  },

  getNews2ScoreFromParams(params) {
    const brpmScore = this.getRRScore(params.brpm);
    const spo2Score = this.getSpo2Score(params.spo2);
    const bpScore = this.getBPScore(params.bp);
    const pulseScore = this.getPulseScore(params.pulse);
    const tempScore = this.getTempScore(params.temp);
    const consciousnessScore = this.getConsciousnessScore(params.consciousness);
    const airOxygenScore = this.getAirOrOxygenScore(params.airoroxygen);
    const _agg = [
      brpmScore,
      spo2Score,
      bpScore,
      pulseScore,
      tempScore,
      consciousnessScore,
      airOxygenScore,
    ];

    const [newsScore, notes] = this.processScore(_agg);

    const sum = brpmScore + spo2Score + bpScore + pulseScore + tempScore;
    return newsScore;
    // return {
    //     combined: sum,
    //     value: newsScore,
    // };
  },

  _getNews2ScoreFromParams(params) {
    const brpmScore = this.getRRScore(params.brpm);
    const spo2Score = this.getSpo2Score(params.spo2);
    const bpScore = this.getBPScore(params.bp);
    const pulseScore = this.getPulseScore(params.pulse);
    const tempScore = this.getTempScore(params.temp);
    const consciousnessScore = this.getConsciousnessScore(params.consciousness);
    const airOxygenScore = this.getAirOrOxygenScore(params.airoroxygen);
    const _agg = [
      brpmScore,
      spo2Score,
      bpScore,
      pulseScore,
      tempScore,
      consciousnessScore,
      airOxygenScore,
    ];

    const newsScore = this.processScore(_agg);
    const sum = brpmScore + spo2Score + bpScore + pulseScore + tempScore;
    return {
      combined: sum,
      value: newsScore,
    };
  },
  getScoreByType(type, pr) {
    switch (type) {
      case "brpm":
      case "respiration_rate":
        // return this.getRRScore(param);
        return this.getRespirationNewsScore(pr);
      case "spo2":
        // return this.getSpo2Score(param);
        return this.getSpo2NewsScore(pr);
      case "spo2_2":
        // return this.getSpo22Score(param);
        return 0;
      case "pulse":
      case "heart_rate":
        // return this.getPulseScore(param);
        return this.getPulseRateNewsScore(pr);
      case "bp":
        // return this.getBPScore(param);
        return this.getBloodPressureNewsScore(pr);
      case "temp":
      case "temperature":
        // return this.getTempScore(param);
        return this.getTemperatureNewsScore(pr);
      case "consciousness":
        // return this.getConsciousnessScore(param);
        return 0;
      case "airoroxygen":
        // return this.getAirOrOxygenScore(param);
        return 0;
      default:
        return 0;
    }
  },

  processScore(scores) {
    let sum = 0;
    let maxExceedsThree = false;

    for (let i = 0; i < scores.length; i++) {
      if (isNaN(scores[i])) {
        continue;
      }
      let num = Number(scores[i]);
      if (num >= 3) {
        maxExceedsThree = true;
      }
      sum += num;
    }

    if (sum >= 5 && sum <= 6) {
      return [2, "Aggregate NEWS2 Score is 5 or 6"];
    }
    if (sum >= 7) {
      return [3, "Aggregate NEWS2 Score >= 7"];
    }
    if (sum >= 1 && sum <= 4) {
      if (maxExceedsThree) {
        return [1, "One parameter is 3"];
      }
      return [0, "Aggregate NEWS2 Score <= 4"];
    } else {
      return [0, "Aggregate News2 Score is 0"];
    }
  },

  getConsciousnessScore(consciousness) {
    if (consciousness === "N/A") {
      return "N/A";
    }
    if (consciousness.toLowerCase() === "cvpu") {
      return 3;
    } else if (consciousness.toLowerCase() === "alert") {
      return 0;
    } else {
      return 0;
    }
  },

  getAirOrOxygenScore(aoo) {
    if (aoo === "N/A") {
      return "N/A";
    }
    if (aoo.toLowerCase() === "oxygen") {
      return 2;
    } else if (aoo.toLowerCase() === "air") {
      return 0;
    } else {
      return 0;
    }
  },
  getTempScore(temp) {
    if (isNaN(temp)) {
      return 0;
    }
    const _temp = Number(temp);
    if (_temp <= 35.0) {
      return 3;
    } else if (_temp > 35 && _temp <= 36.0) {
      return 1;
    } else if (_temp > 36 && _temp <= 38.0) {
      return 0;
    } else if (_temp > 38 && _temp <= 39.0) {
      return 1;
    } else if (_temp >= 39.1) {
      return 2;
    } else {
      return 0;
    }
  },

  getPulseScore(pulse) {
    if (isNaN(pulse)) {
      return 0;
    }
    const _pulse = Number(pulse);
    if (_pulse <= 40) {
      return 3;
    } else if (_pulse > 40 && _pulse <= 50) {
      return 1;
    } else if (_pulse > 50 && _pulse <= 90) {
      return 0;
    } else if (_pulse > 90 && _pulse <= 110) {
      return 1;
    } else if (_pulse > 110 && _pulse < 131) {
      return 2;
    } else if (_pulse >= 131) {
      return 3;
    } else {
      return 0;
    }
  },

  getBPScore(bp) {
    if (isNaN(bp)) {
      return 0;
    }
    const _bp = Number(bp);

    if (_bp > 0 && _bp <= 90) {
      return 3;
    } else if (_bp > 90 && _bp <= 100) {
      return 2;
    } else if (_bp > 100 && _bp <= 110) {
      return 1;
    } else if (_bp > 110 && _bp < 219) {
      return 0;
    } else if (_bp >= 220) {
      return 3;
    } else {
      return 0;
    }
  },
  getSpo2Score(spo2) {
    // scale 1
    if (isNaN(spo2)) {
      return 0;
    }
    const _spo2 = Number(spo2);
    if (_spo2 <= 91) {
      return 3;
    } else if (_spo2 <= 93 && _spo2 > 91) {
      return 2;
    } else if (_spo2 < 96 && _spo2 > 93) {
      return 1;
    } else if (_spo2 >= 96) {
      return 0;
    } else {
      return 0;
    }
  },

  getSpo22Score(spo2_2) {
    if (spo2_2 === "N/A") {
      return "N/A";
    }
    return 0;
  },

  getRRScore(rr) {
    if (isNaN(rr)) {
      return 0;
    }
    const _rr = Number(rr);
    if (_rr >= 25) {
      return 3;
    } else if (_rr < 25 && _rr > 21) {
      return 2;
    } else if (_rr <= 21 && _rr > 12) {
      return 0;
    } else if (_rr <= 12 && _rr > 8) {
      return 1;
    } else if (_rr <= 8) {
      return 3;
    } else {
      return 0;
    }
  },

  getColorByScore(score) {
    if (score === undefined) {
      return "white";
    }
    if (score === 3) {
      return "#EB9079";
    } else if (score === 2) {
      return "#F6C387";
    } else if (score === 1) {
      return "#FFF0A7";
      // return "#aaa914";
    } else if (score === 0) {
      return "#CBCCCB";
    } else {
      return "white";
    }
  },

  getBadgeImage(type, score) {
    if (type === "temperature" || type === "temp") {
      if (score == 3) {
        return thermometer_3;
      }
      if (score == 2) {
        return thermometer_2;
      }
      if (score == 1) {
        return thermometer_1;
      }
      return thermometer_0;
    }
    if (type === "pulse" || type === "heart_rate") {
      if (score == 3) {
        return hr_3;
      }
      if (score == 2) {
        return hr_2;
      }
      if (score == 1) {
        return hr_1;
      }
      return hr_0;
    }
    if (type == "bp") {
      if (score == 3) {
        return bp_3;
      }
      if (score == 2) {
        return bp_2;
      }
      if (score == 1) {
        return bp_1;
      }
      return bp_0;
    }

    if (type === "brpm" || type === "respiration_rate") {
      if (score == 3) {
        return lungs_3;
      }
      if (score == 2) {
        return lungs_2;
      }
      if (score == 1) {
        return lungs_1;
      }
      return lungs_0;
    }
    if (type == "spo2") {
      if (score == 3) {
        return spo2_3;
      }
      if (score == 2) {
        return spo2_2;
      }
      if (score == 1) {
        return spo2_1;
      }
      return spo2_0;
    }
  },
};

export default NewsScoreHelper;
