import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import NotesAPI from "../../../api/NotesAPI";
import NoteForm from "../forms/NoteForm";
import UpdateNoteForm from "../forms/UpdateNoteForm";
import NiceModal from "../../modals/NiceModal";

import pencilImage from './clarity_edit-solid.svg'

export default function PatientNotesPanel(props) {
    const {
        id,
        doctorName = '',
        isDemo = false,
        dayTimestamp
    } = props;

    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        setNotes([]);
        let from = +moment(dayTimestamp).startOf('day') - 1;
        let to = +moment(dayTimestamp).endOf('day') + 1;
        NotesAPI.getPatientNotes(id, from, to).then(arr => {
            setNotes(arr);
            setLoading(false);
        });
    }, [id, dayTimestamp]);

    if (notes.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    let sortedNotes = notes.sort((a, b) => (+b.timestamp - +a.timestamp));
    let selectedNote = (selectedId == undefined) ? undefined : notes.filter(x => (x.id == selectedId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <NoteForm
                
                    loading={loading}
                    buttonName={'Create note'}
                    onSave={async d => {
                        if (isDemo == true){
                            return window.alert('This is a demo portal. For full access please contact admin.');
                        }
                        let data = {
                            ...d,
                            doctorName: doctorName,
                            dayTimestamp: dayTimestamp
                        };
                        await NotesAPI.createNote(id, data);
                        let arr = await NotesAPI.getPatientNotes(id, +moment(dayTimestamp).startOf('day'), +moment(dayTimestamp).endOf('day'));
                        setNotes(arr);
                    }}/>
            </TopPlaceholder>

            <NotesListPlaceholder>
                {sortedNotes.map((note, i) => {
                    return (
                        <NoteItem key={note.id}>
                            <NoteUserPlaceholder>
                                <div>
                                    <NoteName>
                                        {/*{note.doctorName}*/}
                                        {moment(note.dayTimestamp).format('D MMMM YYYY')}
                                    </NoteName>
                                    <NoteDate>
                                        {`created ${moment(note.timestamp).format('DD.MM.YYYY HH:mm')}`}
                                    </NoteDate>
                                </div>
                            </NoteUserPlaceholder>
                            <NoteContentPlaceholder>
                                {note.text}
                            </NoteContentPlaceholder>
                            <NoteEditIcon src={pencilImage} onClick={() => {
                                setSelectedId(note.id);
                            }} />
                        </NoteItem>
                    )
                })}
            </NotesListPlaceholder>

            {selectedNote == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedId(undefined);
                }}>

                    <DialogInner2>

                        <UpdateNoteHeading>
                            {moment(selectedNote.dayTimestamp).format('YYYY-MM-DD')}
                        </UpdateNoteHeading>

                        <UpdateNoteForm {...selectedNote}
                                  loading={loading}
                                  buttonName={'Update note'}
                                  onSave={async d => {
                                      if (isDemo == true){
                                          return window.alert('This is a demo portal. For full access please contact admin.');
                                      }
                                      setLoading(true);
                                      if (d.text == undefined || d.text == '') {
                                          await NotesAPI.deleteNote(selectedNote.id);
                                      } else {
                                          await NotesAPI.updateNote({...d, id: selectedNote.id});
                                      }
                                      let arr = await NotesAPI.getPatientNotes(id);
                                      setNotes(arr);
                                      setSelectedId(undefined);
                                      setLoading(false);
                                  }}/>
                    </DialogInner2>

                </NiceModal>
            }

        </Wrapper>
    );
}

const UpdateNoteHeading = styled.div`
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  color: #000F4B;
  margin-bottom: 10px;
`;

const DialogInner = styled.div`
  width: 720px;
`;

const DialogInner2 = styled.div`
  width: 600px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 20px;
`;

const NotesListPlaceholder = styled.div`

`;

const NoteDate = styled.div`
  font-size: 14px;
  line-height: 32px;
  color: #8798AD;
`;

const NoteItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  background: white;
  border: 1px solid rgba(135, 152, 173, 0.1);
`;

const NoteContentPlaceholder = styled.div`
  flex: 1;
  font-size: 16px;
  line-height: 28px;
  color: #8798AD;
  mix-blend-mode: normal;
  opacity: 0.8;
`;

const NoteEditIcon = styled.img`
  height: 22px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const NoteUserPlaceholder = styled.div`
  width: 220px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NoteName = styled.div`
  color: #000F4B;
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
`;

const Wrapper = styled.div`

`;

const AvatarCircle = styled.div`
  background: #000F4B;
  width: 70px;
  height: 70px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;