import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  DOT_STROKE,
  AXES_STROKE_WIDTH,
  GRID_STROKE_COLOR,
  xaxisTimeFormatter,
  LINE_COLORS,
  GRID_THICKNESS,
  GRID_DASH_ARRAY,
  GRID_GAP_COLOR,
} from "../ui/templates";
import {
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../helpers/BaselineHelper";
import {
  GRID_GAP_DASH_ARRAY,
  GRID_GAP_THICHNESS,
  GRID_THICHNESS_GAP,
} from "../ui/templates/Charts";

const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "weighted",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  // {
  //   value: "adpt",
  //   label: "Adaptive",
  //   color: LINE_COLORS.adaptive,
  //   width: 1,
  //   show: true,
  // },
  // {
  //   value: "gap",
  //   label: "Gap",
  //   show: true,
  //   color: LINE_COLORS.gap,
  //   bstyle: "dashed",
  // },
];
const fillMissingSpots = (data, from) => {
  if (!data || data.length === 0) {
    return;
  }
  const zeroPoint = data[0].t;
  let _m = zeroPoint;
  let emptySpots = [];
  for (let i = 0; i < data.length - 3; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let gap = moment(nextPoints).diff(currentPoints, "minutes");

    if (gap > 10) {
      let newSlot = moment(currentPoints).add(10, "minutes").valueOf();
      emptySpots.push({
        raw: null,
        t: newSlot,
      });
    } else {
      emptySpots.push(data[i]);
    }

    // let match = data.find((x) => x.slot === _m);
    // if (!match) {
    //   const missingPoint = {
    //     slot: _m,
    //     bpm: null,
    //   };
    //   emptySpots.push(missingPoint);
    // } else {
    //   emptySpots.push(match);
    // }
  }
  return emptySpots;
};

const calculateMovingAverage = (data, window) => {
  const length = data.length;
  let spotsWithMaAndAdaptive = [];
  for (let i = 0; i <= length - 1; i++) {
    const currentVal = data[i].temp_sk1;
    const prevVal = i > 0 ? data[i - 1].temp_sk1 : currentVal;
    if (currentVal) {
      let hasValid = false;
      let t0 = moment(data[i].slot);
      let _arr = [];
      let startInd = i >= 80 ? i - 80 : 0;
      for (let j = startInd; j <= i; j++) {
        let df = t0.diff(moment(data[j].slot), "minutes");
        if (df < window) {
          _arr = data.slice(j, i + 1);
          break;
        }
      }

      let _ma = BaselineHelper.calculateMAFromArray(_arr, "temp_sk1");
      let _wma = BaselineHelper.calculateBWMAFromArray(
        _arr,
        "temp_sk1",
        t0,
        currentVal - prevVal
      );
      // console.log(`maa ${i}, ${_ma}`);
      if (_ma === 0) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
        });
        continue;
      }

      let adpt = null;
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: _ma,
        adpt: adpt,
        test: _wma,
      });
    } else {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
      });
    }
  }
  return spotsWithMaAndAdaptive;
};

const PatientDayTemperatureBaselineTool = (props) => {
  let {
    uuid,
    date,
    hasNoData,
    theme,
    unit,
    baselineWindow = 15,
    tempPoints = [],
    loading = false,
    tz,
  } = props;
  const [ref, { x, y, width, height }] = useDimensions();
  //   const [loading, setLoading] = useState(false);
  const [TemperatureRawData, setTemperatureRawData] = useState([]);
  const [TemperatureProcessedData, setTemperatureProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
  });
  const [dataLength, setDataLength] = useState(0);

  const windowSize = 10;
  let windowSec = windowSize;

  let from = moment(date).startOf("day").valueOf();
  let to = moment(date).endOf("day").valueOf();
  let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
  let dataTo = moment(date).endOf("day").subtract(1, "hour").valueOf();

  useEffect(() => {
    setTemperatureProcessedData([]);
    // setLoading(true);
    // DoctorAPI.getAggregatedPointsWithAbsoluteTime(
    //   console.log(`raw windowSec: ${windowSec} temperature data from api`, d);
    let d = tempPoints;
    if (!d || d.length === 0) {
      return;
    }
    setTemperatureRawData(d);

    let _Temperature = d;
    setTemperatureProcessedData(_Temperature);
    setDataLength(_Temperature.filter((x) => x.temp_sk1).length);
    //   setLoading(false);
  }, [date, tempPoints]);

  useEffect(() => {
    // setLoading(true);
    setTemperatureProcessedData([]);
    if (!TemperatureRawData || TemperatureRawData.length === 0) {
      //   setLoading(false);
      return;
    }
    let _temperature = TemperatureRawData;
    let _t0 = performance.now();

    _temperature = calculateMovingAverage(
      TemperatureRawData,
      baselineWindow,
      delta,
      kValue
    );
    _temperature = _temperature.map((s) => {
      return {
        ...s,
        t: moment(s.slot).valueOf(),
      };
    });
    _temperature = fillMissingSpots(_temperature);
    // console.log("_temp", _temperature);

    let elapsed = performance.now() - _t0;
    setTemperatureProcessedData(_temperature);
    setPtime(elapsed.toFixed(3));
    // setLoading(false);
    console.log("ELAPSED TIME", elapsed, "mm");
  }, [TemperatureRawData, kValue, delta]);

  let extraLines = DrawHelper.getExtraDottedChartsData(
    TemperatureProcessedData,
    "temp_sk1"
  );
  let TemperatureWithGaps = TemperatureProcessedData;
  for (let sp of TemperatureWithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`temp_sk1_gap_${el}`] =
          extraLines[el]["points"][0][`temp_sk1_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`temp_sk1_gap_${el}`] =
          extraLines[el]["points"][1][`temp_sk1_gap_${el}`];
      }
    }
  }
  let yticks = [];

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let min = startOfDay;
  let max = startOfNextDay;
  let tickNum = 24;

  let xticks;

  let _min;
  let _max;
  if (tz !== undefined) {
    // xticks = BaselineHelper.getXaxisTicksEachHourWithTz(min, tz);
    xticks = BaselineHelper.getXaxisTicksEachHour(min);
    _min = moment(min).add(tz, "hours").valueOf();
    _max = moment(max).add(tz, "hours").valueOf();
  } else {
    xticks = [];
    for (let i = 0; i < tickNum; i++) {
      xticks.push(moment(min).add(i, "hour").valueOf());
    }
    _min = min;
    _max = max;
  }

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Calculating..."}
        showTopImg={false}
      />
    );
  }

  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }

  return (
    <Wrapper ref={ref}>
      {TemperatureProcessedData.length === 0 ? (
        <SimpleNoDataMessageTool
          loadind={true}
          message={"Fetching data..."}
          showTopImg={false}
        />
      ) : (
        <div>
          <ComposedChart
            width={width}
            height={height}
            data={TemperatureProcessedData}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              dataKey={"t"}
              ticks={xticks}
              tickSize={10}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              //   domain={[_min, _max]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"temp_sk1"}
              //   ticks={yticks}
              //   domain={([min, max]) => [35, 42]}
              domain={([min, max]) => [33, 42]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
              tickFormatter={(x) =>
                unit === "F" ? (+x * 1.8 + 32.0).toFixed(1) : x
              }
            />
            <Tooltip content={<TemperatureTooltip unit={unit} />} />
            <Line
              type="monotone"
              dataKey="test"
              name="Baselien"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              activeDot={true}
              connectNulls={true}
              hide={showLine["weighted"] ? false : true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="adpt"
              name="Adaptive"
              stroke={LINE_COLORS.adaptive}
              connectNulls={true}
              // strokeDasharray="5 5"
              // hide={showLine["adpt"] ? false : true}
              hide={true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="temp_sk1"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              connectNulls={false}
              // strokeDasharray="5 5"
              hide={showLine["raw"] ? false : true}
              //   dot={DOT_STROKE}
              dot={false}
              isAnimationActive={false}
            />
            {extraLines.map((l, i) => {
              return (
                <Line
                  key={i}
                  type={"monotone"}
                  dataKey={`temp_sk1_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={GRID_GAP_THICHNESS}
                  strokeDasharray={GRID_GAP_DASH_ARRAY}
                  stroke={GRID_GAP_COLOR}
                  dot={false}
                  name={"D"}
                  legendType="none"
                  connectNulls={true}
                  //   hide={showLine["raw"] ? false : true}
                  hide={true}
                />
              );
            })}
          </ComposedChart>
          <LineFilterContainer>
            {LINES.map((f, i) => {
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel
                    $color={f.color}
                    $bstyle={f?.bstyle ? f.bstyle : "solid"}
                    theme={theme}
                  >
                    {f.label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}
    </Wrapper>
  );
};

export default PatientDayTemperatureBaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  position: relative;
`;

const TemperatureTooltip = ({ active, payload, label, unit }) => {
  // console.log("unit", unit);

  if (active && payload && payload.length) {
    let temp = payload[0].payload.temp_sk1 ? payload[0].payload.temp_sk1 : null;
    let tempString;
    if (temp) {
      if (unit === "F") tempString = (+temp * 1.8 + 32.0).toFixed(1);
      else {
        tempString = temp;
      }
    } else {
      tempString = "null";
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`Raw Temperature: ${tempString}`}</TooltipRow>
        <TooltipRow className="label">{`Baseline: ${payload[0].payload.test}`}</TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
};

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.$theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.$color};
    border-style: ${(props) => props.$bstyle};
    // border-top: 1rem solid ${(props) => props.$color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;
