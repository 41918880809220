import { useState } from "react";
import styled from "styled-components";
import NiceModal from "../../modals/NiceModal";

import flagsScreenshot from "../../../assets/images/flagsScreenshot.png";
import news2Screenshot from "../../../assets/images/news2Screenshot.png";
import news2Screenshot2 from "../../../assets/images/news2Screenshot2.png";
import infoImg from "./info.svg";

export default function InfoToolBadgeGrey(props) {
  const { type = "", width = 40, height = 40, showText = false, show = true } = props;

  const [visible, setVisible] = useState(false);

  let d = getData(type);

  let { title, content } = d;

  return (
    <Wrapper style={{ display: show ? "" : "none" }}>
      <IconPlaceholder
        onClick={() => {
          setVisible(true);
        }}
      >
        <Box
          style={{
            width: width,
            height: height,
            opacity: "50%",
          }}
        >
          <Icon src={infoImg} className="bp-info-icon" />
        </Box>
        {showText ? <InfoSpan className="bp-info-span">Info</InfoSpan> : null}
      </IconPlaceholder>

      {visible === false ? null : (
        <NiceModal
          onClose={() => {
            console.log("setvisible false");

            setVisible(false);
          }}
        >
          <DialogInner>
            {title === undefined || title === "" ? null : <Heading>{title}</Heading>}
            <ContentPlaceholder>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </ContentPlaceholder>
          </DialogInner>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const Icon = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Box = styled.div`
  border: 1px solid rgba(135, 152, 173, 0.1);
  -webkit-filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  // width: 40px; height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const IconPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InfoSpan = styled.div`
  color: rgb(20, 122, 255);
  cursor: pointer;
  font-weight: 300;
  margin-right: 15px;
`;

const DialogInner = styled.div`
  width: 620px;
  padding: 10px;
  box-sizing: border-box;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  color: #000f4b;
`;

const ContentPlaceholder = styled.div`
  font-family: sans-serif;
  font-weight: 400;
`;

const Wrapper = styled.div``;

const getData = (type) => {
  let d = INFO_DATA[type];
  if (d == undefined) {
    return DEFAULT;
  }
  return d;
};

const DEFAULT = {
  title: "No data",
  content: `<p>No information</p>`,
};

const INFO_DATA = {
  ALARM_SYSTEM_SETTINGS: {
    title: "Web Portal Alarms ",
    content: `

    <div style="margin: 0 auto; text-align: left">
        <p>Patients within all healthcare and community settings may be at risk of physiological deterioration. Early detection, timeliness and competency of response to changes in clinical observations are important determinants of clinical outcomes.</p>
        <p>There are many published physiological alarm systems, which can be separated into single-parameter “track and trigger” systems, and multiple-parameter systems. These systems use a scoring table, which assigns a pre-defined risk score to each vital sign measurement.</p>
        <ul>
        <li><i>Churpek MM, Yuen TC, Edelson DP. Risk stratification of hospitalized patients on the wards. Chest. 2013;143(6):1758-1765.</i></li>
        </ul>
        <p>Corsano System implemented alarms based on single parameter track and trigger <b>Between the flags (BTF)</b>. A single parameter will be the trigger.</p>
        <ul>
        <li><i>Green M, Lander H, Snyder A, Hudson P, Churpek M, Edelson D. Comparison of the Between the Flags calling criteria to the MEWS, NEWS and the electronic Cardiac Arrest Risk Triage (eCART) score for the identification of deteriorating ward patients. Resuscitation. 2018; 123:86-91.</i></li>
        </ul>
        <p>Defaults Alarm Limits (Figure 10 23 below) used in the Corsano System are derived from the NEWS2 score, which is one of the most-validated scores.</p>
        <ul> <li><i>Bein B, Seewald S, Gräsner JT. How to avoid catastrophic events on the ward. Best practice & research. Clinical Anaesthesiology. 2016; 30(2), 237-245.</i></li> </ul>


         
        <div style="display: flex; flex-direction: column; text-align: center">
            <img src=${flagsScreenshot} width="100%"/>
            <div style="font-size: 0.9em">Example of Corsano Web Portal Alarm Patient Limits Settings</div>
        </div>
        
        <p>Where : </p>
        <ul>
        <li>High priority alarms are colored RED</li>
        <li>Medium priority alarms are colored YELLOW</li>
        <li>Low priority alarms are colored CYAN</li>
        </ul>
        
        <p>HCP can adapt from the system Physiological Default Settings and save alarm limits specific for each Patient.</p>
        
        <p>When a Physiological alarm condition is met, the Corsano Web Portal generates an alarm. The alarm is also sent to the Corsano Cloud and pushed to the associated Corsano App (HCP Mode), when the HCP has to walk away from the Corsano Web Portal. </p>
    </div>
    <br/>

    <div style="margin: 0 auto; text-align: left">     
       <h1>NEWS2</h1>
        <p>The National Early Warning Score (NEWS) 2 determines the degree of illness of a patient and prompts critical care intervention. A full set of observations should always include:</p>
        <ul>
        <li><b>Respiration rate</b></li>
        <li><b>Oxygen saturation</b></li>
        <li><b>Systolic blood pressure</b></li>
        <li><b>Pulse rate</b></li>
        <li><b>Level of consciousness</b></li>
        <li><b>Temperature</b></li>
        </ul>
        <div style="display: flex; flex-direction: column; text-align: center">
            <img src=${news2Screenshot} width="100%"/>
            <div style="font-size: 0.9em">Individual Parameter Scores NEWS2 Scoring System</div>
        </div>
        <p>The individual parameter scores should then be added up to derive the aggregate NEWS2 score for the patient.</p>
        <div style="display: flex; flex-direction: column; text-align: center">
            <img src=${news2Screenshot2} width="100%"/>
            <div style="font-size: 0.9em">Aggregate Scores NEWS2 Thresholds and Triggers</div>
        </div>


<p>Assess whether the score needs any response: There are four trigger levels for a clinical alert requiring clinician assessment based on the NEWS2:</p>
<ul>
    <li><b>LOW score:</b> an aggregate NEWS2 score of 1–4</li>
    <li><b>A single RED score:</b> an extreme variation in an individual physiological parameter (a score of 3 in any one parameter, which is colour-coded <b>RED</b> on the NEWS2 chart)</li>
    <li><b>MEDIUM score:</b> an aggregate NEWS2 score of 5 or 6. <b>A NEWS2 score of 5 or more is a key threshold</b> and is indicative of potential serious acute clinical deterioration and the need for an urgent clinical response</li>
    <li><b>HIGH score:</b> an aggregate NEWS2 score of 7 or more</li>
</ul>

<p>These triggers should determine the urgency of the clinical response and the clinical competency of the responder, bearing in mind that physiological abnormalities due to long term conditions should be considered at admission.</p>
<ul>
    
    <li><b>A LOW score (NEW score 1–4)</b> should prompt assessment by a competent registered clinician who should decide if a change to frequency of clinical monitoring or an escalation of clinical care is required.</li>
    <li><b>A MEDIUM score (i.e. NEW score of 5–6 or a RED score)</b> should prompt an <b>urgent (within 1 hour) review by a clinician </b>skilled with competencies in the assessment of acute illness which will be either the Senior Clinician with appropriate skills, GP responsible for the ward, Urgent Care Doctor, Nurse Practitioner or Specialist Paramedic Practitioner with relevant experience, who should consider whether escalation of care to an acute hospital is required. To ensure clinical cover 24/7, this role will be undertaken by an Out of Hours doctor, nurse or emergency care practitioner.</li>
    <li><b>A HIGH score (NEW score of 7 or more)</b> should prompt <b>emergency assessment (immediate) and/ or usually transfer of the patient to an acute care setting</b>. An urgent transfer (999) will be arranged by the senior clinician within the clinical area (where appropriate) using their clinical judgement and this should not be delayed to await a more senior assessment.</li></ul>


    </div>

`,
  },
  SLEEP: {
    title: "Sleep",
    content: `<p>
            Some information about sleep
        </p>`,
  },
  HEART_RATE: {
    title: "Pulse rate",
    content: `<p>
            Pulse rate is the speed of the heartbeat measured by the number of contractions of the heart per minute. 
            The heart rate can vary according to the body's physical needs, including the need to absorb oxygen and excrete carbon dioxide. 
            Activities that can provoke change include physical exercise, sleep, anxiety, stress, illness, and ingestion of drugs.
            Pulse rate is measured in beats per minute.
        </p>`,
  },
  BLOOD_PRESSURE: {
    title: "Blood Pressure",
    content: `<div>
            <p>
                Currently, there are two types of warnings / errors implemented in the output of the SBP and DBP values.
            </p> 
            <p>
In case the model is not good enough to provide any AI prediction at all, the AI model returns none values with the following error messages in the "model_error" field: 

<br/>- No initialisations nor history to train model.
<br/>- Not enough historic data to train model
<br/>- Not enough high quality cuff initialisations to train model            
            </p>
    

<p>
In case the model does provide values, but there are some inconsistencies detected for the given model, they are returned in the "model_warnings" field of the output. Currently the following warnings are implemented:
    <br/>
    <br/>- Night dip < 10%
<br/>- Night dip > 30%
<br/>- Signal flatline
<br/>- Capricious signal
<br/>- Not enough night history
<br/>- Not enough day history

</p>




<p>
If you want to discuss SBP and DBP values with Corsano staff, please let us know the voucher ID of the patient, the date and observation or question. Please make sure you only communicate the anonyme voucher ID.
</p> 

        </div>`,
  },

  EMOGRAPHY: {
    title: "",
    content: `
               <h3>
                EmoGraphy
                </h3>
            <p>
                 EmoGraphy provides insight in how we respond to stress. Humans respond to stress in two ways; an immediate response to the stressor (adrenaline release) and a slower response (cortisol release) to recover from that stressor. Stress has a direct impact on our cognitive performance. High cortisol levels may negatively affect cognitive performance such as contemplation, strategic thinking and memory function. Low levels are thought to increase fatigue by making tasks appear tougher, and limiting endurance of sustained physical and mental activities.
            </p>
            <p>
            EmoGraphy provides detailed insights in this mechanism by displaying stress levels in real-time and calculating the related cognitive functioning of the brain – the CognitiveZone - that is impacted by cortisol. Through these measurements and calculations, EmoGraphy can also predict changes in cognitive abilities up to an hour ahead (assuming the user performs similar activities).
            </p>
            <p>
                EmoGraphy is based on skin conductance (EDA); an accurate measurement of sympathetic nervous system activity that reflects the body’s so-called <i>fight</i> or <i>flight</i> response. The Cognitive Zones help the user to understand how to optimize his/her cognitive performances and <i>avoid under- and over-stimulation</i>.
            </p>
            <h3>
                Stress Level
            </h3>
            <p>
                Stress Level reflects the immediate response to a stressor. It indicates the amount of physical (strong exercise, spicy food, or the temperature) and emotional stress of the user at present time.  
            </p>
            <h3>
                Cognitive Zone
            </h3>
            <p>
                Cognitive Zone is based on the slower response (cortisol release) to a stressor. By remaining in a zone of optimal emotional stimulation, you can avoid both drowsiness and too much stress. This is the objective of Cognitive Zone. The Balanced Zone represents the area of emotional fitness, essential for healthy lifestyle and optimal cognitive performance.  
            </p>
            <ul>
                <li>
                    Cognitive Zone is the current cognitive zone at present time.
                </li>
                <li>
                    Cognitive Zone in One Hour is a prediction of the cognitive zone in one hour assuming the user performs similar activities as at present time
                </li>
                <li>
                    Predictive Cognitive Zone is the next predicated cognitive zone in a number of minutes given by the Predictive Cognitive Zone Transition Time.
                </li>
                <li>
                    Predictive Cognitive Zone Transition Time is the time to reach the next predicted cognitive zone
                    <ul>
                        <li>
                            <u>Over stimulated zone</u>
                            <br/>
                            Decreased cognitive performance; strategic thinking, memory, etc. - you might want to do a relaxing exercise to reach the balanced zone for optimal performance
                        </li>
                        <li>
                            <u>Balanced zone</u>
                            <br/>
                            Optimal cognitive performance – to stay in this zone take regular breaks
                        </li>
                        <li>
                            <u>Under stimulated zone</u>
                            <br/>
                            Enhanced perception of effort, reduced endurance of mental activity – you might want to take a walk or activity to stimulate yourself to reach the balanced zone
                        </li>
                    </ul>
                </li>
            </ul>
            
            <h3>
                Cortisol Contribution
            </h3>
            <p>
                The Cognitive Zones are derived from the estimated Cortisol Contribution which is the amount of cortisol that can be accounted for by stress. Cortisol is the most accurate measure of stress but it can only be measured via blood, hair or saliva samples. By calculating the cortisol contributions, Philips is able to determine the easy-to-understand CognitiveZone.
            </p>
`,
  },
};
