import styled, { css } from "styled-components";

const Input = styled.input`
  ${(props) => {
    switch (props.$mode) {
      case "mode1":
        return css`
          background: #ffffff;
          border: 1px solid rgba(135, 152, 173, 0.1);
          border-radius: 6px;
          outline: none;
          height: 52px;
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          line-height: 18px;
          padding-left: 7px;

          ::placeholder {
            color: #8798ad;
            opacity: 0.5;
          }
        `;
      case "mode2":
        return css`
          background: #ffffff;
          border: 1px solid rgba(135, 152, 173, 0.1);
          border-radius: 6px;
          outline: none;
          height: 52px;
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          line-height: 18px;
          padding-left: 7px;

          ::placeholder {
            color: #8798ad;
            opacity: 0.5;
          }
        `;
      case "mode3":
        return css`
          outline: none;
          box-sizing: border-box;
          overflow: hidden;
          background: white;
          border: none !important;
          font-size: 14px;
        `;
      case "mode4":
        return css`
          outline: none;
          border: 1px solid rgba(135, 152, 173, 0.1);
          box-sizing: border-box;
          overflow: hidden;
          background: white;
          // border: none !important;
          font-size: 14px;
        `;
      default:
        return css`
          box-sizing: border-box;
          width: 100%;
          outline: none;

          border: none;

          height: 50px;
          padding-left: 10px;
          font-size: 18px;

          background: #ffffff;
          box-shadow: 0px 7px 15px rgba(135, 152, 173, 0.1);
          border-radius: 6px;

          ::placeholder {
            color: #8798ad;
            opacity: 0.5;
          }

          :focus {
            border-color: blue;
          }
        `;
    }
  }}
`;

export default Input;
