import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import ch_not_sel from '../../../assets/images/checkbox.svg'
import ch_sel from '../../../assets/images/chbx.svg'
import {Button} from "../../trials/panels/StudySessionsPanel";

const TYPES = [
    {
        label: 'Green PPG',
        value: 'ppg_green'
    },
    {
        label: 'Red PPG',
        value: 'ppg_red'
    },
    {
        label: 'Infrared (IR) PPG',
        value: 'ppg_infra_red'
    }
]

export default function PpgTypesSelector(props) {
    const {
        onExport = types => {

        }
    } = props;

    const [selectedSet, setSelectedSet] = useState(Set());
    let isSomethingSelected = (selectedSet.toArray().length > 0);

    return (
        <Wrapper>

            <InnerWrapper>
                {TYPES.map((a, i) => {
                    let isSelected = selectedSet.has(a.value);
                    return (
                        <Item key={a.value}>
                            <ChImg src={(isSelected == true ? ch_sel : ch_not_sel)} onClick={() => {
                                if (isSelected == true) {
                                    setSelectedSet(selectedSet.delete(a.value));
                                } else {
                                    setSelectedSet(selectedSet.add(a.value));
                                }
                            }}/>
                            <span>
                                {a.label}
                            </span>
                        </Item>
                    )
                })}
            </InnerWrapper>

            <BottomButtonPlaceholder>
                <Button disabled={!isSomethingSelected} onClick={() => {
                    if (isSomethingSelected == false){
                        return;
                    }
                    onExport(selectedSet.toArray());
                }} >
                    Download
                </Button>
            </BottomButtonPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 240px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;

const ChImg = styled.img`
  width: 16px;
  right: 16px;
  margin-right: 5px;
  cursor: pointer;
`;

const BottomButtonPlaceholder = styled.div`
    margin-top: 20px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.loading == true ? 0.5 : 1)};
  display: grid;
  grid-template-columns: 1fr;
`;