import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
} from "react";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import zmImage from "../../../assets/images/badges/zmdi_time-restore.svg";
import batteryImage from "../../../assets/images/badges/akar-icons_battery-full.svg";

import normalBattery from "./images/battery_normal.svg";
import orangeBattery from "./images/battery_yellow.svg";
import redBattery from "./images/battery_red.svg";
import chargingBattery from "./images/battery-charging.svg";

import "./style.css";

function getUIDetails(b) {
    if (+b <= 10) {
        return {
            isBlinking: true,
            image: redBattery,
            color: "#FA4646",
            blinkingClassName: "blink_me",
        };
    }
    if (+b <= 20) {
        return {
            isBlinking: false,
            // image: redBattery,
            image: orangeBattery,
            color: "#FF6D0A",
            blinkingClassName: "blink_me_slow",
        };
    }
    // if (+b <= 30) {
    //     return {
    //         isBlinking: false,
    //         image: orangeBattery,
    //         color: '#FF6D0A',
    //         blinkingClassName: ''
    //     }
    // }
    return {
        isBlinking: false,
        image: normalBattery,
        color: "#000F4B",
        blinkingClassName: "",
    };
}

export default function BatterySpan(props) {
    const {
        uuid,
        forceBattery,
        enableAutoLoading = false,
        isCharging = false,
    } = props;
    const [battery, setBattery] = useState(undefined);
    useEffect(() => {
        if (forceBattery != undefined || enableAutoLoading == false) {
            return;
        }
        DoctorAPI.getBattery(uuid).then((x) => {
            setBattery(x);
        });
    }, [uuid]);

    // console.log('battery: render: battery, forceBattery = ', battery, forceBattery);

    if (battery == undefined && forceBattery == undefined) {
        return null;
    }
    let b = forceBattery == undefined ? battery : forceBattery;
    let { isBlinking, image, color, blinkingClassName } = getUIDetails(b);

    if (isCharging == true) {
        image = chargingBattery;
        blinkingClassName = "blink_me";
    }

    return (
        <Wrapper style={{ color: color }}>
            <img
                style={{ height: 25, marginRight: 10 }}
                src={image}
                className={isCharging ? blinkingClassName : "battery-icon"}
            />
            <span style={{ opacity: 0.6 }}>{`${b}%`}</span>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 40px;
    color: #8798ad;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.8;
`;
