import axios from 'axios'
import moment from 'moment'
import env from "react-dotenv";

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const OptimizedExportAPI = {

    async createExport(data) {
        // let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v5/export`, data)).data;
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v5/export`, {
            ...data,
            client_date_from: moment(+data.from).format('YYYY-MM-DDTHH:mm:ss') + '.000',
            client_date_to: moment(+data.to).format('YYYY-MM-DDTHH:mm:ss') + '.000'
        })).data;
        return pld;
    },

    async createExportOptimized(data) {
        console.log('createExportOptimized: data = ', data);
        // let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v5/export`, data)).data;
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v6/export`, {
            ...data,
            client_date_from: moment(+data.from).format('YYYY-MM-DDTHH:mm:ss') + '.000',
            client_date_to: moment(+data.to).format('YYYY-MM-DDTHH:mm:ss') + '.000'
        })).data;
        return pld;
    },

    async createNewExportOptimized(data) {
        console.log('createExportOptimized: data = ', data);
        // let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v5/export`, data)).data;
        // changing nibp

        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/v7/export`, {
            ...data,
            timestamp_from: +data.from,
            timestamp_to: +data.to
        })).data;
        return pld;
    },

    async getExportStatus(id) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v5/export/${id}`)).data;
        return pld;
    },

    async getNewExportStatus(id) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v7/export/${id}`)).data;
        return pld;
    },


    getDownloadUrl(id, name) {
        return `${DOCTORS_API_ENDPOINT}/v5/export/${id}/data?name=${name}`;
    },

    getNewDownloadUrl(id, name) {
        return `${DOCTORS_API_ENDPOINT}/v7/export/${id}/data?name=${name}`;
    },




}

export default OptimizedExportAPI;
