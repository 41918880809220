import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import QRCode from "react-qr-code";

import Select from 'react-select'

const OPTIONS = [
    {
        label: '287-1',
        value: '287-1'
    },
    {
        label: '287-2',
        value: '287-2'
    }
]

export default function UpdateBraceletForm(props) {

    const {
        id,
        loading = false,
        onSave = d => {

        },
        hasQrCode = false
    } = props;

    const [braceletType, setBraceletType] = useState(props.braceletType || '287-1');
    const [name, setName] = useState(props.name || '');
    const [description, setDescription] = useState(props.description || '');
    const [mac, setMac] = useState(props.mac || '');

    return (
        <Wrapper>
            <Row>
                <Label>
                    Name
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Row>

            {id == undefined ? null :
                <Row>
                    <Label>
                        ID
                    </Label>
                    <div>
                        {id}
                    </div>
                </Row>
            }

            <Row>
                <Label>
                    Bracelet Type
                </Label>
                <Select options={OPTIONS}
                        value={OPTIONS.filter(x => (x.value == braceletType))[0]}
                        onChange={x => {
                            setBraceletType(x.value);
                        }}
                />
            </Row>


            <Row>
                <Label>
                    Mac address
                </Label>
                <Input value={mac} onChange={evt => {
                    setMac(evt.target.value);
                }}/>
            </Row>

            <ButtonsPlaceholder>

                <Button onClick={() => {
                    onSave({
                        mac,
                        name,
                        description,
                        braceletType
                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null :
                        'Save'
                    }
                </Button>

            </ButtonsPlaceholder>


        </Wrapper>
    );
}

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
`;

const ButtonsPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  border-radius: 6px;
  outline: none;
  height: 52px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding-left: 7px;

  ::placeholder {
    color: #8798AD;
    opacity: 0.5;
  }
`;


const Wrapper = styled.div`

`;

const Button = styled.div`
  width: 200px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const InputPlaceholder = styled.div`
  flex: 1;
  margin-right: 30px;
`;