import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import BloodPressureAPI from "../../../api/BloodPressureAPI";
import BpSquareTool from "./BpSquareTool";
import { NOTIFICATION_TO_DETAILS } from "../BpNotifications";
import DataStatusTool from "./DataStatusTool";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DrawHelper from "../../../helpers/DrawHelper";
import { checkBoundaries } from "react-range/lib/utils";

export default function BpDebugTool(props) {
  const { date, uuid } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [warnings, setWarnings] = useState([]);
  const [noWarning, setNoWarning] = useState(false);

  useEffect(() => {
    setLoading(true);
    BloodPressureAPI.getBpReportData(uuid, date).then((pld) => {
      setData(pld);
      setLoading(false);
      let bpData = pld?.bpMeasurements || [];
      let AIwarning = bpData
        .slice(bpData.length - 1, bpData.length)
        .map((x) => {
          let notification = x?._raw_response?.notification_codes;
          // console.log("notification", notification);
          return {
            notification: notification,
            ts: x?.client_start_timestamp,
            //   detail: NOTIFICATION_TO_DETAILS[notification],
          };
        });
      let checkAllWarnings = bpData.map((x) => {
        let notification = x?._raw_response?.notification_codes;
        if (notification === undefined || notification[0] === "OK") {
          return null;
        }
        return notification;
      });
      setWarnings(AIwarning);
      setNoWarning(checkAllWarnings.every((x) => x === null));
    });
  }, [date, uuid]);

  if (loading == true && data == undefined) {
    return <div>loading...</div>;
  }

  let { bpMeasurements = [] } = data || {};
  // console.log("MOment:", moment().diff("1962-12-30", "years"));
  let xPoints = bpMeasurements.map((x) => ({
    t: +x.start_timestamp,
    ...x,
    date: moment(+x.start_timestamp).format("HH:mm"),
  }));

  // console.log('xPoints = ', xPoints);
  let mode = "day";

  let dayTimestamp = +moment(date).startOf("day");
  let gridPoints = DrawHelper.getBpMonotonePoints(
    xPoints,
    mode == "day"
      ? +dayTimestamp
      : moment(+dayTimestamp).startOf("day").add(-12, "hours"),
    mode
  );
  let xData = gridPoints.map((x) => {
    return {
      ["blood pressure"]: [x.dbp, x.sbp],
      t: +x.t,
      ["heart rate"]: x.hr,
      dbp: x?.dbp,
      sbp: x?.sbp,
      date: x.date,
    };
  });

  // let xDataAi = gridPoints.filter(x => (x?._raw_response != undefined)).map(x => {

  let xDataAi = gridPoints.map((x) => {
    return {
      // ['blood pressure']: [x?._raw_response?.DBP_ai, x?._raw_response?.SBP_ai],
      ["blood pressure"]: [x?._raw_response?.DBP_ai, x?._raw_response?.SBP_ai],
      ["blood_pressure"]: [
        x?._raw_response?.DBP_ai_debug,
        x?._raw_response?.SBP_ai_debug,
      ],
      t: +x.t,
      ["heart rate"]: x.hr,
      // ['ai_features']: x?._raw_response?.ai_features,
      ["_raw_response"]: x?._raw_response,
      date: x.date,
      // dbp: x?._raw_response?.DBP_ai,
      dbp: x?._raw_response?.DBP_ai,
      d: x?._raw_response?.DBP_ai,
      dbp_: x?._raw_response?.DBP_ai_debug,
      // sbp: x?._raw_response?.SBP_ai
      sbp: x?._raw_response?.SBP_ai,
      s: x?._raw_response?.SBP_ai,
      sbp_: x?._raw_response?.SBP_ai_debug,
    };
  });
  const InfoTool = () => {
    return (
      <InfoWrapper>
        <div>
          <h1>CardioWatch</h1>
          <Subheading>
            <strong>NIBP</strong> <i>Report</i>{" "}
            <span style={{ fontSize: "14px" }}>
              ({moment(date).subtract(1, "days").format("YYYY MMM DD")} {" - "}
              {moment(date).format("YYYY MMM DD")})
            </span>
          </Subheading>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div>
            <div>
              <b>
                {data?.userInfo?.user?.first_name +
                  " " +
                  data?.userInfo?.user?.last_name}
              </b>
              <i> ({data?.userInfo?.health?.gender})</i>
            </div>
            <div>
              <InfoSpan>
                <b>Age: </b>
                {moment().diff(moment(data?.userInfo?.user?.birthday), "years")}
              </InfoSpan>
              <InfoSpan>
                <b>Height: </b>
                {data?.userInfo?.health?.height}cm
              </InfoSpan>
              <InfoSpan>
                <b>Weight: </b>
                {data?.userInfo?.health?.weight}kg
              </InfoSpan>
            </div>
            {/* <div>
              {moment(date).subtract(1, "days").format("YYYY MMM DD")} {" - "}
              {moment(date).format("YYYY MMM DD")}
            </div> */}
          </div>
        </div>
      </InfoWrapper>
    );
  };
  return (
    <Wrapper className="report-wrapper">
      <InfoTool></InfoTool>
      <>
        <ChartPlaceholder>
          {/*<SimpleBpmSlotsChart*/}
          {/*    yDoman={[60, 100]}*/}
          {/*    slots={xPoints}*/}
          {/*    dataKey={'spo2'}/>*/}

          <ResponsiveContainer height={320}>
            <ComposedChart data={xDataAi}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="t"
                tickFormatter={(a) => {
                  let ss = moment(a).format("HH:mm");
                  if (ss == "Invalid date") {
                    return "";
                  }
                  return ss;
                }}
              />
              <YAxis />
              {/*<Tooltip*/}
              {/*/>*/}
              <Tooltip labelFormatter={(t) => moment(t).format("HH:mm")} />
              {/*<Legend/>*/}
              <Line dataKey="sbp" stroke="#3333FF" strokeWidth={2} />
              <Line dataKey="dbp" stroke="#FF4E45" strokeWidth={2} />
              <Bar
                dataKey="blood pressure"
                fill="#b3cccc56"
                strokeWidth={1}
                barSize={2}
                strokeDasharray="3 5"
              />
              })}
            </ComposedChart>
          </ResponsiveContainer>
        </ChartPlaceholder>
        {noWarning ? (
          <WarningArea>
            <div style={{ fontWeight: "700", fontSize: "20px" }}>{""}</div>
          </WarningArea>
        ) : (
          <WarningArea>
            {warnings
              .slice(warnings.length - 1, warnings.length)
              .map((x, i) => {
                let nfs = x.notification;
                if (!nfs) return null;

                return (
                  <div key={i} style={{ width: "60%", marginBottom: "1rem" }}>
                    <div style={{ fontWeight: "500", fontSize: "20px" }}>
                      Warnings:
                    </div>
                    {/* <TimeText>{moment(x.ts).format("HH:mm")}</TimeText> */}
                    <ul>
                      {nfs
                        .filter((m) => NOTIFICATION_TO_DETAILS[m])
                        .map((n, _i) => {
                          return <li key={_i}>{NOTIFICATION_TO_DETAILS[n]}</li>;
                        })}
                    </ul>
                  </div>
                );
              })}
          </WarningArea>
        )}

        <TabHeading className="patient-statistics-heading">BP AI</TabHeading>

        <ChartPlaceholder>
          <ResponsiveContainer height={320}>
            <ComposedChart data={data?.bpMeasurements}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="t"
                tickFormatter={(a) => {
                  return moment(a).format("HH:mm");
                }}
              />
              <YAxis />

              <Tooltip labelFormatter={(t) => moment(t).format("HH:mm")} />
              <Legend />
              <Bar dataKey="blood_pressure" fill="#8884d8" />
              <Line type="monotone" dataKey="heart rate" stroke="#ff7300" />
            </ComposedChart>
          </ResponsiveContainer>
        </ChartPlaceholder>
      </>
      {/* <pre
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data, null, 2) }}
        ></pre> */}
      <DataStatusTool />
    </Wrapper>
  );
}

const TimeText = styled.div``;
const NotificationText = styled.div`
  font-weight: 500;
`;
const DetailText = styled.div`
  text-indent: 2em;
`;
const WarningArea = styled.div`
  margin-left: 1rem;
`;

const Wrapper = styled.div`
  background: white;
  width: 80%;
  margin: 0 auto;
  // padding-right: 20px;
  // padding-left: 20px;
`;

const TabHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 30px;
  flex-direction: row;
`;

const ChartPlaceholder = styled.div`
  height: 320px;
  width: 100%;
`;
const InfoWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  aligh-items: center;
  position: relative;
  // width: 100%;
`;

const InfoSpan = styled.span`
  margin-right: 1rem;
`;
const Subheading = styled.p`
  font-size: 28px;
`;
