import * as types from "../ActionTypes";

//LOAD_DATA
const DEFAULT_ALARM_PARAMS = {
  heart_rate: true,
  temperature: true,
  spo2: true,
  systolic_blood_pressure: false,
  respiration_rate: false,
};

export function changeTheme(theme) {
  return {
    type: types.CHANGE_THEME,
    theme: theme,
  };
}

export function changeLayout(layout) {
  return {
    type: types.CHANGE_LAYOUT,
    layout: layout,
  };
}

export function changeRole(role) {
  return {
    type: types.CHANGE_ROLE,
    role: role,
  };
}

export function changeAlarm(alarm) {
  return {
    type: types.CHANGE_ALARM,
    alarm: alarm ? alarm : "none",
  };
}

export function changeTempUnit(unit) {
  return {
    type: types.CHANGE_TEMP_UNIT,
    unit: unit,
  };
}

export function changeAlarmParams(newParams) {
  return {
    type: types.CHANGE_ALARM_PARAMS,
    alarmParams: newParams ? newParams : DEFAULT_ALARM_PARAMS,
  };
}

export function changePriority(priority) {
  return {
    type: types.CHANGE_PRIORITY,
    selectedPriority: priority,
  };
}

export function changeOrdering(order) {
  return {
    type: types.CHANGE_ORDERING,
    patientsListOrder: order,
  };
}

export function changeFilter(filter) {
  return {
    type: types.CHANGE_FILTER,
    patientsListFilter: filter,
  };
}
