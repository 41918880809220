import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Button } from "../../trials/panels/StudySessionsPanel";
import toast from "react-hot-toast";

const ORDERS = [
  {
    value: "default",
    label: "Default (Priority then Alphabetical)",
  },
  {
    value: "alphabetical",
    label: "Alphabetical",
  },
  {
    value: "compliance",
    label: "Compliance",
  },
];

const FILTERS = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "hidden",
    label: "Hidden Patients",
  },
  {
    value: "notactivated",
    label: "Not Activated Patients",
  },
];

export default function DoctorOrderFilterPanel(props) {
  let {
    onChangeOrder,
    onChangeFilter,
    currentOrder = "alphabetical",
    currentFilter = "",
  } = props;
  const [newOrder, setNewOrder] = useState(currentOrder);
  const [newFilter, setNewFilter] = useState(currentFilter);
  console.log("curentorder,", currentOrder, currentFilter);

  return (
    <div>
      <DatesPlaceholder>
        <Half>
          <SectionHeading>Sort by</SectionHeading>
          {ORDERS.map((o, i) => {
            return (
              <CheckboxContainer
                key={i}
                // onChange={() => {
                //   setNewOrder(o.value);
                // }}
              >
                <LabelSpan>
                  <CheckBox
                    type="checkbox"
                    checked={newOrder === o.value}
                    onChange={() => {
                      setNewOrder(o.value);
                    }}
                  />
                  {o.label}
                </LabelSpan>
              </CheckboxContainer>
            );
          })}
        </Half>
        <Half>
          <SectionHeading>Filter By</SectionHeading>
          {FILTERS.map((f, i) => {
            return (
              <CheckboxContainer key={i}>
                <LabelSpan>
                  <CheckBox
                    type="checkbox"
                    checked={newFilter === f.value}
                    onChange={() => {
                      setNewFilter(f.value);
                    }}
                  />
                  {f.label}
                </LabelSpan>
              </CheckboxContainer>
            );
          })}
        </Half>
      </DatesPlaceholder>
      <Button
        onClick={() => {
          onChangeOrder(newOrder);
          onChangeFilter(newFilter)
          toast.success("Applied!");
        }}
      >
        Apply
      </Button>
    </div>
  );
}

const DatesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const Half = styled.div`
  box-sizing: border-box;
  padding: 5px;
  flex: 1;
`;

const CheckBox = styled.input`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;

  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;

const CheckboxContainer = styled.div`
  // border: 1px solid blue;
  // display: flex;
  padding: 4px;
  cursor: pointer;
`;

const SectionHeading = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const LabelSpan = styled.label`
  cursor: pointer;
  display: flex;
`;
