import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import ls from "local-storage";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";

const PARAMETERS = [
  {
    label: "Only one patient will use bracelet",
    value: "single",
    default: true,
  },
  {
    label: "Multiple patients will use the same bracelet",
    value: "multi",
    default: false,
    disable: false,
  },
];

const checkboxNamePrefix = "single-multi";

export default function SingleMultiUsersTool(props) {
  const { singleOrMulti, onChange } = props;
  console.log({ singleOrMulti });

  const handleCheckboxChange = (param, value) => {};

  const [selected, setSelected] = useState(singleOrMulti);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "repeat(2, 1fr)",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 1,
      }}
    >
      {PARAMETERS.map((state, index) => {
        console.log({ selected });

        return (
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              textAlign: index === 0 ? "right" : "center",
            }}
            key={index}
          >
            <Label className="setting-text-active" disable={state.disable}>
              <Checkbox
                id={state.value}
                name={`${checkboxNamePrefix}-${index}`}
                type="checkbox"
                disabled={false}
                // checked={state.default}
                checked={state.value === selected}
                onChange={(e) => {
                  setSelected(state.value);
                  console.log(state.value);
                  //   console.log(state.label, state.value, e.target.checked);
                  handleCheckboxChange(state.value, e.target.checked);
                  onChange(state.value);
                }}
              />
              {state.label}
            </Label>
          </div>
        );
      })}
    </div>
  );
}

const Label = styled.div`
  // font-weight: bold;
  margin-bottom: 5px;
  opacity: ${(props) => (props.disable ? 0.5 : 0.9)};
`;

const Checkbox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;
