import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import {Button} from "../panels/StudySessionsPanel";
import StudySessionsAPI from "../../../api/StudySessionsAPI";

import moment from 'moment'

import Spin from 'arui-feather/spin'

export default function UploadSessionToPreventicusTool(props) {
    const {
        session, onSessionUpload = () => {

        },
        loading = false
    } = props;

    const [preventicusSequences, setPreventicusSequences] = useState([]);

    let from = session?.timestamp_from;
    let to = session?.timestamp_to;
    let preventicus_customer_id = session?.preventicus_customer_id;
    let status = session?.status;
    let canUploadSession = (status == 'new');

    useEffect(() => {
        if (session == undefined) {
            return;
        }
        StudySessionsAPI.getInstance().getSessionStatisticsItems(from, to).then(arr => {
            console.log('arr = ', arr);
        });
        if (canUploadSession == false) {
            StudySessionsAPI.getInstance().getPreventicusSequences(preventicus_customer_id).then(prevArr => {
                console.log('prev arr = ', prevArr);
                setPreventicusSequences(prevArr);
            });
        }
    }, [from, to]);

    if (session == undefined) {
        return null;
    }

    return (
        <Wrapper>

            {canUploadSession == false ? null :
                <UploadSessionPlaceholder>
                    <Spin visible={loading} />
                    {loading == true ? null :
                        <Button onClick={() => {
                            onSessionUpload();
                        }}>
                            Upload data to Preventicus
                        </Button>
                    }
                </UploadSessionPlaceholder>
            }

            {canUploadSession == true ? null :
                <SequencesList>

                    <h2>
                        Preventicus Sequences
                    </h2>

                    {preventicusSequences.map((x, i) => {
                        return (
                            <SequenceItem key={`${i}_${x._id}`}>
                                {`${moment(x.start_timestamp).format('DD.MM.YYYY HH:mm:ss')} - ${moment(x.end_timestamp).format('DD.MM.YYYY HH:mm:ss')}`}
                                <br/>
                                {`Status: ${x.details?.status}`}
                                <br/>
                                {`Usable minutes: ${x.details?.usableMinutes}`}
                                {/*<br/>*/}
                                {/*/!*{JSON.stringify(x)}*!/*/}
                            </SequenceItem>
                        )
                    })}
                </SequencesList>
            }

        </Wrapper>
    );
}

const UploadSessionPlaceholder = styled.div`

`;

const SequencesList = styled.div`
  margin-top: 10px;
`;

const SequenceItem = styled.div`
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background: white;
`;

const Wrapper = styled.div`

`;