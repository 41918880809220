import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../redux/actions/UiActions";
import DarkModeIcon from "./icons/theme_dark_mode.svg";
import LightModeIcon from "./icons/theme_light_mode.svg";
import ls from "local-storage";
import styled from "styled-components";

const THEME_DARK = "Dark Mode";
const THEME_LIGHT = "Light Mode";
const LIGHT = "light";
const DARK = "dark";

export default function ThemeToggle(props) {
  // console.log("current theme:", ls.get("theme"));
  // const [theme, setTheme] = useState(ls.get("theme") || LIGHT);
  let { theme } = useMappedState(
    useCallback((state) => {
      return {
        theme: state.ui.theme,
      };
    }, [])
  );

  const dispatch = useDispatch();
  return (
    <div
      className="theme-top-swtich"
      style={{ display: "flex", alignItems: "center" }}
    >
      <InfoItemIcon
        className="theme-icon"
        src={theme === DARK ? DarkModeIcon : LightModeIcon}
        onClick={() => {
          const newTheme = theme === DARK ? LIGHT : DARK;
          ls.set("theme", newTheme);
          // setTheme(newTheme);
          dispatch(UiActions.changeTheme(newTheme));
          console.log("theme changed to:", newTheme);
          console.log(
            "storage after theme changed:",
            localStorage.getItem("theme")
          );
        }}
      />
      {/* {theme === DARK ? THEME_DARK : THEME_LIGHT} */}
    </div>
  );
}

const InfoItemIcon = styled.img`
  margin-right: 1px;
  height: 25px;
  opacity: 0.9;
  border-radius: 50%;
  padding: 7px;
  &:hover {
    background: gray;
    padding: 7px;
    shadow: 2px 2px;
  }
  @media (max-width: 920px) {
    margin-right: 4px;
    height: 17px;
    padding-right: 4px;
  }
`;
