import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CardiolyseAPI from "../../../api/CardiolyseAPI";
import DetailsValuesTable from "./DetailsValuesTable";
import QrsChartTool from "./QrsChartTool";
import ViewEcgRawChartTool from "./ViewEcgRawChartTool";

export function selectFiles() {
    return new Promise(resolve => {
        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.onchange = _ => {
            let files = Array.from(input.files);
            resolve(files);
        };
        input.click();
    });
}

export default function CardiolyseWiffMeasurementTool(props) {
    let {userUUID, measurementUUID, t} = props;

    console.log('CardiolyseWiffMeasurementTool: ');

    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState(undefined);
    const [recordId, setRecordId] = useState();
    const [error, setError] = useState(undefined);

    useEffect(() => {
        setInfo(undefined);
        setLoading(true);
        setError(undefined);
        CardiolyseAPI.getEcgMeasurementCardiolyseResult(measurementUUID).then(x => {
            if (x != undefined) {
                setRecordId(x?.recordId);
            }
            setLoading(false);
        }).catch(exc => {
            setLoading(false);
            setError('Can not process file');
        });
    }, [userUUID, measurementUUID, t]);

    useEffect(() => {
        if (recordId == undefined) {
            return;
        }
        setInfo(undefined);
        setLoading(true);
        CardiolyseAPI.getAllCombinedData(recordId).then(x => {
            setInfo(x);
            setLoading(false);
        });
    }, [recordId]);

    let detailsValues = info?.details?.data?.values || [];
    let qrsPoints = [];
    try {
        qrsPoints = info?.qrs?.data[0]?.data;
    } catch (e) {

    }

    if (error != undefined) {
        return (
            <div style={{color: 'red'}}>
                {error}
            </div>
        )
    }

    return (
        <Wrapper>

            {loading == false ? null :
                <Code/>
            }

            {info == undefined ? null :
                <ContentPlaceholder>

                    <ViewEcgRawChartTool
                        {...props}
                    />

                    {qrsPoints.length == 0 ? null :
                        <>
                            <Section>
                                <SubHeading>
                                    Average QRS
                                </SubHeading>

                                <QrsChartTool
                                    points={qrsPoints}
                                />
                            </Section>

                            <Section>
                                <SubHeading>
                                    RecordId = {recordId}
                                </SubHeading>
                            </Section>

                            <Section>
                                <SubHeading>
                                    Reports
                                </SubHeading>
                                <DownloadPlaceholder>
                                    <DownloadLink target={'_blank'} href={info?.pdfUrl}>
                                        Download PDF report
                                    </DownloadLink>
                                    <DownloadLink target={'_blank'} href={info?.excelUrl}>
                                        Download Excel report
                                    </DownloadLink>
                                </DownloadPlaceholder>
                            </Section>

                            <Section>
                                <SubHeading>
                                    Parameters
                                </SubHeading>

                                <DetailsValuesTable
                                    values={detailsValues}
                                />
                            </Section>
                        </>
                    }

                    <Section>
                        <SubHeading>
                            JSON
                        </SubHeading>
                        <RawPlaceholder>
                            <pre>{JSON.stringify(info, null, 2)}</pre>
                        </RawPlaceholder>
                    </Section>

                </ContentPlaceholder>
            }

        </Wrapper>
    );
}

const Section = styled.div`
  margin-bottom: 40px;
`;

const SubHeading = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold;
`;

const ContentPlaceholder = styled.div`

`;

const RawPlaceholder = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  background: whitesmoke;
`;

const DownloadPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const DownloadLink = styled.a`
  margin-right: 15px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background: white;
  border-radius: 4px;
`;