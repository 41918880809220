import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";

// import MultiRangeSlider from "multi-range-slider-react";

import NewRangeTool, { getDefaultsRangesMap } from "./NewRangeTool";
// import { clearScreenDown } from "readline";

// import './style.css'

export const GOOD_RANGES_OPTIONS = [
  {
    label: "Pulse Rate",
    name: "heart_rate",
    name2: "heart_rate",
    dimension: "Pulse BPM",
    min: 30,
    max: 180,
    step: 1,
    defaults: {
      min: 40,
      // min: 36,
      max: 130,
    },
    shouldRound: true,
  },
  {
    label: "Spo2",
    name: "spo2",
    name2: "spo2",
    dimension: "%",
    step: 1,
    min: 70,
    max: 100,
    defaults: {
      min: 95,
      max: 100,
    },
    shouldRound: true,
  },
  {
    label: "Respiration Rate",
    name: "respiration_rate",
    name2: "respiration_rate",
    dimension: "BrPM",
    min: 0,
    max: 70,
    step: 1,
    defaults: {
      min: 8,
      max: 24,
    },
    shouldRound: true,
    active: true,
  },
  {
    label: "Systolic Blood Pressure",
    name: "blood_pressure",
    name2: "systolic_blood_pressure",
    dimension: "mmHg",
    min: 60,
    max: 250,
    step: 0.1,
    defaults: {
      min: 60,
      max: 250,
    },
  },
  {
    label: "Temperature",
    name: "temperature",
    name2: "temperature",
    dimension: "C",
    // min: 35,
    min: 15,
    max: 44,
    step: 0.1,
    defaults: {
      min: 36,
      max: 40,
    },
  },
  {
    label: "NEWS2",
    name: "NEWS2",
    name2: "news2",
    dimension: "",
    min: 0,
    max: 4,
    step: 1,
    defaults: {
      min: 0,
      max: 3,
    },
    shouldRound: true,
  },
];

export default function NewPatientRangesTool(props) {
  const {
    onChange = (newRanges) => {},
    loading = false,
    saving = false,
  } = props;

  let { alarmParams } = useMappedState(
    useCallback((state) => {
      return {
        alarmParams: state.ui.alarmParams,
      };
    }, [])
  );

  const [ranges, setRanges] = useState(props.ranges || {});
  const [newsOnly, setNewsOnly] = useState(false);
  useEffect(() => {
    setRanges(props.ranges || {});
  }, [props.ranges]);

  // console.log("NewPatientRangesTool.js", alarmParams);

  return (
    <Wrapper className={"ranges_tool"}>
      {GOOD_RANGES_OPTIONS.filter((x) => x.active != false)
        .filter((x) => alarmParams[x.name2]) // currently filtering out parameters that is not selected in the alarm parameters under the Department setting
        .map((a, i) => {
          let xVal = (ranges || {})[a.name] || {};

          let min = xVal.min || a.defaults?.min;
          let max = xVal.max || a.defaults?.max;
          let minLabel = a.shouldRound == true ? Math.round(min) : min;
          let maxLabel = a.shouldRound == true ? Math.round(max) : max;
          if (newsOnly) {
            if (a.label !== "NEWS2") {
              return null;
            }
          } else {
            if (a.label === "NEWS2") {
              return null;
            }
          }

          return (
            <Row key={i} loading={loading}>
              <Label className="alarm-label">
                {a.label}
                {/*<span style={{opacity: 0.8, marginLeft: 5}}>*/}
                {/*    {`${minLabel} - ${maxLabel} ${a.dimension}`}*/}
                {/*</span>*/}
              </Label>
              <SliderPlaceholder>
                <NewRangeTool
                  loading={loading}
                  ranges={(ranges || {})[a.name]}
                  type={a.name}
                  onChange={(x) => {
                    console.log("NewRangeTool: onChange: a, x = ", a, x);
                    let newRanges = { ...ranges, [a.name]: x };
                    setRanges(newRanges);
                    onChange(newRanges);
                  }}
                />

                {/*<MultiRangeSlider*/}
                {/*    minValue={min}*/}
                {/*    maxValue={max}*/}
                {/*    min={a.min}*/}
                {/*    max={a.max}*/}
                {/*    step={a.step}*/}
                {/*    onChange={v => {*/}
                {/*        console.log('onChange: v = ', v);*/}
                {/*        let {minValue, maxValue} = v;*/}
                {/*        let minValue_ = +(Math.round(1.0 * minValue / (+a.step)) * (+a.step)).toFixed(1);*/}
                {/*        let maxValue_ = +(Math.round(1.0 * maxValue / (+a.step)) * (+a.step)).toFixed(1);*/}
                {/*        let newRanges = {...ranges, [a.name]: {min: minValue_, max: maxValue_}};*/}
                {/*        setRanges(newRanges);*/}
                {/*        onChange(newRanges);*/}
                {/*    }}*/}
                {/*/>*/}
              </SliderPlaceholder>
            </Row>
          );
        })}

      <BottomPlaceholder>
        {/* <DefaultSpan
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            setNewsOnly(!newsOnly);
          }}
        >
          Set {newsOnly ? "other" : "NEWS2"} alarm{newsOnly ? "s" : ""}
        </DefaultSpan> */}
        <DefaultSpan
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            // let d = GOOD_RANGES_OPTIONS.reduce((mp, x) => ({...mp, [x.name]: {...x.defaults}}));
            // let d = GOOD_RANGES_OPTIONS.reduce((mp, x) => ({...mp, [x.name]: {...x.defaults}}));
            setRanges(getDefaultsRangesMap());
            onChange(getDefaultsRangesMap());
          }}
        >
          Set default values
        </DefaultSpan>
      </BottomPlaceholder>
    </Wrapper>
  );
}

const BottomPlaceholder = styled.div`
  text-align: right;
`;

const DefaultSpan = styled.span`
  cursor: pointer;
  opacity: 0.6;
  font-style: italic;
  font-size: 12px;

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 30px;
  box-sizing: border-box;
  width: 100%;
  opacity: ${(props) => (props.loading == true ? 0.6 : 1)};
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SliderPlaceholder = styled.div``;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;
