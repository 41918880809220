import styled from "styled-components";
import { Input } from "../../ui/templates";

import searchImage from "../../../assets/images/badges/Search.svg";

export default function SearchInput(props) {
  const {
    placeholder = "Find by name, code, ID",
    value = "",
    onChange = (s) => {},
  } = props;

  return (
    <Wrapper className="search-input-wrapper">
      <Image src={searchImage} placeholder={placeholder} />
      <Input
        $mode={"mode3"}
        placeholder={placeholder}
        className="search-input"
        value={value}
        onChange={(evt) => {
          let s = evt.target.value;
          onChange(s);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;
  border-radius: 6px;
  border: 1px solid lightgrey;
  height: 40px;
  background: white;
  padding-left: 14px;
  box-shadow: 0px 7px 15px rgba(135, 152, 173, 0.1);
`;

const Image = styled.img`
  height: 14px;
  margin-right: 10px;
`;
