import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import UiHelper from "../../helpers/UiHelper";

import voidUserImage from '../../assets/images/user_void_0.png'
import dasboardImage from '../../assets/images/dashboard_white.svg'
import sleepImage from '../../assets/images/sleep.svg'
import activityImage from '../../assets/images/activity_d.svg'
import groupsImage from '../../assets/images/w_connections.svg'
import CommonHelper from "../../helpers/CommonHelper";


export default function MmtTemplate(props) {
    const dispatch = useDispatch();
    const {active = 'index'} = props;
    let {currentUserObj, loading} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading
        }
    }, []));

    if (currentUserObj == undefined) {
        return null;
    }
    const {healthUser, usersUser} = currentUserObj;
    if (healthUser == undefined || usersUser == undefined) {
        return null;
    }
    let {first_name, last_name, customer_code, email} = usersUser;
    let isStudyUser = ((customer_code != undefined) && (customer_code.indexOf('study') > -1));

    let links = (isStudyUser == true) ? STUDY_USER_LINKS : LINKS;
    console.log('render: currentUserObj = ', currentUserObj);
    let uName = (first_name == undefined) ? email : `${first_name} ${last_name}`;
    if (isStudyUser == true) {
        if (email != undefined && email.indexOf('@') > -1) {
            uName = email.split('@')[0];
        }
    }


    return (
        <Wrapper>

            <TopPlaceholder>
                <TopInnerPlaceholder>
                    <Logo src={UiHelper.getTemplateLogoImage()}/>
                    <TopRightPlaceholder onClick={() => {
                        CommonHelper.linkTo('/settings');
                    }}>
                        <AvaCircle/>
                        <CurrentUserName>
                            {uName}
                        </CurrentUserName>
                    </TopRightPlaceholder>
                </TopInnerPlaceholder>
            </TopPlaceholder>

            <LinksArea>

                <LinksInnerPlaceholder>
                    {links.map((l, i) => {
                        let isSelected = (l.name == active);
                        return (
                            <TopLinkItem key={i} selected={isSelected} onClick={() => {
                                CommonHelper.linkTo(l.url);
                            }}>
                                <ItemImage src={l.image} selected={isSelected}/>
                                <ItemText selected={isSelected}>
                                    {l.label}
                                </ItemText>
                            </TopLinkItem>
                        )
                    })}
                </LinksInnerPlaceholder>

            </LinksArea>

            <ContentPlaceholder>
                {props.children}
            </ContentPlaceholder>

        </Wrapper>
    );
}

const breakWidth = 1020;


const ContentPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  background: white;
  margin-top: 10px;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const TopLinkItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  margin-left: 30px;
  margin-right: 30px;
  opacity: ${props => (props.selected == true ? 1 : 0.4)};
  color: white;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const selectedImageSize = 66;
const notSelectedImageSize = 60;

const ItemImage = styled.img`
  width: ${props => (props.selected == true ? selectedImageSize : notSelectedImageSize)}px;
  height: ${props => (props.selected == true ? selectedImageSize : notSelectedImageSize)}px;
  margin-bottom: 20px;
`;

const ItemText = styled.div`
  text-align: center;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
`;

const LinksInnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const LinksArea = styled.div`
  background: rgb(34, 42, 50);
  width: 100vw;
  padding: 20px;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;


const Logo = styled.img`
  height: calc(100% - 4px);
`;

const CurrentUserName = styled.div`

`;

const AvaCircle = styled.div`
  border-radius: 1000px;
  background: lightgrey;
  box-sizing: border-box;
  border: 1px solid grey;
  height: 34px;
  width: 34px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
  background-image: url(${props => (props.src == undefined ? voidUserImage : props.src)});
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background: rgb(250, 250, 252);
  font-family: 'Avenir Next', 'Prompt', sans-serif;
`;

const TopInnerPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: ${breakWidth}px;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
  width: 100vw;
  background: white;
`;

const STUDY_USER_LINKS = [
    {
        label: 'Study',
        name: 'index',
        url: '/',
        image: dasboardImage
    },

    {
        label: 'Sleep',
        name: 'sleep',
        url: '/sleep',
        image: sleepImage
    },

    {
        label: 'Activity',
        name: 'activity',
        url: '/activity',
        image: activityImage
    }
    // {
    //     label: 'Activity',
    //     name: 'activity',
    //     url: '/activity',
    //     image: activityImage
    // },

    // {
    //     label: 'Groups',
    //     name: 'groups',
    //     url: '/groups',
    //     image: groupsImage
    // }

];

const LINKS = [
    {
        label: 'HRV',
        name: 'index',
        url: '/',
        image: dasboardImage
    },

    {
        label: 'Sleep',
        name: 'sleep',
        url: '/sleep',
        image: sleepImage
    },

    {
        label: 'Activity',
        name: 'activity',
        url: '/activity',
        image: activityImage
    },

    // {
    //     label: 'Groups',
    //     name: 'groups',
    //     url: '/groups',
    //     image: groupsImage
    // }

];

