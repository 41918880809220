import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';


import MultiRangeSlider from "multi-range-slider-react";

import './style.css'

export const GOOD_RANGES_OPTIONS = [
    {
        label: 'Pulse Rate',
        name: 'heart_rate',
        dimension: 'BPM',
        min: 30,
        max: 180,
        step: 1,
        defaults: {
            min: 40,
            max: 130
        },
        shouldRound: true
    },
    {
        label: 'Respiration Rate',
        name: 'respiration_rate',
        dimension: 'BrPM',
        min: 0,
        max: 70,
        step: 1,
        defaults: {
            min: 8,
            max: 24
        },
        shouldRound: true,
        visible: false
    },
    {
        label: 'Temperature',
        name: 'temperature',
        dimension: 'C',
        min: 35,
        max: 44,
        step: 0.1,
        defaults: {
            min: 36,
            max: 40
        }
    },
    {
        label: 'Spo2',
        name: 'spo2',
        dimension: '%',
        step: 1,
        min: 70,
        max: 100,
        defaults: {
            min: 95,
            max: 100
        },
        shouldRound: true
    }
]

export default function PatientRangesTool(props) {
    const {
        onChange = newRanges => {

        }
    } = props;

    const [ranges, setRanges] = useState(props.ranges || {});
    useEffect(() => {
        setRanges(props.ranges || {});
    }, [props.ranges]);

    return (
        <Wrapper className={'ranges_tool'}>

            {GOOD_RANGES_OPTIONS.filter(x => (x.visible != false)).map((a, i) => {
                let xVal = (ranges || {})[a.name] || {};
                let min = xVal.min || a.defaults?.min;
                let max = xVal.max || a.defaults?.max;
                let minLabel = (a.shouldRound == true) ? Math.round(min) : min;
                let maxLabel = (a.shouldRound == true) ? Math.round(max) : max;

                return (
                    <Row key={i}>
                        <Label>
                            {a.label}
                            <span style={{opacity: 0.8, marginLeft: 5}}>
                                {`${minLabel} - ${maxLabel} ${a.dimension}`}
                            </span>
                        </Label>
                        <SliderPlaceholder>
                            <MultiRangeSlider
                                minValue={min}
                                maxValue={max}
                                min={a.min}
                                max={a.max}
                                step={a.step}
                                onChange={v => {
                                    console.log('onChange: v = ', v);
                                    let {minValue, maxValue} = v;
                                    let minValue_ = +(Math.round(1.0 * minValue / (+a.step)) * (+a.step)).toFixed(1);
                                    let maxValue_ = +(Math.round(1.0 * maxValue / (+a.step)) * (+a.step)).toFixed(1);
                                    let newRanges = {...ranges, [a.name]: {min: minValue_, max: maxValue_}};
                                    setRanges(newRanges);
                                    onChange(newRanges);
                                }}
                            />
                        </SliderPlaceholder>
                    </Row>
                )
            })}

            <BottomPlaceholder>
                <DefaultSpan onClick={() => {
                    let d = GOOD_RANGES_OPTIONS.reduce((mp, x) => ({...mp, [x.name]: {...x.defaults}}));
                    setRanges(d);
                    onChange(d);
                }}>
                    set default values
                </DefaultSpan>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const BottomPlaceholder = styled.div`
  text-align: right;
`;

const DefaultSpan = styled.span`
  cursor: pointer;
  opacity: 0.6;
  font-style: italic;
  font-size: 12px;

  :hover {
    opacity: 1;
  }
`;

const Row = styled.div`
  margin-bottom: 30px;
  box-sizing: border-box;
  width: 100%;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SliderPlaceholder = styled.div`

`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;