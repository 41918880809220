import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import { DottedSpan } from "../../heart_rate/tools/ActivityHeartRateChart";

const KEYS = [
  "afb_probability",
  "avnn",
  "classification",
  "final_probability",
  "good_ibi_number",
  "good_quality_probability",
  "has_image_file",
  "heart_rate",
  "hta",
  "hta_q",
  "lf",
  "lf_confidence",
  "nsr_probability",
  "pnn50",
  "rmssd",
  "sdnn",
];

export default function HappitechTool(props) {
  const { uuid, from, to } = props;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getHappitechMeasurements(uuid, from, to).then((arr) => {
      setItems(arr);
      setLoading(false);
    });
  }, [uuid, from, to]);

  return (
    <Wrapper>
      {/*{JSON.stringify(items)}*/}

      {visible == true ? null : (
        <div style={{ marginTop: 10, marginBottom: 10, textAlign: "right" }}>
          <DottedSpan
            onClick={() => {
              setVisible(true);
            }}
          >
            show table
          </DottedSpan>
        </div>
      )}

      {visible == false ? null : (
        <>
          <InnerPlaceholder>
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ fontSize: "10px" }}>Date and Time</Th>

                  {KEYS.map((a, i) => {
                    return <Th key={i}>{a}</Th>;
                  })}
                </Tr>
              </Thead>

              <Tbody>
                {items.map((a, i) => {
                  return (
                    <Tr key={`d${i}_${a?._id}`}>
                      <Td>{moment(+a.timestamp).format("DD.MM.YYYY HH:mm")}</Td>
                      {KEYS.map((x, j) => {
                        return <Td key={j}>{a[x]}</Td>;
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </InnerPlaceholder>

          <ExportPlaceholder>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <ExportSpan
                onClick={() => {
                  let keys = [
                    "classification",
                    "final_probability",
                    "afb_probability",
                    "nrs_probability",
                    "good_quality_probability",
                    "af_detected",
                    "af_timestamp",
                  ];
                  let s = `timestamp;date;` + keys.join(";");
                  for (let i in items) {
                    let timestamp = items[i].timestamp;
                    let restVals = keys.map((xx) => items[i][xx]);
                    s = `${s}\n${timestamp};${moment(timestamp).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )};${restVals.join(";")}`;
                  }
                  CommonHelper.downloadFile(`export.csv`, s);
                }}
              >
                Export
              </ExportSpan>
            </div>
          </ExportPlaceholder>
        </>
      )}
    </Wrapper>
  );
}

const ExportPlaceholder = styled.div``;

const ExportSpan = styled.span`
  opacity: 0.8;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Table = styled.table`
  width: 350px;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr``;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
  font-size: 12px;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
  font-size: 12px;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const InnerPlaceholder = styled.div`
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
  overflow-y: auto;
`;

const Spo2TooltipWrapper = styled.div`
  background: white;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
`;

const ChartPlaceholder = styled.div``;

const Wrapper = styled.div`
  height: 360px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;
