import MMTCloud from "./MMTCloud";
import RonasBasicAPI from "./RonasBasicAPI";
import moment from "moment";

class HeartRateAPI {

    private static instance: HeartRateAPI
    private cloud?: MMTCloud;
    private ronasBasicAPI: RonasBasicAPI;

    constructor() {
        this.cloud = MMTCloud.getInstance();
        this.ronasBasicAPI = RonasBasicAPI.getInstance();
    }

    public static getInstance(): HeartRateAPI {
        if (!HeartRateAPI.instance) {
            HeartRateAPI.instance = new HeartRateAPI();
        }
        return HeartRateAPI.instance;
    }

    public async getHeartRateForDayFromCache(dayTimestamp: number) {
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(dayTimestamp, 'heart_rate');
        console.log('cahce: val = ', val);
        return val;
    }

    public async getHeartRateForDayAndYesterdayFromCache(dayTimestamp: number) {
        // let val0 = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(dayTimestamp - 86400000, 'heart_rate');
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(dayTimestamp, 'heart_rate');
        console.log('cahce: val = ', val);
        return val;
    }

    public async getHeartRateSlotsFromYesterdayAndToday(dayTimestamp: number) {
        let yestT = +moment(dayTimestamp).add(-1, 'days').startOf('day');
        let val0 = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(+dayTimestamp - 86400000, 'heart_rate');
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(+dayTimestamp, 'heart_rate');
        console.log('.>> val0, val = ', val0, val);
        let yestSlots = (val0?.slots ? val0?.slots : []).map((x: any) => ({
            ...x,
            t: heartRateSlotsDateToTimestamp(x.date, yestT)
        }));
        let todaySlots = (val?.slots ? val?.slots : []).map((x: any) => ({
            ...x,
            t: heartRateSlotsDateToTimestamp(x.date, +moment(dayTimestamp).startOf('day'))
        }));
        return yestSlots.concat(todaySlots);
    }

    public async getHeartRateSlotsForTodayFromCache(dayTimestamp: number) {
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(+dayTimestamp, 'heart_rate');
        let todaySlots = (val?.slots ? val?.slots : []).map((x: any) => ({
            ...x,
            t: heartRateSlotsDateToTimestamp(x.date, +moment(dayTimestamp).startOf('day'))
        }));
        return todaySlots;
    }

    public async getHeartRateStatsForYearFromCache(yearTimestamp: number) {
        let items = await this.ronasBasicAPI.getAllSummariesOfSpecifiedTypeFromCache('heart_rate');
        let from = +moment(yearTimestamp).startOf('year');
        let to = +moment(yearTimestamp).endOf('year');
        items = items.filter((x: any) => (x.avg_daily_heat_rate != undefined)).filter((x: any) => (+moment(x.date) >= +from && +moment(x.date) <= +to));
        return items;
    }

    public async getHeartRateForDayFromCloud(dayTimestamp: number) {
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRate(dayTimestamp, 'heart_rate');
        return val;
    }

}

export const heartRateSlotsDateToTimestamp = (date: string, t0: number = +moment().startOf('day')) => {
    let h = +date.split(':')[0];
    let m = +date.split(':')[1];
    let t = (m * 60 + 3600 * h) * 1000 + t0;
    return t;
}

export default HeartRateAPI;