import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import { PR_1, PR_2, PR_3 } from "../../ranges/new_tools/NewRangeTool";

const STUB_ITEMS = [
  {
    color: PR_1.color,
    title: "Pulse Rate",
    patientName: "David Richardson",
    text: "Pulse Rate is 110 bpm",
    date: "06-03-2023 10:01:00",
  },
  {
    color: PR_2.color,
    title: "Temperature",
    patientName: "Matthias Boidt",
    text: "Temperature is 39.1 C",
    date: "06-03-2023 09:57:12",
  },
  {
    color: PR_3.color,
    title: "Pulse Rate",
    patientName: "Atanur Deloitte",
    text: "Spo2 is 94",
    date: "06-03-2023 09:47:34",
  },
  {
    color: PR_1.color,
    title: "Pulse Rate",
    patientName: "David Richardson",
    text: "Pulse Rate is 140 bpm",
    date: "06-03-2023 09:45:51",
  },
];

export default function AlertsListTool(props) {
  const { alerts = STUB_ITEMS } = props;

  return (
    <Wrapper>
      {alerts.map((al, i) => {
        return (
          <AlertItem key={i} color={al.color}>
            <LeftPlaceholder>
              <PatientName>{al.patientName}</PatientName>
              <div>{al.text}</div>
            </LeftPlaceholder>
            <RigntPlaceholder>
              <div>{al.title}</div>
              <div>{al.date}</div>
            </RigntPlaceholder>
          </AlertItem>
        );
      })}
    </Wrapper>
  );
}
const Wrapper = styled.div``;

const PatientName = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const AlertItem = styled.div`
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  border: 2px solid ${(props) => props.color};
  display: flex;
  flex-direction: row;
`;

const LeftPlaceholder = styled.div`
  flex: 1;
`;

const RigntPlaceholder = styled.div``;
