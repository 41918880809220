import UiHelper from "../../../helpers/UiHelper";
import styled, { css } from "styled-components";
import { Spin } from "arui-feather/spin";
import { ParamRight } from "./ui/ParamElements";

const renderParamEnhanced2 = (
  value,
  label,
  type,
  priority,
  isMuted,
  isParamLoading = false,
  q,
  alarmType,
  outdated,
  color,
  tileLayout
) => {
  if (window.location.href.indexOf("af.") > -1 && label != "AF Burden") {
    return null;
  }
  if (window.location.href.indexOf("af.") == -1 && label == "AF Burden") {
    return null;
  }

  let isSmallTiles = false;

  if (tileLayout && tileLayout.includes("small")) {
    isSmallTiles = true;
  }

  let sValue = value == undefined ? "-" : value;
  // console.log("svalue", sValue, type);

  let rawString = undefined;
  let bpStringLength;
  if (type === "blood_pressure") {
    rawString = sValue;
    let bpParts = sValue.split("/");
    sValue = parseInt(bpParts[0], 10);
  }
  bpStringLength = rawString ? rawString.length : 0;

  // let alMap = (portalAlertsMap[type] || {});
  // let {color, priority} = alMap;
  let renderColor;
  let ico;
  let isBlinking;
  // let isBlinking = priority != undefined && +priority > 8;

  if (priority !== 0) {
  }

  let defaultIconColor = "#8798AD";

  if (outdated) {
    ico = UiHelper.getBadgeImage(type, 0);
    return (
      <ParamItemEnhanced
        type={type}
        alarmtype={alarmType}
        issmalltiles={isSmallTiles}
        // style2={{ border: "1px solid blue", boxSizing: "border-box" }}
        className={`param-item param-item-${alarmType}`}
      >
        <ParamImageEnhanced
          issmalltiles={isSmallTiles}
          alarmtype={alarmType}
          type={type}
          src={ico}
          className={`ico-priority-${priority} ico-priority-${alarmType}-${priority} param-icon`}
        />
        <ParamRight alarmtype={alarmType}>
          <ParamValueEnhanced
            alarmtype={alarmType}
            tilelayout={tileLayout}
            issmalltiles={isSmallTiles}
            color={defaultIconColor}
            // className={`param-value param-value-${priority}`}
            className="param-value"
          >
            {"-"}
          </ParamValueEnhanced>
          <ParamLabelEnhanced
            alarmtype={alarmType}
            issmalltiles={isSmallTiles}
            color={defaultIconColor}
            className={`param-label`}
          >
            {label}
          </ParamLabelEnhanced>
        </ParamRight>
      </ParamItemEnhanced>
    );
  }

  if (alarmType && alarmType.includes("news")) {
    // let newsScore = NewsScoreHelper.getScoreByType(type, sValue);
    // isBlinking = newsScore && newsScore > 1;
    isBlinking = false;
    renderColor = defaultIconColor;
    // newsScore === 0
    //   ? defaultIconColor
    //   : NewsScoreHelper.getColorByScore(newsScore);

    ico =
      window.location.href.indexOf("portal") > -1
        ? // ? NewsScoreHelper.getBadgeImage(type, newsScore)
          UiHelper.getBadgeImage(type, 0)
        : UiHelper.getBadgeImage(type, 0);
    priority = -1;
  } else if (alarmType && alarmType.includes("flag")) {
    // renderColor = UiHelper.getColorByPriority(priority);
    renderColor = color;
    ico = UiHelper.getBadgeImage(type, priority);
    isBlinking = priority != undefined && +priority > 8;
  } else if (alarmType && alarmType.includes("none")) {
    renderColor = UiHelper.getColorByPriority(0);
    ico = UiHelper.getBadgeImage(type, 0);
    isBlinking = priority != undefined && +priority > 8;
  }

  if (label == "Temperature (F)" && value != undefined) {
    // if (window.location.href.indexOf("portal") > -1) {
    sValue = +value * 1.8 + 32.0;
    sValue = (+sValue).toFixed(1);
    // }
  }

  if (type === "blood_pressure") {
    sValue = rawString;
  }
  let blinkingClassName =
    isBlinking == false ? "" : priority == 10 ? "blink_me" : "blink_me_slow";
  if (window.location.href.indexOf("portal") == -1) {
    blinkingClassName = "";
  }

  return (
    <ParamItemEnhanced
    issmalltiles={isSmallTiles}
      alarmtype={alarmType}
      type={type}
      className={`param-item param-item-${alarmType}`}
    >
      <ParamImageEnhanced
        issmalltiles={isSmallTiles}
        alarmtype={alarmType}
        style={{
          position: "relative",
          right: type === "blood_pressure" ? "-6px" : "0",
          bottom: type === "blood_pressure" ? "2px" : "0",
        }}
        type={type}
        src={ico}
        className={`${blinkingClassName} ico-priority-${priority} param-icon`}
      />
      <ParamRightEnhanced alarmtype={alarmType}>
        <ParamValueEnhanced
          tilelayout={tileLayout}
          alarmtype={alarmType}
          issmalltiles={isSmallTiles}
          tooltip={q}
          style={{
            fontSize:
              // type === "blood_pressure" && bpStringLength > 6 ? "28px" : "",
              tileLayout && type === "blood_pressure" && bpStringLength > 6
                ? tileLayout.includes("small")
                  ? "1em"
                  : "1.5em"
                : "",
            position: "relative",
          }}
          color={
            priority == 0 || window.location.href.indexOf("portal") == -1
              ? defaultIconColor
              : renderColor
          }
          className="param-value"
        >
          {isParamLoading == true ? (
            <Spin visible={true} className="loading-spinner" />
          ) : null}
          {sValue == "-" && isParamLoading == true ? null : sValue}

          {q == undefined ? null : (
            <ParamTooltip className={"ParamTooltip"}>{`Q: ${q}`}</ParamTooltip>
          )}
        </ParamValueEnhanced>
        <ParamLabelEnhanced
          issmalltiles={isSmallTiles}
          alarmtype={alarmType}
          color={
            priority == 0 || window.location.href.indexOf("portal") == -1
              ? defaultIconColor
              : renderColor
          }
          priority={priority}
          className={
            type === "temperature"
              ? `param-label temp-param-label`
              : `param-label`
          }
        >
          {label}
        </ParamLabelEnhanced>
      </ParamRightEnhanced>
    </ParamItemEnhanced>
  );
};

const ParamItemEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          height: 48px;
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: row;
          // align-items: center;
          align-items: start;
          // justify-content: end;
          box-sizing: border-box;
          position: relative;
          &:hover {
            opacity: 80%;
          }
        `;
      default:
        return css`
          height: 48px;
          cursor: pointer;
          flex: 1;
          display: flex;
          flex-direction: row;
          // align-items: center;
          align-items: start;
          //   justify-content: center;
          position: relative;
          // width: 100%;
          width: ${(props) =>
            props.type === "temperature" || props.type === "blood_pressure"
              ? "60px"
              : "100px"};
          &:hover {
            opacity: 80%;
          }
        `;
    }
  }}
`;
let order = `  order: ${(props) =>
  props.type === "blood_pressure" &&
  props.alarmtype === "flags" &&
  props.issmalltiles
    ? "5"
    : ""};`;

const ParamRightEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          display: flex;
          flex-direction: column;
          // justify-content: end;
          align-items: start;
          position: relative;
          // left: -0.2em;
          // border: 1px solid red;
        `;
      default:
        return css`
          display: flex;
          flex-direction: column;
          //   justify-content: end;
          align-items: start;
          position: relative;
          left: -0.2em;
        `;
    }
  }}
`;

// const a = `  font-weight: ${(props) =>
//   props.priority == undefined || props.priority == 0 ? "normal" : "bold"};`;
export const ParamLabelEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          font-size: ${(props) => (props.issmalltiles ? "10px" : "14px")};
          width: max-content;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          font-weight: bold;
          position: relative;
          top: ${(props) => (props.issmalltiles ? "-4px" : "-2px")};
        `;
      default:
        return css`
          font-size: ${(props) => (props.issmalltiles ? "10px" : "14px")};
          width: max-content;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          font-weight: bold;
          position: relative;
          top: ${(props) => (props.issmalltiles ? "-4px" : "-2px")};
        `;
    }
  }}
`;

const reducedImageTypes = ["temperature", "respiration_rate"];
export const ParamImageEnhanced = styled.img`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          margin-right: 10px;
          //   width: 36px;
          //   width: ${(props) =>
            props.type === "temperature" ? "32px" : "36px"};
          width: ${(props) => {
            if (props.issmalltiles) {
              if (reducedImageTypes.includes(props.type)) {
                return "26px";
              } else {
                return "28px";
              }
            } else {
              if (reducedImageTypes.includes(props.type)) {
                return "32px";
              } else {
                return "36px";
              }
            }
          }};
        `;
      default:
        return css`
          margin-right: 10px;
          //   width: 36px;
          //   width: ${(props) =>
            props.type === "temperature" ? "32px" : "36px"};
          width: ${(props) => {
            if (props.issmalltiles) {
              if (reducedImageTypes.includes(props.type)) {
                return "26px";
              } else {
                return "28px";
              }
            } else {
              if (reducedImageTypes.includes(props.type)) {
                return "32px";
              } else {
                return "36px";
              }
            }
          }};
        `;
    }
  }}
`;

const ParamValueEnhanced = styled.div`
  ${(props) => {
    switch (props.alarmtype) {
      case "news":
        return css`
          font-weight: bold;
          font-size: ${(props) => (props.issmalltiles ? "24px" : "30px")};
          margin-bottom: ${(props) => (props.issmalltiles ? "0px" : "0px")};
          margin-top: ${(props) => (props.issmalltiles ? "0px" : "2px")};
          line-height: 1em;
          justify-self: end;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          position: relative;
        `;
      default:
        return css`
          //   margin-bottom: 20px;
          font-weight: bold;
          //   font-size: 30px;
          font-size: ${(props) => (props.issmalltiles ? "20px" : "30px")};
          line-height: 1em;
          color: ${(props) =>
            props.color == undefined ? "#8798AD" : props.color};
          position: relative;
          left: ${(props) => (props.issmalltiles ? "0" : "-0.0rem")};

          // :NewsParamTooltip {
          //   .NewsParamTooltip {
          //     display: block;
          //   }
          // }
        `;
    }
  }}
`;

const ParamTooltip = styled.div`
  display: none;
  position: absolute;
  top: -30px;
  left: -20px;
  width: 80px;
  text-align: center;
  font-size: 12px;
  background: white;
  z-index: 100;
  border-radius: 1000px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
`;

export default renderParamEnhanced2;
