// import GatewaysAPI from '../../api/GatewaysAPI'

import * as types from '../ActionTypes'
import GatewaysAPI from "../../api/GatewaysAPI";


const loadGateways_ = () => {
    return {
        type: types.LOAD_GATEWAYS
    }
}
export const loadGatewaysSuccess = (gateways) => {
    return {
        type: types.LOAD_GATEWAYS_SUCCESS,
        gateways: gateways
    }
}
let loadGatewaysFailed = (error) => {
    return {
        type: types.LOAD_GATEWAYS_FAIL,
        error: error
    }
}

export function setForceGateway(gateway) {
    return (dispatch, getState) => {
        dispatch(loadGatewaysSuccess([gateway]))
    }
}

//thunk
export function loadAllGateways(uuid) {
    return (dispatch, getState) => {
        dispatch(loadGateways_());
        return GatewaysAPI.getDoctorGateways(uuid).then(
            gateways => dispatch(loadGatewaysSuccess(gateways)),
            error => dispatch(loadGatewaysFailed(error))
        )
    }
}


let createGateway_ = () => {
    return {
        type: types.CREATE_GATEWAY
    }
}
let createGatewaySuccess = (gateway) => {
    return {
        type: types.CREATE_GATEWAY_SUCCESS,
        gateway: gateway
    }
}
let createGatewayFailed = (error) => {
    return {
        type: types.CREATE_GATEWAY_FAIL,
        error: error
    }
}
//
// //thunk
export function createGateway(doctorUUID, data) {
    return (dispatch, getState) => {
        dispatch(createGateway_());
        return GatewaysAPI.createGateway(doctorUUID, data).then(
            gateway => dispatch(createGatewaySuccess(gateway)),
            error => dispatch(createGatewayFailed(error))
        )
    }
}

//
let updateGateway_ = () => {
    return {
        type: types.UPDATE_GATEWAY
    }
}
let updateGatewaySuccess = (gateway) => {
    return {
        type: types.UPDATE_GATEWAY_SUCCESS,
        gateway: gateway
    }
}
let updateGatewayFailed = (error) => {
    return {
        type: types.UPDATE_GATEWAY_FAIL,
        error: error
    }
}

//thunk
export function updateGateway(doctorUUID, data, userCode) {
    return (dispatch, getState) => {
        dispatch(updateGateway_());
        return GatewaysAPI.updateGateway(doctorUUID, data, userCode).then(
            gateway => dispatch(updateGatewaySuccess(gateway)),
            error => dispatch(updateGatewayFailed(error))
        )
    }
}

//

let deleteGateway_ = () => {
    return {
        type: types.DELETE_GATEWAY
    }
}
let deleteGatewaySuccess = (id) => {
    return {
        type: types.DELETE_GATEWAY_SUCCESS,
        id: id
    }
}
let deleteGatewayFailed = (error) => {
    return {
        type: types.DELETE_GATEWAY_FAIL,
        error: error
    }
}

//thunk
export function deleteGateway(doctorUUID, id) {
    return (dispatch, getState) => {
        dispatch(deleteGateway_());
        return GatewaysAPI.deleteGateway(doctorUUID, id).then(
            () => dispatch(deleteGatewaySuccess(id)),
            error => dispatch(deleteGatewayFailed(error))
        )
    }
}
