import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import MmtTemplate from '../templates/MmtTemplate'
import SettingsPanel from "../settings/panels/SettingsPanel";
import CardiolyseCsvTool from "../cardiolyse/tools/CardiolyseCsvTool";
import DoctorTemplate from "../templates/DoctorTemplate";

function ToolsApp() {

    return (
        <DoctorTemplate active={'tools'}>

            <Wrapper>
                <CardiolyseCsvTool/>
            </Wrapper>

        </DoctorTemplate>
    );
}

const Wrapper = styled.div`

`;

export default ToolsApp
