import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import ls from "local-storage";
import toast from "react-hot-toast";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";

const ALARMS_STATES = [
  {
    label: "Between Flags RealTime",
    value: "flags-realtime",
  },
  {
    label: "Between Flags 1/30min",
    value: "flags-30min",
  },
  {
    label: "NEWS RealTime",
    value: "news-realtime",
  },
  {
    label: "NEWS 1/30min",
    value: "news-30min",
  },
  {
    label: "Cor-RISK 1/30min",
    value: "corrisk",
  },
];

export default function UserAlarmsTool(props) {
  // const [layoutStatus, setLayoutStatus] = useState(0);
  // let { alarm } = useMappedState(
  //   useCallback((state) => {
  //     return {
  //       alarm: state.ui.alarm,
  //     };
  //   }, [])
  // );
  // const dispatch = useDispatch();

  // useEffect(() => {}, []);
  const { alarms_system } = props;

  const [selected, setSelected] = useState(alarms_system);
  useEffect(() => {
    setSelected(props.alarms_system);
  }, [alarms_system]);

  const handleCheckboxChange = (newAlarm) => {
    setSelected(newAlarm);
    props.onChange(newAlarm);
    // setSelected(newAlarm);
    // ls.set("alarm", newAlarm);
    // dispatch(UiActions.changeAlarm(newAlarm));
    // console.log("ALR: ", alarm);
  };

  return (
    <>
      {/* {ALARMS_STATES.map((value, index) => {
        <div>
          <Label className="setting-text-active">
            <Checkbox
              id={value.value}
              name="select-alarm-1"
              type="checkbox"
              color="blue"
            />
            {value.label}
          </Label>
        </div>;
      })} */}
      <div
        style={{
          // marginBottom: "10px",
          display: "flex",
          // justifyContent: "center",
        }}
      >
        <Label className="setting-text-active">
          <Checkbox
            id="select-none-alarm"
            name="select-alarm-0"
            type="checkbox"
            color="blue"
            checked={selected === "none"}
            onChange={(x) => {
              handleCheckboxChange("none");
              // console.log("alrm x", x);
              // toast.success("Alarm saved to None");
            }}
          />
          None
        </Label>
        <Label className="setting-text-active">
          <Checkbox
            id="flags-realtime"
            name="select-alarm-1"
            type="checkbox"
            color="blue"
            checked={selected === "between-flags"}
            onChange={() => {
              handleCheckboxChange("between-flags");
              // toast.success("Alarm saved to Between Flags");
            }}
          />
          Between Flags
        </Label>
        {/* <Label className="setting-text-active">
          <Checkbox
            id="flags-30min"
            name="select-flags-30min"
            type="checkbox"
            color="blue"
            checked={alarm === "flags2"}
            onChange={() => handleCheckboxChange("flags2")}
          />
          Between Flags 1/30min
        </Label> */}
        <Label className="setting-text-active">
          <Checkbox
            id="news-realtime"
            name="select-news-realtime"
            type="checkbox"
            color="blue"
            checked={selected === "news"}
            onChange={() => {
              handleCheckboxChange("news");
              // toast.success("Alarm saved to NEWS");
            }}
          />
          NEWS2
        </Label>
        {/* <Label className="setting-text-active">
          <Checkbox
            id="news-30min"
            name="select-news-30min"
            type="checkbox"
            color="blue"
            checked={alarm === "news2"}
            onChange={() => handleCheckboxChange("news2")}
          />
          NEWS 1/30min
        </Label> */}
        <Label className="setting-text-inactive" style={{ opacity: "0.45" }}>
          <Checkbox
            id="corrisk"
            name="select-corrisk"
            type="checkbox"
            color="blue"
            // checked={selected === "corrisk"}
            checked={false}
            onChange={() => {
              return 0;
              // handleCheckboxChange("corrisk");
              // toast.success("Alarm saved to COR-Risk");
            }}
          />
          Cor-RISK
        </Label>
      </div>
    </>
  );
}

const Label = styled.div`
  // font-weight: bold;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  opacity: 0.9;
  color: dark;
`;

const Checkbox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;

const SettingSubHeader = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;
