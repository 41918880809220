import { combineReducers } from "redux";

import AuthReducer from "./AuthReducer.js";
import DataReducer from "./DataReducer.js";
import PatientsReducer from "./PatientsReducer.js";
import BraceletsReducer from "./BraceletsReducer.js";
import GroupsReducer from "./GroupsReducer.js";
import InmatesReducer from "./InmatesReducer.js";
import GatewaysReducer from "./GatewaysReducer.js";
import UiReducer from "./UiReducer.js";

export const reducer = combineReducers({
  auth: AuthReducer,
  patients: PatientsReducer,
  data: DataReducer,
  bracelets: BraceletsReducer,
  inmates: InmatesReducer,
  gateways: GatewaysReducer,
  groups: GroupsReducer,
  ui: UiReducer,
});
