import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

export default function HorSelector(props) {
    const {
        options = [], value = undefined, onSelect = a => {

        }
    } = props;

    return (
        <Wrapper>
            {options.map((a, j) => {
                return (
                    <Item selected={(a.value == value)} key={`${a.value}_${j}`} onClick={() => {
                        onSelect(a)
                    }}>
                        {a.label}
                    </Item>
                )
            })}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid lightgrey;
    border-radius: 3px;
`;

const Item = styled.div`
    border-right: 1px solid lightgrey;
    padding: 4px;
    background: ${props => (props.selected == true ? 'lightgrey' : 'white')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    font-weight: ${props => (props.selected == true ? 'normal' : 'normal')};
    padding-left: 6px;
    padding-right: 6px;
    opacity: ${props => props.selected == true ? 1 : 0.5};
    :hover{
      opacity: ${props => props.selected == true ? 1 : 0.8};
    }
    :last-of-type{
      border-right: none;
    }
`;
