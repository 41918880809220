import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";
import axios from "axios";
import DoctorAPI from "../../../api/DoctorAPI";
import DictionariesAPI from "../../../api/DictionariesAPI";
import Spin from "arui-feather/spin";
import toast from "react-hot-toast";
import UsersInterfaceAPI from "../../../api/UsersInterfaceAPI";
import moment from "moment";

const NameEditorTool = (props) => {
  const { currentUserObj, email, id } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      let allInmates = state.inmates.inmatesMap.toArray();
      let id = state?.auth?.currentUserObj?.usersUser?.uuid;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        id: id,
      };
    })
  );
  //   console.log("currentobj", currentUserObj, id);

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [patient, setPatient] = useState(undefined);
  const [device, setDevice] = useState(undefined);

  const [healthSettings, setHealthSettings] = useState(undefined);

  const [statisticsSelectedDayTimestamp, setStatisticsSelectedDayTimestamp] =
    useState();

  const [complianceCurrentMonthTimestamp, setComplianceCurrentMonthTimestamp] =
    useState(+moment().startOf("month").add(2, "days"));

  const [statisticsCalendarOpen, setStatisticsCalendarOpen] = useState(false);
  const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(false);

  const [selectedRanges, setSelectedRanges] = useState({});
  const [rangesLoading, setRangesLoading] = useState(false);
  const [dictionaryItems, setDictionaryItems] = useState([]);
  const [dictionaryData, setDictionaryData] = useState(undefined);

  useEffect(() => {
    setRangesLoading(true);
    UsersInterfaceAPI.getLazySettings(id).then((x) => {
      console.log("setSelectedRanges: x = ", x);
      setSelectedRanges(x.ranges);
      setRangesLoading(false);
    });
    DoctorAPI.getPatientHealthUserSettings(id).then((x) => {
      console.log("!!! got health settings! x = ", x);
      setHealthSettings(x);
    });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getPatient(id).then((x) => {
      console.log("got patient! x = ", x);
      setPatient(x);
      setFirstName(x.first_name);
      setLastName(x.last_name);
      setLoading(false);
      DoctorAPI.getPatientDevice(id).then((d) => {
        console.log("device = ", d);
      });
    });
    DictionariesAPI.getUserItems(id).then((x) => {
      setDictionaryItems(x);
    });
    DictionariesAPI.getUserItems(id).then((arr) => {
      let arr2 = arr.filter((x) => x.type == "dictionary_data");
      let a = arr2.length == 0 ? undefined : arr2[0];
      setDictionaryData(a?.data);
    });
  }, [id]);

  //   let { first_name, last_name, healthUser } = patient;
  //   let name = `${first_name == undefined ? "-" : first_name} ${
  //     last_name == undefined ? "-" : last_name
  //   }`;
  //   let weight = healthUser == undefined ? undefined : healthUser.weight;
  //   let wearing =
  //     healthSettings == undefined ? undefined : healthSettings?.wearing;
  //   let height = healthUser == undefined ? undefined : healthUser.height;
  //   let gender = healthUser == undefined ? undefined : healthUser.gender;
  //   let birthday = healthUser == undefined ? undefined : healthUser.birthday;

  //   if (name == "- -") {
  //     let { code, email } = patient;
  //     if (code != undefined) {
  //       name = code;
  //     }
  //     console.log("- -", patient);
  //   }
  const onSave = async (d, dictData) => {
    console.log("onSave: dictData = ", dictData);
    setLoading(true);
    await DoctorAPI.updateUsersProfile(id, d);
    await DoctorAPI.updateHealthProfile(id, d);
    if (d.wearing != undefined) {
      await DoctorAPI.savePatientHealthUserSettings(id, {
        wearing: d.wearing,
      });
    }
    await DictionariesAPI.saveUserItem({
      uuid: id,
      type: "dictionary_data",
      data: dictData,
    });
    setLoading(false);
    toast("Saved!");
  };

  return (
    <div>
      <Wrapper>
        <TopRow>
          <TopField>
            <Label className="edit-label">Name 1</Label>
            <Input
              value={firstName}
              placeholder={"First name"}
              onChange={(evt) => {
                setFirstName(evt.target.value);
              }}
            />
          </TopField>
          <TopField>
            <Label className="edit-label">Name 2</Label>
            <Input
              value={lastName}
              placeholder={"Last name"}
              onChange={(evt) => {
                setLastName(evt.target.value);
              }}
            />
          </TopField>
        </TopRow>
      </Wrapper>
      <BottomPlaceholder>
        <Button
          onClick={() => {
            if (loading == true) {
              return;
            }
            let dd = {
              first_name: firstName,
              last_name: lastName,
              //   external_patient_id,
              //   weight: weight == undefined || weight == "" ? 70 : +weight,
              //   height: height == undefined || height == "" ? 175 : +height,
              //   gender: gender == undefined || gender == "" ? "male" : gender,
              //   wearing: wearing,
              //   birthday:
              //     birthday == undefined || birthday == ""
              //       ? "2000-01-01"
              //       : birthday,
            };
            onSave(dd, dictionaryData);
          }}
        >
          <Spin visible={loading} />
          {loading == true ? null : "Save"}
        </Button>
      </BottomPlaceholder>
    </div>
  );
};

const Label = styled.div`
  font-weight: bold;
  opacity: 0.5;
`;
const Button = styled.div`
  background: #147ada;
  border-radius: 5px;
  height: 40px;
  width: 100px;
  font-size: 16px;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
`;

const BottomPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`;
const Wrapper = styled.div``;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const TopField = styled.div`
  //   flex: 1;
  margin-bottom: 10px;
  :first-of-type {
    margin-left: 0px;
  }
  :last-of-type {
    margin-right: 0px;
  }
`;
const Input = styled.input`
  background: #ffffff;
  border: 1px solid rgba(135, 152, 173, 0.8);
  border-radius: 6px;
  outline: none;
  height: 42px;
  box-sizing: border-box;
  width: 80%;
  font-size: 16px;
  line-height: 18px;
  padding-left: 7px;
  ::placeholder {
    color: #8798ad;
    opacity: 0.5;
  }
`;
const GroupItem = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
  background: white;
`;

const EditImg = styled.img`
  cursor: pointer;
  height: 14px;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DialogInner = styled.div`
  width: 620px;
  padding: 10px;
  box-sizing: border-box;
  max-height: calc(100vh - 20px);
  // height: 400px;
  max-height: 720px;
  overflow-y: auto;
`;

export default NameEditorTool;
