import moment, { relativeTimeRounding } from "moment";
import DrawHelper from "./DrawHelper";
import { alignTimeToHalfHour } from "../components/bp/PatientDayBpTool";

const BpFilterHelper = {
  removeNullPoinst(points, sbpKey, dbpKey) {
    let noNullArr = points
      .filter((x) => x?.[dbpKey] && x?.[sbpKey])
      .map((x) => {
        return {
          bpm: x?.bpm ? x.bpm : null,
          [dbpKey]: x[dbpKey] ? x[dbpKey] : null,
          [sbpKey]: x[sbpKey] ? x[sbpKey] : null,
          t: x.timestamp,
          date: moment(x.timestamp).format("HH:mm"),
        };
      });
    return noNullArr;
  },

  alignToHalfHour(points, sbpKey, dbpKey) {
    let newPoints = [];
    let startDay;
    let xMap = {};
    points.map((a, i) => {
      let nd = a?.[dbpKey];
      let ns = a?.[sbpKey];
      let bpm = a?.bpm;
      let timestamp = a?.t;
      // align every nibp point to half hour mark
      let timeObject = alignTimeToHalfHour(timestamp, ns && nd);
      let h = timeObject.format("HH");
      let m = timeObject.format("mm");
      // console.log(timeObject.format("HH:mm"), d, timestamp);
      let mapKey;
      if (xMap[`${h}:${m}`]) {
        mapKey = `${+h + 24}:${m}`;
      } else {
        mapKey = `${h}:${m}`;
      }
      // console.log(mapKey);

      xMap[mapKey] = a;
      newPoints.push({
        [dbpKey]: nd ? nd : null,
        [sbpKey]: ns ? ns : null,
        bpm: bpm ? bpm : null,
        t: timeObject ? timeObject.valueOf() : null,
        // date: timeObject ? timeObject.format("HH:mm") : null,
        date: timeObject ? mapKey : null,
        dateWithDay: timeObject ? timeObject.format("DD_HH:mm") : null,
      });
    });
    return newPoints;
  },

  calculateGridPoints(points, mode, dayTimestamp) {
    let xPoints = points.map((x) => ({
      t: +x.start_timestamp,
      ...x,
      date: moment(+x.start_timestamp).format("HH:mm"),
    }));

    let gridPoints = DrawHelper.getBpMonotonePoints(
      xPoints,
      mode == "day"
        ? +dayTimestamp
        : moment(+dayTimestamp).startOf("day").add(-12, "hours"),
      mode
    );
    return gridPoints;
  },

  calculateXData(gridPoints, sbpKey, dbpKey) {
    let xData = gridPoints.map((x) => {
      return {
        ["blood pressure"]: [x[dbpKey], x[sbpKey]],
        t: +x.t,
        ["heart rate"]: x.hr,
        dbp: x?.[dbpKey],
        sbp: x?.[sbpKey],
        date: x.date,
      };
    });
    return xData;
  },

  calculateXDataAi(gridPoints) {
    let xDataAi = gridPoints.map((x) => {
      let _ts = +x.t;
      let timeObject = alignTimeToHalfHour(x.t, x?._raw_response?.SBP_ai);

      return {
        // ['blood pressure']: [x?._raw_response?.DBP_ai, x?._raw_response?.SBP_ai],
        ["blood pressure"]: [
          x?._raw_response?.DBP_ai,
          x?._raw_response?.SBP_ai,
        ],
        ["blood_pressure"]: [
          x?._raw_response?.DBP_ai_debug,
          x?._raw_response?.SBP_ai_debug,
        ],
        // t: +x.t,
        t: timeObject ? timeObject.valueOf() : null,
        ["heart rate"]: x.hr,
        ["_raw_response"]: x?._raw_response,
        // date: x.date,
        date: timeObject ? timeObject.format("HH:mm") : x.date,
        dbp: x?._raw_response?.DBP_ai,
        d: x?._raw_response?.DBP_ai,
        dbp_: x?._raw_response?.DBP_ai_debug,
        sbp: x?._raw_response?.SBP_ai,
        s: x?._raw_response?.SBP_ai,
        sbp_: x?._raw_response?.SBP_ai_debug,
      };
    });
    return xDataAi;
  },

  calculateXDataAiForCombine(gridPoints, sbpKey, dbpKey) {
    let xDataAi = gridPoints.map((x) => {
      let timeObject = alignTimeToHalfHour(x.t, x?.[sbpKey]);
      return {
        t: timeObject ? timeObject.valueOf() : null,
        ["heart rate"]: x.hr,
        // date: x.date,
        date: timeObject ? timeObject.format("HH:mm") : x.date,
        dbp: x?.[dbpKey],
        d: x?.[dbpKey],
        sbp: x?.[sbpKey],
        s: x?.[sbpKey],
      };
    });
    return xDataAi;
  },

  calculateFPoints(xDataAI, mode, startOfDay) {
    let fPoints = xDataAI;
    // for (let i in fPoints) {
    //   if (fPoints[i].sbp == undefined || fPoints[i].sbp == 0) {
    //     delete fPoints[i].sbp;
    //   }
    // }
    // console.table(fPoints, ["date"]);
    fPoints = fPoints
      .filter((x) => x.date != undefined && x.date.indexOf(":") > -1)
      .map((xx, i) => {
        return {
          ...xx,
          date_key:
            (+xx.t < startOfDay ? -24 * 60 : 0) +
            (+xx.date.split(":")[0] * 60 + +xx.date.split(":")[1]),
          date_key0: +xx.date.split(":")[0] * 60 + +xx.date.split(":")[1],
          isDayBefore: +xx.t < startOfDay,
        };
      })
      .map((x) => {
        let xDate_key = Math.floor(+x.date_key / 30) * 30;
        return {
          ...x,
          date_key: xDate_key,
        };
      });

    if (fPoints.length > 1) {
      let xMap = {};
      for (let i = 1; i < fPoints.length; i++) {
        let delta = fPoints[i].date_key - fPoints[+i - 1].date_key;
        let xKey = `delta-${delta}`;
        if (xMap[xKey] == undefined) {
          xMap[xKey] = {
            xKey: xKey,
            delta: delta,
            number: 0,
          };
        }
        xMap[xKey].number = +xMap[xKey].number + 1;
      }
      // console.log("xMap = ", xMap);
      let arr = Object.keys(xMap)
        .map((xKey) => xMap[xKey])
        .sort((a, b) => +b.number - +a.number);
      let freqDelta = +arr[0].delta;
      // console.log("freqDelta = ", freqDelta);
      let step_ = +freqDelta == 1 ? 1 : 30;
      let fMap = {};
      let maxVal = Math.max(...fPoints.map((xx) => xx.date_key));

      if (mode == "day") {
        maxVal = 24 * 60;
      } else {
        maxVal = 12 * 60;
      }

      for (let i in fPoints) {
        let fp = fPoints[i];
        fMap[`x-${fp.date_key}`] = fp;
      }

      let t = mode == "day" ? 0 : -12 * 60;
      let rPoints = [];

      while (+t <= +maxVal) {
        let tKey = `x-${t}`;
        let h_ = Math.floor(+t / 60.0);
        let min_ = +t - h_ * 60;
        let date_ =
          `${t < 0 ? 24 - Math.abs(h_) : h_}`.padStart(2, "0") +
          ":" +
          `${min_}`.padStart(2, "0");
        let val_ = fMap[tKey];
        if (val_ == undefined) {
          val_ = {
            date: date_,
            date_key: t,
          };
        }
        t = +t + +step_;
        rPoints.push(val_);
      }

      fPoints = rPoints;
    }
    return fPoints;
  },

  mapNullValuesToUndefined(fPoints, sbpKey, dbpKey) {
    if (!fPoints || fPoints.length <= 1) {
      return fPoints;
    }
    let uPoints = fPoints.map((x) => {
      return {
        ...x,
        [dbpKey]: x[dbpKey] === null ? undefined : x[dbpKey],
        [sbpKey]: x[sbpKey] === null ? undefined : x[sbpKey],
      };
    });
    return uPoints;
  },
  calculateExtralinesFromFPoints(fPoints, sbpKey, dbpKey) {
    let extraLines = DrawHelper.getExtraDottedBloodPressureChartsDataV1(
      fPoints,
      sbpKey,
      dbpKey
    );
    // console.log(extraLines, sbpKey);

    for (let i in fPoints) {
      let t = fPoints[i].date_key;
      for (let j in extraLines) {
        let eLine = extraLines[j];
        let lp = eLine.points[0];
        let rp = eLine.points[1];
        if (lp.date_key == t) {
          fPoints[i][`${sbpKey}_gap_${j}`] = lp[sbpKey];
          fPoints[i][`${dbpKey}_gap_${j}`] = lp[dbpKey];
        }
        if (rp.date_key == t) {
          fPoints[i][`${sbpKey}_gap_${j}`] = rp[sbpKey];
          fPoints[i][`${dbpKey}_gap_${j}`] = rp[dbpKey];
        }
      }
    }
    return extraLines;
  },
};

export default BpFilterHelper;
