import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import { get24HrTicks, get36HrTicks } from "./AiSleepViewTool";
import useDimensions from "react-use-dimensions";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_DAY = 60 * 60 * 24;
const IS_ASLEEP_HEIGHT = 1;
const IS_AWAKE_HEIGHT = 2;
const IS_AWAKE_COLOR = "#FC0903ff";
const IS_ASLEEP_COLOR = "#3763B5ff";

function findSleepStateChange(sleepData, key) {
  let changePoints = [];
  if (sleepData.length === 0) {
    return changePoints;
  }
  let lastValue = sleepData[0][key];
  let lastIndex = 0;

  for (let i = 1; i < sleepData.length; i++) {
    if (sleepData[i][key] !== lastValue) {
      changePoints.push({
        index: i,
        indexFrom: lastIndex,
        indexTo: i,
        sleepState: sleepData[i - 1][key],
        // dateFrom: sleepData[i - 1].date,
        dateFrom: sleepData[lastIndex].date,
        _dateFrom: moment(sleepData[i - 1].date).format("DD HH:mm Z"),
        dateTo: sleepData[i].date,
        timestamp: sleepData[i].timestamp,
      });
      lastIndex = i;
      lastValue = sleepData[i][key];
    }
  }
  changePoints.push({
    index: sleepData.length - 1,
    indexFrom: lastIndex,
    indexTo: sleepData.length - 1,
    dateFrom: sleepData[sleepData.length - 2].date,
    dateTo: sleepData[sleepData.length - 1].date,
    sleepState: sleepData[sleepData.length - 1][key],
    timestamp: sleepData[sleepData.length - 1].timestamp,
  });
  return changePoints;
}

export default function SleepRriTool(props) {
  const {
    uuid,
    date,
    vertAxisLabels = ["DEEP", "LIGHT", "REM", "AWAKE"],
  } = props;
  const [rriData, setRriData] = useState([]);
  const [rriDataStates, setRriDataStates] = useState([]);
  const [loading, setLoading] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  // const chartRef = useRef(null);
  const [mousePos, setMousePos] = useState({
    mousex: undefined,
    mousey: undefined,
  });
  const [ref, { y, x, left, height, width, bottom }] = useDimensions();
  useEffect(() => {
    let mounted = true;
    const from = +moment(date).clone().subtract(12, "hours");
    const to = +moment(date).clone().add(12, "hours");
    DoctorAPI.getPatientRriSleep(uuid, from, to)
      .then((data) => {
        if (!data || data.length === 0) {
          return;
        }
        console.log("sleep-measurements-continuous/sleep-data", data);
        let _data = findSleepStateChange(data, "sleep_stage");
        // console.log(_data);

        setRriData(data);
        setRriDataStates(_data);
      })
      .catch((e) => {
        console.error(e);
      });
    return () => (mounted = false);
  }, [uuid, date]);

  let ticks;
  let ticksNumber = 12;

  const dateFrom = moment(date);
  const hourOffset = 12;
  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");

  ticks = get24HrTicks(ticksNumber, date);
  //   console.log(rriDataStates);

  //   const optSlots = TarnsformerHelper.getGroupedSleepPoints(rriDataStates);
  //   console.log(optSlots);

  let slots24hr = rriDataStates.map((x, i) => {
    const toFixedValue = 2;
    let _height = TarnsformerHelper.getHeightBySleepStageName(
      rriData[x.indexFrom]?.sleep_stage
    );
    let _color = TarnsformerHelper.getColorOfSleepBarByHeight(_height);
    let _left_percent =
      (100 *
        moment(rriData[x.indexFrom]?.date).diff(twelveHoursBefore, "seconds")) /
      SECONDS_PER_DAY;
    let _width =
      3600000 *
      moment(rriData[x.indexTo]?.date).diff(
        moment(rriData[x.indexFrom]?.date),
        "seconds"
      );
    let _width_percent =
      (100 *
        moment(rriData[x.indexTo]?.date).diff(
          moment(rriData[x.indexFrom]?.date),
          "seconds"
        )) /
      SECONDS_PER_DAY;
    return {
      color: _color,
      start_date: rriData[x.indexFrom]?.date,
      end_date: rriData[x.indexTo]?.date,
      sleep_stage_0: rriData[x.indexFrom]?.sleep_stage,
      sleep_stage_1: rriData[x.indexTo]?.sleep_stage,
      from: Date.parse(rriData[x.indexFrom]?.date),
      to: Date.parse(rriData[x.indexTo]?.date),
      height: _height,
      timestamp: rriData[x.indexFrom]?.date,
      type: x.is_asleep ? 1 : 0,
      // raw: moment(x.raw).diff(twelveHoursBefore, "minutes") / (24 * 60),
      left_percent: _left_percent.toFixed(toFixedValue),
      width: _width.toFixed(toFixedValue), // in milliseconds
      width_percent: _width_percent.toFixed(toFixedValue),
    };
  });
  //   console.log("slots24hr,", slots24hr);

  return (
    <Wrapper className="sleep-rri-wrapper" sfyle={{ height: 260 }}>
      {!rriData || rriData.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there no sleep RRI data for this day."}
        />
      ) : (
        <>
          <LeftPlaceholder>
            {/* <div>tz: {tz}</div> */}
            <LeftInnerPlaceholder>
              {vertAxisLabels.map((x, i) => (
                <VertAxisLabel
                  className="sleep-vert-label"
                  key={`${x}`}
                  height={+i + 1}
                >
                  {x}
                </VertAxisLabel>
              ))}
            </LeftInnerPlaceholder>
          </LeftPlaceholder>
          <RightPlaceholder>
            <TopPlaceholder className="sleep-top-placeholder">
              <ChartPlaceholder ref={ref}>
                {[1, 2, 3, 4].map((x) => (
                  <HorLine key={x} height={x} />
                ))}
                {slots24hr.map((opt, i) => {
                  let {
                    sleep_stage_1,
                    sleep_stage_0,
                    end_date,
                    start_date,
                    from,
                    to,
                  } = opt;

                  //   let sw = TarnsformerHelper.getHeightBySleepStageName(sleepState);
                  let text = sleep_stage_0;
                  const startString = moment(from).format("DD HH:mm");
                  const untilString = moment(to).format("DD HH:mm");
                  return (
                    <Box
                      {...opt}
                      key={`opt_${i}_${opt.width_percent}`}
                      $index={i}
                      onMouseEnter={() => {
                        setHoveredIndex(i);
                      }}
                      onMouseLeave={() => {
                        setHoveredIndex(undefined);
                      }}
                      onMouseMove={(event) => {
                        const pageX = event.pageX;
                        const pageY = event.pageY;
                        // console.log({ pageX, pageY });
                        // console.log(
                        //   90 + pageX - (window.innerWidth * opt.left_percent) / 100
                        // );
                        // console.log(window.innerWidth, opt.left_percent);

                        setMousePos({
                          mousex: pageX,
                          mousey: pageY,
                        });
                      }}
                    >
                      {hoveredIndex === i ? (
                        <BoxToolTip
                          className="box-info"
                          $mousex={mousePos.mousex}
                          $offset={(
                            mousePos.mousex -
                            x -
                            opt.left_percent * 0.01 * width 
                          ).toFixed(0)}
                          $voffset={60}
                        >
                          <div>RRI Sleep Status: {text}</div>
                          <div>Start: {startString}</div>
                          <div>Until: {untilString}</div>
                        </BoxToolTip>
                      ) : null}
                    </Box>
                  );
                })}
              </ChartPlaceholder>
            </TopPlaceholder>

            <BottomPlaceholder className="sleep-bottom-placeholder">
              {ticks.map((x, i) => {
                return (
                  <Tick key={i} left={x.left}>
                    <TickInner className="sleep-tick-label">
                      {moment(x.t).format("DD dd HH:mm")}
                    </TickInner>
                  </Tick>
                );
              })}
            </BottomPlaceholder>
          </RightPlaceholder>
        </>
      )}
    </Wrapper>
  );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = "lightgrey";

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${(props) => `${props.left}%`};
`;

const TickInner = styled.div`
  width: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  top: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${(props) => `calc(${(100.0 * props.height) / 4.0}% - 1px)`};
  z-index: 1;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${(props) => `${(100.0 * props.height) / 4.0}%`};
  width: ${(props) => `${props.width_percent}%`};
  left: ${(props) => `${props.left_percent}%`};
  box-sizing: border-box;
  background: ${(props) => props.color};
  z-index: ${(props) => (100 - props.$index) * 10};
  &:hover {
    .box-info {
      display: block;
      z-index: ${(props) => props.$index * 10};
    }
  }
`;

const BoxToolTip = styled.div`
  display: block;
  padding: 10px;
  z-index: 10000 !important;
  color: #111;
  width: max-content;
  height: 60px;
  border: 1px solid lightgrey;
  background: #ffffff;
  font-size: 16px;
  position: absolute;
  // top: 2rem;
  top: ${(props) => `${props.$voffset}px`};
  left: ${(props) => `${props.$offset}px`};
`;
const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${(props) =>
    `calc(${(100.0 * props.height) / 4.0}% - 1rem + ${bottomHeight}px)`};
  z-index: 1;
  :last-of-type {
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
`;
