import styled from "styled-components";
import bpSpanImg from "./images/bp_0.svg";
import bpSpanImg1 from "./images/bp_1.svg";

function getCuffBpSvg(c) {
  if (isNaN(c)) {
    return {
      image: bpSpanImg,
      color: "#e90909",
      text: `${"No BP Cuff"}`,
    };
  }
  if (c < 3) {
    return {
      image: bpSpanImg,
      color: "#e90909",
      text: `${"Not enough cuffs"}`,
    };
  } else if (c >= 3) {
    return {
      image: bpSpanImg1,
      color: "#52cc99",
      text: `${c}`,
    };
  }
}
const CuffBpSpan = (props) => {
  const { cuffBpCount, isDebugMode } = props;

  // if (isDebugMode && !cuffBpCount) return null;

  let { image, color, text } = getCuffBpSvg(cuffBpCount);

  return (
    <Wrapper style={{ color: color }}>
      <img style={{ height: 25, marginRight: 4 }} src={image} />
      <div>
        <span style={{ opacity: 1, color: color }}>{text}</span>
        {cuffBpCount && cuffBpCount >= 3 && (
          <span
            style={{ fontWeight: 500, marginLeft: 2, fontSize: 11 }}
          >{`BP cuffs`}</span>
        )}
      </div>
    </Wrapper>
  );
};

export default CuffBpSpan;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
  color: #8798ad;
  font-weight: 700;
  font-size: 12px;
`;
