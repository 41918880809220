import axios from 'axios'
import moment from 'moment'
import env from "react-dotenv";
import * as ls from 'local-storage'
import CommonHelper from "../helpers/CommonHelper";
import {DEMO_FAKE_USERS} from "../constants/config";

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;


const DictionariesAPI = {

    async getUserItems(uuid) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v1/dictionary_items/${uuid}`)).data;
        return pld;
    },

    async saveUserItem(data) {
        console.log('saveUserItem: data = ', data);
        if (data.type == undefined || data.uuid == undefined) {
            throw 'type or uuid is not set'
        }
        let {uuid} = data;
        let pld = (await axios.put(`${DOCTORS_API_ENDPOINT}/v1/dictionary_items/${uuid}`, data)).data;
        return pld;
    }

}

export default DictionariesAPI;