import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import moment from "moment";
import {
  ResponsiveContainer,
  Line,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ComposedChart,
  ReferenceArea,
  Tooltip,
  Legend,
  Label,
} from "recharts";

const Y_DOMAIN = [0, 180];
const Y_SLEEP_DOMAIN = [0, 5 * 35];
const SLEEP_COLORS = {
  type4: {
    color: "#0fa9fc",
    height: 4,
    label: "Deep",
  },
  type3: {
    color: "#174d89",
    height: 2,
    label: "Light",
  },
  type2: {
    color: "#3763b5",
    height: 3,
    label: "REM",
  },
  type1: {
    color: "#FF5E58",
    height: 5,
    label: "Awake",
  },
};
const BP_LINES = {
  systolic: {
    color: "#1234cc",
  },
  diastolic: {
    color: "#cc1232",
  },
};
const HEART_RATE_LINE_COLOR = "#a8a4a8aa";

export default function NIBPOverviewTool(props) {
  const { date, data } = props;
  // const data = props.data;
  const bpmData = data?.heartRatePoints || [];
  const bpData = data?.bpMeasurements || [];
  const sleepData = data?.sleepPoints || [];
  const bpmPoints = bpmData.map((bpmp, ind) => {
    const verbalTime = moment(bpmp.timestamp).format("DD dd HH:mm");
    const bpmValue = bpmp.bpm > 0 ? bpmp.bpm : null;
    return {
      bpm: bpmValue,
      date: bpmp.date,
      timestamp: bpmp.timestamp,
      verbal: verbalTime,
    };
  });
  // const bpmPoints = _bpmPoints.filter((bpm) => bpm.bpm > 0);

  const bpPoints = bpData.map((bpp, ind) => {
    return {
      sbp: bpp?.sbp_ai,
      dbp: bpp?.dbp_ai,
      start: moment(bpp?.start_timestamp).format("DD dd HH:mm"),
      end: moment(bpp?.end_timestamp).format("DD dd HH:mm"),
      timestamp: bpp.start_timestamp,
    };
  });

  // console.log("bpmpoints", bpmPoints);
  console.log("bppoints", bpPoints);
  // console.log(
  //   "first bpm/bp points",
  //   bpmPoints[0]?.verbal,
  //   bpmPoints[0]?.timestamp,
  //   "||",
  //   bpPoints[0]?.start
  // );
  // console.log(
  //   "last bpm/bp points",
  //   bpmPoints[bpmPoints.length - 1]?.verbal,
  //   bpmPoints[bpmPoints.length - 1]?.timestamp,
  //   "||",
  //   bpPoints[bpPoints.length - 1]?.start
  // );

  const sleepPoints = sleepData.map((sleepp) => {
    // return sleepp.timestamp;
    return moment(sleepp?.start_date).valueOf();
    // return {
    //   timestamp: sleepp?.timestamp,
    // };
  });
  function findBiggestGapInterval(numbers) {
    let maxGap = -1;
    let maxGapStartIndex = -1;
    let maxGapEndIndex = -1;

    for (let i = 0; i < numbers.length - 1; i++) {
      const gap = numbers[i + 1] - numbers[i];
      if (gap > maxGap) {
        maxGap = gap;
        maxGapStartIndex = i;
        maxGapEndIndex = i + 1;
      }
    }

    return [
      numbers[0],
      numbers[maxGapStartIndex],
      numbers[maxGapEndIndex],
      numbers[numbers.length - 1],
    ];
  }

  const sleepAreasBoundaries = findBiggestGapInterval(sleepPoints);
  // console.log(
  //   `The biggest gap interval is [${moment(sleepAreasBoundaries[0]).format(
  //     "DD HH:mm zz"
  //   )}, ${moment(sleepAreasBoundaries[1]).format("DD HH:mm zz")}, ${moment(
  //     sleepAreasBoundaries[2]
  //   ).format("DD HH:mm zz")}, ${moment(sleepAreasBoundaries[3]).format(
  //     "DD HH:mm zz"
  //   )}].`
  // );

  const dateObj = moment(date, "YYYY-MM-DD");

  const dateFrom = dateObj.clone().subtract(22, "hours").utcOffset("-02:00"); // needs better calculation
  const dateTo = dateObj.clone().add(26, "hours").utcOffset("+02:00");
  console.log(dateFrom.format("DD dd HH:mm zz"));
  console.log(dateTo.format("DD dd HH:mm zz"));

  const X_DOMAIN = [dateFrom.valueOf(), dateTo.valueOf()];
  const tickNum = 24;
  const interval = (dateTo.valueOf() - dateFrom.valueOf()) / tickNum;
  const X_TICKS = Array.from(
    { length: tickNum },
    (_, i) => dateFrom + Math.round(i * interval)
  );

  let sleepRegions = [];
  let lastType;
  let lastStart = 0;
  let lastUntil = 0;
  let lastIndexRange = 0;
  let currentIndex = 0;

  do {
    let regionObj = {};
    regionObj.type = sleepData[currentIndex]?.type;
    // regionObj.start_ts = sleepData[currentIndex]?.timestamp;
    regionObj.start_ts = moment(sleepData[currentIndex]?.start_date).valueOf();
    regionObj.start_ts_v = moment(sleepData[currentIndex]?.timestamp).format(
      "DD HH:mm z"
    );
    regionObj.indexFrom = lastStart;

    for (let i = lastStart; i < sleepData.length; i++) {
      if (
        sleepData[i].type !== sleepData[currentIndex].type ||
        i === sleepData.length - 1
      ) {
        regionObj.indexUntil = i;
        lastStart = i;
        currentIndex = i;
        lastIndexRange = i - lastStart;
        // regionObj.end_ts = sleepData[i].timestamp;
        regionObj.end_ts = moment(sleepData[i]?.start_date).valueOf();
        regionObj.duration =
          moment(sleepData[i]?.start_date).valueOf() - regionObj.start_ts;
        break;
      }
    }
    // console.log(currentIndex, lastIndexRange);

    sleepRegions.push(regionObj);
  } while (currentIndex + lastIndexRange < sleepData.length - 1);
  // console.table(sleepData, ["type", "start_date", "end_date"]);

  // console.table(sleepRegions, ["type", "start_ts_v"]);

  return (
    <NIBPWrapper>
      <p>
        Here some text on possible warnings and errors that might have been
        present during the calculation of the report.
      </p>
      <ChartTitle>48-hour NIBP Overview</ChartTitle>
      <ResponsiveContainer width={"100%"} height={400}>
        <ComposedChart
          title="48-hour NIBP Overview"
          data={bpmPoints}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          {/* <XAxis
            type="number"
            domain={X_DOMAIN}
            xAxisId={1}
            dataKey="timestamp"
            hide={true}
            tickCount={10}
          /> */}
          <XAxis
            type="number"
            domain={X_DOMAIN}
            ticks={X_TICKS}
            tickFormatter={(tick) => {
              return moment(tick).format("DD-HH:mm");
            }}
            xAxisId={2}
            hide={true}
            dataKey={"timestamp"}
            tickCount={tickNum}
            interval={0}
          />
          <YAxis yAxisId={"left"} domain={Y_DOMAIN}>
            <Label
              value={"Blood Pressure"}
              angle={-90}
              position={"outside"}
              fill={"#676767"}
              fontSize={14}
            ></Label>
          </YAxis>
          {/* <YAxis domain={Y_DOMAIN} /> */}
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          {/* <Line
            xAxisId={1}
            yAxisId={"right"}
            type="monotone"
            dataKey="bpm"
            stroke={HEART_RATE_LINE_COLOR}
            dot={false}
            hide={true}
          /> */}
          <Legend
            iconType="circle"
            layout=""
            verticalAlign="top"
            wrapperStyle={{
              border: "1px solid black",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: "0.4rem",
              paddingBottom: "0.4rem",
              margin: "0.3rem",
            }}
            align="right"
            content={renderBPLegend}
          />
          <Line
            xAxisId={2}
            yAxisId={"left"}
            data={bpPoints}
            dataKey="sbp"
            type={"monotone"}
            stroke={BP_LINES.systolic.color}
            dot={true}
          />
          <Line
            xAxisId={2}
            yAxisId={"left"}
            data={bpPoints}
            dataKey="dbp"
            type={"monotone"}
            stroke={BP_LINES.diastolic.color}
            dot={true}
          />
          <ReferenceArea
            x1={sleepAreasBoundaries[0]}
            x2={sleepAreasBoundaries[1]}
            xAxisId={2}
            yAxisId={"left"}
            stroke="red"
            strokeOpacity={0.0}
            ifOverflow={"hidden"}
            fill="#03BFFF"
          />

          <ReferenceArea
            x1={sleepAreasBoundaries[2]}
            x2={sleepAreasBoundaries[3]}
            xAxisId={2}
            yAxisId={"left"}
            stroke="red"
            strokeOpacity={0.0}
            ifOverflow={"hidden"}
            fill="#03BFFF"
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </ComposedChart>
      </ResponsiveContainer>
      <ChartTitle>Activity and Sleep Stages</ChartTitle>
      <ResponsiveContainer width={"100%"} height={200}>
        <ComposedChart
          title="48-hour NIBP Overview"
          data={bpmPoints}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
        >
          <XAxis
            type="number"
            domain={X_DOMAIN}
            xAxisId={1}
            dataKey="timestamp"
            interval={0}
            ticks={X_TICKS}
            tickCount={tickNum}
            tickFormatter={(tick) => {
              const _hr = moment(tick).hour();
              if (_hr === 12 || _hr === 0) {
                return moment(tick).format("Do HH:mm");
              }
              return moment(tick).format("HH:mm");
            }}
            angle={-15}
            hide={false}
          ></XAxis>
          {/* <XAxis
            type="number"
            domain={X_DOMAIN}
            tickFormatter={(tick) => {
              return moment(tick).format("DD-HH:mm");
            }}
            xAxisId={2}
            hide={true}
            dataKey={"timestamp"}
          /> */}
          <YAxis
            yAxisId={"left"}
            domain={Y_SLEEP_DOMAIN}
            tickCount={6}
            ticks={[0, 35, 70, 105, 140]}
            tickFormatter={(t) => {
              switch (t) {
                case 0:
                  return "";
                case 35:
                  return "Deep";
                case 70:
                  return "Light";
                case 105:
                  return "REM";
                case 140:
                  return "Awake";
              }
            }}
            hide={true}
          ></YAxis>
          <YAxis yAxisId={"right"} orientation="left" domain={Y_DOMAIN}>
            <Label
              value={"Heart Rate"}
              angle={-90}
              position={"outside"}
              fill={"#676767"}
              fontSize={14}
            ></Label>
          </YAxis>
          <CartesianGrid
            // vertical={true}
            // verticalPoints={X_TICKS}
            // interval={1}
            // verticalCoordinatesGenerator={(props) => {
            //   console.log("vertprot", props);
            //   return 1;
            // }}
            strokeDasharray="4 4"
          />
          <Legend
            iconType="circle"
            layout=""
            verticalAlign="top"
            wrapperStyle={{
              border: "1px solid black",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: "0.4rem",
              paddingBottom: "0.4rem",
              margin: "0.3rem",
            }}
            align="right"
            content={renderSleepLegend}
          />
          <Tooltip />
          <Line
            xAxisId={1}
            yAxisId={"right"}
            type="monotone"
            dataKey="bpm"
            stroke={HEART_RATE_LINE_COLOR}
            opacity={"50%"}
            dot={false}
          />

          {sleepRegions.map((r, index) => {
            if (r.duration > 180 * 30 * 1000) {
              return null;
            }
            let color = "white";
            let height = 0;
            switch (r.type) {
              case 1:
                color = SLEEP_COLORS.type1.color;
                height = SLEEP_COLORS.type1.height;
                break;
              case 2:
                color = SLEEP_COLORS.type2.color;
                height = SLEEP_COLORS.type2.height;
                break;
              case 3:
                color = SLEEP_COLORS.type3.color;
                height = SLEEP_COLORS.type3.height;
                break;
              case 4:
                color = SLEEP_COLORS.type4.color;
                height = SLEEP_COLORS.type4.height;
                break;
              default:
                color = "white";
            }
            return (
              <ReferenceArea
                x1={r.start_ts}
                x2={r.end_ts}
                y1={0}
                y2={height * 30}
                xAxisId={1}
                yAxisId={"left"}
                opacity={"100%"}
                ifOverflow={"hidden"}
                fill={color}
              />
            );
          })}
          {/* <ReferenceArea
            x1={sleepAreasBoundaries[0]}
            x2={sleepAreasBoundaries[1]}
            xAxisId={1}
            yAxisId={"left"}
            stroke="red"
            strokeOpacity={0.3}
            ifOverflow={"hidden"}
            fill="#03BFFF"
          />

          <ReferenceArea
            x1={sleepAreasBoundaries[2]}
            x2={sleepAreasBoundaries[3]}
            xAxisId={1}
            yAxisId={"left"}
            stroke="red"
            strokeOpacity={0.3}
            ifOverflow={"hidden"}
            fill="#03BFFF"
          /> */}
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </NIBPWrapper>
  );
}

const renderBPLegend = (props) => {
  const { payload } = props;

  const size = "0.7rem";
  return (
    <div style={{ marginRight: "1rem" }}>
      <div style={{ display: "flex", alignItems: "center", fontSize: size }}>
        <div
          style={{
            width: size,
            height: size,
            background: BP_LINES.systolic.color,
            marginRight: ".3rem",
          }}
        ></div>
        <span>Systolic Blood Pressure</span>
      </div>
      <div style={{ display: "flex", alignItems: "center", fontSize: size }}>
        <div
          style={{
            width: size,
            height: size,
            background: BP_LINES.diastolic.color,
            marginRight: ".3rem",
          }}
        ></div>
        <span>Diastolic Blood Pressure</span>
      </div>
    </div>
  );
};

const renderSleepLegend = (props) => {
  const { payload } = props;

  const size = "0.7rem";
  return (
    <div style={{ marginRight: "1rem" }}>
      <div style={{ display: "flex", alignItems: "center", fontSize: size }}>
        <div
          style={{
            width: size,
            height: size,
            background: SLEEP_COLORS.type1.color,
            marginRight: ".3rem",
          }}
        ></div>
        <span>Awake</span>
      </div>
      <div style={{ display: "flex", alignItems: "center", fontSize: size }}>
        <div
          style={{
            width: size,
            height: size,
            background: SLEEP_COLORS.type4.color,
            marginRight: ".3rem",
          }}
        ></div>
        <span>REM</span>
      </div>
      <div style={{ display: "flex", alignItems: "center", fontSize: size }}>
        <div
          style={{
            width: size,
            height: size,
            background: SLEEP_COLORS.type2.color,
            marginRight: ".3rem",
          }}
        ></div>
        <span>LIGHT</span>
      </div>
      <div style={{ display: "flex", alignItems: "center", fontSize: size }}>
        <div
          style={{
            width: size,
            height: size,
            background: SLEEP_COLORS.type3.color,
            marginRight: ".3rem",
          }}
        ></div>
        <span>DEEP</span>
      </div>
    </div>
  );
};

const NIBPWrapper = styled.div`
  width: 100%;
  height: 720px;
  // border: 1px solid blue;
  // box-sizing: content-box;
`;

const ChartTitle = styled.span`
  display: table;
  font-weight: bold;
  margin: 0 auto;
`;
