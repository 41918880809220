import { useState } from "react";
import styled from "styled-components";

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import Spin from "arui-feather/spin";

export default function UpdateSessionForm(props) {
  const { onSave = (d) => {}, loading = false, status = "new" } = props;

  const [timestamp_from, setTimestamp_from] = useState(
    props.timestamp_from == undefined ? +new Date() : +props.timestamp_from
  );
  const [timestamp_to, setTimestamp_to] = useState(
    props.timestamp_to == undefined
      ? +new Date() + 86400000
      : +props.timestamp_to
  );

  const [name, setName] = useState(props.name == undefined ? "" : props.name);
  const [description, setDescription] = useState(
    props.description ? props.description : ""
  );
  const [session_code, setSession_code] = useState(
    props.session_code ? props.session_code : ""
  );

  return (
    <Wrapper>
      <Row>
        <Label>Start date</Label>
        <DateTimePicker
          value={
            timestamp_from == undefined ? new Date() : new Date(timestamp_from)
          }
          onChange={(d) => {
            setTimestamp_from(+new Date(d));
          }}
        />
      </Row>

      <Row>
        <Label>Stop date</Label>
        <DateTimePicker
          value={
            timestamp_to == undefined
              ? new Date(+new Date() + 86400000)
              : new Date(timestamp_to)
          }
          onChange={(d) => {
            setTimestamp_to(+new Date(d));
          }}
        />
      </Row>

      <Row>
        <Label>Session code</Label>
        <Input
          value={session_code}
          onChange={(evt) => {
            setSession_code(evt.target.value);
          }}
        />
      </Row>

      <Row>
        <Label>Name</Label>
        <Input
          value={name}
          onChange={(evt) => {
            setName(evt.target.value);
          }}
        />
      </Row>

      <Row>
        <Label>Description</Label>
        <Textarea
          value={description}
          onChange={(evt) => {
            setDescription(evt.target.value);
          }}
        />
      </Row>

      <Row>
        <Spin visible={loading} />

        {loading == true || status == "uploaded" ? null : (
          <Button
            onClick={() => {
              onSave({
                name: name,
                description: description,
                session_code: session_code,
                timestamp_from: timestamp_from,
                timestamp_to: timestamp_to,
              });
            }}
          >
            Save
          </Button>
        )}
      </Row>
    </Wrapper>
  );
}

const Button = styled.div`
  height: 42px;
  background: ${(props) =>
    props.background == undefined ? "#0072BC" : props.background};
  border-radius: 8px;
  cursor: ${(props) => (props.disabled == true ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;

  padding-left: 20px;
  padding-right: 20px;

  :hover {
    opacity: ${(props) => (props.disabled == true ? 0.5 : 0.8)};
  }
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  outline: none;

  :focus {
    border-color: blue;
  }
`;

const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 4px;
  outline: none;
  min-height: 6em;

  :focus {
    border-color: blue;
  }
`;

const Wrapper = styled.div``;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;
