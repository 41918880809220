import { useState, useEffect } from "react";
import styled from "styled-components";
import { getMaskedEmail } from "./FindHcpTool";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";

export default function MyHcpAdminTool(props) {
  const { uuid } = props;
  const [hcpAdmin, setHcpAdmin] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getHcpAdmin()
      .then((d) => {
        console.log("hcpadmin ", d);
        setHcpAdmin(d.hcpAdmin);
        setLoading(false);
      })
      .catch((e) => console.error(e));
  }, [uuid]);

  if (loading == true) {
    return (
      <div>
        <Code />
      </div>
    );
  }

  return (
    <Wrapper>
      <h2>My HCP Admin</h2>
      {hcpAdmin === undefined ? (
        <div>
          You are not approved yet as an HCP, please contact you HCP admin to
          add you as HCP.
        </div>
      ) : (
        <ItemsPlaceholder>
          <BraceletItem
            key={hcpAdmin.uuid}
            onClick={() => {
              //   onAdd(a);
            }}
          >
            {`${hcpAdmin?.first_name || ""} ${hcpAdmin.last_name || ""}`}
            <div
              style={{
                opacity: 0.5,
                fontSize: "12px",
                fontStyle: "italic",
              }}
            >
              {/* {`${getMaskedEmail(hcpAdmin?.email || "")}`} */}
              {`${hcpAdmin?.email || ""}`}
            </div>
          </BraceletItem>
        </ItemsPlaceholder>
      )}
    </Wrapper>
  );
}

const TopFindPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const normalBorder = `1px solid rgba(135, 152, 173, 0.15)`;
const dangerBorder = `2px solid #FA4646;`;

const BraceletItem = styled.div`
  font-weight: bold;
  padding: 10px;
  border-radius: 4px;
  background: #ffffff;
  border: ${(props) => (props.danger == true ? dangerBorder : normalBorder)};
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 1rem;

  :hover {
    background: whitesmoke;
  }
`;

const ItemsPlaceholder = styled.div``;

const TopPlaceholder = styled.div``;

const Wrapper = styled.div``;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
`;
