import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import { configureStore } from "@reduxjs/toolkit";

import logger from "redux-logger";
import { thunk } from "redux-thunk";
import { reducer } from "./redux/reducers";
import { StoreContext } from "redux-react-hook";
import axios from "axios";

import RouterApp from "./components/apps/RouterApp";
import DoctorAPI from "./api/DoctorAPI";

const store = createStore(reducer, undefined, compose(applyMiddleware(thunk)));
const _store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

axios.interceptors.request.use(
  (config) => {
    let dd = DoctorAPI.getTokensGetParamFromLs();
    if (dd.health_token != undefined) {
      config.headers["x-health-token"] = dd.health_token;
    }
    if (dd.users_token != undefined) {
      config.headers["x-users-token"] = dd.users_token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// const store = createStore(
//     reducer,
//     undefined,
//     compose(
//         applyMiddleware(ReduxThunk)
//     )
// )

function App() {
  return (
    <StoreContext.Provider value={store}>
      <RouterApp />
    </StoreContext.Provider>
  );
}

export default App;
