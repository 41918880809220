import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios'
import {Button} from "../../auth/ui";

import Spin from 'arui-feather/spin'
import NiceModal from "../../modals/NiceModal";
import CommonHelper from "../../../helpers/CommonHelper";

async function loadSummaries(uuid, from, to) {
    let pld = (await axios.get(`https://api.study-integration.corsano.com/users/${uuid}/summaries-in-range/${from}/${to}`)).data;
    return pld;
}

const getDaysArray = function (s, e) {
    for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
        a.push(new Date(d));
    }
    return a;
};

async function loadManySummaries(loadingUUIDs = [], from, to, onUpdated = () => {

}, onStart = xId => {

}) {
    for (let i in loadingUUIDs) {
        let loadingUUID = loadingUUIDs[i];
        onStart(loadingUUID);
        let arr = await loadSummaries(loadingUUID, from, to);
        onUpdated(arr, loadingUUID);
    }
    onStart(undefined);
}

export default function ExportHrvValuesTool(props) {
    const {
        users = [],
        from,
        to
    } = props;

    const [dataMap, setDataMap] = useState({});
    const [loadingUUID, setLoadingUUID] = useState(undefined);
    const [fetchingUUID, setFetchingUUID] = useState(undefined);
    const [totalLoading, setTotalLoading] = useState(false);

    // useEffect(() => {
    //     if (loadingUUID == undefined) {
    //         return;
    //     }
    //     setFetchingUUID(loadingUUID);
    //     loadSummaries(loadingUUID, from, to).then(arr => {
    //         let map = {...dataMap, [loadingUUID]: arr};
    //         setFetchingUUID(undefined);
    //         setDataMap(map);
    //     });
    // }, [loadingUUID, from, to]);

    console.log('ExportHrvValuesTool: render: users = ', users);
    console.log('ExportHrvValuesTool: render: dataMap = ', dataMap);

    if (users.length == 0 || from == undefined || to == undefined) {
        return null;
    }

    let days = getDaysArray(new Date(from), new Date(to)).slice(0, 90);

    return (
        <Wrapper>

            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            Name
                        </Th>
                        <Th>
                            Email
                        </Th>
                        {days.map((a, i) => {
                            return (
                                <React.Fragment key={+a}>
                                    <Th>
                                        {`HRV ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`RHR ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`AWAKE ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`LIGHT ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`DEEP ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`REM ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`TRANQUILITY ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`READINESS ${moment(a).format('DD.MM')}`}
                                    </Th>
                                    <Th>
                                        {`STEPS ${moment(a).format('DD.MM')}`}
                                    </Th>
                                </React.Fragment>
                            )
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {users.map((a, i) => {
                        let arr = dataMap[a.uuid] || [];
                        return (
                            <Tr key={a.uuid}>
                                <Td onClick={() => {
                                    setLoadingUUID(a.uuid);
                                }}>
                                    {`${a.first_name} ${a.last_name}`}
                                </Td>
                                <Td>
                                    {a.email}
                                </Td>
                                {days.map((a, i) => {
                                    let s = moment(a).format('YYYY-MM-DD');
                                    let x = arr.filter(x => (x.date == s))[0];
                                    let hrv = x?.stress?.avg_hrv;
                                    let rhr = x?.recovery?.resting_bpm_today;

                                    let awake_time = x?.sleep?.awake_time;
                                    let light_time = x?.sleep?.light_time;
                                    let rem_time = x?.sleep?.rem_time;
                                    let deep_time = x?.sleep?.deep_time;
                                    let tranquility = x?.sleep?.tranquility;
                                    let readiness = x?.recovery?.value;
                                    let total_steps = x?.activity?.total_steps;

                                    let gs = x => (x == undefined ? '' : x);
                                    return (
                                        <React.Fragment key={+a}>
                                            <Td>
                                                {hrv == undefined ? null : Math.round(+hrv)}
                                            </Td>
                                            <Td>
                                                {rhr == undefined ? null : rhr}
                                            </Td>

                                            <Td>
                                                {gs(awake_time)}
                                            </Td>
                                            <Td>
                                                {gs(light_time)}
                                            </Td>
                                            <Td>
                                                {gs(deep_time)}
                                            </Td>
                                            <Td>
                                                {gs(rem_time)}
                                            </Td>
                                            <Td>
                                                {gs(tranquility)}
                                            </Td>
                                            <Td>
                                                {readiness == undefined ? '' : readiness.toFixed(1)}
                                            </Td>
                                            <Td>
                                                {gs(total_steps)}
                                            </Td>

                                        </React.Fragment>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>

            {loadingUUID != undefined ? null :
                <div style={{marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Button style={{paddingLeft: 20, paddingRight: 20}} onClick={async () => {
                        setTotalLoading(true);
                        setDataMap({});
                        let xMap = dataMap;
                        await loadManySummaries(users.map(x => x.uuid), from, to, (xArr, xUUID) => {
                            console.log('-->> dataMap = ', dataMap);
                            xMap = {...xMap, [xUUID]: xArr};
                            console.log('-->> map = ', xMap);
                            setDataMap(xMap);
                        }, xId => {
                            setFetchingUUID(xId);
                        });
                        setTotalLoading(false);
                        let csv = `Name;Email;${days.map(a => `HRV ${moment(a).format('DD.MM')};RHR ${moment(a).format('DD.MM')};AWAKE;LIGHT;DEEP;REM;TRANQUILITY;READINESS;TOTAL_STEPS`).join(';')}\n`;
                        for (let i in users) {
                            let u = users[i];
                            let arr = xMap[u.uuid] || [];
                            csv = `${csv}${u.first_name} ${u.last_name};${u.email}`;
                            for (let j in days) {
                                let a = days[j];
                                let s = moment(a).format('YYYY-MM-DD');
                                let x = arr.filter(x => (x.date == s))[0];
                                let hrv = x?.stress?.avg_hrv;
                                let rhr = x?.recovery?.resting_bpm_today;

                                let awake_time = x?.sleep?.awake_time;
                                let light_time = x?.sleep?.light_time;
                                let rem_time = x?.sleep?.rem_time;
                                let deep_time = x?.sleep?.deep_time;
                                let tranquility = x?.sleep?.tranquility;
                                let readiness = x?.recovery?.value;
                                let total_steps = x?.activity?.total_steps;


                                hrv = (hrv == undefined) ? '' : Math.round(+hrv);
                                rhr = (rhr == undefined) ? '' : Math.round(+rhr);

                                awake_time = (awake_time == undefined) ? '' : Math.round(+awake_time);
                                light_time = (light_time == undefined) ? '' : Math.round(+light_time);
                                rem_time = (rem_time == undefined) ? '' : Math.round(+rem_time);
                                deep_time = (deep_time == undefined) ? '' : Math.round(+deep_time);
                                tranquility = (tranquility == undefined) ? '' : Math.round(+tranquility);
                                readiness = (readiness == undefined) ? '' : Math.round(+readiness);
                                total_steps = (total_steps == undefined) ? '' : Math.round(+total_steps);



                                csv = `${csv};${hrv};${rhr};${awake_time};${light_time};${deep_time};${rem_time};${tranquility};${readiness};${total_steps}`;
                            }
                            csv = `${csv}\n`;
                        }
                        CommonHelper.downloadFile(`export_${moment(+from).format('DD.MM.YYYY')}.csv`, csv);
                    }}>
                        <Spin visible={totalLoading}/>
                        {totalLoading == true ? null : 'Start exporting'}
                    </Button>
                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const InnerPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 10px;
  width: 620px;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 5px;
`;


const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;