import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import BinaryMeasurementsAPI from "../../../api/BinaryMeasurementsAPI";
import CommonHelper from "../../../helpers/CommonHelper";

export default function EcgStatsTable(props) {
  const { patients = [], tag } = props;
  const [items, setItems] = useState([]);
  const [userEcgMap, setUserEcgMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [vloading, setVloading] = useState(0);
  const [stats, setStats] = useState({
    totalEcgRecords: 0,
    totalSuccessfulRecords: 0,
    totalFailedRecords: 0,
    perUserStats: {},
  });

  //   console.log("ecg patients", patients);
  //   console.log("ecg tag", tag);

  useEffect(() => {
    let uuidList = patients.map((x) => x.uuid);
    // console.log(uuidList);

    let results = uuidList.reduce((acc, item) => {
      acc[item] = { records: [] };
      return acc;
    }, {});
    // console.log("results", results);

    setLoading(true);
    setVloading(0);
    setItems([]);
    uuidList.map((userId, index) => {
      // console.log(userId, index);
      BinaryMeasurementsAPI.getInstance()
        .getBinaryMeasurements(userId, false)
        .then((pld) => {
          results[userId] = { records: pld };
          setVloading((v) => v + 1);
        })
        .catch((e) => console.error("BinaryMeasurementsAPI", e));
    });
    setLoading(false);
    // console.log("FINAL RESULTS", results);
    setUserEcgMap(results);
  }, [patients, tag]);
  //   console.log("userEcgMap", userEcgMap);

  let totalEcgRecords = 0;
  let totalFailedRecords = 0;
  let totalSuccessfulRecods = 0;
  let perUserMap = patients.reduce((acc, item) => {
    acc[item.uuid] = { total: 0, failed: 0, success: 0 };
    return acc;
  }, {});
  //   console.log(perUserMap);

  for (const [key, value] of Object.entries(userEcgMap)) {
    const records = value.records;
    const _length = records.length;

    let successfulRecords = records.filter((x) => {
      let { description } = x;
      let isError =
        description != undefined && description.indexOf("ERROR_") > -1;
      if (isError) {
        return false;
      }
      return true;
    });
    // console.log("successfulRecords.length", successfulRecords.length);

    const successNum = successfulRecords.length;
    const failedNum = _length - successNum;
    perUserMap[key]["total"] = _length;
    perUserMap[key]["success"] = successNum;
    perUserMap[key]["failed"] = failedNum;
    totalEcgRecords += _length;
    totalSuccessfulRecods += successNum;
    totalFailedRecords += failedNum;
  }

  //   console.log({ totalEcgRecords, totalSuccessfulRecods, totalFailedRecords });
  //   console.log(perUserMap);

  return (
    <Wrapper>
      <TableTitle>
        ECG Trending{" "}
        {vloading !== patients.length &&
          window.location.href.indexOf("localhost") < 0 &&
          "(loading...)"}
      </TableTitle>
      <Table>
        <Thead>
          <Tr>
            <Th>User</Th>
            <Th>Total</Th>
            <Th>Success</Th>
            <Th>Failed</Th>
          </Tr>
        </Thead>
        <Tbody>
          {patients.map((p, i) => {
            let total = perUserMap[p.uuid].total;
            let success = perUserMap[p.uuid].success;
            let failed = perUserMap[p.uuid].failed;

            // console.log(perUserMap[p.uuid]);

            return (
              <Tr>
                <SmallTd>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      CommonHelper.linkTo(
                        `/studies/${tag}/user/${p.uuid}`,
                        true
                      );
                    }}
                  >
                    {CommonHelper.getPatientHeadingString(p)}
                  </span>
                </SmallTd>
                <SmallTd>{total}</SmallTd>
                <SmallTd>{success}</SmallTd>
                <SmallTd>{failed}</SmallTd>
              </Tr>
            );
          })}
          <Tr>
            <Th>All</Th>
            <Th>{totalEcgRecords}</Th>
            <Th>{totalSuccessfulRecods}</Th>
            <Th>{totalFailedRecords}</Th>
          </Tr>
        </Tbody>
      </Table>
    </Wrapper>
  );
}

const TableTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  padding: 15px;
  background: white;
  border-radius: 4px;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  height: 40px;
`;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
  font-weight: bold;
  padding-left: 7px;
  opacity: 0.8;
  font-size: 14px;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
  padding-left: 7px;
`;

const SmallTd = styled(Td)`
  font-size: 14px;
`;

const Thead = styled.thead`
  background: #f5f5f5;
`;

const Tbody = styled.tbody``;
