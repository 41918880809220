import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {useInterval} from "../../apps/RouterApp";
import DoctorAPI from "../../../api/DoctorAPI";

import exportImg from './export_img.svg'

export default function PpgExportTool(props) {
    const {
        uuid, sessionId
    } = props;

    const [loading, setLoading] = useState(false);
    const [shouldCheck, setShouldCheck] = useState(false);
    const [statusPld, setStatusPld] = useState(undefined);
    const [done, setDone] = useState(false);

    useInterval(() => {
        if (shouldCheck == false || loading == true || done == true) {
            return;
        }
        setLoading(true);
        DoctorAPI.getSessionPpgExportStatus(uuid, sessionId).then(stPld => {
            setStatusPld(stPld);
            setLoading(false);
            if (stPld.status == 'finished') {
                setDone(true);
                window.open(DoctorAPI.getSessionPpgExportCsvLink(uuid, sessionId), '_blank').focus();
            }
        });
    }, 2000);

    useEffect(() => {
        if (shouldCheck == true) {
            setLoading(true);
            DoctorAPI.getSessionPpgExportStatus(uuid, sessionId).then(stPld => {
                setStatusPld(stPld);
                setLoading(false);
                if (stPld.status == 'finished') {
                    setDone(true);
                    window.open(DoctorAPI.getSessionPpgExportCsvLink(uuid, sessionId), '_blank').focus();
                }
            });
        }
    }, [shouldCheck]);

    let isReady = (statusPld != undefined && statusPld.status == 'finished');

    return (
        <Wrapper onClick={() => {
            setShouldCheck(true);
        }}>

            <SquareBox>
                <ExportImg src={exportImg}/>
            </SquareBox>

            {isReady == false ?
                <Span style={{cursor: (shouldCheck) ? 'default' : 'pointer'}}>
                    {shouldCheck == true ? 'Exporting PPG...' : ' PPG'}
                </Span>
                :
                <DownloadLink href={DoctorAPI.getSessionPpgExportCsvLink(uuid, sessionId)} target={'_blank'}>
                    Download PPG
                </DownloadLink>
            }

        </Wrapper>
    );
}

const DownloadLink = styled.a`
  color: #147AFF;
  font-size: 18px;
  font-weight: bold;
`;

const ExportImg = styled.img`
  height: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Span = styled.div`
  color: #147AFF;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
`;

const SquareBox = styled.div`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
`;