import axios from 'axios'
import moment from 'moment'
import env from "react-dotenv";
import * as ls from 'local-storage'

const {API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const UsersInterfaceAPI = {

    async createUserSettings(d) {
        let {uuid} = d;
        let pld = (await axios.post(`${API_ENDPOINT}/users/${uuid}/interface-settings`, d)).data;
        return pld;
    },

    async updateUserSettings(d) {
        let {uuid} = d;
        let pld = (await axios.put(`${API_ENDPOINT}/users/${uuid}/interface-settings`, d)).data;
        return pld;
    },

    async getUserSettings(uuid) {
        let pld = (await axios.get(`${API_ENDPOINT}/users/${uuid}/interface-settings`)).data;
        return pld;
    },

    async deleteUserSettings(uuid) {
        let pld = (await axios.delete(`${API_ENDPOINT}/users/${uuid}/interface-settings`)).data;
        return pld;
    },

    async getLazySettings(uuid){
        let pld = await this.getUserSettings(uuid);
        if (pld == undefined){
            return {ranges: {}, uuid: uuid};
        }
        return pld;
    }

}

export default UsersInterfaceAPI;
