import styled from "styled-components";
import DoctorTemplate from "../templates/DoctorTemplate";
import BpDebugTool from "../bp/tools/BpDebugTool";
import { useParams } from "react-router-dom";

function BpDebugApp(props) {
  let params = useParams();
  let { uuid, date } = params;
  // let { uuid, date} = props;

  return (
    <DoctorTemplate active={"patients"}>
      <Wrapper key={`xx${date}_${uuid}`}>
        {/*<PatientPanel id={id} tag={tag} isDemo={isDemo} />*/}
        <BpDebugTool uuid={uuid} date={date} />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 60%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default BpDebugApp;
