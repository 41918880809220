import { useState, useEffect } from "react";
import styled from "styled-components";

import ch_not_sel from "../../../assets/images/checkbox.svg";
import ch_sel from "../../../assets/images/chbx.svg";
import ch_half from "../../../assets/images/checkbox-half.svg";
import { Input } from "../../bracelets/forms/UpdateCorsanoPatientForm";

export default function PatientsSelector(props) {
  const {
    patients = [],
    selectedPatients = {},
    handleCheckboxChange = () => {},
    handleSelectAll = () => {},
    handleUnselectAll = () => {},
  } = props;
  const [length, setLength] = useState(patients.length);
  const [selAll, setSelAll] = useState(true);

  const selectedCount = Object.values(selectedPatients).filter(Boolean).length;
  return (
    <TopPlaceholder
    //   style={{ border: "1px solid red", boxSizing: "content-box" }}
    >
      <SectionHeading
      // style={{ border: "1px solid red", boxSizing: "content-box" }}
      >
        {selectedCount} patient{selectedCount > 1 ? "s" : ""} selected
      </SectionHeading>
      <SelectModeContainer>
        <SelectMode
          onClick={() => {
            handleSelectAll();
          }}
        >
          <ChImg
            src={
              selectedCount === patients.length
                ? ch_sel
                : selectedCount === 0
                ? ch_not_sel
                : ch_half
            }
          />
          Select All
        </SelectMode>
        <SelectMode
          onClick={() => {
            handleUnselectAll();
          }}
        >
          {/* <ChImg src={unselAll ? ch_selch_not_sel} onClick={() => {}} /> */}
          Unselect All
        </SelectMode>
      </SelectModeContainer>
      <InnerWrapper>
        <PatientListDiv>
          {patients.map((p, i) => {
            let checked = selectedPatients[p.uuid];
            return (
              <Item>
                <ChImg
                  src={checked ? ch_sel : ch_not_sel}
                  onClick={() => {
                    handleCheckboxChange(p.uuid);
                  }}
                />
                <span>{`${p.first_name} ${p.last_name}`}</span>
              </Item>
            );
          })}
        </PatientListDiv>
      </InnerWrapper>
    </TopPlaceholder>
  );
}

const SectionHeading = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const SelectModeContainer = styled.div`
  display: flex;
  margin-bottom: 1em;
`;

const SelectMode = styled.div`
  //   opacity: 75%;
  cursor: pointer;
  font-weight: semi-bold;
  //   text-decoration: underline;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  margin-right: 1rem;
  padding: 8px;
  display: flex;
  align-items: center;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 15px;
  height: calc(min(600px, 90vh));
  max-height: 800px;
  width: 20rem;
  max-width: 700px;
  //   overflow: scroll;
  //   padding: 10px;
`;

const PatientListDiv = styled.div`
  height: 100%;
  display: grid;
  //   flex-direction: column;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  flex: 1;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  flex: 1;
`;

const EmItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;

const ChImg = styled.img`
  width: 16px;
  right: 16px;
  margin-right: 5px;
  cursor: pointer;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: calc(min(500px, 90vh));
  box-sizing: border-box;
  opacity: ${(props) => (props.loading == true ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: scroll;
`;
