const newMessage =
  "Continuous Blood Pressure requires cuff measurements at 3 different periods. The accuracy of the blood pressure improves with multiple calibrations. ";

const newMessage2 =
  "First and second initializations BP Cuff measurement success. Please do third measurement in next 24 hours. ";

export const NOTIFICATION_TO_DETAILS = {
  Insufficient_BP_Cuff_Measurements: {
    verbal:
      "Continuous Blood Pressure requires cuff measurement at 3 different periods.The accuracy of the continuous blood pressure improves with multiple calibrations.Please perform first BP Cuff measurement.",
    title: "Insufficient BP Cuff Measurement ",
  },
  BP_Cuff_Measurement_Error: {
    title: "BP Cuff Measurement Error",
    verbal: "Please redo measurement.",
  },
  Redo_23: {
    title: "Continue BP Cuff Measurements",
    verbal: "First initialization BP Cuff measurement success. Please do second and third measurement in next 24 hours. Continuous Blood Pressure requires cuff measurement at 3 different periods. The accuracy of the continuous blood pressure improves with multiple calibrations.",
  },

  Redo_3: {
    title: "Continue BP Cuff Measurements",
    verbal: "First and second initializations BP Cuff measurement success. Please do third measurement in next 24 hours. Continuous Blood Pressure requires cuff measurement at 3 different periods. The accuracy of the continuous blood pressure improves with multiple calibrations.",
  },
  Insufficient_BP_Cuff_Measurements: {
    title: "Insufficient BP Cuff Measurements",
    verbal: "Continuous Blood Pressure requires cuff measurement at 3 different periods. The accuracy of the continuous blood pressure improves with multiple calibrations. Please perform first BP Cuff measurement.",
  },
  // BRACELET_NOT_WORN_AND_MISSING_INITS: {
  //   verbal:
  //     "Requirements to obtain Non-Invasive Blood Pressure measurements are not met. Please ask patient to wear the Cardiowatch at least 48 hours consecutively and perform BP Cuff Measurements in the morning, afternoon and evening.",
  //   title: "Bracelet Not Worn And Missing Inits",
  // },
  // BRACELET_NOT_WORN_LONG_ENOUGH: {
  //   verbal:
  //     "Awaiting 48 hours of consecutive data before Non-Invasive Blood Pressure can be measured. Please make sure CardioWatch is worn consecutively for 48 hours.",
  //   title: "Bracelet Not Worn Long Enough",
  // },
  // BAD_MODEL_QUALITY_NEW_INIT_NEEDED: {
  //   verbal:
  //     "The quality of Non-Invasive Blood Pressure predictions seems to be suboptimal. Please ask patient to perform another BP Cuff Measurement to improve the quality of the Blood Pressure Measurements.",
  //   title: "Bad Model Quality New Init Needed",
  // },
  // NO_INIT_MORNING: {
  //   verbal:
  //     "Requirements to obtain Non-Invasive Blood Pressure Measurement are not met. Please ask patient to perform a BP Cuff Measurement in the morning. ",
  //   title: "No Init Morning",
  // },
  // NO_INIT_AFTERNOON: {
  //   verbal:
  //     "Requirements to obtain Non-Invasive Blood Pressure Measurement are not met. Please ask patient to perform a BP Cuff Measurement in the afternoon. ",
  //   title: "No Init Afternoon",
  // },
  // NO_INIT_EVENING: {
  //   verbal:
  //     "Requirements to obtain Non-Invasive Blood Pressure Measurement are not met. Please ask patient to perform a BP Cuff Measurement in the evening. ",
  //   title: "No Init Evening",
  // },
  // NO_VALID_INIT_MORNING: {
  //   verbal:
  //     "Unfortunately, a problem occurred while processing your latest morning BP Cuff Measurement. Please ask patient to repeat your BP Cuff Measurement to improve NIBP predictions. " +
  //     newMessage,
  //   title: "No Valid Init Morning",
  // },
  // NO_VALID_INIT_AFTERNOON: {
  //   verbal:
  //     "Unfortunately, a problem occurred while processing your latest afternoon BP Cuff Measurement. Please ask patient to repeat your BP Cuff Measurement to improve NIBP predictions. " +
  //     newMessage,
  //   title: "No Valid Init Afternoon",
  // },
  // NO_VALID_INIT_EVENING: {
  //   // old : "Unfortunately, a problem occurred while processing your latest evening BP Cuff Measurement. Please ask patient to repeat your BP Cuff Measurement to improve NIBP predictions.",
  //   verbal:
  //     "First initialization BP Cuff measurement success. Please do second and third measurement in next 24 hours. " +
  //     newMessage,
  //   title: "No Valid Init Evening",
  // },
  // MODEL_TRAINING_PROBLEM_CONTACT_DEV: {
  //   verbal:
  //     "Training of the Non-Invasive Blood Pressure model appears unstable. Please contact your Corsano coordinator to request and investigation.",
  //   title: "Model Training Problem Contact Dev",
  // },
  // BAD_MODEL_QUALITY_NEW_INIT_NEEDED:
  //   "Please ask patient to wear the Cardiowatch at least 48 hours consecutively and perform new BP Cuff Measurements in the morning, afternoon and evening.",
};
