import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import SabirBarChart from "../../activity/tools/SabirBarChart";
import DoctorAPI from "../../../api/DoctorAPI";

export default function TempStepsTool(props) {
    const {
        uuid,
        from,
        to,
        startTimestamp
    } = props;

    const [loading, setLoading] = useState([]);
    const [points, setPoints] = useState([]);

    useEffect(() => {
        setLoading(true);
        DoctorAPI.getTempActivityPoints(uuid, from, to).then(arr => {
            setPoints(arr);
            setLoading(false);
            console.log('getTempActivityPoints: points = ', arr);
        })
    }, []);

    if (points.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    // if (points.length == 0) {
    //     return null;
    // }

    let enhItems = points.map(x => {
        return {
            t: x.timestamp,
            value: x.last_steps == undefined ? 0 : x.last_steps,
            time: moment(x.timestamp).format('HH:mm')
        }
    });


    console.log('render: points = ', points);
    console.log('render: enhItems = ', enhItems);
    console.log('positive items = ', enhItems.filter(x => (x != undefined && x.value > 0)));
    let posItems = enhItems.filter(x => (x != undefined && x.value > 0));

    let arr = [];
    let map = {};
    let t = +from;
    for (let i = 0; i < 24; i++){
        map[`x-${i * 60}`] = {
            t: +moment(+from).startOf('day') + i * 60 * 60 * 1000,
            value: 0
        };
    }
    let t0 = +moment(+from).startOf('day');

    for (let i in points) {
        let p = points[i];
        let {timestamp, last_steps} = p;
        let v = (last_steps == undefined) ? 0 : +last_steps;
        let fromStart = +timestamp - t0;
        let minutesFromStart = Math.floor(fromStart / 1000.0 / 60.0 / 60.0);
        minutesFromStart = minutesFromStart * 60;
        let xKey = `x-${minutesFromStart}`;
        if (map[xKey] == undefined){
            continue;
        }
        map[xKey].value = +map[xKey].value + +v;
    }

    // console.log('-->> map = ', map);
    let mapItems = Object.values(map).map(x => ({...x, time: moment(x.t).format('HH:mm')}));
    // console.log('-->> mapItems = ', mapItems);

    return (
        <Wrapper>

            <SabirBarChart
                shouldChangeColorOfSelected={false}
                barColor={'#FF962A'}
                items={mapItems}
                renderItem={it => <span
                    style={{
                        fontWeight: 'normal',
                        color: 'grey',
                        fontSize: 10
                    }}>{it.time}</span>}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 300px;
  background: pink;
  box-sizing: border-box;
  position: relative;
`;