import axios from 'axios'
import env from "react-dotenv";

import ls from 'local-storage'

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const CardiolyseAPI = {

    async uploadCsvEcg(blob) {
        let formData = new FormData();
        formData.append('file', blob);
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/cardiolyse/process-csv`, formData)).data.result;
        return pld;
    },

    async uploadWiffEcg(blob) {
        let formData = new FormData();
        formData.append('file', blob);
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/cardiolyse/process-wiff`, formData)).data.result;
        return pld;
    },

    async getMeasurementResult(userUUID, measurementUUID, sequence_timestamp) {
        let url = `${DOCTORS_API_ENDPOINT}/cardiolyse/health/user/${userUUID}/binary-measurements/${measurementUUID}`;
        if (sequence_timestamp != undefined) {
            url = `${url}/${sequence_timestamp}`;
        }
        let pld = (await axios.get(url)).data.result;
        return pld;
    },


    async getEcgMeasurementCardiolyseResult(measurementUUID) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v4/cardiolyse/get-lazy-report/${measurementUUID}`)).data;
        return pld;
    },


    async getMeasurementEcgPoints(userUUID, measurementUUID, filtered = false) {
        let url = `${DOCTORS_API_ENDPOINT}/v2/health/user/${userUUID}/binary-measurements/${measurementUUID}/ecg-points`;
        let pld = (await axios.get(url, {
            params: {
                filtered: (filtered == true) ? 1 : undefined
            },
            timeout: 40 * 60 * 1000
        })).data;
        return pld;
    },

    async getMeasurementEcgPointsEnhanced(userUUID, measurementUUID, filtered = false) {
        let url = `${DOCTORS_API_ENDPOINT}/v3/health/user/${userUUID}/binary-measurements/${measurementUUID}/ecg-points-advanced`;
        let pld = (await axios.get(url, {
            params: {
                filtered: (filtered == true) ? 1 : undefined
            },
            timeout: 40 * 60 * 1000
        })).data;
        return pld;
    },

    async getMeasurementPpg2Points(userUUID, measurementUUID, metric = '0x7b') {
        let url = `${DOCTORS_API_ENDPOINT}/health/user/${userUUID}/binary-measurements/${measurementUUID}/parsed-points`;
        let pld = (await axios.get(url, {
            params: {
                pld_key: metric
            }
        })).data.result;
        return pld;
    },

    async getEcgResult(recordId) {
        try {
            let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/cardiolyse/record/${recordId}/result`)).data.result;
            return pld;
        } catch (exc) {

        }
        return {}
    },

    async getEcgDetails(recordId) {
        try {
            let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/cardiolyse/record/${recordId}/details`)).data.result;
            return pld;
        } catch (exc) {

        }
        return {}
    },

    async getEcgRaw(recordId) {
        try {
            let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/cardiolyse/record/${recordId}/raw`)).data.result;
            return pld;
        } catch (exc) {

        }
        return {}
    },

    async getEcgQrs(recordId) {
        try {
            let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/cardiolyse/record/${recordId}/qrs`)).data.result;
            return pld;
        } catch (exc) {

        }
        return {}
    },

    getPdfUrl(recordId) {
        return `${DOCTORS_API_ENDPOINT}/cardiolyse/record/${recordId}/pdf?improved=yes`;
    },

    getExcelUrl(recordId) {
        return `${DOCTORS_API_ENDPOINT}/cardiolyse/record/${recordId}/excel`;
    },

    async getAllCombinedData(recordId) {
        let [result, details, raw, qrs] = await Promise.all([
            this.getEcgResult(recordId),
            this.getEcgDetails(recordId),
            this.getEcgRaw(recordId),
            this.getEcgQrs(recordId)
        ]);
        return {
            result, details, raw, qrs, pdfUrl: this.getPdfUrl(recordId), excelUrl: this.getExcelUrl(recordId)
        }
    }

}

export default CardiolyseAPI;
