import { useState, useEffect } from "react";
import styled from "styled-components";
import Spin from "arui-feather/spin";

import infoImg from "../../info/tools/info_b.svg";
import Select from "react-select";
import DoctorDictionarySelector from "../../settings/tools/DoctorDictionarySelector";
import { Input } from "../../ui/templates";

const isSabirMode = window.location.href.indexOf("sabir") > -1;

const GENDER_OPTIONS = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

const WRIST_OPTIONS = [
  {
    label: "Left",
    value: "left",
  },
  {
    label: "Right",
    value: "right",
  },
];

const SKIN_TYPE_OPTIONS = [
  "Fitzpatrick I",
  "Fitzpatrick II",
  "Fitzpatrick III",
  "Fitzpatrick IV",
  "Fitzpatrick V",
  "Fitzpatrick VI",
  // "Not Selected",
].map((a, i) => {
  // let v = a === "Not Selected" ? undefined : i;
  let v = 1 + i;
  return {
    value: v,
    label: a,
  };
});

const HAIR_DENSITY_OPTIONS = ["Nil", "Sparse", "Moderate", "Dense"].map(
  (a, i) => {
    return {
      value: i + 1,
      label: a,
    };
  }
);

function getItemsByType(allItems, type) {
  let xArr = allItems.filter((x) => x.type == type);
  if (xArr.length == 0) {
    return [];
  }
  let a = xArr[0];
  let res =
    a?.data?.items == undefined || a?.data.items.length == 0
      ? []
      : a.data.items;
  return res;
}

function getBYearOptions() {
  let start = 1900;
  let end = 2016;
  let arr = [];
  for (let i = start; i < end; i++) {
    arr.push({
      label: `${i}`,
      value: `${i}-01-01`,
    });
  }
  return arr.reverse();
}

export default function UpdateUserInfoForm(props) {
  const { loading = false, onSave = (d) => {}, dictionaryItems = [] } = props;
  const [showPrivacyInfo, setShowPrivacyInfo] = useState(false);

  const [external_patient_id, setExternal_patient_id] = useState(
    props.healthUser.external_patient_id == undefined
      ? ""
      : props.healthUser.external_patient_id
  );

  const [first_name, setFirst_name] = useState(
    props.first_name == undefined ? "Patient" : props.first_name
  );

  const [last_name, setLast_name] = useState(
    props.last_name == undefined ? props.code : props.last_name
  );
  const [customer_tag, setCustomer_tag] = useState(
    props.customer_tag == undefined ? "" : props.customer_tag
  );

  const [weight, setWeight] = useState(
    props.weight == undefined ? "" : props.weight
  );
  const [height, setHeight] = useState(
    props.height == undefined ? "" : props.height
  );
  const [gender, setGender] = useState(props.gender);
  const [wearing, setWearing] = useState(props.wearing);
  const [birthday, setBirthday] = useState(props.birthday);

  const [hairDensity, setHairDensity] = useState(props.hairDensity);
  const [skinType, setSkinType] = useState(props.skinType);

  const [dictionaryData, setDictionaryData] = useState(props.dictionaryData);

  useEffect(() => {
    if (props.wearing != undefined) {
      setWearing(props.wearing);
    }
  }, [props.wearing]);

  useEffect(() => {
    if (props.dictionaryData != undefined) {
      setDictionaryData(props.dictionaryData);
    }
  }, [props.dictionaryData]);

  let bOptions = getBYearOptions();
  let selYearOption = bOptions.filter(
    (x) => x.value == `${`${birthday}`.split("-")[0]}-01-01`
  )[0];

  // let skinTypeDefaultOption = SKIN_TYPE_OPTIONS.find(
  //   (x) => x.value === skinType
  // );

  // let hairDensityDefaultOption = HAIR_DENSITY_OPTIONS.find(
  //   (x) => x.value === hairDensity
  // );
  let hospitalsItems = getItemsByType(dictionaryItems, "doctor_hospitals");
  let nursesItems = getItemsByType(dictionaryItems, "doctor_staff");
  console.log("UpdateUserInfoForm: render: dictionaryData = ", dictionaryData);

  return (
    <Wrapper>
      <TopRow>
        <TopField>
          <span
            style={{
              fontWeight: "bold",
              opacity: 0.5,
              // display: "flex",
              alignItems: "center",
            }}
            className="edit-label"
          >
            Patient ID / First name
          </span>
          <Icon
            src={infoImg}
            className="bp-info-icon"
            onClick={() => {
              setShowPrivacyInfo(!showPrivacyInfo);
            }}
          />
          {showPrivacyInfo ? (
            <div
              className="normal-text"
              style={{
                maxWidth: "400px",
                cursor: "grab",
                position: "absolute",
                border: "1px solid #d44",
                borderRadius: "12px",
                background: "#efefef",
                opacity: 1,
                zIndex: 100,
                left: "24em",
                padding: "0.5em 1em",
              }}
              dangerouslySetInnerHTML={{ __html: privacyInfoDoc }}
            />
          ) : null}
          <Input
            $mode={"mode2"}
            value={first_name}
            placeholder={"First name"}
            onChange={(evt) => {
              setFirst_name(evt.target.value);
            }}
          />
        </TopField>
        <TopField>
          <span
            style={{
              fontWeight: "bold",
              opacity: 0.5,
              // display: "flex",
              alignItems: "center",
            }}
            className="edit-label"
          >
            Voucher / Last name
          </span>
          <Icon
            src={infoImg}
            className="bp-info-icon"
            onClick={() => {
              setShowPrivacyInfo(!showPrivacyInfo);
            }}
          />
          <Input
            $mode={"mode2"}
            value={last_name}
            placeholder={"Last name"}
            onChange={(evt) => {
              setLast_name(evt.target.value);
            }}
          />
        </TopField>

        <TopField>
          <Label className="edit-label">Hospital User ID</Label>
          <Input
            $mode={"mode2"}
            value={external_patient_id}
            placeholder={""}
            onChange={(evt) => {
              setExternal_patient_id(evt.target.value);
            }}
          />
        </TopField>
      </TopRow>

      <TopRow>
        <TopField>
          <Label className="edit-label">Weight (kg)</Label>
          <Input
            $mode={"mode2"}
            value={weight}
            placeholder={"Weight"}
            onChange={(evt) => {
              setWeight(evt.target.value);
            }}
          />
        </TopField>
        <TopField>
          <Label className="edit-label">Height (cm)</Label>
          <Input
            $mode={"mode2"}
            value={height}
            placeholder={"Height"}
            onChange={(evt) => {
              setHeight(evt.target.value);
            }}
          />
        </TopField>

        <TopField>
          <Label className="edit-label">Gender</Label>
          <Select
            placeholder={"Gender"}
            options={GENDER_OPTIONS}
            value={
              gender == undefined
                ? undefined
                : GENDER_OPTIONS.filter((x) => x.value == gender)[0]
            }
            onChange={(x) => {
              setGender(x.value);
            }}
          />
        </TopField>

        <TopField>
          <Label className="edit-label">Wrist</Label>
          <Select
            placeholder={"Wrist"}
            options={WRIST_OPTIONS}
            value={
              wearing == undefined
                ? undefined
                : WRIST_OPTIONS.filter((x) => x.value == wearing)[0]
            }
            onChange={(x) => {
              setWearing(x.value);
            }}
          />
        </TopField>
      </TopRow>

      <TopRow>
        <TopField>
          <Label className="edit-label">Birth year</Label>
          <Select
            options={bOptions}
            value={selYearOption}
            onChange={(a) => {
              let v = a.value;
              setBirthday(v);
            }}
          />
          {/*<DateTimePicker*/}
          {/*    disableClock={true}*/}
          {/*    format={'y-MM-dd'}*/}
          {/*    onChange={d => {*/}
          {/*        let v = moment(d).format('YYYY-MM-DD');*/}
          {/*        setBirthday(v);*/}
          {/*    }}*/}
          {/*    value={(birthday == undefined || birthday == '') ? new Date() : new Date(birthday)}*/}
          {/*/>*/}
        </TopField>

        <TopField>
          <Label className="edit-label">Skin Type (Fitzpatrick)</Label>
          <Select
            options={SKIN_TYPE_OPTIONS}
            defaultValue={SKIN_TYPE_OPTIONS.find((x) => x.value === skinType)}
            onChange={(a) => {
              setSkinType(a.value);
            }}
          />
        </TopField>
        <TopField>
          <Label className="edit-label">Hair Density</Label>
          <Select
            options={HAIR_DENSITY_OPTIONS}
            // value={hairDensityDefaultOption}
            defaultValue={HAIR_DENSITY_OPTIONS.find(
              (x) => x.value === hairDensity
            )}
            onChange={(a) => {
              setHairDensity(a.value);
            }}
          />
        </TopField>
        {hospitalsItems.length == 0 ? null : (
          <TopField>
            <Label className="edit-label">Hospital</Label>
            <DoctorDictionarySelector
              items={hospitalsItems}
              dataId={dictionaryData?.hospital?.id}
              onChange={(x) => {
                let dd = {
                  ...(dictionaryData == undefined ? {} : dictionaryData),
                  hospital: x,
                };
                if (x == undefined) {
                  delete dd.hospital;
                }
                setDictionaryData(dd);
              }}
            />
          </TopField>
        )}

        {nursesItems.length == 0 ? null : (
          <TopField>
            <Label className="edit-label">Physician</Label>
            <DoctorDictionarySelector
              dataId={dictionaryData?.physician?.id}
              items={nursesItems}
              onChange={(x) => {
                let dd = {
                  ...(dictionaryData == undefined ? {} : dictionaryData),
                  physician: x,
                };
                if (x == undefined) {
                  delete dd.physician;
                }
                setDictionaryData(dd);
              }}
            />
          </TopField>
        )}
      </TopRow>

      {isSabirMode == false ? null : (
        <TopRow>
          <TopField>
            <Input
              $mode={"mode2"}
              value={customer_tag}
              placeholder={"Customer tag"}
              onChange={(evt) => {
                setCustomer_tag(evt.target.value);
              }}
            />
          </TopField>
        </TopRow>
      )}

      <BottomPlaceholder>
        <Button
          onClick={() => {
            if (loading == true) {
              return;
            }
            let dd = {
              first_name,
              last_name,
              external_patient_id,
              weight: weight == undefined || weight == "" ? 70 : +weight,
              height: height == undefined || height == "" ? 175 : +height,
              gender: gender == undefined || gender == "" ? "male" : gender,
              wearing: wearing,
              hair_density: hairDensity,
              skin_color: skinType,
              birthday:
                birthday == undefined || birthday == ""
                  ? "2000-01-01"
                  : birthday,
            };
            if (isSabirMode == true) {
              dd.customer_tag = customer_tag;
            }
            onSave(dd, dictionaryData);
          }}
        >
          <Spin visible={loading} />
          {loading == true ? null : "SAVE"}
        </Button>
      </BottomPlaceholder>
    </Wrapper>
  );
}

const Label = styled.div`
  font-weight: bold;
  opacity: 0.5;
  display: flex;
  align-items: center;
`;
const Button = styled.div`
  background: #147aff;
  border-radius: 5px;
  height: 60px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 300;
`;
const BottomPlaceholder = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div``;
const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
const TopField = styled.div`
  flex: 1;

  margin-left: 10px;
  margin-right: 10px;

  :first-of-type {
    margin-left: 0px;
  }

  :last-of-type {
    margin-right: 0px;
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 0.2em;
  opacity: 1;
  :hover {
    opacity: 0.8;
  }
`;

const privacyInfoDoc = `
<h3>Important Notice for Healthcare Providers (HCP):</h3>
<p>To ensure the privacy and security of patient information, please refrain from entering the first and last names of patients. Instead, use the designated patient ID or Voucher. Alternatively, you may use patient initials or room numbers (e.g., PS in Rm 34). This practice helps in maintaining the confidentiality and integrity of patient data.</p>`;
