import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {Button} from "../../auth/ui";

export default function FindUsetTool(props) {
    const {
        onAdd = x => {

        }
    } = props;

    const [email, setEmail] = useState(undefined);
    const [loading, setLoading] = useState(false);

    return (
        <Wrapper>
            <Row>
                <Input value={email}
                       placeholder={'Type email of the user'}
                       onChange={evt => {
                           setEmail(evt.target.value);
                       }}/>
            </Row>

            <Row>
                <Button onClick={async () => {
                    setLoading(true);
                    let pld = (await axios.get(`https://api.aoc.cardiomood.com/user-by-email`, {
                        params: {
                            email: email
                        }
                    })).data;
                    if (pld == undefined) {
                        return window.alert('user is not found');
                    } else {
                        onAdd(pld.result);
                    }
                    setLoading(false);
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : 'Add'}
                </Button>
            </Row>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding-left: 5px;
  outline: none;
  border: 1px solid whitesmoke;
  height: 50px;
  font-size: 16px;
  border-radius: 6px;

  :focus {
    border-color: blue;
  }
`;