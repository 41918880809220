import {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import moment from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useDimensions from "react-use-dimensions";
import { DottedSpan } from "../../heart_rate/tools/ActivityHeartRateChart";

const KEYS = [
  "afb_probability",
  "avnn",
  "classification",
  "final_probability",
  "good_ibi_number",
  "good_quality_probability",
  "has_image_file",
  "heart_rate",
  "hta",
  "hta_q",
  "lf",
  "lf_confidence",
  "nsr_probability",
  "pnn50",
  "rmssd",
  "sdnn",
];

const Y_AXIS = [
  {
    label: "BADQ",
    value: 0,
  },
  {
    label: "Not enough data",
    value: 1,
  },
  {
    label: "Too Much Movement",
    value: 2,
  },

  {
    label: "UND",
    value: 3,
  },
  {
    label: "NSR",
    value: 4,
  },
  {
    label: "AFB",
    value: 5,
  },
];

function getValByName(name) {
  return Y_AXIS.find((x) => x.label === name)?.value;
}

function getLabelByValue(val) {
  return Y_AXIS.find((x) => x.value === val)?.label;
}

export default function HappitechChart(props) {
  const { uuid, from, to } = props;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [ref, { x, y, width, height }] = useDimensions();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getHappitechMeasurements(uuid, from, to).then((arr) => {
      setItems(arr);
      setLoading(false);
    });
  }, [uuid, from, to]);

  let points = items.map((x) => {
    return {
      ...x,
      y: getValByName(x.classification),
    };
  });

  // console.log("Hap Chart: points = ", points);

  return (
    <Wrapper>
      <ChartPlaceholder ref={ref}>
        {visible == true ? null : (
          <div style={{ marginTop: 10, marginBottom: 10, textAlign: "right" }}>
            <DottedSpan
              onClick={() => {
                setVisible(true);
              }}
            >
              show chart
            </DottedSpan>
          </div>
        )}

        {visible == false ? null : (
          <>
            {loading == true ? (
              <Code />
            ) : (
              <LineChart
                width={width}
                height={height}
                data={points}
                connectNulls={true}
                margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
              >
                <XAxis
                  dataKey="timestamp"
                  tickCount={25}
                  domain={[0, 8]}
                  tickFormatter={(a) => {
                    if (`${a}`.length < 8) {
                      return a;
                    }
                    return moment(+a).format("HH:mm");
                  }}
                />
                <Tooltip />
                <YAxis
                  domain={[0, 5]}
                  tickFormatter={(v) => {
                    return getLabelByValue(v) || "";
                  }}
                />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line
                  type="monotone"
                  dataKey={"y"}
                  stroke="#D92A3E"
                  strokeWidth={2}
                  animationDuration={1}
                  // dot={false}/>
                  dot={{ stroke: "#D92A3E", strokeWidth: 1 }}
                />
              </LineChart>
            )}
          </>
        )}
      </ChartPlaceholder>
    </Wrapper>
  );
}

const Spo2TooltipWrapper = styled.div`
  background: white;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  height: 320px;
  width: 100%;
`;
