import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import axios from 'axios'
import toast from "react-hot-toast";
import {CORSANO_API_ENDPOINT} from "../../../constants/config";
import {Code} from 'react-content-loader'
import NiceModal from "../../modals/NiceModal";
import SimplePdfViewer from "../../afib/tools/SimplePdfViewer";
import FindHcpTool, {getMaskedEmail} from "./FindHcpTool";
import trashImage from "../../../assets/images/trash.svg";

export default function MyHcpsTool(props) {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const [searchVisible, setSearchVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`${CORSANO_API_ENDPOINT}/my-hcps`)
            .then((res) => {
                setItems(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                toast.error('Failed to load HCPs');
            });
    }, []);

    if (items.length == 0 && loading == true) {
        return (
            <div>
                <Code/>
            </div>
        )
    }

    return (
        <Wrapper>


            <ItemsPlaceholder>
                {items.map((a, i) => {
                    return (
                        <BraceletItem key={a.uuid}>

                            <BraceletInner>
                                <BrLeft>
                                    <BrName>
                                        {`${a?.first_name || ''} ${a?.last_name || ''}`}
                                    </BrName>
                                    <div style={{opacity: 0.5, fontSize: '12px', fontStyle: 'italic'}}>
                                        {getMaskedEmail(a?.email)}
                                    </div>
                                </BrLeft>
                                <BrRight>
                                    <GearImg
                                        className="group-info-icon"
                                        src={trashImage}
                                        onClick={async () => {
                                            setLoading(true);
                                            try {
                                                await axios.post(`${CORSANO_API_ENDPOINT}/detach-hcp`, {
                                                    hcpUuid: a.uuid
                                                });
                                                let arr = (await axios.get(`${CORSANO_API_ENDPOINT}/my-hcps`)).data;
                                                setItems(arr);
                                                toast.success('HCP removed');
                                            } catch (exc) {
                                                toast.error('Failed to remove HCP');
                                            }
                                            setLoading(false);
                                        }}
                                    />
                                </BrRight>
                            </BraceletInner>

                        </BraceletItem>
                    )
                })}
            </ItemsPlaceholder>


            <BottomPlaceholder>
                <SearchSpan onClick={() => {
                    setSearchVisible(true);
                }}>
                    Add new HCP
                </SearchSpan>
            </BottomPlaceholder>

            {searchVisible == false ? null :
                <NiceModal
                    onClose={() => {
                        setSearchVisible(false);
                    }}>
                    <SearchInnerPlaceholder>

                        <div>
                            <FindHcpTool
                                onAdd={async (hcp) => {
                                    setSearchVisible(false);
                                    setLoading(true);
                                    try {
                                        await axios.post(`${CORSANO_API_ENDPOINT}/add-hcp`, {
                                            hcpUuid: hcp.uuid
                                        });
                                        let arr = (await axios.get(`${CORSANO_API_ENDPOINT}/my-hcps`)).data;
                                        setItems(arr);
                                    } catch (exc) {
                                        toast.error('Failed to add HCP');
                                    }
                                    setLoading(false);
                                }}
                            />
                        </div>

                    </SearchInnerPlaceholder>
                </NiceModal>
            }

        </Wrapper>
    );
}

const GearImg = styled.img`
  height: 26px;
  width: 26px;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const SearchInnerPlaceholder = styled.div`
  padding: 10px;
  width: 460px;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SearchSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const Wrapper = styled.div`

`;

const ItemsPlaceholder = styled.div``;

const BraceletInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BrLeft = styled.div`
  //display: flex;
  //flex-direction: row;
  //align-items: center;
`;

const BrName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
`;

const BrRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContrItem = styled.div`
  margin-left: 15px;
`;

const normalBorder = `1px solid rgba(135, 152, 173, 0.15)`;
const dangerBorder = `2px solid #FA4646;`;

const BraceletItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: ${(props) => (props.danger == true ? dangerBorder : normalBorder)};
  box-shadow: ${(props) =>
          props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
