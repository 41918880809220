import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import axios from "axios";
import DoctorAPI from "../../../api/DoctorAPI";

export default function ApiKeyTool(props) {
  const [token, setToken] = useState(undefined);

  useEffect(() => {
    DoctorAPI.getGroupToken().then((x) => {
      setToken(x);
    });
  }, []);

  if (token == undefined) {
    return null;
  }

  let isActiveToken = window.currentSubscription?.apiEnabled == true;

  return (
    <Wrapper>
      <Section>
        <TopSectionPlaceholder className="setting-top-section">
          Researcher API Key
        </TopSectionPlaceholder>

        {isActiveToken == false ? (
          <div style={{ color: "red" }}>
            You API Key is not enabled. Please contact ademir@corsano.com to
            enable it.
          </div>
        ) : (
          <>
            <TokenPlaceholder>
              <TokenText>{token}</TokenText>
              <RegenerateButton
                className="setting-text-active"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure? Your old token will be invalidated."
                    ) == false
                  ) {
                    return;
                  }
                  let t = await DoctorAPI.resetGroupToken();
                  setToken(t);
                }}
              >
                reset
              </RegenerateButton>
            </TokenPlaceholder>
          </>
        )}

        <p className="setting-text-active">
          Please learn more how to use that token{" "}
          <a
          className="hplink"
            href={
              "https://developer.corsano.com/rest/tutorials/using_researcher_token"
            }
            target={"_blank"}
          >
            here
          </a>
          .
        </p>
      </Section>
    </Wrapper>
  );
}

const Section = styled.div``;

const Wrapper = styled.div``;

const TopSectionPlaceholder = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const TokenPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 380px;
`;

const RegenerateButton = styled.div`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TokenText = styled.div`
  margin-right: 15px;
  background: white;
  padding: 5px;
  box-sizing: border-box;
  border: 1px dashed lightgrey;
  border-radius: 4px;
`;
