import styled from "styled-components";
import UiHelper from "../../../helpers/UiHelper";
import NewsScoreHelper from "../../../helpers/NewsScoreHelper";
import { Spin } from "arui-feather/spin";

const renderNewsOnTiles = (
  newsScoreData,
  label,
  priority,
  isMuted,
  isParamLoading = false,
  q,
  outdated,
  size
) => {
  if (window.location.href.indexOf("af.") > -1 && label != "AF Burden") {
    return null;
  }
  if (window.location.href.indexOf("af.") == -1 && label == "AF Burden") {
    return null;
  }

  // let value = NewsScoreHelper.getNews2ScoreFromParams(parameters);
  // let newsData = NewsScoreHelper.getNewsScoreData(parameters);
  // console.log("newsScoreData", newsScoreData);

  let value = newsScoreData?.level;
  let total = newsScoreData?.total;
  let sValue = value == undefined ? "-" : value;
  // let color = NewsScoreHelper.getColorByScore(value);
  let color = newsScoreData?.total_color;
  let defaultIconColor = "#CBCCCB";
  let ico = UiHelper.getNewsBadgeImage(value);

  if (outdated) {
    return (
      <NewsParamItem
        // style={{
        //   border: "1px solid blue",
        //   boxSizing: "border-box",
        // }}
        size={size}
      >
        <NewsParamTop>
          <NewsValue
            size={size}
            tooltip={q}
            color={defaultIconColor}
            className="param-value"
          >
            {"-"}
          </NewsValue>
          <NewsParamImage
            src={UiHelper.getNewsBadgeImage(0)}
            width={size === "small" ? SMALL_ICON_SIZE : BIG_ICON_SIZE}
            height={size === "small" ? SMALL_ICON_SIZE : BIG_ICON_SIZE}
          />
        </NewsParamTop>
        <NewsParamLabel
          size={size}
          color={defaultIconColor}
          priority={0}
          className={`param-label news-param-label`}
        >
          {label}
        </NewsParamLabel>
      </NewsParamItem>
    );
  }

  return (
    <NewsParamItem
      //   style={{
      //     border: "1px solid blue",
      //     boxSizing: "border-box",
      //   }}
      size={size}
    >
      <NewsParamTop>
        <NewsValue
          tooltip={q}
          color={color}
          className="param-value"
          size={size}
        >
          {isParamLoading == true ? (
            <Spin visible={true} className="loading-spinner" />
          ) : null}
          {/* {sValue == "-" && isParamLoading == true ? null : sValue} */}
          {total}
          {q == undefined ? null : (
            <NewsParamTooltip className={"NewsParamTooltip"} size={size}>
              <NewsParamToolTipRow
                size={size}
                score={0}
                style={{ borderBottom: "1px solid gray" }}
              >
                <NewsParamToolTipCell
                  style={{
                    borderRight: "1px solid black",
                    fontSize: "small",
                    width: "150px",
                  }}
                >
                  {"Parameter"}
                </NewsParamToolTipCell>
                <NewsParamToolTipCell
                  style={{
                    width: "20px",
                  }}
                >
                  {"Value"}
                </NewsParamToolTipCell>
                <NewsParamToolTipCell
                  style={{
                    width: "20px",
                  }}
                >
                  {"Score"}
                </NewsParamToolTipCell>
              </NewsParamToolTipRow>
              {Object.keys(VITALS_NAMES_NAME).map((key, idx) => {
                if (key === "spo2_2") {
                  return null;
                }
                return (
                  <NewsParamToolTipRow
                    size={size}
                    score={newsScoreData[key]}
                    style={{
                      borderBottom:
                        idx === Object.keys(VITALS_NAMES_NAME).length
                          ? ""
                          : "1px solid gray",
                    }}
                    key={idx}
                  >
                    <NewsParamToolTipCell
                      style={{
                        borderRight: "1px solid black",
                        fontSize: "small",
                        width: "150px",
                      }}
                    >
                      {VITALS_NAMES_NAME[key]}
                    </NewsParamToolTipCell>
                    <NewsParamToolTipCell
                      style={{
                        width: "20px",
                      }}
                    >
                      {newsScoreData[`${key}_value`]
                        ? newsScoreData[`${key}_value`]
                        : null}
                    </NewsParamToolTipCell>
                    <NewsParamToolTipCell
                      style={{
                        width: "20px",
                      }}
                    >
                      {newsScoreData[key]}
                    </NewsParamToolTipCell>
                  </NewsParamToolTipRow>
                );
              })}
              <NewsParamToolTipRow
                score={0}
                key={"-1"}
                size={size}
                style={{
                  display: "block",
                  color: "black",
                  fontSize: "12px",
                  alignContent: "center",
                  // width: "max-content",
                }}
              >
                Note: {newsScoreData?.note}
              </NewsParamToolTipRow>
            </NewsParamTooltip>
          )}
        </NewsValue>
        <NewsParamImage
          src={ico}
          width={size === "small" ? SMALL_ICON_SIZE : BIG_ICON_SIZE}
          height={size === "small" ? SMALL_ICON_SIZE : BIG_ICON_SIZE}
        />
      </NewsParamTop>
      <NewsParamLabel
        size={size}
        color={color}
        priority={priority}
        className={`param-label news-param-label`}
      >
        {label}
      </NewsParamLabel>
    </NewsParamItem>
  );
};

const BIG_ICON_SIZE = 38;
const SMALL_ICON_SIZE = 34;

const NewsParamItem = styled.div`
  //   margin-left: 10px;
  // margin-top: -12px;
  //   margin: 0 22px;
  //   margin-left: 18px;
  cursor: pointer;
  margin-right: ${(props) => (props.size === "small" ? "10px" : "36px")};
  position: relative;
  top: -6px;
  display: flex;
  flex-direction: column;
  opacity: 100%;
  align-items: center;
  &:hover {
    .NewsParamTooltip {
      display: block;
    }
  }
`;

const NewsParamTop = styled.div`
  display: flex;
  //   margin-top: 10px;
  margin-bottom: -1px;
  align-items: flex-end;
  //   align-items: start;
`;

const NewsParamImage = styled.img`
  //   border: 1px solid green;
  //   box-sizing: border-box;
  position: relative;
  bottom: 6px;
`;

const NewsValue = styled.div`
  font-weight: bold;
  // font-size: 36px;
  font-size: ${(props) => (props.size === "small" ? "32px" : "38px")};
  margin-bottom: ${(props) => (props.size === "small" ? "0px" : "0px")};
  color: ${(props) => (props.color == undefined ? "#8798AD" : props.color)};
  position: relative;
  top: 2px;
`;

const NewsParamLabel = styled.div`
  font-size: ${(props) => (props.size === "small" ? "12px" : "14px")};
  line-height: ${(props) => (props.size === "small" ? "0px" : "0px")};
  color: ${(props) => (props.color == undefined ? "#8798AD" : props.color)};
  margin-top: ${(props) => (props.size === "small" ? "0px" : "2px")};
  font-weight: bold;
`;

const NewsParamToolTipCell = styled.span`
  color: black;
  // border-left: 1px solid black;
  // width: 80px;
  height: 100%;
  align-content: center;
  box-sizing: border-box;
`;

const NewsParamToolTipRow = styled.div`
  z-index: 100;
  background-color: ${(props) => NewsScoreHelper.getColorByScore(props.score)};
  display: grid;
  grid-template-columns: repeat(3, minmax(40px, auto));
  height: ${(props) => (props.size === "small" ? "30px" : "30px")};
  align-items: center;
  gap: 0.1rem;
`;

const NewsParamTooltip = styled.div`
  border: 1px solid gray;
  display: none;
  //   border-top: 1px solid black;
  //   border-bottom: 1px solid black;
  //   border-right: 1px solid black;
  position: absolute;
  top: 30px;
  right: -20px;
  width: 260px;
  text-align: center;
  font-size: 12px;
  z-index: 100;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
`;
const VITALS_NAMES_NAME = {
  respiration_rate: "Respiration Rate",
  spo2: "SpO2",
  // respiration_rate: "Respiration Rate",
  onOxygen: "Supplemental Oxygen",
  blood_pressure: "Blood Pressure",
  pulse_rate: "Pulse Rate",
  consciousness: "Level of Consciousness",
  temperature: "Temperature",
};
export default renderNewsOnTiles;
