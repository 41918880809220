import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import axios from "axios";
import { CORSANO_API_ENDPOINT } from "../../../constants/config";
import { Button, Input } from "../../ui/templates";

import toast from "react-hot-toast";

import QRCode from "react-qr-code";
import NiceModal from "../../modals/NiceModal";

export default function MfaTool(props) {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [updT, setUpdT] = useState(0);

  const [otpCode, setOtpCode] = useState("");

  const [enableModalOpen, setEnableModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get(`${CORSANO_API_ENDPOINT}/auth/otp/state`).then((x) => {
      setData(x.data);
      setLoading(false);
    });
  }, [updT]);

  if (data == undefined) {
    return <Wrapper>Loading...</Wrapper>;
  }

  let isEnabled = data.is_enabled == true;
  let provisioning_uri = data.provisioning_uri;

  return (
    <Wrapper>
      <p>
        Two-factor authentication (2FA) is an extra layer of security for your
        account. It is important to enable it to protect your account from
        unauthorized access.
      </p>

      <StatePlaceholder>
        <span style={{ color: isEnabled ? "green" : "red" }}>
          {isEnabled ? "2FA is enabled" : "2FA is not enabled"}
        </span>
        <span style={{ marginLeft: 40 }}>
          <Button
            onClick={async () => {
              setLoading(true);
              if (isEnabled == true) {
                await axios.post(`${CORSANO_API_ENDPOINT}/auth/otp/disable`);
              } else {
                setEnableModalOpen(true);
                setOtpCode("");
              }
              setLoading(false);
              setUpdT(+updT + 1);
            }}
          >
            {loading == true ? (
              "Loading..."
            ) : (
              <span>{isEnabled ? "Disable 2FA" : "Enable 2FA"}</span>
            )}
          </Button>
        </span>
      </StatePlaceholder>

      {enableModalOpen == false ? null : (
        <NiceModal
          onClose={() => {
            setEnableModalOpen(false);
          }}
        >
          <InnerModalPlaceholder>
            <div>
              {provisioning_uri == undefined ? null : (
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <QRCode value={`${provisioning_uri}`} size={200} />
                  </div>
                  <div>
                    {`Please scan the QR code above with your 2FA app (e.g. Google Authenticator, Microsoft Authenticator, etc.) to enable 2FA.`}
                  </div>

                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Input
                      value={otpCode}
                      placeholder={"6 digit code"}
                      style={{
                        textAlign: "center",
                        width: 200,
                        border: "2px solid #e0e0e0",
                      }}
                      onChange={(evt) => {
                        setOtpCode(evt.target.value);
                      }}
                    />
                  </div>

                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={async () => {
                        if (
                          otpCode == undefined ||
                          `${otpCode}`.length != 6 ||
                          window.isNaN(otpCode) == true
                        ) {
                          toast.error("Please enter a valid 6 digit OTP code.");
                          return;
                        }
                        setLoading(true);
                        try {
                          await axios.post(
                            `${CORSANO_API_ENDPOINT}/auth/otp/enable`,
                            {
                              otp: otpCode,
                            }
                          );
                          toast.success("2FA enabled successfully.");
                          setEnableModalOpen(false);
                        } catch (exc) {
                          toast.error("Incorrect OTP code. Please try again.");
                        }
                        setLoading(false);
                        setUpdT(+updT + 1);
                      }}
                    >
                      {loading == true ? (
                        "Loading..."
                      ) : (
                        <span>{"Enable 2FA"}</span>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </InnerModalPlaceholder>
        </NiceModal>
      )}

      {/*{JSON.stringify(data, null, 2)}*/}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const InnerModalPlaceholder = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StatePlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
