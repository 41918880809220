import styled from "styled-components";
import updateImg from "./images/update.svg";
import { useState } from "react";

export const appVersionCheck = (os_name, app_version, appVersion) => {
  const { ios_app_version, fw_version, android_app_version } = appVersion;
  if (!os_name || !ios_app_version || !android_app_version || !fw_version) {
    return true;
  }

  let userVersion = app_version;
  let officialVersion;
  if (os_name.includes("ios")) {
    officialVersion = ios_app_version;
  } else if (os_name.includes("android")) {
    officialVersion = android_app_version;
  } else {
    return null;
  }
  let userParts = userVersion.split(".");
  let officialParts = officialVersion.split(".");
  const maxLength = Math.max(userParts.length, officialParts.length);
  for (let i = 0; i < maxLength; i++) {
    const userPart = userParts[i] || 0; // Default to 0 if part is missing
    const latestPart = officialParts[i] || 0; // Default to 0 if part is missing
    // console.log({ userPart, latestPart });

    // Compare the current segments
    if (userPart < latestPart) {
      // console.log("outdated", { userVersion, officialVersion });

      return false; // Outdated
    }
    if (userPart > latestPart) {
      // console.log("uptodate", { userVersion, officialVersion });
      return true; // Up-to-date or newer
    }
  }
  return true;
};

export const fwVersionCheck = (userVersion, officialVersion) => {
  if (!userVersion || !officialVersion) {
    return true;
  }
  let userParts = userVersion.split(".");
  let officialParts = officialVersion.split(".");
  const maxLength = Math.max(userParts.length, officialParts.length);
  for (let i = 0; i < maxLength; i++) {
    const userPart = userParts[i] || 0; // Default to 0 if part is missing
    const latestPart = officialParts[i] || 0; // Default to 0 if part is missing
    // console.log({ userPart, latestPart });

    // Compare the current segments
    if (userPart < latestPart) {
      // console.log("outdated", { userVersion, officialVersion });
      return false; // Outdated
    }
    if (userPart > latestPart) {
      // console.log("uptodate", { userVersion, officialVersion });
      return true; // Up-to-date or newer
    }
  }
  return true;
};

const versionMap = {
  app: { text: "Update App" },
  firmware: { text: "Update Firmware" },
  both: { text: "Update App & Firmware" },
};

const VersionSpan = (props) => {
  const {
    userVersion = {},
    appVersion = {},
    fwVersion = {},
    isDebugMode,
  } = props;
  // console.log(fwVersion);
  const [test, setTest] = useState(false);

  if (!userVersion || !appVersion) {
    return null;
  }

  const { os_name, app_version } = userVersion;
  const { firmware_version } = fwVersion;
  const { ios_app_version, fw_version, android_app_version } = appVersion;

  // if (isDebugMode && !cuffBpCount) return null;

  const color = "#8798AD";
  // console.log(userVersion);
  // console.log(appVersion);
  // console.log({
  //   os_name,
  //   app_version,
  //   ios_app_version,
  //   fw_version,
  //   android_app_version,
  // });

  // console.log(version);
  let isNewestApp = appVersionCheck(os_name, app_version, appVersion);
  // let isNewestApp = true;
  let isNewestFw = fwVersionCheck(firmware_version, fw_version);
  let message = "";
  let showNeedUpdateIcon = false;
  if (!isNewestApp && !isNewestFw) {
    message = versionMap["both"].text;
    showNeedUpdateIcon = true;
  } else if (!isNewestApp && isNewestFw) {
    message = versionMap["app"].text;
    showNeedUpdateIcon = true;
  } else if (isNewestApp && !isNewestFw) {
    message = versionMap["firmware"].text;
    showNeedUpdateIcon = true;
  } else {
    message = "";
    showNeedUpdateIcon = false;
  }

  return (
    <Wrapper
      style={{ color: color, display: showNeedUpdateIcon ? "" : "none" }}
    >
      <img style={{ height: 25, marginRight: 0 }} src={updateImg} />
      <UpdateMessageContainer
        onClick={() => {
          // if (window.location.hostname === "localhost") {
          //   setTest(!test);
          // }
        }}
      >
        {message}
        <VersionToolTip
          style={{
            display: test ? "block" : "none",
          }}
          // style={{ display: "block" }}
          className="VersionToolTip"
        >
          {os_name === "ios" ? (
            <div
              style={{ display: isNewestApp ? "none" : "" }}
            >{`iOS version ${app_version}; Latest: ${ios_app_version}`}</div>
          ) : (
            <div
              style={{ display: isNewestApp ? "none" : "" }}
            >{`Android version ${app_version}; Latest: ${android_app_version}`}</div>
          )}
          {!isNewestFw && (
            <div>{`Firmware ${firmware_version}; Latest: ${fw_version}`}</div>
          )}
        </VersionToolTip>
      </UpdateMessageContainer>
    </Wrapper>
  );
};

export default VersionSpan;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;
  color: #8798ad;
  font-weight: 700;
  font-size: 12px;
`;

const UpdateMessageContainer = styled.div`
  &:hover {
    .VersionToolTip {
      display: block;
    }
  }
`;
const VersionToolTip = styled.div`
  display: none;
  position: absolute;
  top: 30px;
  // left: 320px;
  width: 260px;
  text-align: center;
  border: 1px solid whitesmoke;
  background: #aaccaa;
`;
