import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import CalcAPI from "../../../api/CalcAPI";
import HrvChart from "../charts/HrvChart";

import { HRV_PARAMS_OPTIONS } from "./CalcHrvParamsTool";
import Tabs, { TabItem } from "../../ui/Tabs";

const allHrvParams = HRV_PARAMS_OPTIONS.batches.reduce(
  (arr, a) => arr.concat(a.params),
  []
);

export default function HrvPlotsTool(props) {
  const { rrs = [], theme } = props;
  const [series, setSeries] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (rrs.length == 0) {
      return;
    }
    setLoading(true);
    CalcAPI.getParamsPlot(rrs).then((arr) => {
      setSeries(arr);
      setLoading(false);
    }).catch(e=>console.error(e));
  }, [rrs.join("_")]);

  if (rrs.length == 0) {
    return null;
  }
  if (loading == true) {
    return (
      <div>
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }
  if (series == undefined || series.length < 2) {
    return null;
  }

  const tabs = allHrvParams.map((a, k) => {
    return {
      label: a.label,
      content: (
        <TabItem>
          <PlotItem>
            <HrvChart {...props} points={series} paramName={a.name} />
          </PlotItem>
        </TabItem>
      ),
    };
  });

  return (
    <Wrapper>
      <Tabs center={true} mode={"inline"} tabs={tabs} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid whitesmoke;
  border-radius: 3px;
`;

const PlotItem = styled.div`
  margin-bottom: 10px;
  height: 340px;
  width: 100%;
`;
