import { useCallback } from "react";
import styled from "styled-components";
import { useMappedState } from "redux-react-hook";
import PrintVoucherLetterTool from "../dashboard/tools/PrintVoucherLetterTool";
import { useParams } from "react-router-dom";

function GroupCodesPrintApp(props) {
  let params = useParams();
  let { code } = params;

  let { currentUserObj } = useMappedState(
    useCallback((state) => {
      return {
        currentUserObj: state.auth.currentUserObj,
      };
    }, [])
  );
  let currentUUID = currentUserObj?.healthUser?.uuid;
  // let isDemo = false;
  if (currentUserObj == undefined) {
    return null;
  }

  return (
    <FullWrapper>
      <PrintVoucherLetterTool code={code} />
    </FullWrapper>
  );
}

const FullWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default GroupCodesPrintApp;
