import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import ls from "local-storage";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";

export const AREAS = [
  {
    name: "Optimal",
    s: 120,
    s_from: 0,

    d: 80,
    d_from: 0,
    color: "#2F8B81",
  },
  {
    name: "Normal",
    s: 130,
    s_from: 120,

    d: 85,
    d_from: 80,
    color: "#3EBFB3",
  },
  {
    name: "Elevated",
    s: 140,
    s_from: 130,

    d: 90,
    d_from: 85,
    color: "#F5C09D",
  },
  {
    name: "Grade 1 Hypertension",
    s: 160,
    s_from: 140,

    d: 100,
    d_from: 90,
    color: "#F0A170",
  },
  {
    name: "Grade 2 Hypertension",
    s: 180,
    s_from: 160,

    d: 110,
    d_from: 100,
    color: "#B7450F",
  },
  {
    name: "Grade 3 Hypertension",
    s: 200,
    s_from: 180,

    d: 120,
    d_from: 110,
    color: "#74140B",
  },
];

function renderBars(items) {
  // console.log("renderBars: items = ", items);
  let nonZeroItems = items.filter(
    (x) => x != undefined && x.perc != undefined && +x.perc > 0
  );
  return (
    <BarsPlaceholder>
      {nonZeroItems.map((a, i) => {
        return (
          <Bar key={i} style={{ width: `${a.perc}%` }} color={a.color}>
            {a?.perc == undefined ? null : (
              <span
                style={{
                  fontSize: "13px",
                  transform: a?.perc < 5 ? "rotate(-90deg)" : "none",
                }}
              >
                {`${a.perc.toFixed(1)}%`}
              </span>
            )}
          </Bar>
        );
      })}
    </BarsPlaceholder>
  );
}

export default function BpSquareTool(props) {
  const { items = [] } = props;
  const theme = ls.get("theme");
  // console.log("bpsquare", items);
  let minS = 85;
  let minD = 60;
  // let minS = 60;
  // let minD = 40;
  let maxS = AREAS[AREAS.length - 1].s;
  let maxD = AREAS[AREAS.length - 1].d;

  //   console.log("maxS = ", maxS);
  //   console.log("maxD = ", maxD);
  let totalNonZeroS = items.filter((x) => x.s > 0).length;
  let totalNonZeroD = items.filter((x) => x.d > 0).length;

  //   console.log("BpSquareTool: render: items = ", items);

  let sortedAreas = AREAS.sort((a, b) => {
    return b.s - a.s;
  }).map((a) => {
    let sItems = items.filter((x) => x.s && +x.s >= +a.s_from && +x.s < +a.s);
    let dItems = items.filter((x) => x.d && +x.d >= +a.d_from && +x.d < +a.d);

    let sPerc =
      totalNonZeroS == 0 ? 0 : (100.0 * sItems.length) / totalNonZeroS;
    let dPerc =
      totalNonZeroD == 0 ? 0 : (100.0 * dItems.length) / totalNonZeroD;

    return {
      ...a,
      sItemsNumber: sItems.length,
      dItemsNumber: dItems.length,

      sPerc: sPerc,
      dPerc: dPerc,
    };
  });

  // console.table(
  //   items.map((x) => {
  //     return { s: x.s, d: x.d };
  //   })
  // );

  let avrS = getAverageNonZero(items.map((x) => x.s));
  let avrD = getAverageNonZero(items.map((x) => x.d));

  let avrSPercent = ((avrS - minS) / minS) * 100;
  let avrDPercent = ((avrD - minD) / minD) * 100;
  // console.log("avrDPercent", avrDPercent, avrSPercent);

  // console.log("bpsquare avrS = ", avrS, minS);
  // console.log("bpsquare avrD = ", avrD, minD);
  avrDPercent = avrDPercent < 0 ? 0.01 : avrDPercent;

  return (
    <OuterWrapper className="third-bg">
      {/* <div>
        <div>avg dia: {avrD}</div>
        <div>avg sys: {avrS}</div>
      </div> */}
      <LeftPlaceholder>
        <LeftHeading className="heading">{"Blood Pressure"}</LeftHeading>

        <BpValue>
          <span style={{ fontWeight: "bold" }}>
            {`${Math.round(avrS)}/${Math.round(avrD)}`}
          </span>
          <span
            style={{
              marginLeft: "12px",
              opacity: 0.7,
              fontStyle: "italic",
            }}
          >
            {"mmHg"}
          </span>
        </BpValue>

        <div
          style={{
            fontSize: "24px",
            marginBottom: "15px",
            marginTop: "15px",
            color: theme === "dark" ? "white" : "",
          }}
        >
          Percentage in range
        </div>

        <div>
          <div
            className="normal-text"
            style={{ fontStyle: "italic", fontSize: "12px" }}
          >
            Systolic Blood Pressure
          </div>
          <div style={{ width: "100%" }}>
            {renderBars(sortedAreas.map((xx) => ({ ...xx, perc: xx.sPerc })))}
          </div>
        </div>

        <div
          className="normal-text"
          style={{ marginTop: "20px", width: "100%" }}
        >
          <div style={{ fontStyle: "italic", fontSize: "12px" }}>
            Diastolic Blood Pressure
          </div>
          <div style={{ width: "100%" }}>
            {renderBars(sortedAreas.map((xx) => ({ ...xx, perc: xx.dPerc })))}
          </div>
        </div>
      </LeftPlaceholder>

      <SquarePlaceholder>
        <Square>
          {sortedAreas.map((a, i) => {
            let ds = a.s - minS;
            let dd = a.d - minD;
            // console.log(a.s, minS);
            // console.log(a.d, minD);

            let percS = (ds / 100.0) * 100;
            let percD = (dd / 60.0) * 100;
            return (
              <SquareItem
                key={i}
                style={{
                  background: a.color,
                  width: `${percD}%`,
                  height: `${percS}%`,
                  zIndex: i * 1,
                  left: 0,
                  bottom: 0,
                }}
              >
                <SquareInnerPlaceholder>
                  <VertAxisLabel className="normal-text">{a.s}</VertAxisLabel>
                  <HorAxisLabel className="normal-text">{a.d}</HorAxisLabel>

                  <SquareLabel>{a.name}</SquareLabel>
                </SquareInnerPlaceholder>
              </SquareItem>
            );
          })}

          <Dot x={avrDPercent} y={avrSPercent} />

          <HorAxis>
            <HorLa className="normal-text">Diastolic</HorLa>
          </HorAxis>

          <VertAxis>
            <VertLa className="normal-text">Systolic</VertLa>
          </VertAxis>
        </Square>
      </SquarePlaceholder>
    </OuterWrapper>
  );
}

function getAverageNonZero(arr) {
  // console.log("getAverageNonZero: arr = ", arr);
  let sum = arr.filter((x) => x != undefined).reduce((s, x) => +s + x, 0);
  // console.log("sum = ", sum);
  let nonZeroCount = arr.filter((x) => x > 0).length;
  if (nonZeroCount == 0) {
    return 0;
  }
  return sum / nonZeroCount;
}

const DOT_SIZE = 12;

const BarsPlaceholder = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
`;

const Bar = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${(props) => props.color};
`;

const LeftHeading = styled.div`
  font-style: italic;
  color: gray;
  font-size: 24px;
  margin-bottom: 10px;
`;

const BpValue = styled.div`
  font-size: 36px;
`;

const VertAxis = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: -50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HorAxis = styled.div`
  left: 0px;
  bottom: -30px;
  right: 0px;
  text-align: center;
  z-index: 100000;
  position: absolute;
`;

const VertLa = styled.div`
  transform: rotate(90deg);
  font-size: 12px;
  color: gray;
  font-style: italic;
`;

const HorLa = styled.div`
  font-size: 12px;
  color: gray;
  font-style: italic;
`;

const Dot = styled.div`
  display: ${(props) => (props.x > 0 && props.y > 0 ? "block" : "none")};
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border-radius: 50%;
  border: 2px solid white;
  background: black;

  position: absolute;
  left: ${(props) => `calc(${props.x}% - ${DOT_SIZE / 2}px)`};
  bottom: ${(props) => `calc(${props.y}% - ${DOT_SIZE / 2}px)`};
  z-index: 100;
`;

const SquareInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: white;
  position: relative;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: -20px;
  top: -5px;
  width: 20px;
  color: grey;
  font-size: 10px;
`;

const HorAxisLabel = styled.div`
  position: absolute;
  right: -10px;
  bottom: -17px;
  width: 20px;
  color: grey;
  font-size: 10px;
  transform: rotate(90deg);
`;

const SquareLabel = styled.div`
  font-size: 12px;
  padding: 5px;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: white;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
`;

const SquarePlaceholder = styled.div`
  margin-left: 40px;
  margin-top: 40px;
`;

const LeftPlaceholder = styled.div`
  flex: 1;
  padding-right: 40px;
`;

const Square = styled.div`
  position: relative;
  width: 310px;
  height: 280px;
  background: white;
  box-sizing: border-box;
`;

const SquareItem = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
`;
