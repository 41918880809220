import React, {useState} from 'react';
import styled from 'styled-components';
import Spin from 'arui-feather/spin'

export default function PushNotificationForm(props) {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const {
        onSave = d => {

        },
        buttonName = 'Send',
        loading = false
    } = props;

    return (
        <Wrapper>


            <Row>
                <Input value={title}
                       placeholder={'Title'}
                       onChange={evt => {
                           setTitle(evt.target.value);
                       }}/>
            </Row>

            <Row>
                <Textarea value={body}
                       placeholder={'Message'}
                       onChange={evt => {
                           setBody(evt.target.value);
                       }}/>
            </Row>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }} >
                <Button onClick={() => {
                    if (loading == true) {
                        return;
                    }
                    if (title == undefined || title == '' || body == undefined || body == '') {
                        return;
                    }
                    onSave({
                        title: title,
                        body: body
                    });
                }}>
                    <Spin visible={loading}/>
                    {loading == true ? null : buttonName}
                </Button>
            </div>


        </Wrapper>
    );
}

const Heading = styled.div`
    
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  border-radius: 6px;
  outline: none;
  height: 52px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding-left: 7px;

  ::placeholder {
    color: #8798AD;
    opacity: 0.5;
  }
`;

const Textarea = styled.textarea`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  border-radius: 6px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  padding-left: 7px;
  min-height: 6em;
  padding-top: 10px;
  ::placeholder {
    color: #8798AD;
    opacity: 0.5;
  }
  
`;

const Wrapper = styled.div`
  padding-top: 10px;
`;

const Button = styled.div`
  width: 200px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const InputPlaceholder = styled.div`
  flex: 1;
  margin-right: 30px;
`;