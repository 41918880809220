import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import ls from "local-storage";

const HIDE_WHEN_NOT_ADMIN = ["Push Notifications"];
// const HIDE_WHEN_NOT_ADMIN = ["New Voucher", "Compliance", "Push Notifications"];

export default function Tabs(props) {
  const {
    mode,
    center = false,
    isHCPAdmin = false,
    defaultTabIndex = 0,
  } = props;
  const [activeNumber, setActiveNumber] = useState(defaultTabIndex);
  const theme = ls.get("theme");
  const {
    tabs = [
      {
        label: "tab1",
        content: <div>tab 1</div>,
      },
      {
        label: "tab2",
        content: <div>tab 2</div>,
      },
    ],
  } = props;

  if (tabs.length == 0) {
    return null;
  }
  if (tabs.length == 1) {
    return tabs[0].content;
  }

  const activeContent = tabs[activeNumber].content;

  return (
    <Wrapper>
      <Heading mode={mode} center={center} className="tabs-wrapper">
        {tabs.map((tb, k) => {
          if (!isHCPAdmin && HIDE_WHEN_NOT_ADMIN.includes(tb.label)) {
            console.log("role isHCAAS", tb.label);

            return null;
          }
          let isSelected = +k == +activeNumber;
          let { isExtraLink } = tb;
          return (
            <HeadingItem
              className="tab-item"
              mode={mode}
              selected={isSelected}
              theme={theme}
              key={k}
              onClick={() => {
                setActiveNumber(k);
              }}
            >
              <TabName theme={theme} selected={isSelected}>
                {tb.label}
              </TabName>
            </HeadingItem>
          );
        })}
      </Heading>

      <ContentPlaceholder>{activeContent}</ContentPlaceholder>
    </Wrapper>
  );
}

const HeadingItemDarkBackground = "white";
const HeadingItemLightBackground = "#eee";
const HeadingItemDarkColor = "white";
const HeadingItemLightColor = "black";

const Wrapper = styled.div``;

const Heading = styled.div`
  display: ${(props) => (props.mode == "inline" ? "block" : "flex")};
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.center == true ? "center" : "flex-start"};
`;

const inlineVertPadding = 4;
const normalVertPadding = 8;

const HeadingItem = styled.div`
  border-color: #dee2e6 #dee2e6 #fff;
  border-width: 1px;
  margin-right: 15px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: ${(props) =>
    props.mode == "inline" ? inlineVertPadding : normalVertPadding}px;
  padding-bottom: ${(props) =>
    props.mode == "inline" ? inlineVertPadding : normalVertPadding}px;
  line-height: 21px;
  background: ${(props) =>
    props.selected == true
      ? props.theme === "dark"
        ? HeadingItemDarkBackground
        : HeadingItemLightBackground
      : "transparent"};
  border-bottom: ${(props) =>
    props.selected == true ? `2px solid #294594` : "2px solid transparent"};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  flex-direction: row;
  display: ${(props) => (props.mode == "inline" ? "inline-flex" : "flex")};
`;

const TabName = styled.div`
  color: ${(props) =>
    props.selected == false
      ? props.theme === "dark"
        ? HeadingItemDarkColor
        : HeadingItemLightColor
      : "rgb(33, 33, 33)"};
  border-bottom: ${(props) =>
    props.selected == true ? "none" : "1px dotted rgb(66, 86, 132)"};
`;

const ContentPlaceholder = styled.div`
  // padding-top: 20px;
`;

const SupImagePlaceholder = styled.div``;

const SupLinkImg = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 4px;
  vertical-align: super;
`;

function openInNewTab(url) {
  let win = window.open(url, "_blank");
  win.focus();
}

export const TabItem = styled.div`
  padding-top: 10px;
`;
