import styled from "styled-components";
import moment from "moment";

export default function BaselineToolTipTool(props) {
  const { active, payload, label, field } = props;
  //   console.log(props);

  let showSteps = false;
  let showSeconds = false;
  if (window.location.href.includes("localhost")) {
    showSteps = true;
    showSeconds = true;
  }
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          showSeconds ? "HH:mm:ss" : "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`Raw ${fieldMap[field]}: ${payload[0].payload[field]}`}</TooltipRow>
        {/* <TooltipRow className="label">{`Baseline: ${payload[0].payload.ma}`}</TooltipRow> */}
        <TooltipRow className="label">{`Baseline: ${payload[0].payload.baseline}`}</TooltipRow>
        <TooltipRow className="label">{`Q: ${
          payload[0].payload[`${field}_q`]
        }`}</TooltipRow>
        {showSteps && (
          <TooltipRow className="label">{`Steps: ${payload[0].payload.steps}`}</TooltipRow>
        )}
      </TooltipWrapper>
    );
  }

  return null;
}

const fieldMap = {
  spo2: "SpO2",
};

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
