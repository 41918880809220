import { Map } from "immutable";
import * as types from "../ActionTypes.js";

const initialState = {
  loading: false,

  patientsMap: Map(),
  error: undefined,
};

// const startLoading = (state, action) => {
//   return { ...state, loading: true, error: undefined };
// };

// const stopLoading = (state, action) => {
//   return { ...state, loading: false, error: action.error };
// };

const PatientsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CREATE_PATIENT:
    case types.UPDATE_PATIENT:
    case types.DELETE_PATIENT:
    case types.LOAD_PATIENTS:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case types.CREATE_PATIENT_FAIL:
    case types.UPDATE_PATIENT_FAIL:
    case types.DELETE_PATIENT_FAIL:
    case types.LOAD_PATIENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case types.CREATE_PATIENT_SUCCESS:
    case types.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        patientsMap: state.patientsMap.set(action.patient.uuid, action.patient),
      };

    case types.DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        patientsMap: state.patientsMap.delete(action.uuid),
      };

    case types.LOAD_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientsMap: state.patientsMap.merge(
          action.patients.reduce((map, form) => map.set(form.uuid, form), Map())
        ),
      };

    default:
      return state;
  }
};

export default PatientsReducer;
