import QRCode from "react-qr-code";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-hot-toast";

export default function QRCodeSpan(props) {
  const { code } = props;
  const [show, setShow] = useState(false);

  return <BottomValue>Show QR Code</BottomValue>;
}

const BottomValue = styled.span`
  font-size: 14px;
  line-height: 32px;
  font-weight: bold;
  color: #8798ad;
  cursor: pointer;
  text-decoration: underline;
`;

const QrPopup = styled.div`
  cursor: pointer;
  z-index: 1000;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: #333;
  border: 1px solid blue;
  // width: 20%;
  // height: 30%;
  width: 300px;
  height: 300px;
  margin: auto;
  // top: 50%;
  // left: 50%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
`;
