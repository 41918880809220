import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import GroupsAPI from "../../../api/GroupsAPI";
import NiceModal from "../../modals/NiceModal";
import AlertsForm from "../tools/AlertsForm";

import pencilImage from "../../../assets/images/clarity_edit-solid.svg";
import { toast } from "react-hot-toast";

export default function ComplianceAlertsPanel(props) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    GroupsAPI.getGroups().then((x) => {
      setGroups(x);
      setLoading(false);
    });
  }, []);

  let selectedGroup =
    selectedId == undefined
      ? undefined
      : groups.find((x) => x.id == selectedId);

  return (
    <Wrapper>
      {groups.map((a, i) => {
        let alertsEnabled = a?.emailAlertsSettings?.enabled == true;
        let dLimits = a?.emailAlertsSettings?.doctor_alert_data_limits || {};
        let { raw_sleep, raw_activity, raw_ppg } = dLimits;

        return (
          <GroupItem key={a.id} className="compliance-item">
            <Name>
              <span style={{ marginRight: 5 }}>{a.name}</span>
              <EditImg
                src={pencilImage}
                onClick={() => {
                  setSelectedId(a.id);
                }}
              />
            </Name>
            <div>
              Alerts: {alertsEnabled ? "Enabled" : "Disabled"}
              {alertsEnabled == false ? null : (
                <span style={{ marginLeft: 10 }}>
                  {`Activity: ${raw_activity || "N/A"} , Sleep: ${
                    raw_sleep || "N/A"
                  } , PPG: ${raw_ppg || "N/A"}`}
                </span>
              )}
            </div>
            {alertsEnabled == false ? null : (
              <div>
                <div>
                  Emails:{" "}
                  {(a?.emailAlertsSettings?.doctor_emails || []).join(", ")}
                </div>
                <div>
                  {`Schedule: every day at ${a?.emailAlertsSettings?.doctor_alert_hour}:00 UTC`}
                </div>
              </div>
            )}
            <div>
              {alertsEnabled == true ? (
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={async () => {
                    setLoading(true);
                    let emailAlertsSettingsOld = a?.emailAlertsSettings || {};
                    emailAlertsSettingsOld.enabled = false;
                    await GroupsAPI.updateGroup({
                      code: a?.code,
                      emailAlertsSettings: emailAlertsSettingsOld,
                    });
                    let arr = await GroupsAPI.getGroups();
                    setGroups(arr);
                    setLoading(false);
                    toast.success(`Alerts disabled for ${a.name}`);
                    setSelectedId(undefined);
                  }}
                >
                  Disable alerts
                </span>
              ) : (
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={async () => {
                    setLoading(true);
                    let emailAlertsSettingsOld = a?.emailAlertsSettings || {};
                    emailAlertsSettingsOld.enabled = true;
                    await GroupsAPI.updateGroup({
                      code: a?.code,
                      emailAlertsSettings: emailAlertsSettingsOld,
                    });
                    let arr = await GroupsAPI.getGroups();
                    setGroups(arr);
                    setLoading(false);
                    toast.success(`Alerts enabled for ${a.name}`);
                    setSelectedId(undefined);
                  }}
                >
                  Enable alerts
                </span>
              )}
            </div>
          </GroupItem>
        );
      })}

      {selectedGroup == undefined ? null : (
        <NiceModal
          onClose={() => {
            setSelectedId(undefined);
          }}
        >
          <DialogInner>
            <AlertsForm
              loading={loading}
              {...(selectedGroup?.emailAlertsSettings || {})}
              onSave={async (d) => {
                setLoading(true);
                let emailAlertsSettingsOld =
                  selectedGroup?.emailAlertsSettings || {};
                emailAlertsSettingsOld.enabled = true;
                await GroupsAPI.updateGroup({
                  code: selectedGroup?.code,
                  emailAlertsSettings: {
                    ...d,
                    enabled: true,
                  },
                });
                let arr = await GroupsAPI.getGroups();
                setGroups(arr);
                setLoading(false);
                toast.success("Saved");
                setSelectedId(undefined);
              }}
            />
          </DialogInner>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const EditImg = styled.img`
  cursor: pointer;
  height: 14px;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DialogInner = styled.div`
  width: 620px;
  padding: 10px;
  box-sizing: border-box;
  max-height: calc(100vh - 20px);
  // height: 400px;
  max-height: 720px;
  overflow-y: auto;
`;

const Wrapper = styled.div``;

const GroupItem = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 10px;
  background: white;
`;
