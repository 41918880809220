import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useDimensions from "react-use-dimensions";

const KEYS = [
  "afb_probability",
  "avnn",
  "classification",
  "final_probability",
  "good_ibi_number",
  "good_quality_probability",
  "has_image_file",
  "heart_rate",
  "hta",
  "hta_q",
  "lf",
  "lf_confidence",
  "nsr_probability",
  "pnn50",
  "rmssd",
  "sdnn",
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let tt = payload[0]?.payload?.t;
    let bpm_q = payload[0]?.payload?.Q || payload[0]?.payload?.bpm_q;
    let bpm = payload[0]?.payload?.bpm;
    return (
      <TooltipWrapper>
        {tt == undefined ? null : (
          <TooltipRow className="desc">{`${moment(tt).format(
            "HH:mm"
          )}`}</TooltipRow>
        )}
        <TooltipRow className={"desc"}>{`Pulse Rate: ${bpm}`}</TooltipRow>
        <TooltipRow className="desc">{`Q: ${bpm_q}`}</TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
};

const Y_AXIS = [
  {
    label: "BADQ",
    value: 0,
  },
  {
    label: "Not enough data",
    value: 1,
  },
  {
    label: "Too Much Movement",
    value: 2,
  },

  {
    label: "UND",
    value: 3,
  },
  {
    label: "NSR",
    value: 4,
  },
  {
    label: "AFB",
    value: 5,
  },
];

function getValByName(name) {
  return Y_AXIS.find((x) => x.label === name)?.value;
}

function getLabelByValue(val) {
  return Y_AXIS.find((x) => x.value === val)?.label;
}

export default function ActivityHeartRateChart(props) {
  const { uuid, dayTimestamp } = props;
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [ref, { x, y, width, height }] = useDimensions();
  const [visible, setVisible] = useState(false);

  const [data, setData] = useState(undefined);

  useEffect(() => {
    setVisible(false);
  }, [dayTimestamp]);

  useEffect(() => {
    if (visible == false) {
      return;
    }
    setLoading(true);
    DoctorAPI.getActivitiesDataPointsForDay(uuid, dayTimestamp).then((arr) => {
      setData(arr);
      setLoading(false);
    });
  }, [uuid, dayTimestamp, visible]);

  // let points = items.map(x => {
  //     return {
  //         ...x,
  //         y: getValByName(x.classification)
  //     }
  // });

  // console.log("ActivityHeartRateChart: data = ", data);
  let startT = +moment(dayTimestamp).startOf("day");
  let endT = +moment(dayTimestamp).endOf("day");

  // let firstPoint = ()

  let hrPoints = data?.heart_rate || [];
  // console.log("data?.heart_rate_temp = ", data?.heart_rate_temp);
  let hrPointsDownsampled = downsample(
    +moment(dayTimestamp).startOf("day"),
    data?.heart_rate_temp || [],
    300,
    "bpm_q"
  );
  let hrPointsTemp = data?.heart_rate_temp || [];

  // console.log("render: hrPointsDownsampled = ", hrPointsDownsampled);

  // if (hrPoints.length > 0){
  //     hrPoints = [{
  //         ...hrPoints[0],
  //         t: startT
  //     }].concat(hrPoints).concat([
  //         {
  //             ...hrPoints[hrPoints.length - 1],
  //             t: endT
  //         }
  //     ])
  // }

  return (
    <Wrapper>
      {visible == true ? null : (
        <div style={{ marginTop: 10, marginBottom: 10, textAlign: "right" }}>
          <DottedSpan
            onClick={() => {
              setVisible(true);
            }}
          >
            show Corsano experimental charts
          </DottedSpan>
        </div>
      )}

      {visible == false ? null : (
        <React.Fragment>
          <TopHeading>{`Heart Rate Temp (downsampled)`}</TopHeading>

          <ChartPlaceholder>
            {loading == true ? (
              <Code />
            ) : (
              <LineChart
                width={width}
                height={height}
                data={hrPointsDownsampled}
                connectNulls={false}
                margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
              >
                <XAxis
                  dataKey="t"
                  tickCount={25}
                  domain={[startT, endT]}
                  type={"number"}
                  tickFormatter={(a) => {
                    if (`${a}`.length < 8) {
                      return a;
                    }
                    return moment(+a).format("HH:mm");
                  }}
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line
                  type="monotone"
                  dataKey={"bpm"}
                  stroke="#D92A3E"
                  strokeWidth={2}
                  animationDuration={1}
                  dot={false}
                />
              </LineChart>
            )}
          </ChartPlaceholder>

          <TopHeading>{`Heart Rate (raw activity)`}</TopHeading>

          <ChartPlaceholder ref={ref}>
            {loading == true ? (
              <Code />
            ) : (
              <LineChart
                width={width}
                height={height}
                data={hrPoints}
                connectNulls={false}
                margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
              >
                <XAxis
                  dataKey="t"
                  tickCount={25}
                  domain={[startT, endT]}
                  type={"number"}
                  tickFormatter={(a) => {
                    if (`${a}`.length < 8) {
                      return a;
                    }
                    return moment(+a).format("HH:mm");
                  }}
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line
                  type="monotone"
                  dataKey={"bpm"}
                  stroke="#D92A3E"
                  strokeWidth={2}
                  animationDuration={1}
                  dot={false}
                />
              </LineChart>
            )}
          </ChartPlaceholder>

          <TopHeading>{`Heart Rate Temp (raw activity)`}</TopHeading>

          <ChartPlaceholder>
            {loading == true ? (
              <Code />
            ) : (
              <LineChart
                width={width}
                height={height}
                data={hrPointsTemp}
                connectNulls={false}
                margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
              >
                <XAxis
                  dataKey="t"
                  tickCount={25}
                  domain={[startT, endT]}
                  type={"number"}
                  tickFormatter={(a) => {
                    if (`${a}`.length < 8) {
                      return a;
                    }
                    return moment(+a).format("HH:mm");
                  }}
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                <Line
                  type="monotone"
                  dataKey={"bpm"}
                  stroke="#D92A3E"
                  strokeWidth={2}
                  animationDuration={1}
                  dot={false}
                />
              </LineChart>
            )}
          </ChartPlaceholder>
        </React.Fragment>
      )}
    </Wrapper>
  );
}

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TopHeading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 1px;
  margin-bottom: 10px;
`;

export const DottedSpan = styled.span`
  border-bottom: 1px dashed lightgrey;
  opacity: 0.5;
  cursor: pointer;
  font-size: 12px;
  font-style: italic;

  :hover {
    border-bottom: 1px solid lightgrey;
    opacity: 1;
  }
`;

const Spo2TooltipWrapper = styled.div`
  background: white;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
`;

const ChartPlaceholder = styled.div`
  height: 320px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

function downsample(from, data, seconds = 300.0, qKeyName = "resp_q") {
  const intervalMap = {};

  function roundToHalfHour(ts) {
    let dt = +ts - +from;
    let x =
      Math.floor(+dt / 1000.0 / (seconds * 1.0)) * Math.round(seconds) * 1000;
    return +from + x;
  }

  data.forEach((item) => {
    const roundedTs = roundToHalfHour(item.t);
    let key = `t-${roundedTs}`;
    if (!intervalMap[key]) {
      intervalMap[key] = {
        t: roundedTs,
        points: [],
      };
    }
    intervalMap[key].points.push(item);
  });

  // console.log("down: intervalMap = ", intervalMap);
  const result = [];
  const startOfDay = moment(+from).startOf("day").valueOf();
  const endOfDay = moment(+from).endOf("day").valueOf();

  for (let ts = +from; ts <= endOfDay; ts += Math.round(seconds) * 1000) {
    // 30 minutes in milliseconds
    if (intervalMap[`t-${ts}`]) {
      let respPoints = intervalMap[`t-${ts}`].points.map((x) => x.bpm);
      let qPoints = intervalMap[`t-${ts}`].points.map((x) => x[qKeyName]);
      const avgValue =
        respPoints.length == 0
          ? 0
          : respPoints.reduce((acc, val) => acc + val, 0) / respPoints.length;
      const avgValueQ =
        qPoints.length == 0
          ? 0
          : qPoints.reduce((acc, val) => acc + val, 0) / qPoints.length;
      result.push({ t: ts, bpm: Math.round(+avgValue), Q: avgValueQ });
    } else {
      result.push({ t: ts, bpm: null, Q: null }); // or 0 or some default value
    }
  }

  return result;
}
