import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

export default function RawSleepMetricsTableTool(props) {
    const {metrics = []} = props;

    return (
        <Wrapper>

            <Table>
                <THead>
                    <Th>
                        Date
                    </Th>
                    <Th>
                        Sleep state
                    </Th>
                </THead>

                <Tbody>
                    {metrics.map((a, i) => {
                        return (
                            <Tr key={i}>
                                <Td>
                                    {a.date}
                                </Td>
                                <Td>
                                    {a.sleep_state}
                                </Td>
                            </Tr>
                        )
                    })}
                </Tbody>

            </Table>

        </Wrapper>
    );
}

const Tbody = styled.tbody`

`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid grey;
  text-align: left;
`;


const THead = styled.thead`

`;

const Th = styled.th`

`;

const Tr = styled.tr`

`;

const Td = styled.td`

`;