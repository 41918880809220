import styled from "styled-components";

export const Button = styled.div`
  height: 56px;
  background: ${(props) =>
    props.background == undefined ? "#147AFF" : props.background};
  border-radius: 8px;
  cursor: ${(props) => (props.disabled == true ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  :hover {
    opacity: ${(props) => (props.disabled == true ? 0.5 : 0.8)};
  }
`;

export const Input = styled.input`
  height: 56px;
  line-height: 56px;
  background: white;
  border: 1px solid rgba(135, 152, 173, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  outline: none;
  text-align: left;
  color: #202224;
  font-weight: bold;
  font-size: 17px;
  padding-left: 25px;
  :focus {
  }
  :-webkit-autofill,
  :-webkit-autofill:focus,
  :-webkit-autofill:hover {
    background: white !important;
    color: #202224 !important;
  }
`;

export function LinedText(props) {
  let { text } = props;
  return (
    <LinedTextWrapper>
      <Line />
      <LinedTextComp>
        <TextInner>{text}</TextInner>
      </LinedTextComp>
    </LinedTextWrapper>
  );
}

const LinedTextWrapper = styled.div`
  position: relative;
  height: 50px;
  text-align: center;
  line-height: 50px;
  box-sizing: border-box;
  width: 100%;
`;

const LinedTextComp = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const TextInner = styled.div`
  background: white;
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  z-index: 1;
  font-size: 18px;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: bold;
`;

const Line = styled.div`
  position: relative;
  z-index: 0;
  top: 25px;
  height: 1px;
  width: 100%;
  background: whitesmoke;
`;
