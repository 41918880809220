import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import SleepAPI from "../../../api/SleepAPI";
import RonasBasicAPI from "../../../api/RonasBasicAPI";
import useDimensions from "react-use-dimensions";
import SleepScheduleBarChart from "./SleepScheduleBarChart";
import CommonHelper from "../../../helpers/CommonHelper";

export default function SevenDaysSleepScheduleTool(props) {
    const {
        dayTimestamp,
        transformValue = x => x,
        momentMask = 'ddd, D',
        strokeColor = 'rgb(25, 136, 227)',
        loading = false
    } = props;
    const [points, setPoints] = useState([]);

    useEffect(() => {
        let mounted = true;
        SleepAPI.getInstance().getSleepStatsFor7DaysFromCache(dayTimestamp).then(arr => {
            if (mounted == false) {
                return;
            }
            setPoints(arr.map(xx => ({
                ...xx,
                t: +moment(CommonHelper.getTimestampFromDateString(xx.date)).startOf('day')
            })).map(x => ({...x, value: Math.max(0, x.value)})));
            if (mounted == false) {
                return;
            }
            SleepAPI.getInstance().getSleepStatsFor7DaysFromCache(dayTimestamp).then(arr2 => {
                if (mounted == false) {
                    return;
                }
                setPoints(arr2.map(xx => ({
                    ...xx,
                    t: +moment(CommonHelper.getTimestampFromDateString(xx.date)).startOf('day')
                })).map(x => ({...x, value: (x.value <= 0) ? null : Math.max(0, x.value)})));
            });
        });
        return () => {
            mounted = false;
        }
    }, [dayTimestamp]);

    return (
        <Wrapper>
            <SleepScheduleBarChart sleepPoints={points}/>
        </Wrapper>
    );
}

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;