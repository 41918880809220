import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {useInterval} from "../../apps/RouterApp";
import ExportAPI from "../../../api/ExportAPI";
import ProgressTool from "../../progress/tools/ProgressTool";

import download_img from '../../../assets/images/download_folder_2.svg'

const getDateSpans = (from, to) => {
    let n = Math.ceil(((+moment(+to).endOf('day')) - (+moment(+from).startOf('day'))) / 86400000.0);
    let start = +moment(+from).startOf('day');
    let dt = 86400000;
    let arr = [];
    for (let i = 0; i < n; i++) {
        let t = +start + i * dt;
        arr.push({
            from: Math.max(+t, +from),
            to: Math.min(+t + 86400000 - 1, +to),
            name: moment(+t).format('YYYY-MM-DD')
        })
    }
    return arr;
}

function stringHash(s) {
    let x = s.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a
    }, 0);
    return `x${x}`;
}

export default function ExportServiceTool(props) {
    const {
        study_tag = '',
        users = [],
        types = [],
        from, to,
        name = '',
        expUserId
    } = props;

    const jobId = stringHash(`${study_tag}_${types.join('_')}_${from}_${to}`) + `_${expUserId}`;
    const [job, setJob] = useState(undefined);

    let data = {
        date_spans: getDateSpans(from, to),
        id: jobId,
        users: users,
        types: types,
        study_tag: study_tag
    }

    useEffect(() => {
        ExportAPI.createExportJob({
            ...data,
        }).then(pld => {
            setJob(pld);
        });
    }, [jobId]);

    useInterval(() => {
        ExportAPI.createExportJob({
            ...data,
        }).then(pld => {
            setJob(pld);
        });
    }, 2 * 1000);

    let logs = (job?.logs || []).sort((a, b) => (+b.t - +a.t));

    let jobStatsus = job?.status;
    if (jobStatsus == 'new') {
        jobStatsus = 'enqueued';
    }

    return (
        <Wrapper>

            {(job?.status == 'processing' || job?.status == 'finished') ? null :
                <TopPlaceholder>
                    <div>
                        {`Export status: ${jobStatsus || 'New'}`}
                    </div>
                </TopPlaceholder>
            }

            {job?.progress == undefined || job?.progress == 100.0 ? null :
                <div style={{marginBottom: 7}}>
                    <ProgressTool
                        leftText={`Export progress - ${job?.progress.toFixed(0)}%`}
                        percent={+job?.progress}
                        color={'blue'}
                    />
                </div>
            }

            {(job?.status == 'finished') ? null :
                <LogsPlaceholder>
                    {logs.map((a, i) => {
                        return (
                            <LogItem key={`${a.t}_${i}`}>
                                <LogDate>
                                    {moment(a.t).format('HH:mm:ss')}
                                </LogDate>
                                <LogMessage>
                                    {a.message}
                                </LogMessage>
                            </LogItem>
                        )
                    })}
                </LogsPlaceholder>
            }

            <DownloadPlaceholder>
                {job?.status != 'finished' ? 'Preparing archive...' :
                    <DownloadArchiveLink href={ExportAPI.getJobDownloadUrl(jobId, name)} target={'_blank'}>
                        <DownloadImage src={download_img}/>
                        <div>
                            Download archive
                        </div>
                    </DownloadArchiveLink>
                }
            </DownloadPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const LogItem = styled.div`

`;

const LogMessage = styled.div`
  opacity: 0.5;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const LogDate = styled.div`
  font-size: 12px;
`;

const TopPlaceholder = styled.div`
  font-size: 16px;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 5px;
`;

const LogsPlaceholder = styled.div`
  max-height: 320px;
  height: 320px;
  overflow-y: auto;
  border-bottom: 1px solid whitesmoke;
`;

const DownloadPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DownloadArchiveLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DownloadImage = styled.img`
  height: 80px;
`;