import MMTCloud from "./MMTCloud";
import RonasBasicAPI from "./RonasBasicAPI";
import TarnsformerHelper from "../helpers/TarnsformerHelper";
import moment from "moment";

class StepsAPI {

    private static instance: StepsAPI
    private cloud?: MMTCloud;
    private ronasBasicAPI: RonasBasicAPI;

    constructor() {
        this.cloud = MMTCloud.getInstance();
        this.ronasBasicAPI = RonasBasicAPI.getInstance();
    }

    public static getInstance(): StepsAPI {
        if (!StepsAPI.instance) {
            StepsAPI.instance = new StepsAPI();
        }
        return StepsAPI.instance;
    }

    public async getActivityForDayFromCache(dayTimestamp: number) {
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(dayTimestamp, 'activity');
        console.log('cahce: val = ', val);
        return val;
    }

    public async getActivityForDayFromCloud(dayTimestamp: number) {
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRate(dayTimestamp, 'activity');
        return val;
    }

    public async getStepsItemsForDayFromCache(dayTimestamp: number) {
        let s = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(dayTimestamp, 'activity');
        let slots = (s == undefined) ? [] : (s.slots == undefined ? [] : s.slots);
        let arr = TarnsformerHelper.get24HoursStepsArray(slots);
        return arr;
    }

    public async getStepsItemsForDayFromCloud(dayTimestamp: number) {
        let s = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRate(dayTimestamp, 'activity');
        let slots = (s == undefined) ? [] : (s.slots == undefined ? [] : s.slots);
        let arr = TarnsformerHelper.get24HoursStepsArray(slots);
        // console.log('getStepsItemsForDayFromCloud: slots, arr = ', slots, arr);
        return {
            ...s,
            slots: arr
        };
        // return arr;
    }

    public async getActivityStatsForYearFromCache(yearTimestamp: number) {
        let items = await this.ronasBasicAPI.getAllSummariesOfSpecifiedTypeFromCache('activity');
        let from = +moment(yearTimestamp).startOf('year');
        let to = +moment(yearTimestamp).endOf('year');
        items = items.filter((x: any) => (x.distance != undefined)).filter((x: any) => (+moment(x.date) >= +from && +moment(x.date) <= +to));
        return items;
    }

    public async getActivityStatsFor7DaysFromCache(dayTimestamp: number) {
        let items = await this.ronasBasicAPI.getAllSummariesOfSpecifiedTypeFromCache('activity');
        let from = +moment(dayTimestamp).add(-6, 'days').startOf('day');
        let to = +moment(dayTimestamp).endOf('day');
        items = items.filter((x: any) => (x.distance != undefined)).filter((x: any) => (+moment(x.date) >= +from && +moment(x.date) <= +to));
        return items;
    }


}

export default StepsAPI;