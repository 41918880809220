import { useState } from "react";
import styled from "styled-components";

import Spin from "arui-feather/spin";
import DoctorAPI from "../../api/DoctorAPI";

import toast from "react-hot-toast";
import { Input } from "../auth/ui";
import MMTCloud from "../../api/MMTCloud";

import cm_new_logo from "../../assets/images/cm_new_logo.png";
import { getLabelsInfoData } from "../groups/panels/GroupsPanel";

import fr_image from "../../assets/images/cm_group_people.svg";
import { useParams } from "react-router-dom";

function JoinApp(props) {
  let params = useParams();
  let { code } = params;

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [joined, setJoined] = useState(false);

  // const {
  //     currentUserObj,
  //     email
  // } = useMappedState(useCallback(state => {
  //     let email = state?.auth?.currentUserObj?.usersUser?.email;
  //     return {
  //         currentUserObj: state.auth.currentUserObj,
  //         email: email
  //     }
  // }));
  // console.log('currentUserObj = ', currentUserObj);
  // let uuid = currentUserObj?.healthUser?.uuid;

  if (joined == true) {
    return (
      <Wrapper>
        <InnerBoxSuccess style={{ fontSize: 26 }}>
          <img src={fr_image} style={{ width: "80%" }} />
          <div style={{ fontSize: 30 }} className="join-page">
            You joined the group!
          </div>
          <div style={{ fontSize: 12, marginTop: 10, opacity: 0.5 }}>
            You will be contacted by the group owner.
          </div>
        </InnerBoxSuccess>
      </Wrapper>
    );
  }

  return (
    <Wrapper className="join-page">
      <InnerBox>
        <h2>{`Join group`}</h2>

        <h1>{code}</h1>

        <Row>
          <Label>Email</Label>
          <Input
            placeholder={"Email"}
            value={email}
            onChange={(evt) => {
              setEmail(evt.target.value);
            }}
          />
        </Row>

        <Row>
          <Label>Password</Label>
          <Input
            value={password}
            type={"password"}
            onChange={(evt) => {
              setPassword(evt.target.value);
            }}
          />
        </Row>

        {errorMessage == undefined ? null : (
          <div style={{ color: "red", padding: 15 }}>{errorMessage}</div>
        )}

        <Button
          onClick={async () => {
            setLoading(true);
            setErrorMessage(undefined);
            let pld = await MMTCloud.getInstance().checkLoginAndPassword(
              email,
              password
            );
            if (pld == undefined) {
              setErrorMessage(`Incorrect email or password`);
              toast(`Incorrect email or password`);
              setLoading(false);
              return;
            }
            let { uuid } = pld;
            console.log("render: pld = ", pld);
            await DoctorAPI.joinGroup(uuid, code);
            setLoading(false);
            toast(`You are added to the group [${code}]`);
            setJoined(true);
          }}
        >
          <Spin visible={loading} />

          {loading == true ? null : "Join"}
        </Button>

        {getLabelsInfoData().invite_enabled == false ? null : (
          <img
            src={cm_new_logo}
            style={{ height: 30, marginTop: 50, opacity: 0.3 }}
          />
        )}
      </InnerBox>
    </Wrapper>
  );
}

const Row = styled.div`
  margin-bottom: 10px;
  text-align: left;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InnerBoxSuccess = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 720px;
  margin: 0 auto;
  text-align: center;
  opacity: 0.7;
  @media (max-width: 720px) {
    width: 100%;
    padding: 10px;
  }
`;

const InnerBox = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 620px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 720px) {
    width: 100%;
    padding: 10px;
  }
`;

export const Button = styled.div`
  height: 56px;
  background: ${(props) =>
    props.background == undefined ? "#147AFF" : props.background};
  border-radius: 8px;
  cursor: ${(props) => (props.disabled == true ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;

  :hover {
    opacity: ${(props) => (props.disabled == true ? 0.5 : 0.8)};
  }
`;

export default JoinApp;
