import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useMappedState } from "redux-react-hook";
import styled from "styled-components";
import ch_not_sel from "../../../assets/images/checkbox.svg";
import ch_sel from "../../../assets/images/chbx.svg";
import DoctorAPI from "../../../api/DoctorAPI";
import moment from "moment";
import { Button } from "../../ui/templates";
import { Spin } from "arui-feather/spin";

export const DEFAULT_SO = 2;
export const DEFAULT_LOC = "A";

const AoO = [
  {
    value: 2,
    label: "Air",
    default: true,
    note: "Patient is breathing room air",
  },
  {
    value: 1,
    label: "Oxygen",
    note: "Patient is receiving oxygen",
  },
];

const LOC_OPTIONS = [
  {
    value: "A",
    label: "Alert (A)",
    default: true,
    note: "Patient is fully awake",
  },
  {
    value: "C",
    label: "Confusion (C)",
    note: "Patient shows new confusion or disorientation",
  },
  {
    value: "V",
    label: "Voice (V)",
    note: "Patient responds to verbal stimuli",
  },
  {
    value: "P",
    label: "Pain (P)",
    note: "Patient responds to painful stimuli",
  },
  {
    value: "U",
    label: "Unresponsive (U)",
    note: "Patient does not respond to any stimuli",
  },
];

export default function PatientOtherNewsTool(props) {
  const { uuid, map, setSpotId = () => {}, callbackFunc = () => {} } = props;
  const [consciousnessLevel, setConsciousnessLevel] = useState(undefined);
  const [supplementalOxygen, setSupplementalOxygen] = useState(undefined);
  const [selectedConsciousnessLevel, setSelectedConsciousnessLevel] =
    useState(undefined);
  const [selectedSupplementalOxygen, setSelectedSupplementalOxygen] =
    useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userSpots = map[uuid];
    if (!userSpots) return;
    const cL = userSpots?.external_device_spot_measurement_consciousness_level;
    const sO = userSpots?.external_device_spot_measurement_supplemental_oxygen;
    // console.log("so", sO);

    setConsciousnessLevel(cL?.consciousness_level);
    setSupplementalOxygen(sO?.supplemental_oxygen);
    setSelectedSupplementalOxygen(sO?.supplemental_oxygen);
    setSelectedConsciousnessLevel(cL?.consciousness_level);
  }, [uuid, map]);

  const handleSubmit = async (e) => {
    const D = moment().toISOString(true);
    setLoading(true);

    if (selectedSupplementalOxygen !== undefined) {
      let _b = {
        supplemental_oxygen: selectedSupplementalOxygen,
        date: D,
      };

      DoctorAPI.postUserSupplementalOxygen(uuid, _b)
        .then((res) => {
          setLoading(false);
          setSpotId(undefined);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
    if (selectedConsciousnessLevel !== undefined) {
      let _b = {
        consciousness_level: selectedConsciousnessLevel,
        date: D,
      };
      DoctorAPI.postUserConsciousnessLevel(uuid, _b)
        .then((res) => {
          setLoading(false);
          setSpotId(undefined);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  return (
    <Wrapper>
      {/* <div>{moment().toISOString(true)}</div> */}
      <Label>Supplemental Oxygen </Label>
      <HorizontalOptionsContainer>
        {AoO.map((l, i) => {
          let selected;
          if (selectedSupplementalOxygen === undefined) {
            selected =
              supplementalOxygen !== undefined
                ? supplementalOxygen === l.value
                : l.value === DEFAULT_SO;
          } else {
            selected = selectedSupplementalOxygen === l.value ? true : false;
          }

          return (
            <OptionItem
              key={`${i}_${l.value}`}
              selected={selected}
              onClick={() => {
                setSelectedSupplementalOxygen(l.value);
              }}
            >
              <CheckBox src={selected ? ch_sel : ch_not_sel} />
              <div style={{ display: "block" }}>
                <OptionSpan selected={selected}>{l.label}</OptionSpan>
                <Note>{l.note}</Note>
              </div>
            </OptionItem>
          );
        })}
      </HorizontalOptionsContainer>
      <Label>Level of Consciousness</Label>
      <VertitalOptionsContainer>
        {LOC_OPTIONS.map((l, i) => {
          let selected;
          if (selectedConsciousnessLevel === undefined) {
            selected =
              consciousnessLevel !== undefined
                ? consciousnessLevel === l.value
                : l.value === DEFAULT_LOC;
          } else {
            selected = selectedConsciousnessLevel === l.value ? true : false;
          }
          return (
            <OptionItem
              key={`${i}_${l.value}`}
              selected={selected}
              onClick={() => {
                setSelectedConsciousnessLevel(l.value);
              }}
            >
              <CheckBox src={selected ? ch_sel : ch_not_sel} />

              <div style={{ display: "block" }}>
                <OptionSpan selected={selected}>{l.label}</OptionSpan>
                <Note>{l.note}</Note>
              </div>
            </OptionItem>
          );
        })}
      </VertitalOptionsContainer>
      <div>
        <Button
          style={{ width: "90%" }}
          className="range-button"
          onClick={() => {
            // if (
            //   window.confirm(
            //     "Please confirm you want to add spot measurements."
            //   ) == false
            // ) {
            //   return;
            // }
            handleSubmit();
            // callbackFunc();
          }}
        >
          <Spin visible={loading} className="range-spin" />
          {loading ? null : "Save"}
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const HorizontalOptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
`;

const VertitalOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  margin: 0 auto;
  margin-bottom: 30px;
  width: max-content;
`;
const Label = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Note = styled.div`
  font-size: 16px;
  opaque: 75%;
`;

const OptionItem = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
`;

const OptionSpan = styled.span`
  font-weight: ${(props) => (props.selected ? 600 : 400)};
`;

const CheckBox = styled.img`
  width: 14px;
  right: 16px;
  margin-right: 5px;
  cursor: pointer;
`;
