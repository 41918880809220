import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import EventImg from "./eventBell.svg";
import NiceModal from "../../modals/NiceModal";

const CorEventsTool = (props) => {
  const items = Array.from(Array(10).keys()).map((x, i) => {
    return {
      title: `Test ${i}`,
      t: moment().valueOf(),
      index: i,
    };
  });

  const [showEventPopup, setShowEventPopup] = useState(undefined);

  return (
    <div>
      <EventContainer>
        <TabSubHeading>Cor-Events</TabSubHeading>
        <HorizontalContainer>
          {items.map((x, i) => {
            return (
              <EventBox
                onClick={() => {
                  setShowEventPopup(x.index);
                }}
              >
                <EventContent>
                  <div style={{ display: "flex" }}>
                    <img
                      src={EventImg}
                      width={20}
                      height={20}
                      style={{ marginRight: "5px" }}
                    />
                    <EventTitle>{x.title}</EventTitle>
                  </div>
                  <EventTime>
                    {moment(x.t).format("DD MMM YYYY HH:mm")}
                  </EventTime>
                </EventContent>
              </EventBox>
            );
          })}
        </HorizontalContainer>
      </EventContainer>
      {showEventPopup !== undefined && (
        <NiceModal
          onClose={() => {
            setShowEventPopup(undefined);
          }}
        >
          <div>
            <div style={{ marginBottom: "2em", marginTop: "1em" }}>
              Test Event {showEventPopup}
            </div>
            <EventButtonGroup>
              <EventButton onClick={() => setShowEventPopup(undefined)}>
                Confirm
              </EventButton>
              <EventButton onClick={() => setShowEventPopup(undefined)}>
                Escalate
              </EventButton>
            </EventButtonGroup>
          </div>
        </NiceModal>
      )}
    </div>
  );
};

const EventContainer = styled.div`
  height: 150px;
  margin-bottom: 25px;
`;

const EventBox = styled.div`
  min-width: 150px;
  max-width: 150px;
  height: 60px;
  background-color: #e59079;
  border-radius: 15px;
  display: flex;
  // justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const EventContent = styled.div`
  display: flex;

  flex-direction: column;
`;

const EventTime = styled.div`
  font-size: 0.8em;
  color: #dedede;
  font-weight: 500;
`;
const EventTitle = styled.div`
  color: #e1e1e1;
  font-size: 1em;
  font-weight: 600;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  scrollbar-color: #ccc #eee;
  scrollbar-width: thin;
  padding: 10px;
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
    background: transparent;
    width: 0;
    height: 0;
    border-left: 16px solid #333;
    border-top: 16px solid #333;
    border-bottom: 16px solid white;
    border-right: 16px solid white;
  }
`;

const TabSubHeading = styled.div`
  //   font-weight: light;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  // margin-bottom: 10px;
`;

const EventButtonGroup = styled.div`
  display: flex;
  gap: 20px;
`;
const EventButton = styled.div`
  font-weight: 600;
  background: #488f8d;
  padding: 5px;
  margin: 0 auto;
  border-radius: 10px;
  height: 24px;
  color: #efeefe;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

export default CorEventsTool;
