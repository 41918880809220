import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SurveyViewerTool from "../../surveys/panels/SurveyViewerTool";

import paramsData from './cardiolyse_params.json'

function getParamsMap() {
    if (window.CARDIOLYSE_PARAMS_MAP != undefined){
        return window.CARDIOLYSE_PARAMS_MAP;
    }
    let map = {};
    let arr = paramsData.data;
    for (let i in arr) {
        let a = arr[i];
        map[a.parameterId] = a;
    }
    window.CARDIOLYSE_PARAMS_MAP = map;
    return map;
}

export default function DetailsValuesTable(props) {
    const {
        values = []
    } = props;

    let parMap = useMemo(() => getParamsMap(), []);
    console.log('parMap = ', parMap);

    return (
        <Wrapper>

            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            <div style={{padding: 3}} >
                                Parameter
                            </div>
                        </Th>
                        <Th>
                            <div style={{padding: 3}} >
                                Value
                            </div>
                        </Th>
                        <Th>
                            <div style={{padding: 3}} >
                                Normalized value
                            </div>
                        </Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {values.map((a, i) => {
                        let {parameterName, value, normalizedValue} = a;
                        let descOb = parMap[a.parameterName];
                        return (
                            <React.Fragment key={`${i}_${parameterName}`}>
                                <Tr>
                                    <Td>
                                        <div style={{fontSize: '16px', fontWeight: 'bold', padding: 3}} >
                                            {descOb?.text}
                                        </div>
                                        <div style={{width: 520, opacity: 0.5, fontSize: '12px', fontStyle: 'italic', padding: 3}} >
                                            {descOb?.description}
                                        </div>

                                    </Td>
                                    <Td>
                                        {value}
                                    </Td>
                                    <Td>
                                        {normalizedValue}
                                    </Td>

                                </Tr>
                            </React.Fragment>
                        )
                    })}
                </Tbody>

            </Table>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;


const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;


const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;