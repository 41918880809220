import MMTCloud from "./MMTCloud";
import RonasBasicAPI from "./RonasBasicAPI";
import TarnsformerHelper from "../helpers/TarnsformerHelper";
import moment from 'moment'

class SleepAPI {

    private static instance: SleepAPI
    private cloud?: MMTCloud;
    private ronasBasicAPI: RonasBasicAPI;

    constructor() {
        this.cloud = MMTCloud.getInstance();
        this.ronasBasicAPI = RonasBasicAPI.getInstance();
    }

    public static getInstance(): SleepAPI {
        if (!SleepAPI.instance) {
            SleepAPI.instance = new SleepAPI();
        }
        return SleepAPI.instance;
    }

    public async getSleepForDayFromCache(dayTimestamp: number) {
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRateFromCache(dayTimestamp, 'sleep');
        console.log('cahce: val = ', val);
        return val;
    }

    public async getRawSleepMetrics(dayTimestamp: number) {
        // let date = moment(dayTimestamp).format('YYYY-MM-DD');
        let fromDate = (new Date(+moment(dayTimestamp).startOf('day').add(-4, 'hours'))).toJSON();
        let toDate = (new Date(+moment(dayTimestamp).endOf('day'))).toJSON();
        let dd = await this.ronasBasicAPI.getRawMetricsForRange(fromDate, toDate, 'sleep');
        return dd;
    }

    public async getSleepForDayFromCloud(dayTimestamp: number) {
        let val0 = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRate(dayTimestamp - 86400000, 'sleep'); // loading data for prev day
        let val = await this.ronasBasicAPI.getDailySummaryActivitySleepOrHeartRate(dayTimestamp, 'sleep');
        return val;
    }

    public async getSleepStatsForYearFromCache(yearTimestamp: number) {
        let items = await this.ronasBasicAPI.getAllSummariesOfSpecifiedTypeFromCache('sleep');
        let from = +moment(yearTimestamp).startOf('year');
        let to = +moment(yearTimestamp).endOf('year');
        items = items.filter((x: any) => (x.tranquility != undefined)).filter((x: any) => (+moment(x.date) >= +from && +moment(x.date) <= +to));
        return items;
    }

    public async getSleepStatsFor7DaysFromCache(dayTimestamp: number) {
        let items = await this.ronasBasicAPI.getAllSummariesOfSpecifiedTypeFromCache('sleep');
        let from = +moment(dayTimestamp).add(-6, 'days').startOf('day');
        let to = +moment(dayTimestamp).endOf('day');
        items = items.filter((x: any) => (x.tranquility != undefined)).filter((x: any) => (+moment(x.date) >= +from && +moment(x.date) <= +to));
        let enhIt = (it: any) => {
            let arr = it?.slots ? it?.slots : [];
            if (arr.length == 0) {
                return it;
            }
            return {...it, sleep_start: arr[0].start_date, sleep_end: arr[arr.length - 1].end_date}
        }
        return items.map((x: any) => enhIt(x));
    }


}

export default SleepAPI;