// theme
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_TEMP_UNIT = "CHANGE_TEMP_UNIT";

// list
export const CHANGE_PRIORITY = "CHANGE_PRIORITY";
export const CHANGE_ORDERING = "CHANGE_ORDERING";
export const CHANGE_FILTER = "CHANGE_FILTER";

// role
export const CHANGE_ROLE = "CHANGE_ROLE";

// alarm
export const CHANGE_ALARM_PARAMS = "CHANGE_ALARM_PARAMS";
export const CHANGE_ALARM = "CHANGE_ALARM";

// performance
export const LOG_LOADING_PERFORMANCE = "LOG_LOADING_PERFORMANCE";

//users
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_FAIL = "LOAD_USERS_FAIL";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const INITIALIZE_AUTH = "INITIALIZE_AUTH";
export const INITIALIZE_AUTH_SUCCESS = "INITIALIZE_AUTH_SUCCESS";
export const INITIALIZE_AUTH_FAIL = "INITIALIZE_AUTH_FAIL";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

//

export const LOAD_DATA = "LOAD_DATA";
export const LOAD_DATA_SUCCESS = "LOAD_DATA_SUCCESS";
export const LOAD_DATA_FAIL = "LOAD_DATA_FAIL";

export const SELECT_DAY = "SELECT_DAY";

// realtime
export const UPDATE_ONLINE_TUNNELS = "UPDATE_ONLINE_TUNNELS";
export const UPDATE_ONLINE_PHONES = "UPDATE_ONLINE_PHONES";

// money
export const SET_USER_BALANCE = "SET_USER_BALANCE";

// sessions

export const CREATE_SESSION = "CREATE_SESSION";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_FAIL = "CREATE_SESSION_FAIL";

export const UPDATE_SESSION = "UPDATE_SESSION";
export const UPDATE_SESSION_SUCCESS = "UPDATE_SESSION_SUCCESS";
export const UPDATE_SESSION_FAIL = "UPDATE_SESSION_FAIL";

export const DELETE_SESSION = "DELETE_SESSION";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_FAIL = "DELETE_SESSION_FAIL";

export const LOAD_SESSIONS = "LOAD_SESSIONS";
export const LOAD_SESSIONS_SUCCESS = "LOAD_SESSIONS_SUCCESS";
export const LOAD_SESSIONS_FAIL = "LOAD_SESSIONS_FAIL";

// plans
export const LOAD_PLANS = "LOAD_PLANS";
export const LOAD_PLANS_SUCCESS = "LOAD_PLANS_SUCCESS";
export const LOAD_PLANS_FAIL = "LOAD_PLANS_FAIL";

export const CREATE_PLAN = "CREATE_PLAN";
export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_FAIL = "CREATE_PLAN_FAIL";

export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";

export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAIL = "DELETE_PLAN_FAIL";

//points
export const LOAD_POINTS = "LOAD_POINTS";
export const LOAD_POINTS_SUCCESS = "LOAD_POINTS_SUCCESS";
export const LOAD_POINTS_FAIL = "LOAD_POINTS_FAIL";

// groups
export const LOAD_GROUPS = "LOAD_GROUPS";
export const LOAD_GROUPS_SUCCESS = "LOAD_GROUPS_SUCCESS";
export const LOAD_GROUPS_FAIL = "LOAD_GROUPS_FAIL";

export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL";

//

export const CREATE_PATIENT = "CREATE_PATIENT";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAIL = "CREATE_PATIENT_FAIL";

export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL";

export const DELETE_PATIENT = "DELETE_PATIENT";
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS";
export const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL";

export const LOAD_PATIENTS = "LOAD_PATIENTS";
export const LOAD_PATIENTS_SUCCESS = "LOAD_PATIENTS_SUCCESS";
export const LOAD_PATIENTS_FAIL = "LOAD_PATIENTS_FAIL";

// bracelets

export const LOAD_BRACELETS = "LOAD_BRACELETS";
export const LOAD_BRACELETS_SUCCESS = "LOAD_BRACELETS_SUCCESS";
export const LOAD_BRACELETS_FAIL = "LOAD_BRACELETS_FAIL";

export const CREATE_BRACELET = "CREATE_BRACELET";
export const CREATE_BRACELET_SUCCESS = "CREATE_BRACELET_SUCCESS";
export const CREATE_BRACELET_FAIL = "CREATE_BRACELET_FAIL";

export const UPDATE_BRACELET = "UPDATE_BRACELET";
export const UPDATE_BRACELET_SUCCESS = "UPDATE_BRACELET_SUCCESS";
export const UPDATE_BRACELET_FAIL = "UPDATE_BRACELET_FAIL";

export const DELETE_BRACELET = "DELETE_BRACELET";
export const DELETE_BRACELET_SUCCESS = "DELETE_BRACELET_SUCCESS";
export const DELETE_BRACELET_FAIL = "DELETE_BRACELET_FAIL";

// inmates (corsano-patients)

export const CREATE_INMATE = "CREATE_INMATE";
export const CREATE_INMATE_SUCCESS = "CREATE_INMATE_SUCCESS";
export const CREATE_INMATE_FAIL = "CREATE_INMATE_FAIL";

export const UPDATE_INMATE = "UPDATE_INMATE";
export const UPDATE_INMATE_SUCCESS = "UPDATE_INMATE_SUCCESS";
export const UPDATE_INMATE_FAIL = "UPDATE_INMATE_FAIL";

export const DELETE_INMATE = "DELETE_INMATE";
export const DELETE_INMATE_SUCCESS = "DELETE_INMATE_SUCCESS";
export const DELETE_INMATE_FAIL = "DELETE_INMATE_FAIL";

export const LOAD_INMATES = "LOAD_INMATES";
export const LOAD_INMATES_SUCCESS = "LOAD_INMATES_SUCCESS";
export const LOAD_INMATES_FAIL = "LOAD_INMATES_FAIL";

// gateways

export const CREATE_GATEWAY = "CREATE_GATEWAY";
export const CREATE_GATEWAY_SUCCESS = "CREATE_GATEWAY_SUCCESS";
export const CREATE_GATEWAY_FAIL = "CREATE_GATEWAY_FAIL";

export const UPDATE_GATEWAY = "UPDATE_GATEWAY";
export const UPDATE_GATEWAY_SUCCESS = "UPDATE_GATEWAY_SUCCESS";
export const UPDATE_GATEWAY_FAIL = "UPDATE_GATEWAY_FAIL";

export const DELETE_GATEWAY = "DELETE_GATEWAY";
export const DELETE_GATEWAY_SUCCESS = "DELETE_GATEWAY_SUCCESS";
export const DELETE_GATEWAY_FAIL = "DELETE_GATEWAY_FAIL";

export const LOAD_GATEWAYS = "LOAD_GATEWAYS";
export const LOAD_GATEWAYS_SUCCESS = "LOAD_GATEWAYS_SUCCESS";
export const LOAD_GATEWAYS_FAIL = "LOAD_GATEWAYS_FAIL";
