import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import { useInterval } from "../../apps/RouterApp";
// import TimeAgo from "javascript-time-ago";
import zmImage from "../../../assets/images/badges/zmdi_time-restore.svg";
import zmImageRed from "../../../assets/images/badges/zmdi_time-restore_red.svg";

// const timeAgo = new TimeAgo('en-US');

let imgSize = 18;

export default function AgoSpan(props) {
  const { agoTimestamp, layout } = props;

  const [updT, setUpdT] = useState(0);

  // useInterval(() => {
  //   if (agoTimestamp == undefined) {
  //     return;
  //   }
  //   setUpdT(+new Date());
  // }, 1000);

  // let tooLongAgo = (+new Date() - +agoTimestamp > 5 * 60 * 1000);
  let tooLongAgo = +new Date() - +agoTimestamp > 5.0 * 60 * 1000;
  let agoString =
    agoTimestamp == undefined ? "" : moment(agoTimestamp).fromNow();

  let dt = agoTimestamp == undefined ? undefined : +new Date() - +agoTimestamp;
  if (dt < 60 * 1000) {
    let seconds = Math.round(dt / 1000.0);
    agoString = `${seconds} ${layout === "small_tiles" ? "sec" : "second"}${
      seconds < 2 || layout === "small_tiles" ? "" : "s"
    } ago`;
  }

  if (dt < 1 * 1000) {
    agoString = `1 second ago`;
  }

  return (
    <Wrapper
      key={updT}
      style={{ color: tooLongAgo == true ? "red" : "#8798AD", marginRight: 5 }}
      className="bottom-age-span"
    >
      <img
        style={{ height: imgSize, marginRight: 5, width: imgSize }}
        src={tooLongAgo ? zmImageRed : zmImage}
        className={tooLongAgo == true ? "blink_me " : "too-long-logo"}
      />
      <span>{agoString}</span>
    </Wrapper>
  );
}

const Wrapper = styled.span`
  color: #8798ad;
  display: inline-flex;
  flex-direction: row;
  width: max-content;

  // justify-content: center;
  align-items: center;

  font-size: 14px;
  line-height: 32px;
  font-weight: bold;
`;
