import QRCode from "react-qr-code";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-hot-toast";

const QrCodesList = (props) => {
    let { patientsList } = props;
    const [visibleList, setVisibleList] = useState(
        patientsList.map((x) => {
            return { code: x?.code, show: false };
        })
    );

    const setCodeVisible = (code) => {
        const newVisibleList = visibleList.map((v, i) => {
            const _show = v.show;
            if (v.code === code) {
                return {
                    ...v,
                    show: !_show,
                };
            } else {
                return {
                    ...v,
                };
            }
        });
        setVisibleList(newVisibleList);
    };
    return (
        <div style={{ display: "block", textAlign: "center" }}>
            <div className="normal-text">
                {patientsList.length > 0
                    ? "Please use your Corsano app to scan the QR codes provided."
                    : "To recruit participant for this study via QR codes, first generate new vouchers."}
            </div>
            {patientsList.map((p, i) => {
                let code = p?.code;
                let visible = visibleList.find((c) => c.code === code).show;
                return (
                    <div
                        key={`qr${i}`}
                        className="normal-text"
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            border: "1px dashed #339911",
                            textAlign: "center",
                            display: "block",
                        }}
                    >
                        <div
                            style={{
                                display: "block",
                                textAlign: "center",
                            }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                Patient {code}
                            </span>
                            {/* <span
                                className="normal-text"
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    opacity: "0.5",
                                }}
                                onClick={() => {
                                    setCodeVisible(code);
                                }}
                            >
                                show
                            </span> */}
                        </div>
                        <QRCode
                            onClick={() => {
                                setCodeVisible(code);
                            }}
                            style={
                                visible
                                    ? { ...QRCodeStyle }
                                    : { ...QRCodeStyle, filter: "blur(5px)" }
                            }
                            value={`https://install.corsano.com/?voucher=${code}`}
                            size={120}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const QRCodeStyle = {
    cursor: "pointer",
};

export default QrCodesList;
