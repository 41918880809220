import * as types from '../ActionTypes'
import RonasBasicAPI from '../../api/RonasBasicAPI';
import SleepAPI from '../../api/SleepAPI';

//LOAD_DATA

let startLoadData = () => {
    return {
        type: types.LOAD_DATA
    }
}
let loadDataFailed = (err) => {
    return {
        type: types.LOAD_DATA_FAIL,
        error: err
    }
}
let loadDataSuccess = (data) => {
    return {
        type: types.LOAD_DATA_SUCCESS,
        data: data
    }
}

//thunk
export function loadYearSummaries(yearTimestamp) {
    return (dispatch, getState) => {
        dispatch(startLoadData());
        return RonasBasicAPI.getInstance().getUserSummariesForYear(yearTimestamp, false).then(
            d => dispatch(loadDataSuccess(d)),
            err => dispatch(loadDataFailed(err))
        );
    }
}

export function loadSleepForDay(dayTimestamp) {
    return (dispatch, getState) => {
        dispatch(startLoadData());
        return SleepAPI.getInstance().getSleepForDayFromCloud(dayTimestamp).then(
            d => dispatch(loadDataSuccess(d)),
            err => dispatch(loadDataFailed(err))
        );
    }
}

export const selectDay = (t) => {
    console.log('-->> SELECT DAY');
    return {
        type: types.SELECT_DAY,
        t: t
    }
}