import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import NiceModal from "../../modals/NiceModal";
import ItemForm from "./ItemForm";
import Spin from "arui-feather/spin";
import pencilImage from "../../notes/panels/clarity_edit-solid.svg";
import trashImage from "../../../assets/images/trash.svg";
import Select from 'react-select'
import {Textarea} from "../../bracelets/forms/UpdateCorsanoPatientForm";

const OPTIONS = [
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'German',
        value: 'de'
    },
    {
        label: 'Dutch',
        value: 'nl'
    },
    {
        label: 'Dutch (Flemish)',
        value: 'fl'
    },
    {
        label: 'French',
        value: 'fr'
    },
    {
        label: 'Spanish',
        value: 'sp'
    }
];

function getLangLabel(l) {
    let arr = OPTIONS.filter(x => (x.value == l));
    if (arr.length == 0) {
        return undefined;
    }
    return arr[0].label;
}

export default function TranslationsEditorTool(props) {
    const {
        loading = false,
        items = [],
        onChange = arr => {

        },
        itemPrefix = `Description `
    } = props;

    console.log('DictionaryEditorTool: items = ', items);

    const [addVisible, setAddVisible] = useState(false);
    const [newLang, setNewLang] = useState(undefined);

    return (
        <Wrapper>

            <ItemsPlaceholder>
                {items.map((a, i) => {
                    return (
                        <div style={{marginBottom: 15}} key={a.id}>
                            <Label>
                                <span>
                                    {`${itemPrefix} [${getLangLabel(a.lang)}]`}
                                </span>
                                <img style={{height: 24, cursor: 'pointer'}} src={trashImage} onClick={() => {
                                    let newItems = items.filter(x => (x.lang != a.lang));
                                    onChange(newItems);
                                }}/>
                            </Label>
                            <Textarea value={a.text} onChange={evt => {
                                let s = evt.target.value;
                                let newItems = items.map((x, j) => (i == j ? {lang: x.lang, text: s} : x));
                                onChange(newItems);
                            }}/>
                        </div>
                    )
                })}
            </ItemsPlaceholder>

            {addVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddVisible(false);
                }}>
                    <InnerPlaceholder>
                        <div>
                            <Label>
                                Language
                            </Label>
                            <Select options={OPTIONS.filter(x => (items.map(xx => xx.lang).indexOf(x.value) == -1))}
                                    onChange={x => {
                                        setNewLang(x.value);
                                    }}/>
                        </div>
                        <div style={{marginTop: 10}}>
                            <Button onClick={() => {
                                if (newLang == undefined) {
                                    return window.alert('Please select language');
                                }
                                onChange(items.concat([{
                                    lang: newLang,
                                    text: ''
                                }]));
                                setNewLang(undefined);
                                setAddVisible(false);
                            }}>
                                Add
                            </Button>
                        </div>

                    </InnerPlaceholder>

                </NiceModal>
            }

            <BottomPlaceholder>
                <AddSpan onClick={() => {
                    setAddVisible(true);
                }}>
                    Add translation
                </AddSpan>
            </BottomPlaceholder>

        </Wrapper>
    );
}

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Item = styled.div`

`;

const InnerPlaceholder = styled.div`
  width: 520px;
  padding: 10px;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
`;


const TopHeading = styled.div`
  color: #000F4B;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 1px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div`

`;

const TopRight = styled.div`

`;

const NoteEditIcon = styled.img`
  height: 22px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const Button = styled.div`
  width: 200px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const AddSpan = styled.span`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const BottomPlaceholder = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemLeft = styled.div`
  flex: 1;
`;

const ItemRight = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const Description = styled.div`
  opacity: 0.8;
`;

const ItemsPlaceholder = styled.div`

`;

const BraceletInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BrLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BrName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
`;

const BrRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContrItem = styled.div`

`;

const normalBorder = `1px solid rgba(135, 152, 173, 0.15)`;
const dangerBorder = `2px solid #FA4646;`;

const BraceletItem = styled.div`
  border-radius: 4px;
  background: #FFFFFF;
  border: ${props => (props.danger == true ? dangerBorder : normalBorder)};
  box-shadow: ${props => (props.danger == true ? '0px 4px 5px rgba(250, 70, 70, 0.05);' : 'none')};
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;