import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import moment from 'moment'
import DoctorTemplate from '../templates/DoctorTemplate'
import DoctorAPI from "../../api/DoctorAPI";
import PatientPanel from "../patients/panels/PatientPanel";
import {useMappedState} from "redux-react-hook";

function CardioMoodIndexApp(props) {
    let {currentUserObj, loading} = useMappedState(useCallback(state => {
        return {
            currentUserObj: state.auth.currentUserObj,
            loading: state.auth.loading
        }
    }, []));
    if (currentUserObj == undefined) {
        return null;
    }

    const {healthUser, usersUser} = currentUserObj;
    if (healthUser == undefined || usersUser == undefined) {
        return null;
    }

    return (
        <DoctorTemplate active={'patients'} >

            <Wrapper>

                <PatientPanel id={usersUser.uuid}
                              hasBackButton={false}
                              hasSendPushButton={false}
                />

            </Wrapper>

        </DoctorTemplate>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default CardioMoodIndexApp
