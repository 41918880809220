import axios from 'axios'
import env from "react-dotenv";

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const RealtimeAPI = {

    async getSettings(uuid) {
        try {
            let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v3/centrifugo/config/${uuid}`, {
                params: {}
            })).data;
            return pld;
        } catch (exc) {

        }
        return [];
    },

    async getHistoryInChannel(centrifugeInstance, channel) {
        try {
            let resp = await centrifugeInstance.history(channel, {limit: 10});
            console.log('getHistoryInChannel: resp = ', resp);
            return resp;
        } catch (exc) {

        }
    },

    async getLastMessageInChannel(centrifugeInstance, channel) {
        try {
            // console.log('getLastMessageInChannel: channel = ', channel);
            let pld = await this.getHistoryInChannel(centrifugeInstance, channel);
            if (pld == undefined) {
                return undefined;
            }
            // console.log('getLastMessageInChannel: pld = ', pld);
            let {publications = []} = pld;
            if (publications.length == 0) {
                return undefined;
            }
            let lastPub = publications[publications.length - 1];
            if (lastPub == undefined) {
                return undefined;
            }
            let {data} = lastPub;
            console.log('returning data = ', data);
            return data;
        } catch (exc) {
            console.log('getLastMessageInChannel: exc = ', exc);
        }
    },

    async getLastMessagesMapForUUIDs(centrifugeInstance, uuids = []) {
        console.log('getLastMessagesMapForUUIDs: uuids = ', uuids);
        let items = await Promise.all(uuids.map(x => this.getLastMessageInChannel(centrifugeInstance, `realtime:${x}`)));
        console.log('getLastMessagesMapForUUIDs: items = ', items);
        let map = {};
        try {
            for (let i in items) {
                let item = items[i];
                let uuid = uuids[i];
                let xItem = {};
                for (let key in item) {
                    let v = item[key];
                    if (v != undefined) {
                        if (window.isNaN(v) == true) {
                            xItem[key] = v;
                        } else {
                            if (key == 't') {
                                xItem[key] = (+v) * 1000;
                            } else {
                                xItem[key] = +v;
                            }
                        }
                    }
                }
                map[uuid] = xItem;
            }
        } catch (exc) {

        }
        console.log('getLastMessagesMapForUUIDs: map = ', map);
        return map;
    },

    async getInitialMapForGroup(code) {
        // console.debug("optimizing async getInitialMapForGroup");
        let arr = [];
        try {
            // arr = (await axios.get(`${DOCTORS_API_ENDPOINT}/centrifugo/group_values/${code}`, {
            arr = (await axios.get(`${DOCTORS_API_ENDPOINT}/v2/centrifugo/group_values/${code}`, {
                params: {}
            })).data;

        } catch (exc) {

        }
        let map = {};
        for (let i in arr){
            let a = arr[i];
            map[a.uuid] = a;
        }
        return map;
    },

    async getInitialMapForGroupV2(code) {
        // console.debug("optimizing async getInitialMapForGroup");
        let arr = [];
        try {
            arr = (await axios.get(`${DOCTORS_API_ENDPOINT}/v2/centrifugo/group_values/${code}`, {
                params: {}
            })).data;

        } catch (exc) {

        }
        let map = {};
        for (let i in arr){
            let a = arr[i];
            map[a.uuid] = a;
        }
        return map;
    },


}

export default RealtimeAPI;