import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import styled from 'styled-components';

function getUrl(recordId){
    return `https://app.ecg.corsano.com/#/report/${recordId}`
}

export default function ViewEcgTool(props) {
    const {uuid, recordId} = props;
    console.log('ViewEcgTool: props = ', props);

    const [loading, setLoading] = useState(false);

    useEffect(() => {

    }, [recordId]);

    return (
        <Wrapper>
            {recordId}

            <div className='content-record' style={{position:"relative"}}>
                <iframe title="ECG-record" width="100%"
                        style={{minHeight: "520px", border: "none", position: "absolute", top: 66}}
                        src={getUrl(recordId)}/>
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;