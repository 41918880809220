import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import moment from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import CommonHelper from "../../../helpers/CommonHelper";
import backImage from "../icons/akar-icons_arrow-back-thick-fill.svg";

import usernameIcon from "../icons/username_icon.svg";
import ls from "local-storage";
import playImg from "../icons/play_img.svg";
import pauseImg from "../icons/stop_img.svg";
import NiceModal from "../../modals/NiceModal";
import PpgExportTool from "../tools/PpgExportTool";
import RRsExportTool from "../tools/RRsExportTool";
import SleepExportTool from "../tools/SleepExportTool";
import ActivityExportTool from "../tools/ActivityExportTool";
import TemperatureExportTool from "../tools/TemperatureExportTool";
import WorkoutsExportTool from "../tools/WorkoutsExportTool";
import ExportServiceTool from "../../export/tools/ExportServiceTool";
import TypesSelector from "../../export/tools/TypesSelector";
import { Button } from "../../trials/panels/StudySessionsPanel";
import { Sidebar } from "arui-feather/sidebar";
import OptimizedDoctorExportPanel, {
  getFolderNameByPatient,
  renderExportProgress,
} from "../../export/panels/OptimizedDoctorExportPanel";
import OptimizedExportAPI from "../../../api/OptimizedExportAPI";
import { useInterval } from "../../apps/RouterApp";
import { toast } from "react-hot-toast";

export default function PatientSingleSessionPanel(props) {
  const { uuid, sessionId, study_tag = "study" } = props;
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(undefined);
  const [patient, setPatient] = useState(undefined);

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [exportToolData, setExportToolData] = useState(undefined);
  const [types, setTypes] = useState([]);
  const [debugVisible, setDebugVisible] = useState(false);

  const [exporting, setExporting] = useState(false);
  const [gettingExportId, setGettingExportId] = useState(false);
  const [exportId, setExportId] = useState();
  const [fetching, setFetching] = useState(false);

  const [exportStatusData, setExportStatusData] = useState(undefined);

  const [exportData, setExportData] = useState();
  const theme = ls.get("theme");

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getPatient(uuid).then((p) => {
      setPatient(p);
      DoctorAPI.getPatientSessionById(uuid, sessionId).then((x) => {
        setSession(x);
        setLoading(false);
      });
    });
  }, [uuid, sessionId]);

  useInterval(() => {
    if (
      exportId == undefined ||
      fetching == true ||
      exportStatusData?.status == "done"
    ) {
      return;
    }
    setFetching(true);
    OptimizedExportAPI.getExportStatus(exportId).then((d) => {
      setExportStatusData(d);
      setFetching(false);
      if (d.status == "done") {
        setExportId(undefined);
        toast("Finished!");
      }
      if (d.status == "no_data") {
        setExportId(undefined);
        toast("No data to export!");
      }
    });
  }, 5000);

  if (loading == true && (session == undefined || patient == undefined)) {
    return (
      <div>
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }

  if (patient == undefined || session == undefined) {
    return null;
  }

  let { first_name, last_name, birthday, healthUser } = patient;
  let { session_code, timestamp_from, timestamp_to, status, description } =
    session;

  let name = `${first_name == undefined ? "-" : first_name} ${
    last_name == undefined ? "-" : last_name
  }`;
  let weight = healthUser == undefined ? undefined : healthUser.weight;
  let height = healthUser == undefined ? undefined : healthUser.height;
  let gender = healthUser == undefined ? undefined : healthUser.gender;

  let isInProgress = timestamp_to == undefined || timestamp_from == undefined;
  let isUploaded = status == "uploaded";

  console.log("PatientSingleSessionPanel: session = ", session);

  return (
    <Wrapper>
      <TopPlaceholder>
        <TopLeftPlaceholder className="heading">
          <BackSquare
            onClick={() => {
              CommonHelper.linkTo(`/user/${uuid}`);
            }}
          >
            <BackImage src={backImage} />
          </BackSquare>
          <PatientName className="heading">{`Session №${session_code}`}</PatientName>
        </TopLeftPlaceholder>
        <TopRightPlaceholder>
          <ExportButton
            onClick={() => {
              setExportModalVisible(true);
            }}
          >
            {`EXPORT DATA`}
          </ExportButton>
        </TopRightPlaceholder>
      </TopPlaceholder>

      <InfoPlaceholder>
        <InfoTopRow className="item-row">
          <InfoBadge>
            <InfoItemIcon src={usernameIcon} />
            <InfoLabel>{name}</InfoLabel>
          </InfoBadge>

          <InfoBadge>
            <InfoItemIcon src={playImg} />
            <InfoLabel>
              {timestamp_from == undefined
                ? ""
                : moment(timestamp_from).format("YYYY-MM-DD | HH:mm")}
            </InfoLabel>
          </InfoBadge>

          <InfoBadge>
            <InfoItemIcon src={pauseImg} />
            <InfoLabel>
              {timestamp_to == undefined
                ? "In progress.."
                : moment(timestamp_to).format("YYYY-MM-DD | HH:mm")}
            </InfoLabel>
          </InfoBadge>

          <InfoBadge style={{ flex: "none" }}>
            <InfoLabel>
              {`Status: `}
              <StatusSpan inProgress={isInProgress} isUploaded={isUploaded}>
                {isInProgress == true
                  ? "In progress..."
                  : status == "uploaded"
                  ? "Uploaded"
                  : "Finished"}
              </StatusSpan>
            </InfoLabel>
          </InfoBadge>
        </InfoTopRow>

        {description == undefined ? null : (
          <InfoTopRow style={{ background: `rgb(135, 152, 173, 0.03)` }}>
            <InfoLabel>{description}</InfoLabel>
          </InfoTopRow>
        )}
      </InfoPlaceholder>

      {exportModalVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setExportModalVisible(false);
          }}
        >
          <HeadingPlaceholder>{`Session ${session_code}`}</HeadingPlaceholder>

          <ExportInnerPlaceholder>
            <OptimizedDoctorExportPanel
              patients={[patient]}
              exportTimestamp={timestamp_from}
              from={timestamp_from}
              to={timestamp_to}
              exportName={`session_${session_code}.zip`}
            />

            {/*<TypesSelector*/}
            {/*    onTypesChange={arr => {*/}
            {/*        setTypes(arr);*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<BottomPlaceholder>*/}
            {/*    <Button disabled={types.length == 0} onClick={async () => {*/}
            {/*        if (types.length == 0) {*/}
            {/*            return;*/}
            {/*        }*/}
            {/*        if (exportId != undefined || gettingExportId == true) {*/}
            {/*            return window.alert('Can not start exporting because there is a running export');*/}
            {/*        }*/}
            {/*        let expData = {*/}
            {/*            types: types,*/}
            {/*            from: timestamp_from,*/}
            {/*            to: timestamp_to,*/}
            {/*            users: [patient],*/}
            {/*            study_tag: study_tag,*/}
            {/*            name: `export.zip`,*/}
            {/*            uuids: [patient?.uuid],*/}
            {/*            user_folder_names: [{*/}
            {/*                user_uuid: patient?.uuid,*/}
            {/*                folder_name: getFolderNameByPatient(patient)*/}
            {/*            }]*/}
            {/*        };*/}
            {/*        setExportData(expData);*/}
            {/*        setGettingExportId(true);*/}
            {/*        setExportStatusData(undefined);*/}
            {/*        let d = await OptimizedExportAPI.createExportOptimized(expData);*/}
            {/*        setExportStatusData(d);*/}
            {/*        setExportId(d?._id);*/}
            {/*        setGettingExportId(false);*/}
            {/*    }}>*/}
            {/*        Export*/}
            {/*    </Button>*/}
            {/*</BottomPlaceholder>*/}

            {/*{timestamp_to == undefined ? null :*/}
            {/*    <div style={{marginTop: 20, opacity: 0.5, textAlign: 'center'}}>*/}
            {/*        <span style={{cursor: 'pointer'}} onClick={() => {*/}
            {/*            setDebugVisible(true);*/}
            {/*        }}>*/}
            {/*            show debug info*/}
            {/*        </span>*/}
            {/*    </div>*/}
            {/*}*/}
          </ExportInnerPlaceholder>
        </NiceModal>
      )}

      {/*{exportToolData == undefined ? null :*/}
      {/*    <NiceModal onClose={() => {*/}
      {/*        setExportToolData(undefined);*/}
      {/*    }}>*/}
      {/*        <LogsInnerPlaceholder>*/}
      {/*            <ExportServiceTool {...exportToolData} />*/}
      {/*        </LogsInnerPlaceholder>*/}
      {/*    </NiceModal>*/}
      {/*}*/}

      {exportData == undefined ? null : (
        <NiceModal
          onClose={() => {
            setExportData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            {gettingExportId == false ? null : (
              <div>{"Starting export..."}</div>
            )}

            {exportStatusData == undefined ? null : (
              <div>
                <div>
                  {renderExportProgress(
                    exportStatusData,
                    [patient],
                    `export.zip`
                  )}
                </div>
              </div>
            )}

            {/*<ExportServiceTool {...exportData} />*/}
          </LogsInnerPlaceholder>
        </NiceModal>
      )}

      {debugVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setDebugVisible(false);
          }}
        >
          <InnerPlaceholderRaw>
            <WiffRow>
              <a
                href={`https://api.study-integration.corsano.com/health/user/${uuid}/continuous-measurements/ppg2/${timestamp_from}/${timestamp_to}/wiff`}
                target={"_blank"}
              >
                ppg2.wiff
              </a>
            </WiffRow>

            <WiffRow>
              <a
                href={`https://api.study-integration.corsano.com/health/user/${uuid}/continuous-measurements/acc/${timestamp_from}/${timestamp_to}/wiff`}
                target={"_blank"}
              >
                acc.wiff
              </a>
            </WiffRow>

            <WiffRow>
              <a
                href={`https://api.study-integration.corsano.com/health/user/${uuid}/continuous-measurements/bioz/${timestamp_from}/${timestamp_to}/wiff`}
                target={"_blank"}
              >
                bioz.wiff
              </a>
            </WiffRow>
          </InnerPlaceholderRaw>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const InnerPlaceholderRaw = styled.div`
  padding: 20px;
  background: white;
  width: 320px;
  text-align: center;
`;

const WiffLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }
`;

const WiffRow = styled.div`
  margin-bottom: 10px;
`;

const BottomPlaceholder = styled.div`
  margin-top: 20px;
`;

const LogsInnerPlaceholder = styled.div`
  width: 560px;
  box-sizing: border-box;
  max-height: calc(90vh - 100px);
  overflow-y: auto;
`;

const HeadingPlaceholder = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: #000f4b;
  font-weight: bold;
  font-size: 24px;
`;

const ExportRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const ExportInnerPlaceholder = styled.div`
  width: 320px;
`;

const StatusSpan = styled.span`
  width: 140px;
  margin-left: 5px;
  color: ${(props) =>
    props.inProgress == true
      ? "#147AFF"
      : props.isUploaded == true
      ? "#01C2A9"
      : "#8798AD"} !important;
`;

const InfoLabel = styled.div`
  color: #000f4b;
  font-size: 18px;
  line-height: 18px;
  @media (max-width: 920px) {
    font-size: 14px;
  }
  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

const InfoItemIcon = styled.img`
  margin-right: 17px;
  height: 25px;
  opacity: 0.5;
  @media (max-width: 920px) {
    margin-right: 4px;
    height: 17px;
    padding-right: 4px;
  }
`;

const InfoBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const InfoTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 20px;
`;

const InfoPlaceholder = styled.div`
  margin-top: 25px;
  @media (max-width: 880px) {
  }
`;

const ExportButton = styled.div`
  background: #147aff;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div``;

const BackSquare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  width: 40px;
  height: 40px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const PatientName = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
`;

const BackImage = styled.img`
  height: 22px;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;
