import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { Input, Button } from "../ui";

import Spin from "arui-feather/spin";
import { set } from "local-storage";

const USER_CLOUD_API = "https://api.users.cloud.corsano.com";
const SEND_VERIFICATION_CODE = "Send Verification Code";
const CODE_SENT = "Code Sent!";

export default function LoginByCodeForm(props) {
  // Discarded
  const { onSubmit = () => {}, loading = false } = props;
  const [email, setEmail] = useState("");
  const [emaiIsValid, setEmailIsValid] = useState(false);
  const [buttonHit, setButtonHit] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);

  const emailValidator = (email) => {
    let emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  return (
    <Wrapper>
      <TopPlaceholder></TopPlaceholder>

      <SubTopPlaceholder></SubTopPlaceholder>
      <ContentPlaceholder style={{ display: "block" }}>
        {!codeSent ? (
          <Field>
            <Input
              placeholder={"Email"}
              autofill={false}
              value={email}
              type={"email"}
              onChange={(e) => {
                setButtonHit(false);
                setEmail(e.target.value);
                setEmailIsValid(emailValidator(e.target.value));
                console.log("emaiIsValid", emaiIsValid);
              }}
            />
            {buttonHit && !emaiIsValid ? (
              <span
                style={{
                  opacity: "80%",
                  float: "right",
                  fontSize: "small",
                }}
              >
                Email is not valid
              </span>
            ) : null}
            <Button
              style={{ marginTop: "1.5rem" }}
              onClick={() => {
                setCodeSent(false);
                console.log("buttonHit", buttonHit);

                setButtonHit(true);

                axios
                  .post(`${USER_CLOUD_API}/auth/send-login-verification-code`, {
                    email: email,
                  })
                  .then((response) => {
                    console.log(response);
                    console.log("Verification code sent successfully");
                    setCodeSent(true);
                  })
                  .catch((error) => {
                    console.error("Error sending verification code:", error);
                  });
              }}
            >
              {codeSent ? CODE_SENT : SEND_VERIFICATION_CODE}
            </Button>
          </Field>
        ) : null}
        {codeSent ? (
          <Field>
            <Field>
              <Input
                disabled={!emaiIsValid}
                value={emaiIsValid ? verificationCode : ""}
                // style={
                //   !emaiIsValid
                //     ? { background: "#c8c8c8", cursor: "not-allowed" }
                //     : {}
                // }
                type={"text"}
                placeholder={"Verification code"}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSubmit({ email: email.toLowerCase(), verificationCode });
                  }
                }}
                onChange={(e) => {
                  // setButtonHit(false);
                  setVerificationCode(e.target.value);
                }}
                autofill={false}
              />
              <div style={{ display: "block" }}>
                <span
                  style={{
                    marginBottom: "1rem",
                    float: "right",
                    cursor: "pointer",
                    fontSize: "small",
                    textDecoration: "underline",
                    opacity: "80%",

                    // background: "gray",
                  }}
                  onClick={() => {
                    setButtonHit(false);
                    setEmail("");
                    setCodeSent(false);
                    setVerificationCode("");
                    setEmailIsValid(false);
                  }}
                >
                  use another email
                </span>
                <Button
                  style={{
                    width: "100%",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                    marginRight: "1rem",
                  }}
                  onClick={() => {
                    onSubmit({
                      email: email.toLowerCase(),
                      password: verificationCode,
                    });
                  }}
                >
                  Sign in
                </Button>
              </div>
            </Field>
          </Field>
        ) : null}

        {/*<div style={{padding: 10, paddingBottom: 20, fontSize: '13px', color: 'red'}} >*/}
        {/*    Too many login attempts.*/}
        {/*    Please try again after 60 minutes.*/}
        {/*</div>*/}
      </ContentPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 400px;
  height: 300px;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SubTopPlaceholder = styled.div`
  color: #202224;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.0642857px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 25px;
`;

const TopPlaceholder = styled.div`
  width: 100%;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 46px;
  color: #465e6f;
  text-align: center;
`;

const ContentPlaceholder = styled.div``;

const Field = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
  opacity: 0.8;
  letter-spacing: -0.0642857px;

  color: #202224;
`;

const Link = styled.div`
  color: #999999;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;

  :hover {
    opacity: 0.8;
  }
`;
