import { useState } from "react";
import BasicPasswordRecoveryForm from "../auth/forms/BasicPasswordRecoveryForm";
import PasswordRecoveryForm from "../auth/forms/PasswordRecoveryForm";
import styled from "styled-components";
import UiHelper from "../../helpers/UiHelper";
import CommonHelper from "../../helpers/CommonHelper";
import doctorsImg from "../../assets/images/doctors_login_gr3.jpg";

function PasswordRecoveryApp(props) {
  return (
    <Wrapper>
      <InnerWrapper2Sides>
        <Left>
          <LeftBackgroundImage src={doctorsImg} />
        </Left>

        <Right>
          <ClinicLogo
            onClick={() => {
              CommonHelper.linkTo("/");
            }}
            src={UiHelper.getTemplateLogoImage()}
            style={{ marginBottom: "1rem", cursor: "pointer" }}
          />

          <h2>Reset Your Password</h2>
          <BasicPasswordRecoveryForm />
        </Right>
      </InnerWrapper2Sides>
    </Wrapper>
  );
}

export default PasswordRecoveryApp;

// const Wrapper = styled.div`
//   background: white;
//   padding: 1rem;
//   width: 100vw;
//   height: 100vh;
//   box-sizing: border-box;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Left = styled.div`
  flex: 1;
  background: white;
`;

const LeftBackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url("${(props) => props.src}");
`;

const Right = styled.div`
  width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafafb;
  @media (max-width: 920px) {
    width: 60vw;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const InnerWrapper2Sides = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100vw;
`;

const ClinicLogo = styled.img`
  margin: 0 auto;
  height: 45px;
`;
