import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import GaitViewTool from "./GaitViewTool";

export default function SummaryActivityGaitViewTool(props) {
  let { avg_gait_asymmetry, avg_gait_speed, avg_gait_var } = props;

  // console.log(
  //   "SummaryActivityGaitViewTool: render: avg_gait_speed = ",
  //   avg_gait_speed,
  //   ", avg_gait_var = ",
  //   avg_gait_var,
  //   ", avg_gait_asymmetry = ",
  //   avg_gait_asymmetry,
  //   ", props = ",
  //   props
  // );

  return (
    <Wrapper className="gait-view">
      <Triplet style={{ marginRight: 20 }} >
        <GaitViewTool type={"speed"} value={avg_gait_speed} />
      </Triplet>

      <Triplet style={{ marginLeft: 20, marginRight: 20 }}>
        <GaitViewTool type={"variability"} value={avg_gait_var} />
      </Triplet>

      <Triplet style={{ marginLeft: 20 }}>
        <GaitViewTool type={"asymmetry"} value={avg_gait_asymmetry} />
      </Triplet>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Triplet = styled.div`
  box-sizing: border-box;
  padding: 4px;
`;
